import { IDeliveredTaxDocument, IDeliveredTaxReturns } from "../../components/common/DeliveredTaxReturns";
import { ISignerModel, ITaxReturn, initialTaxReturnState } from "../../components/common/TaxReturn";
import { DefaultDownloadInfoType } from "../../components/navigation/profile/MySettings";
import { IFilters, QuickReportType } from "../../components/reports/Filters";
import { IGroupSettingsModel } from "../groupedreturns/GroupedReturnProcessState";
import { IColumnSettings } from "./ColumnSettings/ColumnSettingsState";
import {
    DeliveredGroupedReturnsModel,
    IPopupStateGroupInfo
} from "./GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsState";
import { IPopupStateDeliveredTaxReturnSingle } from "./GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsState";

export interface ISignatureStatusList {
    value: number;
    name: string;
}

export interface DeliveredReturnsTableModel {
    count: number;
    documents: IDeliveredTaxDocument[];
}

export enum ReturnStatusList {
    None = 0,
    Esigned,
    ManuallySigned,
    Uploaded,
    AwaitingEsign,
    AwaitingUpload,
    Locked,
    MailFax,
    SignedESigned,

    AutoParsed,
    Processing,
    Delivering,
    Delivered,
    DeliveryFailed
}

export interface DeliveredReturnsState {
    deliveredReturnTableModel: DeliveredReturnsTableModel;
    query: string;
    totalRowCount: number;
    // quickReportState: QuickReportState;
    loading: boolean;
}
export interface RowState {
    showClientInfo: boolean;
    docId: number;
}
export interface DeliveredReturnsPageState {
    page: number;
    pageSize: number;
    selectedRows: number[];
    filter: IFilters;
    searchString: string;
    sortName: string;
    sortOrder: string;
    filterTaxYear: any;
    saveFilterShow: boolean;
    filterName: string;
    filterClientId: string;
    filterPartner: string;
    filterStatus: any;
    filterLocation: any;
    filterDeliveredOn?: Date;
    filterEngagementType: number;
    filterCustomColumn: string;
    filterDownloadedBy: string;
    filterLastReminderOn?: Date;
    filterRetentionPeriod?: Date;
    filterSentBy: string;
    hideCustomCulumnLoader: boolean;
    selectedStatus: number;
    signatureStatusList: ISignatureStatusList[];
    selectedColumnValue: number;
    isBulkSelectionEnabled: boolean;
    showBulkSelectionMessage: boolean;
    deSelectedRows: number[];
    columnSettings: IColumnSettings[];
    isSelectAllChecked: boolean;
    screenShareOtp: string;
    selectedDownloadType: DefaultDownloadInfoType;
    selectedDefaultDownloadType: boolean;
    showQuickReports: boolean;
    selectedQuickReport: QuickReportType;
    defaultGroup: string;
    loadExcelSpinner: boolean;
    filterDocumentFilingType: number;
    filterGroupType: number;

    //Popups
    editClientInfoState: IPopupStateSingle;
    setAccessState: IPopupStateMultiple;
    reportProblemState: IPopupStateSingle;
    groupReportProblemState: IPopupStateSingle;
    deleteReturnsState: IPopupStateSingle;
    archiveReturnsState: IPopupStateMultiple;
    changeStatusState: IPopupStateMultiple;
    customColumnState: IPopupStateMultiple;
    DownloadTypeOptionState: IPopupStateMultiple;
    resendAccessLinkState: IPopupStateSingle;
    groupedReturnResendAccessLinkState: IGroupReturnPopupState;
    clientTrackingState: IPopupStateSingle;
    recallReturnState: IPopupStateSingle;
    reprocessReturnState: IPopupStateSingle;
    voucherReminderState: IPopupStateSingle;
    accessCodeState: IPopupStateSingle;
    groupedReturnAccessCodeState: IGroupReturnPopupState;
    retentionPolicyState: IPopupStateSingle;
    downloadReturnState: IPopupStateSingle;
    downloadEformFilesState: IPopupStateSingle;
    uploadFormsState: IPopupStateSingle;
    signedDetailsState: IPopupStateSingle;
    downloadHistoryState: IPopupStateSingle;
    clientViewState: IPopupStateSingle;
    showLoader: boolean;
    restoreReturnsState: IPopupStateMultiple;
    screenShareState: IPopupStateSingle;
    refreshDelay?: boolean;
    uploadRevisionDocumentState: IPopupStateSingle;
    selectedDocumentGuid: string;
    editClientSaveState: IEditClientSaveState;
    unlockReturnState: IUnlockReturnModalState;
    sendReminderState: ISendReminderState;
    changeOfficeLocationState: IPopupStateMultiple;
    editGroupInfoState: IPopupStateGroupInfo;
    rowChangeStatusState: IPopupStateDeliveredTaxReturnSingle;
    groupSendReminderState: IPopupVoucherReminderState;
    controllerViewState: IPopupStateSingle;
}

export interface ISendReminderState extends IPopupState {
    selectedDocumentIds: number[] | undefined;
    bulkReturnsSelected: ITaxReturn[] | undefined;
    individualReturnSelected: ITaxReturn | undefined;
    isBulkSelectionEnabled: boolean;
    isSigningReminderEnabled: boolean;
    totalCount: number;
    isGroupedReturnExists: boolean;
}

export interface IDeliveredReturnsDictionary {
    [index: string]: DeliveredReturnsState;
}

export interface IPopupState {
    show: boolean;
}
export interface IPopupStateSingle extends IPopupState {
    model: ITaxReturn | undefined;
    saveCheckId: number | undefined;
}
export interface IPopupStateSingleGroup extends IPopupState {
    model: DeliveredGroupedReturnsModel | undefined;
    saveCheckId: number | undefined;
}
export interface IPopupStateMultiple extends IPopupState {
    selected: ITaxReturn[] | undefined;
    saveCheckIds: number[] | undefined;
    remove: boolean;
}

export interface IGroupReturnPopupState extends IPopupState {
    model: IDeliveredTaxReturns | undefined;
}

export interface IPopupVoucherReminderState extends IPopupState {
    model: IDeliveredTaxReturns | undefined;
    groupSettings: IGroupSettingsModel | undefined;
}

export interface ISelectedDocument {
    id: number;
    isfullyLoaded: boolean;
}

export interface IEditClientSaveState {
    id: number;
    taxReturn: ITaxReturn;
    isMailSend: boolean;
    clientGUIDs: string[];
    isEmailUpdated: boolean;
    signerInfo: ISignerModel | undefined;
    callback?: () => void;
}

export const intialEditClientSaveState: IEditClientSaveState = {
    id: 0,
    taxReturn: initialTaxReturnState,
    isEmailUpdated: false,
    isMailSend: false,
    clientGUIDs: [],
    signerInfo: undefined
};

export interface IUnlockReturnModalState {
    show: boolean;
    clients: string;
    documentId: number;
}
