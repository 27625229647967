export const EngagementList: any = {
    1: '1040',
    2: '1065',
    3: '1120',
    4: '1120S',
    5: '1041',
    6: '1040NR',
    7: "990/T",
    8: "990PF",
    9: "990EZ"
}

export const SignatureStatusList: any = {
    1: 'E-SIGNED',
    2: 'MANUALLY SIGNED',
    3: 'UPLOADED',
    4: 'AWAITING E-SIGN',
    5: 'AWAITING UPLOAD',
    8: 'SIGNED AND E-SIGNED',
    17: 'ALTERNATE DELIVERY',
    20: 'PARTIALLY SIGNED',
    22: 'E-SIGNED – IN OFFICE',
    //19: 'PRINTED AND UPLOADED'

}

export const SignatureStatusOptionsList = [
    { label: 'E-SIGNED', value: 1 },
    { label: 'MANUALLY SIGNED', value: 2 },
    { label: 'UPLOADED', value: 3 },
    { label: 'AWAITING E-SIGN', value: 4 },
    { label: 'AWAITING UPLOAD', value: 5 },
    { label: 'SIGNED AND E-SIGNED', value: 8 },
    { label: 'ALTERNATE DELIVERY', value: 17 },
    { label: 'PARTIALLY SIGNED', value: 20 },
    { label: 'E-SIGNED – IN OFFICE', value: 22 },
    //{ label: 'PRINTED AND UPLOADED', value: 19 }
];


export enum DateRangeOptions {
    None = 0,
    Last7Days = 1,
    LastMonth = 2,
    LastYear = 3,
    MonthToDate = 4,
    YearToDate = 5,
    Custom = 6
}

export interface SelectOptions {
    value: number,
    label: string,
}