import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { DeliveredReturnsState, DeliveredReturnsTableModel } from './DeliveredReturnsState';
import { ISignerModel, ClientTypes, SignatureStatus, TaxDocumentRevisionStatus, TaxClients } from '../../components/common/TaxReturn';
import { IDeliveredTaxDocument, IDeliveredTaxReturns } from '../../components/common/DeliveredTaxReturns';
import {
	RequestTaxDocumentAction, ReceiveTaxDocumentAction, ArchiveTaxDocumentAction, TaxReturnSource,
	UpdateTaxDocumentCustomColumnValueAction, SendReminderTaxDocumentAction, RecallTaxDocumentAction,
	ResetDeliveredReturnsAction, IProcessReturnViewModel, ReceiveTaxDocumentsAction
} from '../common/TaxDocumentStore';
import { ITaxReturn } from '../../components/common/TaxReturn';
import { actionTypes } from '../../types/ActionTypes';
import {
	RequestDeliveredReturnsAction, ReceiveDeliveredReturnsAction, ReceiveDeliveredReturnsPagesAction, RequestDeliveredReturnsPagesAction,
	ReceiveNextSignerDetailsAction, UnlockLockedDocument, DiscardRevisedTaxDocumentAction, UpdateRevisedTaxDocumentStatusAction,
	ReceiveUpdatedRevisedTaxDocumentStatusAction
} from "./KnownTypes";
import { handleBlobResponse, handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { DeliveredReturnsConstants, PreviewConstants, FinishProcessReturn } from '../../components/helper/Constants';
import { HideLoader } from '../../components/helper/Loader';
import { encodeTaxDocumentHtmlTags, openWindowWithPostRequest } from '../../components/helper/HelperFunctions';
import { API_BASE_URL } from '../../utils/constants';
import { getRequestVerificationToken } from '../../oidcClient/helpers'
import * as CRSHelper from '../../components/helper/CRSHelper'
import { ReceiveControllerAccessLink, ReceiveGroupOTPViewModelAction } from './GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsStore';

type KnownAction = RequestDeliveredReturnsAction | ReceiveDeliveredReturnsAction | ArchiveTaxDocumentAction
	| RecallTaxDocumentAction | ReceiveTaxDocumentAction | UpdateTaxDocumentCustomColumnValueAction
	| ResetDeliveredReturnsAction | SendReminderTaxDocumentAction | DiscardRevisedTaxDocumentAction |
    UpdateRevisedTaxDocumentStatusAction | ReceiveUpdatedRevisedTaxDocumentStatusAction |
    ReceiveGroupOTPViewModelAction | ReceiveControllerAccessLink;

type AllKnownAction =
	RequestDeliveredReturnsAction |
	ReceiveDeliveredReturnsAction |
	ReceiveTaxDocumentAction |
	ReceiveTaxDocumentsAction |
	ArchiveTaxDocumentAction |
	RecallTaxDocumentAction |
	RequestTaxDocumentAction |
	ReceiveDeliveredReturnsPagesAction |
	RequestDeliveredReturnsPagesAction |
	UpdateTaxDocumentCustomColumnValueAction |
	NotificationAction |
	ReceiveNextSignerDetailsAction |
	UnlockLockedDocument |
	ResetDeliveredReturnsAction |
	DiscardRevisedTaxDocumentAction |
	ReceiveUpdatedRevisedTaxDocumentStatusAction;

export const actionCreators = {
	requestDeliveredReturns: (query: string, reload: boolean = false, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		let state = getState();
		if (reload || query !== state.deliveredReturns.query) {

			let page = state.deliveredReturnsPages[query];


			if (!reload && page) {
				dispatch({ type: actionTypes.REQUEST_DELIVERED_RETURNS, query: query });
				dispatch({ type: actionTypes.RECEIVE_DELIVERED_RETURNS, query: query, table: page.deliveredReturnTableModel });
				return;
			}

			const fetchTask = fetch(API_BASE_URL + 'api/Reports/DeliveredReturns/GetDeliveredReturns' + query, {
				method: 'GET',
				credentials: 'include'
			})
				.then(handleResponse)
				.then(response => response as Promise<DeliveredReturnsTableModel>)
				.then(data => {

					let documents: ITaxReturn[] = [];

					data.documents.forEach((model, i) => {
						documents.push(model.document);
					});

					dispatch({
						type: actionTypes.RECEIVE_TAX_DOCUMENTS, taxDocuments: documents,
						source: TaxReturnSource.DeliveredReturns
					});

					dispatch({ type: actionTypes.RECEIVE_DELIVERED_RETURNS, query: query, table: data });
					dispatch({ type: actionTypes.RECEIVE_DELIVERED_RETURNS_PAGES, query: query, table: data, totalRowCount: data.count });
					if (callback) {
						callback();
					}
				})
				.catch((error) => {
					const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {
						dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
					}
					if (callback) {
						callback();
					}
				});
			addTask(fetchTask);
			dispatch({ type: actionTypes.REQUEST_DELIVERED_RETURNS, query: query });
			dispatch({ type: actionTypes.REQUEST_DELIVERED_RETURNS_PAGES, query: query });
		}
	},
	updateTaxDocumentCustomColumnValue: (id: number, customColumn: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE, id: id, customColumn: customColumn });
	},
	exportDeliveredReturnsAsExcel: (query: string, callback?: () => void, resourceId:string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/ExportToExcel/ExportExcelDeliveredReturns' + query, { credentials: 'include', headers:{'X-Resource-Id':resourceId} })
			.then(handleBlobResponse)
			.then(blob => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				const urlParams = new URLSearchParams(query);
				const fileName = urlParams.has('isArchived') && urlParams.get('isArchived') == 'true'
					? 'ArchivedAssignments.xlsx'
					: 'DeliveredAssignments.xlsx';
				link.setAttribute('download', fileName);
				link.id = "download_link";
				link.target = '_blank';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				if (callback) {
					callback();
				}
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
				if (callback) {
					callback();
				}
			});
		addTask(fetchTask);
    },
    requestDeliveredReturnClientNames: (isArchived: boolean, callback: (clientName: string[]) => void): AppThunkAction<AllKnownAction> => (dispatch, getstate) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/DeliveredReturns/GetDeliveredReturnClientNamesAsync?isArchive=' + isArchived, {
            method: 'GET',
            credentials: 'include',
        })
            .then(handleResponse).then(data => {
                callback(data);
            })
            .catch((error: any) => {
                console.log(error);
            });

        addTask(fetchTask);
    },
	generateTaxpayerView: (taxdocument: ITaxReturn, clientType?: ClientTypes,
		resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getstate) => {
		encodeTaxDocumentHtmlTags(taxdocument);
		let modelData: IProcessReturnViewModel = { taxDocument: taxdocument, parts: [], isK1Replaced: false, isK1Restored: false, isMFJChanged: false, clientType: ClientTypes.Undefied };
		modelData.taxDocument = taxdocument;
		modelData.parts = [];
		modelData.clientType = clientType;
		const formData = new FormData();
		formData.append('taxDocument', JSON.stringify(modelData));
		let options: any = {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'X-Resource-Id':resourceId,
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			},
			body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(modelData))
		};
		const fetchTask = fetch(API_BASE_URL + 'api/ProcessReturn/GenerateTaxpayerViewAsync', options)
			.then(response => response.json())
			.then((data) => {
				HideLoader();
				openWindowWithPostRequest(data.url, getRequestVerificationToken(), 'CPAToken', PreviewConstants.Scope.ClientView, null, getstate().userProfile?.userId);
			})
			.catch((error: any) => {
				console.log(error);
			});
		addTask(fetchTask);
	},
	requestNextSignerDetails: (taxDocumentId: number, signatureStatus: SignatureStatus): AppThunkAction<AllKnownAction> => (dispatch, getstate) => {
		const fetchTask = fetch(API_BASE_URL + 'api/Reports/DeliveredReturns/GetNextSignerDetailsAsync/' + taxDocumentId + '/' + signatureStatus, {
			method: 'GET',
			credentials: 'include',
		})
			.then(handleResponse)
			.then(response => response as Promise<ISignerModel>)
			.then(data => {
				dispatch({ type: actionTypes.RECEIVE_NEXT_SIGNER_DETAILS, id: taxDocumentId, nextSignerDetails: data });
			})
			.catch((error: any) => {
				console.log(error);
			});

		addTask(fetchTask);
	},
    unlockDocument: (documentid: number, client: TaxClients, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/DeliveredReturns/UnlockDocument/' + documentid + '/' + client, {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json; charset=utf-8',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			}
		})
			.then(handleResponse)
			.then(() => {
				dispatch({ type: actionTypes.UNLOCK_LOCKED_DOCUMENT, documentId: documentid });
				dispatch({ type: actionTypes.NOTIFICATION, statusMessage: DeliveredReturnsConstants.StatusMessage.UnlockDocumentSuccess, statusType: StatusType.Success });
				if (callback) {
					callback();
				}
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
			});
		addTask(fetchTask);
	},
	updateDeliveredReturns: (): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
		let state = getState();
		dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
		dispatch(actionCreators.requestDeliveredReturns(state.deliveredReturns.query, true));
	},
	updateDeliveredReturnRevisionStatus: (documentGuid: string, documentId: number): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
		let state = getState();
		//if (state.taxDocuments[documentId]) { // notifcationmessage not contain documentid in case voucher rivision 
		//	dispatch(actionCreators.requestUpdatedRevisionDocumentStatus(state.deliveredReturns.query, documentGuid));
		//}
		if (state.deliveredReturns.query && state.deliveredReturns.query != "") {
			dispatch(actionCreators.requestUpdatedRevisionDocumentStatus(state.deliveredReturns.query, documentGuid));
		}
	},
	updateArchivedReturnsRevisionStatus: (documentGuid: string, documentId: number): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
		let state = getState();
		//if (state.taxDocuments[documentId]) { // notifcationmessage not contain documentid in case voucher rivision 
		//	let archiveReportQuery = state.deliveredReturns.query;
		//	archiveReportQuery = archiveReportQuery.replace("isArchived=false", "isArchived=true");
		//	dispatch(actionCreators.requestUpdatedRevisionDocumentStatus(archiveReportQuery, documentGuid));
		//}
		if (state.deliveredReturns.query && state.deliveredReturns.query != "") {
			let archiveReportQuery = state.deliveredReturns.query;
			archiveReportQuery = archiveReportQuery.replace("isArchived=false", "isArchived=true");
			dispatch(actionCreators.requestUpdatedRevisionDocumentStatus(archiveReportQuery, documentGuid));
		}
	},
	requestScreenShareUrl: (clientGuid: string, controllerGuid?: string, resourceId:string=""): AppThunkAction<AllKnownAction> => (dispatch, getstate) => {
		
		const fetchTask = fetch(API_BASE_URL + 'api/Common/GetScreenShareUrl/' + clientGuid, {
			method: 'GET',
            credentials: 'include',
            headers: { 'X-Resource-Id': resourceId }
		}).then(response => response.json())
			.then(data => {
				HideLoader();
				openWindowWithPostRequest(data.url, getRequestVerificationToken(), 'CPAToken', PreviewConstants.Scope.ClientView, controllerGuid, getstate().userProfile?.userId);
			})
			.catch((error: any) => {
				console.log(error);
			});

		addTask(fetchTask);
	},
	requestGroupedReturnScreenShareUrl: (clientGuid: string, controllerGuid?: string,resourceId:string=""): AppThunkAction<AllKnownAction> => (dispatch, getstate) => {
		
		const fetchTask = fetch(API_BASE_URL + 'api/Common/GetGroupedReturnScreenShareUrl/' + clientGuid, {
			method: 'GET',
			credentials: 'include',
			headers:{
				'X-Resource-Id':resourceId,
			}
		})
			.then(response => response.json())
			.then(data => {
				HideLoader();
				openWindowWithPostRequest(data.url, getRequestVerificationToken(), 'CPAToken', PreviewConstants.Scope.ClientView, controllerGuid, getstate().userProfile?.userId);
			})
			.catch((error: any) => {
				console.log(error);
			});

		addTask(fetchTask);
	},
	generateTimeBasedOTP: (clientGUID: string, rowIndex: number, callBack?: (rowIdx: number, otp: string) => void, resourceId:string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/OTP/GetTimeBasedOtp/' + clientGUID, {
			method: 'GET',
            credentials: 'include',
            headers: { 'X-Resource-Id': resourceId }
		})
			.then(handleResponse)
			.then(response => response as Promise<string>)
			.then(data => {
				if (callBack) {
					callBack(rowIndex, data);
				}
			})
			.catch(error => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
			});
		addTask(fetchTask);
	},

	discardRevisedTaxDocuments: (id: number, callback?: () => void, resourceId:string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
		let options: any = {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId
			},
		};
		const fetchTask = fetch(API_BASE_URL + 'api/RevisedTaxDocument/DiscardRevisedTaxDocument/' + id, options)
			.then(handleResponse)
			.then((data) => {
				if (data) {
					dispatch({ type: actionTypes.RECEIVE_DISCARDED_REVISED_TAXDOCUMENT_STATE, taxDocumentId: id });
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: FinishProcessReturn.StatusMessage.DiscardRevisedTaxDocumentSuccess,
						statusType: StatusType.Success
					});
					callback && callback();
				}
				else {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: FinishProcessReturn.StatusMessage.DiscardRevisedTaxDocumentError,
						statusType: StatusType.Error
					});
				}
			})
			.catch(error => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: FinishProcessReturn.StatusMessage.DiscardRevisedTaxDocumentError,
					statusType: StatusType.Error
				});
			});
		addTask(fetchTask);
	},

    discardUnrecognizedRevisedTaxDocuments: (id: number, resourceId: string = ""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
		let options: any = {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId
			},
		};
		const fetchTask = fetch(API_BASE_URL + 'api/RevisedTaxDocument/DiscardUnRecognizedTaxDocument/' + id, options)
			.then(handleResponse)
			.then(() => {
			})
			.catch(error => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
			});
		addTask(fetchTask);
	},

	updateReviseDocumentStatus: (id: number, status: TaxDocumentRevisionStatus): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.UPDATE_REVISED_TAXDOCUMENT_STATUS, taxDocumentId: id, status: status });
	},
	requestUpdatedRevisionDocumentStatus: (query: string, documentGuid: string): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/Reports/DeliveredReturns/GetDeliveredReturns' + query, {
			method: 'GET',
			credentials: 'include'
		})
			.then(handleResponse)
			.then(response => response as Promise<DeliveredReturnsTableModel>)
			.then(data => {

				let documents: ITaxReturn[] = [];

				data.documents.forEach((model, i) => {
					documents.push(model.document);
				});

				dispatch({ type: actionTypes.RECEIVE_UPDATED_REVISED_TAXDOCUMENT_STATUS, tableData: data, documentGuid: documentGuid });
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
			});
		addTask(fetchTask);
	},
};

const unloadedState: DeliveredReturnsState = {
	deliveredReturnTableModel: {
		documents: [],
		count: 0
	} as DeliveredReturnsTableModel,
	loading: true,
	query: '',
	totalRowCount: 0
} as DeliveredReturnsState;

const unloadedDeliveredTaxDocument: IDeliveredTaxDocument = {
	customColumn: '',
	document: {},
	downloadsCount: 0,
	lastReminderOn: undefined,
	retentionPeriod: undefined,
	signedCount: 0,
	bulkDownloadCount: 0
} as IDeliveredTaxDocument;


export const reducer: Reducer<DeliveredReturnsState> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case actionTypes.REQUEST_DELIVERED_RETURNS:
			return ({
				...unloadedState,
				query: action.query,
				loading: true
			}) as DeliveredReturnsState;
		case actionTypes.RECEIVE_DELIVERED_RETURNS:
			return {
				query: action.query,
				deliveredReturnTableModel: action.table,
				totalRowCount: action.table.count,
				loading: false
			} as DeliveredReturnsState;
		case actionTypes.RECEIVE_TAX_DOCUMENT:
		case actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE:
		case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
			return updateState(action.type, state, action);

		case actionTypes.ARCHIVE_TAX_DOCUMENT:
		case actionTypes.RECALL_TAX_DOCUMENT:
		case actionTypes.RESET_DELIVERED_RETURNS:
			return clearTaxReturns(state);

		case actionTypes.RECEIVE_DISCARDED_REVISED_TAXDOCUMENT_STATE:
			let discardedRevisedTaxDocumentState = { ...state };
			const index = discardedRevisedTaxDocumentState.deliveredReturnTableModel.documents.findIndex(x => x.document.id == action.taxDocumentId);
			let deliveredDocument = discardedRevisedTaxDocumentState.deliveredReturnTableModel.documents[index];
			if (index > -1) {
				deliveredDocument.hasRevisionDocumentReady = false;
				deliveredDocument.hasUploadedRevisionDocument = false;
			}
			discardedRevisedTaxDocumentState.deliveredReturnTableModel.documents[index] = deliveredDocument;
			return discardedRevisedTaxDocumentState;

		case actionTypes.UPDATE_REVISED_TAXDOCUMENT_STATUS:
			let mergedRevisedTaxDocumentState = { ...state };
			const docIndex = mergedRevisedTaxDocumentState.deliveredReturnTableModel.documents.findIndex(x => x.document.id == action.taxDocumentId);
			if (docIndex > -1) {
				if (action.status == TaxDocumentRevisionStatus.Merged) {
					mergedRevisedTaxDocumentState.deliveredReturnTableModel.documents[docIndex].hasRevisionDocumentMerged = true;
				}
				else if (action.status == TaxDocumentRevisionStatus.DeliveryInProgress) {
					mergedRevisedTaxDocumentState.deliveredReturnTableModel.documents[docIndex].hasRevisionDocumentReady = false;
					mergedRevisedTaxDocumentState.deliveredReturnTableModel.documents[docIndex].hasUploadedRevisionDocument = false;
				}
			}
			return mergedRevisedTaxDocumentState;

		case actionTypes.RECEIVE_UPDATED_REVISED_TAXDOCUMENT_STATUS:
			let deliveredReportState = { ...state };
			const reportIndex = deliveredReportState.deliveredReturnTableModel.documents.findIndex(x => x.document.documentGuid == action.documentGuid);
			const taxreturnIndex = action.tableData.documents.findIndex(x => x.document.documentGuid == action.documentGuid);
			if (reportIndex > -1 && taxreturnIndex > -1) {
				deliveredReportState.deliveredReturnTableModel.documents[reportIndex].document = action.tableData.documents[taxreturnIndex].document;
				deliveredReportState.deliveredReturnTableModel.documents[reportIndex].hasUploadedRevisionDocument = action.tableData.documents[taxreturnIndex].hasUploadedRevisionDocument;
				deliveredReportState.deliveredReturnTableModel.documents[reportIndex].hasRevisionDocumentReady = action.tableData.documents[taxreturnIndex].hasRevisionDocumentReady;
				deliveredReportState.deliveredReturnTableModel.documents[reportIndex].hasRevisionDocumentMerged = action.tableData.documents[taxreturnIndex].hasRevisionDocumentMerged;
			}
            return deliveredReportState;

        case actionTypes.RECEIVE_GROUP_OTP_VIEWMODEL:
            let otpViewTableModel = { ...state };
            const returnIndex = otpViewTableModel.deliveredReturnTableModel.documents.findIndex(x => x.document.groupId === action.groupId
                && x.document.id === action.documentId);
            if (returnIndex > -1)
                otpViewTableModel.deliveredReturnTableModel.documents[returnIndex].document.groupedReturnOTPViewModel = action.viewModel;
            state.loading = false;
            return otpViewTableModel;

        case actionTypes.RECEIVE_CONTROLLER_ACCESSLINK_SUCCESS:
            let groupReturnModel = { ...state };
            const groupIndex = groupReturnModel.deliveredReturnTableModel.documents.findIndex(x => x.document.groupId === action.groupId
                && x.document.id === action.documentId);
            if (groupIndex > -1)
                groupReturnModel.deliveredReturnTableModel.documents[groupIndex].document.controllerViewModel = action.accessLink;
            state.loading = false;
            return groupReturnModel;

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}

	return state;
};

export const nextSignerReducer: Reducer<ISignerModel> = (state = unloadedNextSignerDetailsState, incomingAction) => {
	const action = incomingAction as AllKnownAction;
	switch (action.type) {
		case actionTypes.RECEIVE_NEXT_SIGNER_DETAILS:
			var received = { ...state };
			received = action.nextSignerDetails;
			return received;
	}
	return state;
}

const unloadedNextSignerDetailsState = {} as ISignerModel;

function updateState(type: actionTypes, state: DeliveredReturnsState, action: KnownAction): DeliveredReturnsState {
	let i: number = -1;
	let oldDocument: IDeliveredTaxDocument = unloadedDeliveredTaxDocument;
	let document: IDeliveredTaxReturns = {} as IDeliveredTaxReturns;
	let customColumn: string = "";
	let id: number = 0;
	switch (action.type) {
		case actionTypes.RECEIVE_TAX_DOCUMENT:
			document = action.taxDocument as IDeliveredTaxReturns;
			id = action.id;
			break;
		case actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE:
			customColumn = action.customColumn;
			id = action.id;
			break;
		case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
			id = action.id;
			break;
	}
	if (state.deliveredReturnTableModel.documents) {
		state.deliveredReturnTableModel.documents.forEach((value, index) => {
			if (value.document.id === id) {
				i = index;
				oldDocument = value;
				return;
			}
		});
	}
	if (i !== -1) {
		let deliveredTaxDocument: IDeliveredTaxDocument = {
			document: action.type == actionTypes.RECEIVE_TAX_DOCUMENT ? document : oldDocument.document,
			customColumn: action.type == actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE ? customColumn : oldDocument.customColumn,
			downloadsCount: oldDocument.downloadsCount,
			signedCount: oldDocument.signedCount,
			lastReminderOn: action.type == actionTypes.SEND_REMINDER_TAX_DOCUMENT ? new Date() : oldDocument.lastReminderOn,
			retentionPeriod: oldDocument.retentionPeriod,
			lockType: oldDocument.lockType,
			clientGuid: oldDocument.clientGuid,
			isDocumentLocked: oldDocument.isDocumentLocked,
			bulkDownloadCount: oldDocument.bulkDownloadCount,
			documentFilingType: oldDocument.documentFilingType,
			hasRevisionDocumentReady: oldDocument.hasRevisionDocumentReady,
			hasUploadedRevisionDocument: oldDocument.hasUploadedRevisionDocument,
            hasRevisionDocumentMerged: oldDocument.hasRevisionDocumentMerged,
            clientTypes: oldDocument.clientTypes
		};

		let documents = [
			...state.deliveredReturnTableModel.documents?.slice(0, i),
			deliveredTaxDocument,
			...state.deliveredReturnTableModel.documents?.slice(i + 1)];
		let deliveredReturnTableModel: DeliveredReturnsTableModel = {
			count: state.deliveredReturnTableModel.count,
			documents: documents
		}

		return {
			query: state.query,
			deliveredReturnTableModel: deliveredReturnTableModel,
			totalRowCount: state.totalRowCount,
			loading: false
		} as DeliveredReturnsState;
	}
	return state;
}

function clearTaxReturns(state: DeliveredReturnsState): DeliveredReturnsState {
	return {
		...unloadedState,
		query: state.query,
		isLoading: true
	} as DeliveredReturnsState;
}
