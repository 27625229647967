import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, FormGroup, FormControl, Col, Alert } from 'react-bootstrap';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ITaxReturn, IDocumentAccess } from '../common/TaxReturn';
import * as Helper from '../helper/HelperFunctions';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { GroupConfigurationBody } from '../GroupedReturns/GroupConfigurationBody';
import * as GroupedReturnStore from '../../store/groupedreturns/GroupedReturnStore';
import * as Constants from '../helper/Constants';
import { stat } from 'fs';
import { IGroupInfo } from '../../store/groupedreturns/GroupedReturnProcessState'
import { initialGroupInfo } from '../../store/groupedreturns/GroupedReturnProcessStore';
import { Guid } from '../../Core/Utilities/Guid';

interface IGroupConfigurationModalProps {
    showGroupConfiguration: boolean;
    selectedAssignments: ITaxReturn[];
    count: number;
    groupInfo: IGroupInfo[],
    onCancel(): void;
    onCreateNew: (groupInfo: IGroupInfo) => void;
    onAddtoExisting: (groupId: number) => void;
    hideUnGroupReturn: boolean;
    disableUnGroupReturn?: boolean;
    UnMapGroupedReturns?: () => void;
}

interface IGroupConfigurationModalStates {
    saving: boolean;
    message: string;
    alertMsg: string;
    alertIcon: string;
    selectedValue: number;
    newGroupInfo: IGroupInfo;
    options: GroupOptions;
}

const msg = {
    loading: 'Loading,Please Wait...',
    saving: 'Submitting the changes...',

}
export enum GroupOptions {
    AddNew,
    AddtoExisting,
    UnGroup
}

export interface IGroupAccess {
    groupId: number;
    userId: number;
}
export class GroupConfigurationModal extends React.Component<IGroupConfigurationModalProps, IGroupConfigurationModalStates> {

    constructor(props: IGroupConfigurationModalProps) {
        super(props);

        this.state = {
            saving: false,
            message: '',
            alertMsg: '',
            alertIcon: 'fa-info-circle',
            selectedValue: 0,
            newGroupInfo: Object.assign({}, initialGroupInfo),
            options: GroupOptions.AddNew
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onAddGroupInfo = this.onAddGroupInfo.bind(this);
        this.onDropDownChange = this.onDropDownChange.bind(this);
        this.onGroupNameChange = this.onGroupNameChange.bind(this);
        this.prepareGroup = this.prepareGroup.bind(this);
        this.handleCreateNewGroup = this.handleCreateNewGroup.bind(this);
    }

    componentWillReceiveProps(nextProps: IGroupConfigurationModalProps) {
        const saving = !nextProps.showGroupConfiguration ? false : this.state.saving;
        this.setState({
            newGroupInfo: Object.assign({}, initialGroupInfo),
            saving: saving,
            options: GroupOptions.AddNew,
            selectedValue: 0,
        });
    }

    public render() {
        return <Modal
            className="group-configuration-modal"
            show={this.props.showGroupConfiguration}
            onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title className='custom-modal-header'>
                    <span className='modalIcon text-secondary fa fa-user-check'>
                    </span>Group Configuration
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingOverlay style={{ height: '100%' }}>
                    <Alert variant="info" >
                        <div style={{ paddingBottom: 5 }}><i className="fas fa-info-circle"></i><span style={{ marginLeft: 5 }}>{Constants.UploadtaxReturnConstants.GroupedReturnNote}</span></div>
                        <i className='fas fa-info-circle'>
                        </i>{this.props.count > 1 ? " " + this.props.count + " Returns selected." : " 1 Return selected."}
                    </Alert>
                    {this.state.saving ?
                        <Alert
                            closeLabel={'remove'}
                            variant="warning"
                            dismissible
                            onClose={() => { this.setState({ alertMsg: '', saving: false }) }}>
                            <span
                                className={'fa ' + this.state.alertIcon}
                                style={{ marginRight: '5px' }}>
                            </span>{this.state.alertMsg}
                        </Alert>
                        : false}

                    <Loader loading={this.state.saving} text={this.state.message} />
                </LoadingOverlay>
                <GroupConfigurationBody
                    options={this.state.options}
                    groupInfo={this.props.groupInfo}
                    onDropdownChange={this.onDropDownChange}
                    groupName={this.state.newGroupInfo.name}
                    selectedValue={this.state.selectedValue}
                    onGroupNameChange={this.onGroupNameChange}
                    loadGroup={this.prepareGroup(this.props.groupInfo)}
                    onOptionsClick={this.handleCreateNewGroup}
                    hideUnGroupReturn={this.props.hideUnGroupReturn}
                    disableUnGroupReturn={this.props.disableUnGroupReturn}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    disabled={this.state.saving}
                    onClick={this.props.onCancel}
                ><i className='fas fa-times'></i>Cancel</Button>
                <Button
                    variant="info"
                    disabled={this.state.saving}
                    onClick={this.onSubmit}
                ><i className='fas fa-save'></i>Save</Button>
            </Modal.Footer>
        </Modal>
    }

    private onSubmit = (e: React.BaseSyntheticEvent) => {
        e?.preventDefault();
        if (!this.state.saving) {
            this.setState({ saving: true, message: msg.saving }, () => {
                if (this.state.options == GroupOptions.AddNew) {
                    this.onAddGroupInfo();
                }
                else if (this.state.options == GroupOptions.AddtoExisting) {
                    this.onAddtoExisting();
                }
                else if (this.state.options == GroupOptions.UnGroup) {
                    this.props.UnMapGroupedReturns && this.props.UnMapGroupedReturns();
                }
            });
        }
    }

    private onDropDownChange = (value: number) => {
        this.setState({ selectedValue: value });
    }

    private onGroupNameChange = (event: any) => {
        let temp = this.state.newGroupInfo;
        temp.name = event.target.value;
        this.setState({
            newGroupInfo: temp,
        })
    }
    private onAddtoExisting = () => {
        if (this.state.selectedValue > 0) {
            this.props.onAddtoExisting(this.state.selectedValue);
        }
        else {
            this.setState({ saving: false, message: "" });
            VenusNotifier.Warning(Constants.GroupReturnConstants.ValidationMessage.GroupConfigDropDownWarning, null);
        }
    }
    private onAddGroupInfo = () => {
        if (this.state.newGroupInfo.name.trim() != "") {
            if (this.isGroupExists(this.state.newGroupInfo.name)) {
                this.state.newGroupInfo.controllerInfo.clientGuid = Guid.newGuid();
                this.props.onCreateNew(this.state.newGroupInfo);
                initialGroupInfo.controllerInfo.clientGuid = Guid.newGuid();
                this.setState({
                    newGroupInfo: Object.assign({}, initialGroupInfo)
                });
            }
        }
        else {
            this.setState({ saving: false, message: "" });
            VenusNotifier.Warning(Constants.GroupReturnConstants.ValidationMessage.GroupConfigurationWarning, null);
        }
    }



    private isGroupExists = (groupName: string) => {
        let isValid: boolean = true;
        this.props.groupInfo.map((list, index) => {
            if (list.name.toLowerCase() == groupName.trim().toLowerCase()) {
                this.setState({ saving: false, message: "" });
                VenusNotifier.Warning(Constants.GroupReturnConstants.ValidationMessage.GroupConfigurationAlreadyExist, null);
                isValid = false;
            }
        });
        return isValid;
    }

    private prepareGroup = (groupInfo: IGroupInfo[]) => {
        var group: any = [];
        group.push({
            value: 0,
            label: "Select"
        })

        groupInfo.map((column: any) =>
            group.push({
                value: column.id,
                label: column.name
            }));

        return group;
    }

    private handleCreateNewGroup = (mode: GroupOptions) => {
        this.setState({
            options: mode
        })
    }
};


