import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Select from 'react-select';
import { Card, FormGroup, Col, FormControl, FormLabel } from 'react-bootstrap';
import { ClientInfoProperty } from './ClientInfoEnum';
import { ITaxClient } from './TaxClient';
import { FilterSSN, GetCountryCode } from '../helper/HelperFunctions';
import { TaxIdentificationNumber } from '../common/controls/TaxIdentificationNumber';
import { ITaxReturn, DocumentStatus, EngagementType } from './TaxReturn';
import { PhoneNumberComponent } from '../common/PhoneNumberComponent';
import { IDropdown } from '../../Core/Common/Dropdown';
import ToggleSwitch from '../common/ToggleSwitch';
import { ISMSNotificationDetails } from '../../store/common/SMSNotificationDetailsStore';
import { Link } from "react-router-dom";
import { getUsersByLocation } from "../common/ChangeOfficeLocationModal/ChangeOfficeLocationModal.api";
import { Loader, LoadingOverlay } from "react-overlay-loader";

export interface IPartnershipProps {
    clientId: string,
    model: ITaxClient,
    taxReturn: ITaxReturn,
    handleValueChange(item: ClientInfoProperty, value: string): void
    isDeliveredReturn: boolean,
    locationDropdown: IDropdown[],
    isGroupedReturn: boolean,
    engagementType: EngagementType;
    showSMSTextNotifications: boolean;
    isTPOpted: boolean;
    smsNotificationDetails: ISMSNotificationDetails;
    updateTPOpted: (tpOpted: boolean) => void;
}

export interface IPartnershipState {
    isDisabled: boolean;
    isLoading: boolean,
    contactPersonList: IDropdown[],
}

export class Partnership extends React.Component<IPartnershipProps, IPartnershipState> {
    state = {
        isDisabled: false,
        isLoading:false,
        contactPersonList:[],
    }

    componentDidMount() {
        if(this.props.taxReturn.locationId)
        {
            this.loadContactPersons(this.props.taxReturn.locationId)
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: IPartnershipProps) {
        if (nextprops.taxReturn != undefined) {

            let isDisabled: boolean = (nextprops.taxReturn.documentStatus === DocumentStatus.DELIVEREDTOTAXCADDY);
            this.setState({ isDisabled: isDisabled });
        }
    }

    onOfficeLocationChange = (event: any) => {
        const selectedValue = event ? event.value.toString() : 0;
        this.props.handleValueChange(ClientInfoProperty.officeLocation, selectedValue);
        this.loadContactPersons(selectedValue);
    };

    loadContactPersons = (locationId: number) => {
        this.setState({isLoading : true})
        if (locationId > 0) {
            getUsersByLocation(locationId, (data) => {
                this.setState({ contactPersonList: data, isLoading: false }, () => {
                    this.changeContactPersonOnOfficeChange();
                });
            });
        } else {
            this.setState({contactPersonList:[],isLoading : false});
        }
    }; 
    
    changeContactPersonOnOfficeChange = ()=>{
        const contactPersonEvent = {
            value:  this.state.contactPersonList?.some((item: IDropdown) => item.value == this.props.taxReturn.documentSettings.deliverySettings.contactPerson)
                    ? this.props.taxReturn.documentSettings.deliverySettings.contactPerson
                    : 0
        };
        this.onContactPersonChange(contactPersonEvent);
    }
    
    onContactPersonChange = (event: any) => {
            const selectedValue = event ? event.value.toString() : 0;
            this.props.handleValueChange(ClientInfoProperty.contactPerson, selectedValue);
    };

    prepareLocation = () => {
        return this.props.locationDropdown?.map(item => {
            return {
                value: item.value.toString(),
                label: item.name
            }
        });
    }

    prepareContactPersonDropDown = ()=>{
        return this.state.contactPersonList?.map((item : IDropdown) => {
            return {
                value: item.value.toString(),
                label: item.name
            };
        });
    }

    private handleCompanyToggleSwitchChange = (event: any) => {
        this.props.updateTPOpted(event.target.checked);
    };

    public render() {
        return <div className='col-12' >
            <Card style={{ border: 'none' }}>
                {!this.props.isGroupedReturn && <Card.Header style={{ border: '1px solid rgba(0,0,0,.125)' }}>
                <b>{this.props.engagementType.toString() === EngagementType[EngagementType.E1065] || this.props.engagementType.toString() === EngagementType[EngagementType.E1120] 
                         || this.props.engagementType.toString() === EngagementType[EngagementType.E1120S] ? "Entity" : "Trust"}</b>                         
                </Card.Header>
                }
                <Card.Body>
                <LoadingOverlay style={{ height: "100%" }}>
                    <FormGroup>
                        <Col className="text-left padT07" sm={2} as={FormLabel}>
                            Client ID<span className="fas fa-asterisk compulsory-star"></span>
                        </Col>
                        <Col className='marB10' sm={10}>
                            <FormControl
                                type="text"
                                disabled={this.state.isDisabled}
                                value={this.props.clientId}
                                onChange={(event: any) => { this.props.handleValueChange(ClientInfoProperty.clientId, event.target?.value) }}
                                placeholder="Client ID"
                                data-test-auto="E21E3F14-D51E-4336-BAA5-A5D9E210172A"
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col className="text-left padT07" sm={2} as={FormLabel}>
                            Name<span className="fas fa-asterisk compulsory-star"></span>
                        </Col>
                        <Col className='marB10' sm={10}>
                            <FormControl
                                type="text"
                                disabled={this.state.isDisabled}
                                value={this.props.model.name}
                                onChange={(event: any) => { this.props.handleValueChange(ClientInfoProperty.partnerName, event.target?.value) }}
                                placeholder="Company Name"
                                data-test-auto="E47AB592-4B96-4161-8A03-440C85250742"
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col className="text-left padT07" sm={2} as={FormLabel}>
                            Email<span className={`fas ${(this.props.taxReturn.documentStatus == DocumentStatus.DOWNLOADPDF) ? "" : "fa-asterisk compulsory-star"}`}></span>
                        </Col>
                        <Col className='marB10' sm={10}>
                            <FormControl
                                type="email"
                                disabled={this.state.isDisabled}
                                value={this.props.model.email}
                                onChange={(event: any) => { this.props.handleValueChange(ClientInfoProperty.partnerEmail, event.target?.value) }}
                                placeholder="Company Email"
                                data-test-auto="A5D4525B-9982-445A-B9AC-D206FDD84947"
                            />
                        </Col>
                    </FormGroup>
                    {(!this.props.isGroupedReturn && this.props.isDeliveredReturn) ?
                        <FormGroup>
                            <Col className="text-left padT07" sm={2} as={FormLabel}>
                                Office<span className="fas fa-asterisk compulsory-star"></span>
                            </Col>
                            <Col className='marB10' sm={10}>
                                <Select
                                    name="form-field-edit-info-state-partnership"
                                    className='editClientInfo_dropdown'
                                    value={this.props.taxReturn?.locationId}
                                    onChange={this.onOfficeLocationChange}
                                    options={this.prepareLocation()}
                                    data-test-auto="1F29729F-075F-422F-92F9-EC51033A44F6"
                                    clearable={false}
                                    placeholder="Select..."
                                >
                                </Select>

                            </Col>
                        </FormGroup> : <FormGroup></FormGroup>
                    }
                     {(!this.props.isGroupedReturn && this.props.isDeliveredReturn) ?
                        <FormGroup>
                            <Col className="text-left" sm={2} as={FormLabel}>
                            Contact Person<span className="fas fa-asterisk compulsory-star"></span>
                            </Col>
                            <Col className='marB10' sm={10}>
                                <Select
                                    name="form-field-edit-info-state-partnership"
                                    className='editClientInfo_dropdown'
                                    value={this.props.taxReturn.documentSettings.deliverySettings.contactPerson}
                                    onChange={this.onContactPersonChange}
                                    options={this.prepareContactPersonDropDown()}
                                    data-test-auto="1F29729F-075F-422F-92F9-EC51033A44F6"
                                    clearable={false}
                                    placeholder="Select..."
                                >
                                </Select>

                            </Col>
                        </FormGroup> : <FormGroup></FormGroup>
                    }
                    <FormGroup>
                        <Col className="text-left" sm={2} as={FormLabel}>
                            Country Code
                        </Col>
                        <Col className='marB10' sm={4}>
                            <Select
                                name="form-field-edit-info-state-partnership"
                                className='editClientInfo_dropdown'
                                value={this.props.model.countryCode}
                                onChange={(event: any) => { this.props.handleValueChange(ClientInfoProperty.partnerCountryCode, event ? event.value : "") }}
                                options={GetCountryCode()}
                                data-test-auto="1F29729F-075F-422F-92F9-EC51033A44F5"
                                clearable={true}
                                disabled={this.state.isDisabled}
                                searchable={true}
                            >
                            </Select>

                        </Col>
                        <Col className="text-left padT07" sm={2} as={FormLabel}>
                            Mobile
                        </Col>
                        <Col sm={4}>
                            <PhoneNumberComponent
                                phoneNumber={this.props.model.mobileNumber ? this.props.model.mobileNumber : ""}
                                handleChangePhoneNumber={(value: string) => { this.props.handleValueChange(ClientInfoProperty.partnerMobileNumber, value) }}
                                disabled={this.state.isDisabled}
                            >

                            </PhoneNumberComponent>

                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col className="text-left padT07" sm={2} as={FormLabel}>
                            EIN
                        </Col>
                        <Col sm={4} className={"zeroborderRadius"}>
                            <TaxIdentificationNumber
                                value={FilterSSN(this.props.model.ssn)}
                                onChange={(value: string) => { this.props.handleValueChange(ClientInfoProperty.partnerEin, value) }}
                                placeholder="Company EIN"
                                mask='00-0000000'
                                disabled={this.state.isDisabled}
                            />
                        </Col>
                        {this.props.showSMSTextNotifications && (<>
                            <Col className="text-left" sm={2} as={FormLabel} style={{ marginLeft: "-6px" }}>
                                <ToggleSwitch
                                    switched={this.props.isTPOpted}
                                    handleChange={this.handleCompanyToggleSwitchChange}
                                    disabled={
                                        !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                        !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                    }
                                    className={
                                        !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                            !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                            ? "disable"
                                            : ""
                                    }
                                    dataTestChecked={this.props.isTPOpted}
                                    dataTestAuto="5d40b53e-f908-47a1-ad8d-ba70947f159a"
                                />
                            </Col>
                            <Col className="text-left" sm={4} as={FormLabel} style={{ paddingLeft: "22px", paddingRight: "0px", display: "flex", alignItems: "center", marginTop: "-12px" }}>
                                SMS Text Notification
                                {(!this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                    !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn) && (
                                        <Link
                                            to={"#"}
                                            style={{ paddingLeft: "3px", marginTop: "3px" }}
                                            className="help-icon marL05 "
                                            data-placement="right"
                                            data-toggle="tooltip"
                                            title={
                                                !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting
                                                    ? "To change, enable SMS notification at the Suite level."
                                                    : !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                                        ? "Client can opt-in again by replying START."
                                                        : ""
                                            }
                                        >
                                            <i className="fas fa-question-circle" style={{ fontSize: "18px" }}></i>
                                        </Link>
                                    )}
                            </Col>
                        </>
                        )}
                    </FormGroup>
                    <Loader loading={this.state.isLoading} text="loading....." />
                    </LoadingOverlay>
                </Card.Body>
            </Card>
        </div>
    }
}
export default Partnership;
