import * as React from "react";

export const WhiteColor : string = "#fff";
export const PrimaryBlueColor : string = "#05386B";
export const DisableColor : string = "#c4c6c8";

export interface IIconsProps {
    color?: string;
}

export const ImportCCHAxcessIcon: React.FC<IIconsProps> = (props) => {
    return ( 
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path 
                    fill-rule="evenodd" 
                    clip-rule="evenodd"
                    d="M6.49951 2.99921C6.22337 2.99921 5.99951 3.22306 5.99951 3.49921V5.49921C5.99951 5.77535 5.77565 5.99921 5.49951 5.99921C5.22337 5.99921 4.99951 5.77535 4.99951 5.49921V3.49921C4.99951 2.67078 5.67108 1.99921 6.49951 1.99921H14.4995C15.3279 1.99921 15.9995 2.67078 15.9995 3.49921V12.4992C15.9995 13.3276 15.3279 13.9992 14.4995 13.9992H6.49951C5.67108 13.9992 4.99951 13.3276 4.99951 12.4992V10.4992C4.99951 10.2231 5.22337 9.99921 5.49951 9.99921C5.77565 9.99921 5.99951 10.2231 5.99951 10.4992V12.4992C5.99951 12.7753 6.22337 12.9992 6.49951 12.9992H14.4995C14.7757 12.9992 14.9995 12.7753 14.9995 12.4992V3.49921C14.9995 3.22306 14.7757 2.99921 14.4995 2.99921H6.49951ZM8.85307 4.64565L11.8531 7.64565C12.0483 7.84092 12.0483 8.1575 11.8531 8.35276L8.85307 11.3528C8.6578 11.548 8.34122 11.548 8.14596 11.3528C7.9507 11.1575 7.9507 10.8409 8.14596 10.6457L10.2924 8.49921H1.49951C1.22337 8.49921 0.999512 8.27535 0.999512 7.99921C0.999512 7.72306 1.22337 7.49921 1.49951 7.49921H10.2924L8.14596 5.35276C7.9507 5.1575 7.9507 4.84092 8.14596 4.64565C8.34122 4.45039 8.6578 4.45039 8.85307 4.64565Z" 
                    fill={ props.color || PrimaryBlueColor}
                />
            </svg>
    );
};

export const GetCCHReturnsIcon: React.FC<IIconsProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16">
            <g 
            fill={ props.color || WhiteColor}
            clipPath="url(#clip0_916_3165)">
                <path d="M12.033 7h3.933a.25.25 0 01.192.41L14.19 9.768a.25.25 0 01-.384 0l-1.966-2.36a.25.25 0 01.192-.41zM1.033 9h3.933a.25.25 0 00.192-.41L3.19 6.23a.25.25 0 00-.384 0L.841 8.59a.25.25 0 00.192.41z"></path>
                <path
                    fillRule="evenodd"
                    d="M8.5 3c-1.553 0-2.94.706-3.858 1.818a.5.5 0 01-.77-.637 6.002 6.002 0 0110.545 2.818h-1.018a5.002 5.002 0 00-4.9-4zM3.6 9a5.002 5.002 0 008.757 2.18.5.5 0 01.77.638A6.002 6.002 0 012.583 9H3.6z"
                    clipRule="evenodd"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_916_3165">
                    <path                     
                    fill={ props.color || WhiteColor}
                    d="M0 0H16V16H0z" transform="translate(.5)"></path>
                </clipPath>
            </defs>
        </svg>
    );
};

export const RefreshIcon: React.FC<IIconsProps> = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4835_4706)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9999 8.00002C12.9995 6.91937 12.649 5.86795 12.0009 5.00322C11.3528 4.1385 10.4419 3.50703 9.40484 3.20338C8.36773 2.89974 7.26016 2.94027 6.24802 3.3189C5.23588 3.69754 4.37365 4.3939 3.79048 5.30368C3.2073 6.21346 2.93458 7.28769 3.01315 8.36548C3.09172 9.44326 3.51736 10.4666 4.22631 11.2822C4.93526 12.0978 5.88936 12.6617 6.94572 12.8895C8.00207 13.1174 9.10381 12.9969 10.0859 12.546C10.2053 12.4969 10.3391 12.496 10.4592 12.5433C10.5793 12.5906 10.6765 12.6825 10.7303 12.7998C10.7842 12.9171 10.7906 13.0507 10.7482 13.1727C10.7059 13.2946 10.6179 13.3954 10.5029 13.454C9.32432 13.995 8.00218 14.1396 6.73453 13.8661C5.46688 13.5926 4.32196 12.9157 3.47127 11.9369C2.62057 10.9581 2.1099 9.73006 2.01575 8.43666C1.92159 7.14326 2.24903 5.85417 2.949 4.76249C3.64896 3.6708 4.68379 2.83528 5.89847 2.38108C7.11315 1.92688 8.44229 1.87845 9.6868 2.24305C10.9313 2.60765 12.0242 3.36565 12.8018 4.4035C13.5793 5.44136 13.9997 6.7032 13.9999 8.00002L12.9999 8.00002Z"
                    fill={ props.color || PrimaryBlueColor}
                />
                <path
                    d="M11.5339 8.00002L15.4659 8.00002C15.5134 8.00004 15.5599 8.01359 15.6 8.03909C15.6401 8.06458 15.6721 8.10097 15.6922 8.14399C15.7124 8.18701 15.7198 8.23488 15.7138 8.282C15.7077 8.32911 15.6883 8.37352 15.6579 8.41002L13.6919 10.77C13.5919 10.89 13.4079 10.89 13.3079 10.77L11.3419 8.41002C11.3115 8.37352 11.2921 8.32911 11.2861 8.282C11.28 8.23488 11.2875 8.18701 11.3076 8.14399C11.3277 8.10097 11.3597 8.06458 11.3998 8.03909C11.4399 8.01359 11.4864 8.00004 11.5339 8.00002Z"
                    fill={ props.color || PrimaryBlueColor}
                />
            </g>
            <defs>
                <clipPath id="clip0_4835_4706">
                    <rect width="16" height="16" fill="white" transform="translate(16) rotate(90)" />
                </clipPath>
            </defs>
        </svg>
    );
};
