import * as React from "react";
import { RouteComponentProps } from "react-router";
import { DeliveredReturnsHeader } from "./DeliveredReturns/DeliveredReturnsHeader";
import { DeliveredReturnsTable } from "./DeliveredReturns/DeliveredReturnsTable";
import { ReportProblemModal } from "../common/ReportProblemModal";
import { CustomColumn } from "./DeliveredReturns/CustomColumn";
import { DownloadTypeOption } from "./DeliveredReturns/DownloadTypeOption";
import { IColumnValues } from "../settings/GeneralSettings";
import { ReportChangeStatusModal } from "../common/ReportsChangeStatusModal";
import { ResendAccessLinkModal } from "../common/ResendAccessLinkModal";
import { IRevisionDocumentUploadData } from "../common/RevisionDocuments/RevisionDocuments";
import { IFilters, SortDirections, ReportFilterType, QuickReportType } from "./Filters";
import { VenusNotifier } from "../helper/VenusNotifier";
import * as Helper from "../helper/HelperFunctions";
import {
    DeliveredReturnsConstants,
    GroupTabConstants,
    ArchiveReturnsConstants,
    Common,
    ReportFilterConstants,
    RevisionDocumentsConstant
} from "../helper/Constants";
import * as bootbox from "bootbox";
import { IDeliveredTaxDocument, IQuickReportDocument } from "../common/DeliveredTaxReturns";
import {
    SignatureStatus,
    ClientTypes,
    SignatureType,
    ITaxReturn,
    IDocumentAccess,
    ISignerModel,
    DocumentGroups,
    isIndividual,
    isMutual,
    isPartnership,
    DocumentStatus,
    getUnformattedClientName,
    DeliveryMode,
    PaperReturnDeliveryType,
    TaxDocumentRevisionStatus,
    ITaxDocumentRevision,
    IVoucher,
    VoucherStatus,
    ClientTypesNumber,
    IClientInfoViewModel,
    initialClientInfoViewModel,
    TaxClients,
    ITaxDocumentViewModel,
    ITaxDocumentAccessViewModel
} from "../common/TaxReturn";
import { DownloadReturns } from "../common/DownloadReturns";
import { DownloadEfileForm } from "../common/DownloadEfileForms";
import { DownloadHistory } from "../common/DownloadHistory";
import { UploadForms } from "../common/UploadForms";
import { SignedDetails } from "../common/SignedDetails";
import { ViewAccessCode } from "../common/ViewAccessCode";
import { RetentionComponent } from "../common/RetentionComponent";
import { ClientInfo } from "../common/ClientInfo";
import * as DeliveredReturnsStore from "../../store/reports/DeliveredReturnsStore";
import * as FilterStore from "../../store/reports/FilterStore";
import * as TaxDocumentStore from "../../store/common/TaxDocumentStore";
import * as DeliveredReturnsState from "../../store/reports/DeliveredReturnsState";
import * as UserStore from "../../store/userManagement/UserStore";
import * as UserSettingStore from "../../store/userManagement/UserSettingStore";
import * as CustomColumnStore from "../../store/common/CustomColumnStore";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as ReminderStore from "../../store/common/ReminderStore";
import { ShowLoader, HideLoader } from "../helper/Loader";
import * as UploadTaxReturnStore from "../../store/common/uploadTaxReturn";
import * as AttachmentsStore from "../../store/ProcessReturn/AttachmentsStore";
import * as AttachmentsState from "../../store/ProcessReturn/AttachmentsState";
import { DeleteTaxReturnConstants } from "../helper/Constants";
import { BulkOperationQuery, QuickReportBulkOperationQuery } from "../../Core/ViewModels/Common/BulkOperationQuery";
import * as BulkOperationsStore from "../../store/common/BulkOperationsStore";
import * as ColumnSettingsStore from "./../../store/reports/ColumnSettings/ColumnSettingsStore";
import { IColumnSettingsState, ReportType } from "./../../store/reports/ColumnSettings/ColumnSettingsState";
import {
    initialCompanySettings,
    ISuiteTaxSoftwareSetting,
    ReportingEngineType
} from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import * as AdditionalEsignDocumentStore from "../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore";
import { IDocumentData } from "../../components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument";
import { ScreenShare } from "../common/ScreenShare";
import { UploadRevisionDocumentModal } from "../common/RevisionDocuments/UploadRevisionDocumentModal";
import { DefaultDownloadInfoType } from "../navigation/profile/MySettings";
import { IUserProfile } from "./../navigation/profile/ProfileObjects";
import { ReportProblemTypes, IReportProblemDetails, ReportedStep } from "../common/ReportProblem/ReportProblemModel";
import { QuickReports } from "./QuickReports/QuickReports";
import { QuickReportState } from "./QuickReports/QuickReportState";
import * as QuickReportStore from "../../store/reports/QuickReportStore";
import * as GroupedReturnStore from "../../store/groupedreturns/GroupedReturnStore";
import * as VoucherStore from "../../store/ProcessReturn/voucher/VoucherStore";
import * as UploadStore from "../../store/common/Upload";
import * as PdfStore from "../../store/pdf/PdfStore";
import * as UserSignatureStore from "../../store/common/UserSignatureStore";
import { RevisedProcessReturnModal } from "../../components/common/RevisedTaxReturnProcess/RevisedProcessReturnModal";
import { ProcessReturnConstants } from "../helper/Constants";
import { Loader } from "react-overlay-loader";
import * as RevisionDocumentStore from "../../store/common/RevisionDocumentStore";
import * as PaperFileStore from "../../store/ProcessReturn/PaperFileStore";
import { SetAccessType } from "../../store/reports/GroupedReturns/SendGroupedReturnsState";
import { logger } from "../../routes/LoggedIn";
import * as K1AttachmentStore from "../../store/ProcessReturn/K1AttachmentStore";
import { CommonResources, DeliveredReturnReportResourceId, GroupedReturnReportResources } from "../helper/ResourceIdConstants";
import { IAuthState } from "../../store/auth/reducer";
import MismatchModal from "../../components/common/MismatchModal/MismatchModal";
import {
    IClientInfoComparisonResult,
    IClientInfoMismatchType,
    getNewClientInfo,
    initialClientInfoComparisonResult,
    setNewClientInfoComparisonResult
} from "../../components/common/MismatchModal/MismatchModal.model";
import { addNewClientInfo, compareClientInfo, editClientInfo } from "../../components/common/MismatchModal/MismatchModal.Api";
import * as OfficeLocationStore from "../../store/common/OfficeLocationStore";
import LocationMismatchActionModal from "../common/LocationMismatchActionModal/LocationMismatchActionModal";
import SuiteModal from "../common/SuiteModal";
import SetAccessCommonModal from "../common/SetAccessCommonModal";
import {
    IAccessingUsersAndUserGroups,
    IUserDataFromDB,
    IUserGroupDataFromDB
} from "../common/SetAccessCommonModal/SetAccessCommonModal.model";
import * as ClientManagementSettingStore from "../../store/company/SuiteClientManagementSettingStore";
import { ISMSNotificationDetails, initialSMSNotificationDetails } from "../../store/common/SMSNotificationDetailsStore";
import * as SMSNotificationDetailsStore from "../../store/common/SMSNotificationDetailsStore";
import UnlockReturnModal from "../common/UnlockReturnModal";
import SendReminder from "./DeliveredReturns/SendReminder";
import ChangeOfficeLocationModal from "../common/ChangeOfficeLocationModal/ChangeOfficeLocationModal";
import EventTrackingModal from "./DeliveredReturns/EventTrackingModal";
import {
    IGroupInfo,
    IControllerInfo,
    initialControllerInfo,
    IGroupedReturnsClientInfoModel,
    IGroupLevelReturnDictionary,
    initialGroupRetrunClientInfo,
    IGroupSettingsModel
} from "../../store/groupedreturns/GroupedReturnProcessState";
import { EditGroupInfoModal } from "../GroupedReturns/EditGroupInfoModal";
import * as DeliveredGroupedReturnsStore from "../../store/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsStore";
import * as GroupedTaxDocumentStore from "../../store/groupedreturns/GroupedTaxDocumentStore";
import GroupedReturnViewAccessCode from "../common/GroupLevelActions/GroupedReturnViewAccessCode";
import { GroupedReturnResendAccessLinkModal } from "../common/GroupLevelActions/GroupedReturnResendAccessLinkModal";
import { DeliveredGroupedIndividualChangeStatusModal } from "./GroupedReturns/DeliveredGroupedIndividualChangeStatusModal";
import * as SendGroupedReturnsStore from "../../store/reports/GroupedReturns/SendGroupedReturnsStore";
import { ControllerScreenShare } from "../../components/common/ControllerScreenShare";
import * as GroupedReturnsCommonStore from "../../store/common/GroupedReturnsCommonStore";
import * as GroupedReturnProcessStore from "../../store/groupedreturns/GroupedReturnProcessStore";
import { GroupReturnClientInfo } from "../common/GroupReturnClientInfo";
import GroupReminderModal from "./DeliveredReturns/GroupReminderModal";
import { GroupedReturnSendReminderRequest } from "../../store/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsHeaderState";
import * as DeliveredGroupedReturnsHeaderStore from "../../store/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsHeaderStore";
import { Row } from "react-bootstrap";

let moment = require("moment");
const pageSize: number = 20;
const NO_INDEX = -1;
let clientNames: string[] = [];

export interface INameValue {
    [index: string]: string;
}

const successMsg: INameValue = {
    editClientInfoState: "Successfully updated client info for the tax-return",
    changeStatusState: "Status has been successfully changed for the Tax Returns",
    reportProblemState: "Problem on the tax-return is successfully reported",
    UploadRevisionDocumentState: "Successfully uploaded new estimates for the tax-return",
    deleteReturnsState: "Successfully deleted the selected return(s)!",
    assignReturnsState: "Successfully ssigned the document(s) to selected user",
    setAccessState: "Document Access set successfully for all returns",
    archiveReturnsState: "Successfully archived the selected return(s)!",
    customColumnState: "Custom column Value(s) has been successfully updated for the Tax Returns",
    customColumnRemoveState: "Custom column value(s) has been successfully removed for the Tax Returns",
    sendReminderState: "Reminder has been sent successfully for the selected Tax Returns",
    resendAccessLinkState: "Access Link has been sent successfully for the selected Tax Returns",
    recallReturnState: "Return Recalled successfully.",
    reprocessReturnState: "Return Reprocessed successfully.",
    voucherReminderState: "Successfully updated reminder for the tax-return",
    accessCodeState: "Access Code generated successfully",
    retentionPolicyState: "Retention policy updated successfully",
    uploadFormsState: "Successfully uploaded the form for the tax-return",
    changeStatusConfirmation: "You are about to change the status of the document manually. Do you want to proceed?",
    restoreReturnsState: "Successfully restored the selected return(s)!"
};

const errorMsg: INameValue = {
    editClientInfoState: "ClientInfo Edit failed! Error returned from server while updating the client info!",
    changeStatusState: "Failed to update the changed status!",
    reportProblemState: "Failed to report the problem!",
    UploadRevisionDocumentState: "Failed to upload new estimates for the tax-return",
    deleteReturnsState: "Failed to delete the selected document(s)!",
    assignReturnsState: "Failed to assign the document(s) to user!",
    setAccessState: "Setting document access for the users failed!",
    archiveReturnsState: "Failed to archive the selected document(s)!",
    customColumnState: "Failed to update the Custom column!",
    sendReminderState: "Failed to send reminder for the selected document(s)!",
    resendAccessLinkState: "Failed to resend access link for the selected document(s)!",
    recallReturnState: "Failed to recall the return!",
    reprocessReturnState: "Failed to reprocess the return!",
    voucherReminderState: "Failed to update reminder for the tax-return!s",
    accessCodeState: "Failed to generate access code for the tax-return",
    retentionPolicyState: "Failed to update retention policy for the tax-return",
    uploadFormsState: "Failed to upload the form for the tax-return",
    restoreReturnsState: "Failed to restore the selected document(s)!"
};

const BulkOperationEndPoint = {
    UpdateCustomColumn: "SaveCustomColumnAsync",
    RemoveCustomColumn: "RemoveCustomColumnAsync"
};

// At runtime, Redux will merge together...
type DeliveredReturnsProps = {
    auth: IAuthState;
    delivered: DeliveredReturnsState.DeliveredReturnsState; // ... state we've requested from the Redux store
    taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
    userStore: UserStore.ISSRUserData;
    reportFilters: FilterStore.IFilterState;
    userSettings: UserSettingStore.UserSettings;
    customColumnData: CustomColumnStore.ICustomColumnState;
    company: CompanyStore.ICompanyData;
    reminderData: ReminderStore.IReminderData;
    uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState;
    attachments: AttachmentsState.AttachmentsState;
    nextSignerDetails: ISignerModel;
    isArchived: boolean;
    pageTitle: string;
    columnSettings: IColumnSettingsState;
    userProfile: IUserProfile;
    groupedReturnStore: GroupedReturnStore.IGroupInfoState;
    quickReports: QuickReportState;
    userSignatures: UserSignatureStore.IUserSignatures;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    voucherStore: VoucherStore.IVoucherState;
    userResources: UserStore.IUserResources;
    parentResourceId: string;
    ssrUsers: UserStore.ISSRUserListData;
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
    officeLocation: OfficeLocationStore.IOfficeLocationState;
    clientManagementSetting: ClientManagementSettingStore.IClientManagementSetting;
    parentControllerGridResourceIdPrefix: string;
    groupedReturnProcessState: IGroupLevelReturnDictionary;
} & typeof DeliveredReturnsStore.actionCreators & // ... plus action creators we've requested
    typeof TaxDocumentStore.actionCreators &
    typeof UserStore.actionCreators &
    typeof FilterStore.actionCreators &
    typeof UserSettingStore.actionCreators &
    typeof CustomColumnStore.actionCreators &
    typeof CompanyStore.actionCreators &
    typeof ReminderStore.actionCreators &
    typeof UploadTaxReturnStore.actionCreators &
    typeof AttachmentsStore.actionCreators &
    typeof BulkOperationsStore.actionCreators &
    typeof ColumnSettingsStore.actionCreator &
    typeof AdditionalEsignDocumentStore.actionCreators &
    typeof GroupedReturnStore.actionCreators &
    typeof QuickReportStore.actionCreators &
    typeof UploadStore.actionCreators &
    typeof VoucherStore.actionCreators &
    typeof PdfStore.actionCreators &
    typeof UserSignatureStore.actionCreators &
    typeof RevisionDocumentStore.actionCreators &
    typeof PaperFileStore.actionCreators &
    typeof K1AttachmentStore.actionCreators &
    typeof OfficeLocationStore.actionCreators &
    typeof ClientManagementSettingStore.actionCreators &
    typeof SMSNotificationDetailsStore.actionCreators &
    typeof DeliveredGroupedReturnsStore.actionCreators &
    typeof GroupedTaxDocumentStore.actionCreators &
    typeof SendGroupedReturnsStore.actionCreators &
    typeof GroupedReturnsCommonStore.actionCreators &
    typeof GroupedReturnProcessStore.actionCreators &
    typeof DeliveredGroupedReturnsHeaderStore.actionCreators &
    RouteComponentProps<{ page: string }>; // ... plus incoming routing parameters

export interface IPopupState {
    show: boolean;
}

export interface IGroupedReturnClientInfoViewModel extends IPopupState {
    model: ITaxReturn | undefined;
    groupInfo: IGroupInfo | undefined;
    saveCheckId: number | undefined;
}

interface ReturnStates extends DeliveredReturnsState.DeliveredReturnsPageState {
    isFilterCleared: boolean;
    revisedProcessReturnWindow: IPopupState;
    revisedProcessReturnModalLoader: boolean;
    processingDocumentId: number;
    groupInfo: IGroupInfo | undefined;
    revisionDocuments: ITaxDocumentRevision[];
    showMismatchModal: boolean;
    clientInfoComparisonResult: IClientInfoComparisonResult;
    popupInProgress: boolean;
    clientInfo: IClientInfoViewModel;
    clientBasicInfoId: number;
    showLocationMismatchActionModel: boolean;
    loggedInUserLocations: number[];
    loadingMessage: boolean;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
    showSMSTextNotifications: boolean;
    isTPOpted: boolean;
    isSpouseOpted: boolean;
    smsNotificationDetails: ISMSNotificationDetails;
    oldClientId: string;
    smsSettingChanged: boolean;
    controller: IControllerInfo;
    editControllerInfoState: IGroupedReturnClientInfoViewModel;
    isGroupedReturnExists: boolean;
    isControllerScreenShare: boolean;
}

export class DeliveredReturns extends React.Component<DeliveredReturnsProps, ReturnStates> {
    private proxyFilter: any;
    private reportFilterType: any;
    private ssShow = false;
    private ssClientId: string;
    private ssClientGuid: string;
    public DeliveredReturnsTable: any = null;
    public tableRef: any = null;
    public isSendReminderFromHeader = true;

    constructor(props: DeliveredReturnsProps) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            filter: {
                name: "",
                searchText: "",
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                fields: {},
                isDefaultFilter: false,
                filterType: ReportFilterType.Delivered,
                isMasterFilter: false
            },
            selectedRows: [],
            selectedDocumentGuid: "",
            searchString: "",
            sortName: "",
            sortOrder: "desc",
            filterTaxYear: new Date().getFullYear() - 1,
            saveFilterShow: false,
            filterClientId: "",
            filterCustomColumn: "",
            filterDeliveredOn: undefined,
            filterDownloadedBy: "",
            filterEngagementType: 0,
            filterLastReminderOn: undefined,
            filterName: "",
            filterPartner: "",
            filterSentBy: "",
            filterStatus: "",
            filterLocation: "",
            hideCustomCulumnLoader: false,
            isFilterCleared: false,
            filterDocumentFilingType: 0,
            filterGroupType: -1,

            signatureStatusList: [],
            selectedStatus: 0,
            selectedColumnValue: 0,

            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false,
            deSelectedRows: [],
            columnSettings: [],
            isSelectAllChecked: false,
            screenShareOtp: "",
            selectedDownloadType: DefaultDownloadInfoType.PromptMeEveryTime,
            selectedDefaultDownloadType: false,
            showQuickReports: false,
            selectedQuickReport: QuickReportType.None,
            defaultGroup: "",
            loadExcelSpinner: false,

            //SetAccess States
            setAccessState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },

            //EditClientInfo states
            editClientInfoState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //EditControllerInfo states
            editControllerInfoState: {
                model: undefined,
                show: false,
                groupInfo: undefined,
                saveCheckId: undefined
            },
            //Report a Problem states
            reportProblemState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            groupReportProblemState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Delete Action states
            deleteReturnsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Archive Action states
            archiveReturnsState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },

            //ChangeStatus states
            changeStatusState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },
            rowChangeStatusState: {
                show: false,
                model: undefined
            },
            showLoader: false,
            //CustomColumn states
            customColumnState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },
            //Download Type Option State
            DownloadTypeOptionState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },

            //SendRemider states
            sendReminderState: {
                show: false,
                selectedDocumentIds: undefined,
                bulkReturnsSelected: [],
                isSigningReminderEnabled: false,
                isBulkSelectionEnabled: false,
                individualReturnSelected: undefined,
                totalCount: 0,
                isGroupedReturnExists: false
            },

            //Voucher Reminder states
            voucherReminderState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            groupSendReminderState: {
                model: undefined,
                groupSettings: undefined,
                show: false
            },

            //Resend Access Link states
            resendAccessLinkState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            groupedReturnResendAccessLinkState: {
                model: undefined,
                show: false
            },

            //Client Tracking states
            clientTrackingState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Recall Return states
            recallReturnState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //Reprocess Return states
            reprocessReturnState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Access Code states
            accessCodeState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            groupedReturnAccessCodeState: {
                model: undefined,
                show: false
            },

            //Retention Policy states
            retentionPolicyState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Download Return State
            downloadReturnState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Download Efile Forms State
            downloadEformFilesState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Upload Forms State
            uploadFormsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Signed Details State
            signedDetailsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Download History State
            downloadHistoryState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Client View State
            clientViewState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Controller View State
            controllerViewState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Restore Action states
            restoreReturnsState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },

            //Screen Share State
            screenShareState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Unlock Return State
            unlockReturnState: {
                show: false,
                clients: "",
                documentId: 0
            },

            //ChangeOfficeLocation states
            changeOfficeLocationState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },
            editGroupInfoState: {
                show: false,
                model: undefined
            },

            refreshDelay: false,

            uploadRevisionDocumentState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            editClientSaveState: DeliveredReturnsState.intialEditClientSaveState,
            revisedProcessReturnWindow: { show: false },
            revisedProcessReturnModalLoader: false,
            processingDocumentId: 0,
            groupInfo: undefined,
            revisionDocuments: [],
            showMismatchModal: false,
            clientInfoComparisonResult: initialClientInfoComparisonResult,
            popupInProgress: false,
            clientInfo: initialClientInfoViewModel,
            clientBasicInfoId: 0,
            showLocationMismatchActionModel: false,
            loggedInUserLocations: [],
            loadingMessage: false,
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: [],
            showSMSTextNotifications: true,
            isTPOpted: false,
            isSpouseOpted: false,
            smsNotificationDetails: initialSMSNotificationDetails,
            oldClientId: "",
            smsSettingChanged: false,
            controller: initialControllerInfo,
            isGroupedReturnExists: false,
            isControllerScreenShare: false
        };
        this.ssClientId = "";
        this.ssClientGuid = "";
        this.onPageReload = this.onPageReload.bind(this);

        this.applyDefaultFilter = this.applyDefaultFilter.bind(this);
        this.onFilterSave = this.onFilterSave.bind(this);
        this.onFilterUpdate = this.onFilterUpdate.bind(this);
        this.onFilterDelete = this.onFilterDelete.bind(this);
        this.onSetDefaultFilter = this.onSetDefaultFilter.bind(this);
        this.onSetDefaultFilterSuccess = this.onSetDefaultFilterSuccess.bind(this);
        this.onRemoveDefaultFilter = this.onRemoveDefaultFilter.bind(this);
        this.onRemoveDefaultFilterSuccess = this.onRemoveDefaultFilterSuccess.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onSaveFilterShow = this.onSaveFilterShow.bind(this);
        this.onSaveFilterHide = this.onSaveFilterHide.bind(this);
        this.onRequestUserSettingsSuccess = this.onRequestUserSettingsSuccess.bind(this);
        //

        this.onTaxpayerView = this.onTaxpayerView.bind(this);
        this.onRemoveReport = this.onRemoveReport.bind(this);

        //Grid Operation Handlers
        this.onExportToExcel = this.onExportToExcel.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onFilterNameChange = this.onFilterNameChange.bind(this);

        //EditClientInfo Event Handlers
        this.onEditClientInfoOpen = this.onEditClientInfoOpen.bind(this);
        this.onEditClientInfoCancel = this.onEditClientInfoCancel.bind(this);
        this.onEditClientInfoSave = this.onEditClientInfoSave.bind(this);
        this.onSaveTaxDocument = this.onSaveTaxDocument.bind(this);
        this.sendMail = this.sendMail.bind(this);

        this.onSetAccessOpen = this.onSetAccessOpen.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);
        this.onSetAccessCancel = this.onSetAccessCancel.bind(this);

        this.onReportProblemOpen = this.onReportProblemOpen.bind(this);
        this.onReportProblemCancel = this.onReportProblemCancel.bind(this);
        this.onReportProblemSave = this.onReportProblemSave.bind(this);

        this.onArchiveReturnsOpen = this.onArchiveReturnsOpen.bind(this);
        this.onArchiveReturnsSave = this.onArchiveReturnsSave.bind(this);

        this.onChangeStatusOpen = this.onChangeStatusOpen.bind(this);
        this.onChangeStatusSave = this.onChangeStatusSave.bind(this);
        this.onChangeStatusCancel = this.onChangeStatusCancel.bind(this);

        this.onCustomColumnOpen = this.onCustomColumnOpen.bind(this);
        this.getSelectedCustomColumnValue = this.getSelectedCustomColumnValue.bind(this);
        this.onCustomColumnDropdownChange = this.onCustomColumnDropdownChange.bind(this);
        this.onCustomColumnSave = this.onCustomColumnSave.bind(this);
        this.onCustomColumnRemove = this.onCustomColumnRemove.bind(this);
        this.onCustomColumnCancel = this.onCustomColumnCancel.bind(this);
        this.onDownloadTypeCancel = this.onDownloadTypeCancel.bind(this);
        this.onDownloadOptionSave = this.onDownloadOptionSave.bind(this);
        this.onDownloadOptionChange = this.onDownloadOptionChange.bind(this);
        this.useDefaultDownloadType = this.useDefaultDownloadType.bind(this);
        this.onSendReminderValidate = this.onSendReminderValidate.bind(this);
        this.onSendReminderValidateIndividualReturn = this.onSendReminderValidateIndividualReturn.bind(this);
        this.OnSendReminderCancel = this.OnSendReminderCancel.bind(this);

        this.onResendAccessLinkOpen = this.onResendAccessLinkOpen.bind(this);
        this.onResendAccessLink = this.onResendAccessLink.bind(this);
        this.onResendAccessLinkCancel = this.onResendAccessLinkCancel.bind(this);

        this.onClientTrackingOpen = this.onClientTrackingOpen.bind(this);
        this.onClientTrackingCancel = this.onClientTrackingCancel.bind(this);

        this.onRecallReturnOpen = this.onRecallReturnOpen.bind(this);
        this.onRecallReturnSave = this.onRecallReturnSave.bind(this);

        this.onUploadRevisionDocumentOpen = this.onUploadRevisionDocumentOpen.bind(this);
        this.onNotificationReminderSave = this.onNotificationReminderSave.bind(this);
        this.onBulkNotificationReminderSave = this.onBulkNotificationReminderSave.bind(this);

        this.onViewAccessCodeOpen = this.onViewAccessCodeOpen.bind(this);
        this.onViewAccessCodeCancel = this.onViewAccessCodeCancel.bind(this);
        this.onGenerateOTP = this.onGenerateOTP.bind(this);

        this.onRetentionPolicyOpen = this.onRetentionPolicyOpen.bind(this);
        this.onRetentionPolicySave = this.onRetentionPolicySave.bind(this);
        this.onRetentionPolicyCancel = this.onRetentionPolicyCancel.bind(this);

        this.onDownloadReturnOpen = this.onDownloadReturnOpen.bind(this);
        this.onDownloadReturnCancel = this.onDownloadReturnCancel.bind(this);
        this.onDownloadReturnAll = this.onDownloadReturnAll.bind(this);
        this.onDownloadFile = this.onDownloadFile.bind(this);
        this.downloadMergedPaperFileDocument = this.downloadMergedPaperFileDocument.bind(this);

        this.onDownloadEfileFormsOpen = this.onDownloadEfileFormsOpen.bind(this);
        this.onDownloadEfileFormsCancel = this.onDownloadEfileFormsCancel.bind(this);
        this.onMultipleDownloadEfileForms = this.onMultipleDownloadEfileForms.bind(this);

        this.onUploadFormsOpen = this.onUploadFormsOpen.bind(this);
        this.onUploadFormsCancel = this.onUploadFormsCancel.bind(this);
        this.onUploadFormsSuccess = this.onUploadFormsSuccess.bind(this);

        this.onSignedDetailsOpen = this.onSignedDetailsOpen.bind(this);
        this.onSignedDetailsCancel = this.onSignedDetailsCancel.bind(this);

        this.onDownloadHistoryOpen = this.onDownloadHistoryOpen.bind(this);
        this.onDownloadHistoryCancel = this.onDownloadHistoryCancel.bind(this);

        this.onTaxPayerViewClick = this.onTaxPayerViewClick.bind(this);
        this.onTaxPayerViewLinkClick = this.onTaxPayerViewLinkClick.bind(this);
        this.onTaxPayerViewCancel = this.onTaxPayerViewCancel.bind(this);

        this.onDeleteTaxReturnOpen = this.onDeleteTaxReturnOpen.bind(this);
        this.onDeleteTaxReturnSave = this.onDeleteTaxReturnSave.bind(this);
        this.onDeleteCompletion = this.onDeleteCompletion.bind(this);

        this.onScreenShareViewClick = this.onScreenShareViewClick.bind(this);
        this.onScreenShareOpen = this.onScreenShareOpen.bind(this);
        this.onScreenShareCancel = this.onScreenShareCancel.bind(this);
        this.onScreenShareStart = this.onScreenShareStart.bind(this);
        this.setClearFilter = this.setClearFilter.bind(this);

        //Support Methods
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.setSelectedDocumentsGuid = this.setSelectedDocumentsGuid.bind(this);
        this.selectedDocumentIdsAndLoadingStatus = this.selectedDocumentIdsAndLoadingStatus.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.lazyLoadSelected = this.lazyLoadSelected.bind(this);
        this.updateStates = this.updateStates.bind(this);
        this.getStoreItem = this.getStoreItem.bind(this);
        this.onUpdateCompletion = this.onUpdateCompletion.bind(this);
        this.onUpdateCompletionMultiple = this.onUpdateCompletionMultiple.bind(this);
        this.checkForPopupCompletion = this.checkForPopupCompletion.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.fetchDeliveredReturns = this.fetchDeliveredReturns.bind(this);
        this.loadDeliveredReturns = this.loadDeliveredReturns.bind(this);
        this.getSignedDetails = this.getSignedDetails.bind(this);
        this.selectedQuickReportDocuments = this.selectedQuickReportDocuments.bind(this);
        this.onChangeStatusForQuickReportOpen = this.onChangeStatusForQuickReportOpen.bind(this);
        this.OnSendReminderForQuickReport = this.OnSendReminderForQuickReport.bind(this);
        this.onBulkNotificationReminderSaveForQuickReport = this.onBulkNotificationReminderSaveForQuickReport.bind(this);
        this.onExportToExcelQuickReport = this.onExportToExcelQuickReport.bind(this);
        this.loggedInUserLocation = this.loggedInUserLocation.bind(this);
    }

    //Code commented as it was using both new and old api in this component.
    //because of this code so old lifecycle methods wont be get called after upgrading react
    //static getDerivedStateFromProps(props: DeliveredReturnsState.DeliveredReturnsState, state: DeliveredReturnsState.DeliveredReturnsPageState) {
    //    return state;
    //}
    componentDidMount() {
        const element = document.getElementById("content-wrapper");
        element && element.scrollTo(0, 0);
    }
    UNSAFE_componentWillMount() {
        this.initData();
        this.props.requestAllSSRUsers(true);
        this.props.requestUserSettings(true, this.onRequestUserSettingsSuccess);
        this.props.requestCustomColumn(true);
        this.props.getAllReportFilter(true, this.reportFilterType);
        //this.props.requestDeliveredReturns(this.buildQuery(this.state.page, pageSize), true);
        this.props.requestTaxingAuthorities();
        this.props.requestColumnSettings(ReportType.Delivered);
        this.props.requestColumnSettings(ReportType.Archived);
        if (this.props.company.isDocumentStoreDirty) {
            this.props.reloadDeliveredReturns();
            this.props.forceRefreshTaxDcoumentStore(false);
            this.onPageReload();
        }
        this.props.requestCompanySettings(false);

        this.props.requestGroupConfiguration(true);
        this.props.requestGroupAccess(true);
        this.props.requestSSRUsers(true);
        this.props.isArchived && this.props.requestDeliveredReturnClientNames(this.props.isArchived,
            (taxClientNames) => { clientNames = taxClientNames; });
    }

    UNSAFE_componentWillReceiveProps(nextProps: DeliveredReturnsProps) {
        this.initData();
        this.updateStates(nextProps);
        this.checkForPopupCompletion(nextProps.taxDocuments);
        nextProps.userSettings.settings &&
            nextProps.userSettings.settings.defaultSettings &&
            nextProps.userSettings.settings.defaultSettings.download &&
            this.setState({
                selectedDownloadType: nextProps.userSettings.settings.defaultSettings.download.downloadType
            });
    }

    paperFileEnabled(): boolean {
        return this.props.company.companySettings && this.props.company.companySettings?.displaySettingsModel.isEnablePaperFiled;
    }

    public setClearFilter() {
        this.setState({ isFilterCleared: true });
    }

    onRequestUserSettingsSuccess() {
        let defaultReportFilter = this.props.userSettings.settings.defaultSettings.reportFilter;
        if (!defaultReportFilter || defaultReportFilter == "") {
            this.loadDeliveredReturns();
        }
        let downloadType =
            this.props.userSettings.settings.defaultSettings.download.downloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        this.setState({ selectedDownloadType: downloadType });
    }
    initData = () => {
        this.proxyFilter =
            ReportFilterType[this.props.isArchived ? ReportFilterType.Archived : ReportFilterType.Delivered].toUpperCase() +
            ReportFilterConstants.DefaultFilterBuilder;
        this.reportFilterType = this.props.isArchived ? ReportFilterType.Archived : ReportFilterType.Delivered;
    };

    onTaxpayerView(rowIndex: number) {
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        this.props.requestTaxDocument(id, true, true);
    }

    //non-implemented method
    onRemoveReport() {}

    setTableRef = (refer: any) => {
        this.tableRef = refer;
    };

    getBulkOperationQuery = (): BulkOperationQuery => {
        let query: BulkOperationQuery = {
            sortBy: this.state.sortName,
            sortOrder: this.state.sortOrder,
            searchText: this.state.searchString,
            clientId: this.state.filterClientId,
            customColumn: this.state.filterCustomColumn,
            downloadedBy: this.state.filterDownloadedBy,
            lastReminderOn: this.state.filterLastReminderOn,
            name: this.state.filterName === "-1" ? "" : this.state.filterName,
            partner: this.state.filterPartner,
            retentionPeriod: this.state.filterRetentionPeriod,
            deliveredOn: this.state.filterDeliveredOn,
            engagementType: this.state.filterEngagementType,
            sentBy: this.state.filterSentBy,
            status: this.state.filterStatus === "-1" ? "" : this.state.filterStatus,
            officeLocation: this.state.filterLocation === "-1" ? "" : this.state.filterLocation,
            isArchived: this.props.isArchived,
            taxYear: this.state.filterTaxYear === "-1" ? "" : this.state.filterTaxYear,
            unSelectedIds: this.state.deSelectedRows,
            assignedTo: "",
            reportType: this.props.isArchived ? ReportFilterType.Archived : ReportFilterType.Delivered,
            documentFilingType: this.state.filterDocumentFilingType,
            isPaperFileEnabled: this.paperFileEnabled(),
            quickReportStatus: "",
            groupName: Helper.getGroupName(this.state.filterGroupType, this.props.groupedReturnStore.groupInfo),
            taxSoftware: "",
            createdBy: "",
            globalSearch: "",
            setAccess: SetAccessType.None,
            filterGroupType: this.state.filterGroupType
        };

        return query;
    };

    getQuickReportBulkOperationQuery = (): QuickReportBulkOperationQuery => {
        let quickReportRef: any = this.refs.QuickReturnsTable;
        let query = quickReportRef.getQuickReportBulkOperationQuery();
        return query;
    };

    //======================Grid Operation Starts here===============================
    private onExportToExcel(onExportToExcelComplete: () => void) {
        let downloadType = this.state.selectedDownloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        if (downloadType == DefaultDownloadInfoType.DownloadNow) {
            let queryString = this.buildQuery(this.state.page, pageSize);
            this.props.exportDeliveredReturnsAsExcel(
                queryString,
                onExportToExcelComplete,
                this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.ExportToExcel
            );
        } else if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadToMyDownloads) {
            this.props.exportToExcelBulk(
                this.getBulkOperationQuery(),
                () => {
                    this.props.getMyDownloadsListAsync();
                    onExportToExcelComplete();
                },
                this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.ExportToExcel
            );
        } else {
            onExportToExcelComplete();
            this.setState({
                DownloadTypeOptionState: {
                    show: true,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false
                },
                selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads,
                loadExcelSpinner: true,
                selectedDefaultDownloadType: false
            });
        }
    }

    private onRowSelect(row: any, isSelected: any, e: React.BaseSyntheticEvent) {
        let newList = [...this.state.selectedRows];
        let deSelectedRows = this.state.deSelectedRows;

        if (
            e.target.tagName !== "BUTTON" &&
            e.target.tagName !== "I" &&
            e.target.tagName !== "SPAN" &&
            e.target.tagName !== "A"
        ) {
            if (isSelected) {
                newList.push(row.rowIndex);
                if (this.state.isBulkSelectionEnabled) {
                    let index = deSelectedRows.indexOf(row.id);
                    deSelectedRows.splice(index, 1);
                }
            } else {
                let index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
                if (this.state.isBulkSelectionEnabled) {
                    deSelectedRows.push(row.id);
                }
            }
            if (newList.length === 0) {
                this.setState({
                    showBulkSelectionMessage: false
                });
            }
            if (deSelectedRows.length === this.props.delivered.totalRowCount) {
                this.setState({
                    isBulkSelectionEnabled: false
                });
            }
            this.state.isBulkSelectionEnabled
                ? this.setState({ deSelectedRows: deSelectedRows, selectedRows: newList }, this.setSelectedDocumentsGuid)
                : this.setState({ selectedRows: newList }, this.setSelectedDocumentsGuid);
        }
        this.forceUpdate();
    }

    private onSelectAll(isSelected: any, rows: any, e: any) {
        let selectedRows: number[] = [];
        let showBulkSelectionMessage = false;

        if (isSelected) {
            let count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());

            showBulkSelectionMessage = true;
        }

        this.setState({ isSelectAllChecked: isSelected });

        this.setState(
            {
                selectedRows: selectedRows,
                deSelectedRows: [],
                showBulkSelectionMessage: showBulkSelectionMessage,
                isBulkSelectionEnabled: false
            },
            this.setSelectedDocumentsGuid
        );
    }

    private onPageChange(page: number, sizePerPage: number) {
        if (this.state.isBulkSelectionEnabled) {
            this.setState(
                {
                    page: page
                },
                this.loadDeliveredReturns
            );
        } else {
            this.setState(
                {
                    page: page,
                    selectedRows: [],
                    selectedDocumentGuid: "",
                    showBulkSelectionMessage: false
                },
                this.loadDeliveredReturns
            );
        }
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        this.clearSelection();
        let temp: IFilters = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));

        this.setState(
            {
                filter: temp,
                sortName: sortName,
                sortOrder: sortOrder == "asc" ? "asc" : "desc"
            },
            () => this.fetchDeliveredReturns(1, pageSize)
        );
    }

    private onFilterNameChange(event: any) {
        let temp: IFilters = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp
        });
    }

    private onPageReload() {
        this.setState({ refreshDelay: true }, () => {
            let query = this.buildQuery(this.state.page, this.state.pageSize);
            this.props.requestDeliveredReturns(query, true, () => {
                this.setState({ refreshDelay: false });
            });
            this.clearSelection();
        });
    }

    private onBulkSelectionChange = (isEnabled: boolean) => {
        let showBulkSelectionMessage = false;

        this.setState({
            isBulkSelectionEnabled: isEnabled,
            showBulkSelectionMessage: showBulkSelectionMessage
        });
    };

    private clearSelection = () => {
        this.setState({
            selectedRows: [],
            deSelectedRows: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false,
            selectedDocumentGuid: ""
        });
    };

    //======================Grid Operation Ends here=================================

    //======================Filter Operation Starts here=================================

    private applyDefaultFilter(filter: IFilters) {
        this.setState({ filter: filter }, () => this.fetchDeliveredReturns(1, pageSize));
    }

    private onFilterChange(dataField: any, filterType: ReportFilterType) {
        let newState: any = {
            filterName: "",
            filterClientId: "",
            filterPartner: "",
            filterSentBy: "",
            filterDeliveredOn: undefined,
            filterLastReminderOn: undefined,
            filterRetentionPeriod: undefined,
            filterEngagementType: 0,
            filterDownloadedBy: "",
            filterCustomColumn: "",
            filterDocumentFilingType: 0,
            filterGroupType: -1
        } as any;
        let filterStatus: any;
        let filterTaxYear: any;
        let filterLocation: any;
        let isClearFilter = true;
        var dict: { [columnName: string]: string } = {};

        for (let field of Object.keys(dataField)) {
            var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            dict[field.valueOf().toString()] = data;

            switch (field) {
                case "taxDocumentName":
                    newState.filterName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "clientId":
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "partner":
                    newState.filterPartner = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "deliveredBy":
                    newState.filterSentBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "status":
                    let tempField = dataField[field];
                    if (tempField.value !== undefined) {
                        newState.filterStatus = tempField.value;
                    } else {
                        newState.filterStatus = tempField;
                    }
                    break;
                case "locationName":
                    let tempLocation = dataField[field];
                    if (tempLocation.value !== undefined) {
                        newState.filterLocation = tempLocation.value;
                    } else {
                        newState.filterLocation = tempLocation;
                    }
                    break;
                case "deliveredDate":
                    newState.filterDeliveredOn = Helper.GetNumberOfDays(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    );
                    break;
                case "lastReminder":
                    newState.filterLastReminderOn = moment(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    ).format("MM/DD/YYYY");
                    break;
                case "engagementType":
                    newState.filterEngagementType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "downloaded":
                    newState.filterDownloadedBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "testColumn":
                    newState.filterCustomColumn = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "retentionPeriod":
                    newState.filterRetentionPeriod = moment(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    ).format("MM/DD/YYYY");
                    break;
                case "taxYear":
                    let tempYear = dataField[field];
                    if (tempYear.value !== undefined) {
                        newState.filterTaxYear = tempYear.value;
                    } else {
                        newState.filterTaxYear = tempYear;
                    }
                    break;
                case "documentFilingType":
                    newState.filterDocumentFilingType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "groupName":
                    newState.filterGroupType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
            }
        }
        let tempfilter: IFilters = { ...this.state.filter };
        tempfilter.fields = dict;
        tempfilter.filterType = filterType;
        if (isClearFilter) {
            Helper.SessionStore.remove(this.proxyFilter);
        } else {
            Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
        }
        if (this.isFilterChanged(newState, filterStatus, filterTaxYear, filterLocation)) {
            this.clearSelection();
            this.setState({ ...newState, filter: tempfilter, page: 1, pageSize: pageSize }, () => {
                let deliveredReturnsTable: any = this.tableRef;
                if (!deliveredReturnsTable.isAppiedFilter)
                    this.props.requestDeliveredReturns(this.buildQuery(this.state.page, this.state.pageSize));
            });
        }
    }

    private isFilterChanged(
        newState: DeliveredReturnsState.DeliveredReturnsPageState,
        filterStatus: any,
        filterTaxYear: any,
        filterLocation: any
    ): boolean {
        return (
            newState.filterName !== this.state.filterName ||
            newState.filterClientId !== this.state.filterClientId ||
            newState.filterPartner !== this.state.filterPartner ||
            newState.filterSentBy !== this.state.filterSentBy ||
            filterStatus !== this.state.filterStatus ||
            filterLocation !== this.state.filterLocation ||
            newState.filterDeliveredOn !== this.state.filterDeliveredOn ||
            newState.filterLastReminderOn !== this.state.filterLastReminderOn ||
            newState.filterRetentionPeriod !== this.state.filterRetentionPeriod ||
            newState.filterEngagementType !== this.state.filterEngagementType ||
            newState.filterDownloadedBy !== this.state.filterDownloadedBy ||
            newState.filterCustomColumn !== this.state.filterCustomColumn ||
            filterTaxYear !== this.state.filterTaxYear ||
            newState.filterDocumentFilingType !== this.state.filterDocumentFilingType ||
            newState.filterGroupType !== this.state.filterGroupType
        );
    }

    onFilterSave(onApplyFilter: (filter: IFilters) => void) {
        if (this.state.filter.name.trim() != "") {
            let filter = this.props.reportFilters.filters.find((x) => x.name == this.state.filter.name);
            if (filter) {
                VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterNameAlreadyExists, null);
            } else {
                this.props.addReportFilter(this.state.filter.name, this.state.filter, () => {
                    onApplyFilter(this.state.filter);
                    this.onSaveFilterHide();
                });
            }
        } else {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    onFilterUpdate(name: string) {
        let filter = this.props.reportFilters.filters.find((x) => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterDoesNotExists, null);
        } else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            filter.isDefaultFilter = this.state.filter.isDefaultFilter;
            filter.filterType = this.state.filter.filterType;

            this.props.updateReportFilter(name, filter);
            this.onSaveFilterHide();
        }
    }

    onFilterUpdateForTaxYear = (name: string, taxYearString: string) => {
        let filter = this.props.reportFilters.filters.find((x) => x.name == name);

        if (filter !== undefined) {
            let tempFilterObj = filter.fields !== undefined ? filter.fields : {};
            if (tempFilterObj.taxYear) {
                tempFilterObj.taxYear = taxYearString;

                filter.fields = tempFilterObj;
                filter.name = name;

                this.props.updateReportFilter(name, filter);
            }
        }
    };

    onSetDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.SetDefaultFilterSuccess, null);
    }

    onSetDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.addDefaultFilter(name, filterType, () => {
            this.onSetDefaultFilterSuccess();
        });
    }

    onRemoveDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.RemoveDefaultFilterSuccess, null);
    }

    onRemoveDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.removeDefaultFilter(name, filterType, () => {
            this.onRemoveDefaultFilterSuccess();
        });
        let deliveredReturnsTable: any = this.tableRef;
        deliveredReturnsTable.refs.Filters?.setState({ activeFilter: "", appliedFilters: [] });
        this.onPageReload;
    }

    onFilterDelete(filterName: string, filterType: ReportFilterType) {
        this.props.deleteReportFilter(filterName, filterType);
    }

    onSaveFilterHide() {
        this.setState({
            saveFilterShow: false
        });
    }

    onSaveFilterShow() {
        this.setState({
            saveFilterShow: true
        });
    }

    //======================Filter Operation Ends here=================================

    //------------------EditControllerAndClientInfo Starts here---------------------------------

    private handleEditControllerInfoOpenRequest(taxReturn: ITaxReturn) {
        ShowLoader(Common.OverlayMessages.Processing);
        this.props.requestNextSignerDetails(taxReturn.id, taxReturn.signatureStatus);
        this.props.requestGroupInfo(taxReturn.groupId, (groupInfo: IGroupInfo) => {
            if (!Helper.isClientInfoLoaded(taxReturn)) {
                this.props.requestTaxDocument(
                    taxReturn.id,
                    true,
                    false,
                    undefined,
                    (data) => {
                        this.props.requestSignedDetailsForGroupedReturn(data.id, (signedDetails) => {
                            data.signedDetails = signedDetails;
                            this.setState({
                                editControllerInfoState: {
                                    model: data,
                                    groupInfo: groupInfo,
                                    saveCheckId: undefined,
                                    show: true
                                }
                            });
                            HideLoader();
                        });
                    },
                    true
                );
            } else {
                HideLoader();
                this.setState({
                    editControllerInfoState: {
                        show: true,
                        model: taxReturn ? taxReturn : undefined,
                        groupInfo: groupInfo,
                        saveCheckId: undefined
                    }
                });
            }
        });
    }

    private onGroupReturnClientInfoCancel = () => {
        let item = this.state.editControllerInfoState.model as ITaxReturn;
        this.props.requestTaxDocument(item.id, true, false, undefined, undefined, true);

        this.setState({
            editControllerInfoState: {
                show: false,
                model: undefined,
                groupInfo: undefined,
                saveCheckId: undefined
            }
        });
    };

    private onGroupReturnClientInfoSave = (
        taxReturn: ITaxReturn,
        controllerInfo: IControllerInfo,
        canSendControllerEmailUpdateMail: boolean,
        callback: () => void
    ) => {
        let groupReturnClientInfo: IGroupedReturnsClientInfoModel = initialGroupRetrunClientInfo;
        groupReturnClientInfo.taxDocument = taxReturn;
        groupReturnClientInfo.controllerInfo = controllerInfo;
        groupReturnClientInfo.isEditDeliveredClientInfoRequest = true;
        groupReturnClientInfo.canSendControllerEmailUpdateMail = canSendControllerEmailUpdateMail;
        this.props.saveGroupReturnClientInfoData(
            groupReturnClientInfo,
            () => {
                callback();
                this.setState({
                    editControllerInfoState: {
                        show: false,
                        model: undefined,
                        groupInfo: undefined,
                        saveCheckId: undefined
                    }
                });
            },
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.EditClientInfo
        );
    };

    //------------------EditControllerAndClientInfo Ends here---------------------------------

    //------------------EditClientInfo Starts here---------------------------------

    private getSignedDetails(data: any) {
        let id: number = data.id;
        this.getSignedDetailsById(id);
        this.props.requestNextSignerDetails(id, this.props.taxDocuments[id]?.taxReturn.signatureStatus);
    }

    private loggedInUserLocation(userLocations: number[]) {
        this.setState({ loggedInUserLocations: userLocations });
    }

    private onEditClientInfoOpen(rowIndex: number) {
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        let taxReturn = this.props.taxDocuments[id]?.taxReturn;

        if (taxReturn && taxReturn.groupId > 0) {
            this.handleEditControllerInfoOpenRequest(taxReturn);
        } else {
            this.props.getSMSNotificationDetails(id, true, (smsNotificationDetails: ISMSNotificationDetails) => {
                this.setState({
                    smsNotificationDetails: smsNotificationDetails,
                    isTPOpted: smsNotificationDetails.isTaxPayerSMSOptedIn,
                    isSpouseOpted: smsNotificationDetails.isSpouseSMSOptedIn
                });
            });
            this.props.requestClientManagementSetting();
            if (!Helper.isClientInfoLoaded(taxReturn)) {
                ShowLoader(Common.OverlayMessages.Processing);
                this.props.requestTaxDocument(id, true, undefined, undefined, this.getSignedDetails, undefined);
                this.setState({
                    editClientInfoState: {
                        show: false,
                        model: taxReturn ? taxReturn : undefined,
                        saveCheckId: undefined
                    }
                });
                logger.trackPageView(
                    `Edit client info started for documentId: ${taxReturn.id?.toString()} and documentGuid: ${
                        taxReturn.documentGuid
                    }`,
                    { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                );
                return;
            }

            this.getSignedDetailsById(taxReturn.id);

            this.setState({
                editClientInfoState: {
                    show: true,
                    model: taxReturn ? taxReturn : undefined,
                    saveCheckId: undefined
                },
                smsSettingChanged: false
            });
        }
    }

    private onArchiveTaxReturn = (rowIndex: number = NO_INDEX) => {
        let ids = this.onPopupOpen(rowIndex);
        if (ids.length === 0) {
            VenusNotifier.Warning("Please select document(s) to archive!", null);
            return;
        }
        logger.trackPageView(`archived return started for documentIds: ${ids?.toString()}`);
        let obj = this;
        let model = ids
            .map((x) => {
                let index = this.props.delivered.deliveredReturnTableModel.documents.findIndex((z) => z.document.id === x);
                if (index > NO_INDEX) {
                    return {
                        documentId: x,
                        isGroupedReturn: this.props.delivered.deliveredReturnTableModel.documents[index].document.groupId > 0,
                        groupId: this.props.delivered.deliveredReturnTableModel.documents[index].document.groupId
                    } as ITaxDocumentViewModel;
                } else {
                    return { documentId: 0, isGroupedReturn: false, groupId: 0 } as ITaxDocumentViewModel;
                }
            })
            .filter((y) => y.documentId > 0);
        bootbox.confirm({
            title: '<i className="text-secondary fas fa-archive"></i>Archive TaxReturn',
            message: "Are you sure. Do you want to archive these selected document(s)?",
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.state.isBulkSelectionEnabled
                        ? !this.state.showQuickReports
                            ? this.archiveReturnsBulk()
                            : this.archiveQuickReportReturnsBulk()
                        : this.onArchiveReturnsSave(model);
                }
            }
        });
    };

    private getSignedDetailsById = (id: any) => {
        ShowLoader(Common.OverlayMessages.Processing);
        this.props.requestSignedDetails(id, this.signedDetailsCallback);
    };

    private signedDetailsCallback = () => {
        HideLoader();
        this.setState({
            editClientInfoState: {
                show: true,
                model: this.state.editClientInfoState.model,
                saveCheckId: undefined
            }
        });
    };

    private getLocationName = () => {
        let item = this.state.editClientInfoState.model as ITaxReturn;
        const index = this.props.officeLocation?.locationDropdown?.findIndex(
            (x) => x.value == this.props.taxDocuments[item?.id]?.taxReturn.locationId
        );
        if (index != NO_INDEX) {
            return this.props.officeLocation?.locationDropdown[index].name;
        }
        return "";
    };

    private onEditClientInfoCancel(id: number) {
        let item = this.state.editClientInfoState.model as ITaxReturn;
        this.props.requestTaxDocument(item.id, true);
        this.setState({
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            },
            smsSettingChanged: false
        });
    }

    private onEditClientInfoSave(
        id: number,
        isMailSend: boolean,
        clientGUIDs: string[],
        isEmailUpdated: boolean,
        signerInfo: ISignerModel,
        callback?: any
    ) {
        let taxReturn: ITaxReturn = this.props.taxDocuments[id]?.taxReturn;
        let deliveredBy = taxReturn.deliveredByUser?.userId;
        taxReturn.deliveredBy = deliveredBy;
        if (Helper.validateClientInfo(taxReturn)) {
            this.setState(
                (prevState) => ({
                    editClientSaveState: {
                        ...prevState,
                        id: id,
                        taxReturn: taxReturn,
                        clientGUIDs: clientGUIDs,
                        isEmailUpdated: isEmailUpdated,
                        isMailSend: isMailSend,
                        signerInfo: signerInfo,
                        callback: callback?.bind(this)
                    }
                }),
                () => {
                    let uid =
                        isIndividual(taxReturn) || isMutual(taxReturn)
                            ? taxReturn.taxpayer.ssn
                            : isPartnership(taxReturn)
                            ? taxReturn.partnership.ssn
                            : "";

                    Helper.getClientInfoByClientId(taxReturn.clientId, uid, this.compareClientInfo);
                }
            );
        }
    }

    private isUserHasAccessToDocumentLocation = () => {
        let item = this.state.editClientInfoState.model as ITaxReturn;
        const taxReturn: ITaxReturn = Object.assign({}, this.props.taxDocuments[item.id]?.taxReturn);
        const index = this.state.loggedInUserLocations.findIndex((x) => x == taxReturn.locationId);
        return index != NO_INDEX;
    };

    private setLoadingMessageToInitialState = () => {
        this.setState({ loadingMessage: false });
    };

    private onCloseLocationMismatchActionModal = () => {
        this.setState({ showLocationMismatchActionModel: false, popupInProgress: false, loadingMessage: true });
    };

    private onContinueLocationMismatchActionModal = () => {
        this.setState({ showLocationMismatchActionModel: false }, () => {
            compareClientInfo(
                getNewClientInfo(
                    this.state.editClientSaveState.taxReturn,
                    this.state.clientBasicInfoId,
                    this.state.clientInfo,
                    this.getLocationName()
                ),
                this.setClientInfoComparisonResult,
                this.onSecondaryButtonClick,
                this.newClientCallback,
                this.onAutoCMUpdate,
                this.setClientBasicInfoId
            );
        });
    };

    private compareClientInfo = (clientInfo: IClientInfoViewModel) => {
        this.setState({ clientInfo: clientInfo });
        if (this.isUserHasAccessToDocumentLocation()) {
            compareClientInfo(
                getNewClientInfo(
                    this.state.editClientSaveState.taxReturn,
                    this.state.clientBasicInfoId,
                    this.state.clientInfo,
                    this.getLocationName()
                ),
                this.setClientInfoComparisonResult,
                this.onSecondaryButtonClick,
                this.newClientCallback,
                this.onAutoCMUpdate,
                this.setClientBasicInfoId,
                (oldClientId: string) => {
                    this.setState({ oldClientId: oldClientId });
                }
            );
        } else {
            this.setState({ showLocationMismatchActionModel: true });
        }
    };

    private sendMail(id: number, isMailSend: boolean, clientGUIDs: string[], signerInfo?: ISignerModel): void {
        let taxReturn: any = this.props.taxDocuments[id]?.taxReturn;

        if (isMailSend) {
            if (
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.AwaitingESign] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.AwaitingUpload] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.ManuallySigned] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.PartiallySigned]
            ) {
                logger.trackTrace(
                    `sendClientInfoMail for DocumentId: ${taxReturn.id?.toString()} and DocumentGuid: ${taxReturn.documentGuid}`,
                    { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                );
                this.props.sendClientInfoMail(
                    id,
                    clientGUIDs,
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.GridPrefix +
                        DeliveredReturnReportResourceId.EditClientInfo
                );
            } else if (
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.ESigned] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.ESignedInOffice] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.SignedAndESigned] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.Uploaded]
            ) {
                logger.trackTrace(
                    `SendDownloadTaxReturnMailOnDeliveredEmailAddressChange for DocumentId: ${taxReturn.id?.toString()} and DocumentGuid: ${
                        taxReturn.documentGuid
                    }`,
                    { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                );
                this.props.SendDownloadTaxReturnMailOnDeliveredEmailAddressChange(
                    id,
                    clientGUIDs,
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.GridPrefix +
                        DeliveredReturnReportResourceId.EditClientInfo
                );
            }
        }

        if (signerInfo !== undefined) {
            var model = {
                ...taxReturn.signedDetails.find((x) => x.signer == signerInfo.signer && x.signerType == signerInfo.signerType)
            };
            if (ClientTypesNumber.Taxpayer == model.signerType) {
                model.clientGuid = taxReturn.taxpayer.clientGuid;
                model.signer = taxReturn.taxpayer.email;
            } else {
                model.clientGuid = taxReturn.spouse.clientGuid;
                model.signer = taxReturn.spouse.email;
            }

            this.props.resendAccessLink(
                taxReturn.id,
                model,
                this.props.parentResourceId +
                    DeliveredReturnReportResourceId.GridPrefix +
                    DeliveredReturnReportResourceId.EditClientInfo
            );
        }
    }

    private onSaveTaxDocument = () => {
        let taxReturn: ITaxReturn = this.state.editClientSaveState.taxReturn;
        logger.trackTrace(
            `Edit CLient Info in Delivered Reports Screen for DocumentId: ${taxReturn.id?.toString()} and DocumentGuid: ${
                taxReturn.documentGuid
            }`,
            { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
        );
        this.updateSMSNotificationDetails(taxReturn);
        this.props.saveTaxDocument(
            taxReturn,
            undefined,
            (data) => {
                this.state.editClientSaveState.callback && this.state.editClientSaveState.callback();
                if (this.state.editClientSaveState.isEmailUpdated) {
                    this.onTaxDocumentUpdated(taxReturn);
                }
                let query = this.buildQuery(this.state.page, this.state.pageSize);
                this.props.requestDeliveredReturns(query, true);
                this.setState({
                    editClientSaveState: DeliveredReturnsState.intialEditClientSaveState
                });
                if (this.state.smsSettingChanged) {
                    this.props.documentSMSSettingChangeAsync(taxReturn.id);
                }
            },
            this.sendMail,
            this.state.editClientSaveState.isMailSend,
            this.state.editClientSaveState.clientGUIDs,
            true,
            this.state.editClientSaveState.signerInfo,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.EditClientInfo
        );
    };

    //#region ClientInfo Comparision
    private setClientInfoComparisonResult = (clientInfoComparisonResult: IClientInfoComparisonResult) => {
        this.setState({
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: this.state.editClientSaveState.id
            }
        });
        this.setState({ clientInfoComparisonResult }, () => {
            this.setState({ showMismatchModal: true, popupInProgress: false });
        });
    };

    private setClientBasicInfoId = (id: number) => {
        this.setState({ clientBasicInfoId: id });
    };
    private onPrimaryButtonClick = () => {
        if (this.state.clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.NewClient) {
            this.addNewClientInCM();
        } else {
            this.editClientInfoInCM();
        }

        this.setState({ showMismatchModal: false, popupInProgress: true });
        this.setState({
            editClientInfoState: {
                show: true,
                model: undefined,
                saveCheckId: this.state.editClientSaveState.id
            }
        });
    };

    private addNewClientInCM = () => {
        const clientInfo = getNewClientInfo(
            this.state.editClientSaveState.taxReturn,
            this.state.clientBasicInfoId,
            this.state.clientInfo,
            this.getLocationName()
        );
        addNewClientInfo(clientInfo, this.onSaveTaxDocument);
    };

    private onAutoCMUpdate = () => {
        this.setState({
            showMismatchModal: false,
            popupInProgress: false
        });
        this.editClientInfoInCM();
    };

    private editClientInfoInCM = () => {
        const clientInfo = getNewClientInfo(
            this.state.editClientSaveState.taxReturn,
            this.state.clientBasicInfoId,
            this.state.clientInfo,
            this.getLocationName()
        );
        editClientInfo(clientInfo, this.onSaveTaxDocument);

        if (
            this.state.clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.DataMismatch ||
            this.state.clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.SSNMismatch ||
            this.state.clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.ClientIdMismatch
        ) {
            const clientInfoResultData = this.state.clientInfoComparisonResult.clientInfoResultData;
            const propertiesWithDifference = Object.keys(clientInfoResultData).reduce((result, property) => {
                if (clientInfoResultData[property]?.hadDifference === true) {
                    result[property] = clientInfoResultData[property].taxReturnValue;
                }
                return result;
            }, {});

            propertiesWithDifference["ProductType"] = "Returns";
            logger.trackTrace("Client-Info Updated", propertiesWithDifference);
        }
    };

    private onSecondaryButtonClick = () => {
        this.setState({
            showMismatchModal: false,
            popupInProgress: false
        });
        this.onSaveTaxDocument();
        this.setState({
            editClientInfoState: {
                show: true,
                model: undefined,
                saveCheckId: this.state.editClientSaveState.id
            }
        });
    };

    private newClientCallback = (clientInfo: IClientInfoViewModel) => {
        switch (this.props.clientManagementSetting.reconcileType) {
            case ClientManagementSettingStore.ReconcileType.Ignore: {
                this.onSecondaryButtonClick();
                break;
            }
            case ClientManagementSettingStore.ReconcileType.Add: {
                this.setState(
                    {
                        showMismatchModal: false,
                        popupInProgress: false
                    },
                    () => this.addNewClientInCM()
                );
                this.setState({
                    editClientInfoState: {
                        show: true,
                        model: undefined,
                        saveCheckId: this.state.editClientSaveState.id
                    }
                });
                break;
            }
            default: {
                this.setClientInfoComparisonResult(setNewClientInfoComparisonResult(clientInfo));
                break;
            }
        }
    };

    private closeMismatchModal = () => {
        this.state.editClientSaveState.callback && this.state.editClientSaveState.callback();
        this.setState({
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: 0
            }
        });
        this.setState({ showMismatchModal: false, popupInProgress: false });
    };

    //#endregion ClientInfo Comparision
    //-------------------EditClientInfo Ends Here--------------------------------

    //====================SetAccess Starts Here=======================================

    private onSetAccessOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning("Please select document(s) to set access for other users!", null);
            return;
        }
        logger.trackPageView(`Set access started for documentIds: ${ids?.toString()}`);
        this.props.requestAllSSRUsers(false);
        this.lazyLoadSelected(ids);
        let accessMaps = ids
            .map((x) => {
                let index = this.props.delivered.deliveredReturnTableModel.documents.findIndex((z) => z.document.id === x);
                if (index > NO_INDEX) {
                    return {
                        documentId: x,
                        isGroupedReturn: this.props.delivered.deliveredReturnTableModel.documents[index].document.groupId > 0,
                        groupId: this.props.delivered.deliveredReturnTableModel.documents[index].document.groupId
                    } as ITaxDocumentViewModel;
                } else {
                    return { documentId: 0, isGroupedReturn: false, groupId: 0 } as ITaxDocumentViewModel;
                }
            })
            .filter((y) => y.documentId > 0);
        this.props.getDocumentAccess(
            accessMaps,
            true,
            (result: IDocumentAccess) => {
                this.setState({
                    availableUserGroups: result.availableUserGroups,
                    availableUsers: result.availableUsers,
                    selectedUserGroups: result.selectedUserGroups,
                    selectedUsers: result.selectedUsers
                });

                let selectedAssignments = ids.map((id, i) => {
                    return this.props.taxDocuments[id]?.taxReturn;
                });

                const isGroupedReturnExists = selectedAssignments.some((x) => x.groupId > 0);
                this.setState({
                    setAccessState: {
                        show: true,
                        selected: selectedAssignments,
                        saveCheckIds: undefined,
                        remove: false
                    },
                    isGroupedReturnExists: isGroupedReturnExists
                });
            },
            this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.SetAccess_Header
        );
    }

    private onSetAccessSave(accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) {
        let ids = this.selectedDocumentIds();
        let accessMaps: ITaxDocumentAccessViewModel = {
            taxDocuments: ids
                .map((x) => {
                    let index = this.props.delivered.deliveredReturnTableModel.documents.findIndex((z) => z.document.id === x);
                    if (index > NO_INDEX) {
                        return {
                            documentId: x,
                            isGroupedReturn: this.props.delivered.deliveredReturnTableModel.documents[index].document.groupId > 0,
                            groupId: this.props.delivered.deliveredReturnTableModel.documents[index].document.groupId
                        } as ITaxDocumentViewModel;
                    } else {
                        return { documentId: 0, isGroupedReturn: false, groupId: 0 } as ITaxDocumentViewModel;
                    }
                })
                .filter((y) => y.documentId > 0),
            userGroups: accessingUsersAndUserGroups.selectedUserGroups,
            users: accessingUsersAndUserGroups.selectedUsers,
            documents: []
        };

        let quickReportRef: any = this.refs.QuickReturnsTable;
        let callback = this.state.showQuickReports === false ? this.onPageReload : quickReportRef.onPageReload;

        this.props.setTaxDocumentsAccessMaps(
            accessMaps,
            callback,
            this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.SetAccess_Header
        );
        logger.trackTrace(
            `Set access saved successfully for documentIds: ${ids?.toString()} and userIds: ${accessingUsersAndUserGroups.selectedUsers?.toString()}`
        );
        this.setState({
            setAccessState: {
                show: true,
                selected: undefined,
                saveCheckIds: ids,
                remove: false
            },
            selectedRows: [],
            selectedDocumentGuid: "",
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: [],
            isGroupedReturnExists: false
        });
    }

    private onSetAccessCancel() {
        this.setState({
            setAccessState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            },
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: [],
            isGroupedReturnExists: false
        });
    }

    //====================SetAccess Ends Here=======================================

    //====================ReportProblem Starts Here=================================

    private onReportProblemOpen(rowIndex: number) {
        const id =
            this.props.delivered.deliveredReturnTableModel.documents[
                this.state.processingDocumentId > 0 ? this.PROCESSINGROWINDEX : rowIndex
            ].document.id;
        if (!Helper.isClientInfoLoaded(this.props.taxDocuments[id]?.taxReturn)) {
            this.props.requestTaxDocument(this.props.taxDocuments[id]?.taxReturn.id, true, false, undefined, undefined, true);
        }
        logger.trackPageView(
            `Report Problem started for documentId: ${id?.toString()} and documentguid: ${
                this.props.taxDocuments[id]?.taxReturn.documentGuid
            }`,
            { DocumentId: id?.toString(), DocumentGuid: this.props.taxDocuments[id]?.taxReturn.documentGuid }
        );
        this.setState({
            reportProblemState: {
                show: true,
                model: this.getStoreItem(this.state.processingDocumentId > 0 ? this.PROCESSINGROWINDEX : rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onReportProblemCancel() {
        this.setState({
            reportProblemState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onReportProblemSave(rowIndex: number, problemDetails: IReportProblemDetails, callback?: () => void) {
        rowIndex = this.state.processingDocumentId > 0 ? this.PROCESSINGROWINDEX : rowIndex;
        const id = this.props.delivered.deliveredReturnTableModel.documents.findIndex((x) => x.document.id == rowIndex);
        this.props.reportTaxDocumentProblem(
            problemDetails,
            callback,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.ReportAProblem
        );
        this.setState({
            reportProblemState: {
                show: false,
                model: undefined,
                saveCheckId: rowIndex
            }
        });
    }

    //====================ReportProblem Ends Here=================================

    //====================ArchiveReturns Starts Here=================================

    private onArchiveReturnsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning("Please select document(s) to archive!", null);
            return;
        }
        logger.trackPageView(`archived return started for documentIds: ${ids?.toString()}`);
        let obj = this;
        let model = ids
            .map((x) => {
                let documents = this.state.showQuickReports
                    ? this.props.quickReports.quickReportTableModel.documents
                    : this.props.delivered.deliveredReturnTableModel.documents;
                let index = documents.findIndex((z) => z.document.id === x);
                if (index > NO_INDEX) {
                    return {
                        documentId: x,
                        isGroupedReturn: documents[index].document.groupId > 0,
                        groupId: documents[index].document.groupId
                    } as ITaxDocumentViewModel;
                } else {
                    return { documentId: 0, isGroupedReturn: false, groupId: 0 } as ITaxDocumentViewModel;
                }
            })
            .filter((y) => y.documentId > 0);
        bootbox.confirm({
            title: '<i className="text-secondary fas fa-archive"></i>Archive TaxReturn',
            message: "Are you sure. Do you want to archive these selected document(s)?",
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.state.isBulkSelectionEnabled
                        ? !this.state.showQuickReports
                            ? this.archiveReturnsBulk()
                            : this.archiveQuickReportReturnsBulk()
                        : this.onArchiveReturnsSave(model);
                }
            }
        });
    }

    private onArchiveReturnsSave(model: ITaxDocumentViewModel[]) {
        let callback = !this.state.showQuickReports
            ? this.clearFilters
            : () => {
                  let quickReportRef: any = this.refs.QuickReturnsTable;
                  this.setState({ selectedRows: [], selectedDocumentGuid: "" }, quickReportRef.onPageReload);
              };
        this.props.archiveTaxDocument(
            model,
            callback,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.HeaderPrefix +
                DeliveredReturnReportResourceId.ArchiveReturn
        );
        //changing the page number when all documents from last page is archived
        if (this.state.showQuickReports === false) {
            let totalPages = Math.ceil(this.props.delivered.totalRowCount / pageSize);
            if (
                this.state.page === totalPages &&
                this.props.delivered.deliveredReturnTableModel.documents.length === this.state.selectedRows.length &&
                totalPages !== 1
            ) {
                this.setState({ page: totalPages - 1 });
            }
        }
        this.setState({
            archiveReturnsState: {
                show: false,
                selected: undefined,
                saveCheckIds: model.map((i) => i.documentId),
                remove: false
            }
        });
    }

    private archiveReturnsBulk = () => {
        let query = this.getBulkOperationQuery();

        this.props.archiveDocumentsBulk(
            query,
            () => {
                this.setState(
                    {
                        archiveReturnsState: {
                            show: false,
                            selected: undefined,
                            saveCheckIds: undefined,
                            remove: false
                        },
                        deSelectedRows: [],
                        selectedRows: [],
                        selectedDocumentGuid: "",
                        isBulkSelectionEnabled: false
                    },
                    () => {
                        logger.trackTrace(`return archived successfully for clientId: ${query.clientId}`, {
                            ClientId: query.clientId
                        });
                    }
                );
            },
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.HeaderPrefix +
                DeliveredReturnReportResourceId.ArchivePrefix
        );
    };

    private archiveQuickReportReturnsBulk = () => {
        let query = this.getQuickReportBulkOperationQuery();

        this.props.archiveQuickReportDocumentsBulk(
            query,
            () => {
                this.setState({
                    archiveReturnsState: {
                        show: false,
                        selected: undefined,
                        saveCheckIds: undefined,
                        remove: false
                    },
                    deSelectedRows: [],
                    selectedRows: [],
                    selectedDocumentGuid: "",
                    isBulkSelectionEnabled: false
                });
            },
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.HeaderPrefix +
                DeliveredReturnReportResourceId.ArchiveReturn
        );
    };
    //====================ArchiveReturns Ends Here=================================

    //====================RestoreReturns Starts Here=================================

    private onRestoreReturnsOpen = (rowIndex: number = NO_INDEX, resourceId: string) => {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length === 0) {
            VenusNotifier.Warning(ArchiveReturnsConstants.PleaseSelectDocumentToRestore, null);
            return;
        }
        logger.trackPageView(`Return restore started for documentIds: ${ids?.toString()}`);

        let model = ids
            .map((x) => {
                let documents = this.state.showQuickReports
                    ? this.props.quickReports.quickReportTableModel.documents
                    : this.props.delivered.deliveredReturnTableModel.documents;
                let index = documents.findIndex((z) => z.document.id === x);
                if (index > NO_INDEX) {
                    return {
                        documentId: x,
                        isGroupedReturn: documents[index].document.groupId > 0,
                        groupId: documents[index].document.groupId
                    } as ITaxDocumentViewModel;
                } else {
                    return { documentId: 0, isGroupedReturn: false, groupId: 0 } as ITaxDocumentViewModel;
                }
            })
            .filter((y) => y.documentId > 0);

        bootbox.confirm({
            title: '<i className="text-secondary"></i>Restore Tax Return',
            message: ArchiveReturnsConstants.documentRestoreConfirmation,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.onRestoreReturnsSave(model, resourceId);
                }
            }
        });
    };

    private onRestoreReturnsSave = (model: ITaxDocumentViewModel[], resourceId: string) => {
        let callback = !this.state.showQuickReports
            ? this.clearFilters
            : () => {
                  let quickReportRef: any = this.refs.QuickReturnsTable;
                  this.setState({ selectedRows: [], selectedDocumentGuid: "" }, quickReportRef.onPageReload);
              };
        this.props.restoreArchivedTaxDocument(model, callback, resourceId);
        this.setState({
            restoreReturnsState: {
                show: false,
                selected: undefined,
                saveCheckIds: model.map((i) => i.documentId),
                remove: false
            }
        });
    };

    //====================RestoreReturns Ends Here=================================

    //====================ChangeStatus Starts Here=================================

    private onChangeStatusOpen(rowIndex: number = NO_INDEX) {
        let selectedDocumentIds = this.selectedDocumentIds();
        let selectedDocuments = this.selectedDocuments();
        let _selectedStatus = this.state.selectedStatus;

        if (selectedDocumentIds.length == 0) {
            VenusNotifier.Error("Error: Please select documents to change the status.", null);
        } else {
            logger.trackPageView(`Change status started for documentIds: ${selectedDocumentIds?.toString()}`);
            selectedDocumentIds.map((id, i) => {
                if (this.props.taxDocuments[id].isFullyLoaded == undefined || !this.props.taxDocuments[id].isFullyLoaded) {
                    this.props.requestTaxDocument(id, true);
                }
            });

            let _signatureStatusList: DeliveredReturnsState.ISignatureStatusList[] = [];
            let _signatureStatusAwaitingEsign: any = SignatureStatus[SignatureStatus.AwaitingESign];
            let _signatureStatusAwaitingUpload: any = SignatureStatus[SignatureStatus.AwaitingUpload];
            let _signatureStatusManuallySigned: any = SignatureStatus[SignatureStatus.ManuallySigned];
            let _signatureStatusPartiallySigned: any = SignatureStatus[SignatureStatus.PartiallySigned];

            if (
                selectedDocuments.every((i: any) => i.document.signatureStatus == _signatureStatusAwaitingEsign) ||
                selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusAwaitingUpload) ||
                selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusManuallySigned) ||
                selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusPartiallySigned)
            ) {
                if (selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusAwaitingEsign)) {
                    if (selectedDocuments.every((i) => i.signedCount == 0) == true) {
                        _signatureStatusList.push({
                            value: SignatureStatus.AwaitingESign,
                            name: SignatureStatus[SignatureStatus.AwaitingESign]
                        });
                        _signatureStatusList.push({
                            value: SignatureStatus.ManuallySigned,
                            name: SignatureStatus[SignatureStatus.ManuallySigned]
                        });
                        _selectedStatus = SignatureStatus.AwaitingESign;
                    }

                    //Exception condition in case of both type of AwaitingESign Documents are selected together
                    else if (selectedDocuments.some((i) => i.signedCount == 0)) {
                        VenusNotifier.Error("Error : Status cannot be changed.", null);
                    } else {
                        _signatureStatusList.push({
                            value: SignatureStatus.AwaitingESign,
                            name: SignatureStatus[SignatureStatus.AwaitingESign]
                        });
                        _signatureStatusList.push({
                            value: SignatureStatus.SignedAndESigned,
                            name: SignatureStatus[SignatureStatus.SignedAndESigned]
                        });
                        _selectedStatus = SignatureStatus.AwaitingESign;
                    }
                } else if (selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusPartiallySigned)) {
                    _signatureStatusList.push({
                        value: SignatureStatus.PartiallySigned,
                        name: SignatureStatus[SignatureStatus.PartiallySigned]
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.SignedAndESigned,
                        name: SignatureStatus[SignatureStatus.SignedAndESigned]
                    });
                    _selectedStatus = SignatureStatus.PartiallySigned;
                } else if (selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusAwaitingUpload)) {
                    _signatureStatusList.push({
                        value: SignatureStatus.AwaitingUpload,
                        name: SignatureStatus[SignatureStatus.AwaitingUpload]
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.ManuallySigned,
                        name: SignatureStatus[SignatureStatus.ManuallySigned]
                    });
                    _selectedStatus = SignatureStatus.AwaitingUpload;
                } else if (selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusManuallySigned)) {
                    _signatureStatusList.push({
                        value: SignatureStatus.ManuallySigned,
                        name: SignatureStatus[SignatureStatus.ManuallySigned]
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.AwaitingUpload,
                        name: SignatureStatus[SignatureStatus.AwaitingUpload]
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.AwaitingESign,
                        name: SignatureStatus[SignatureStatus.AwaitingESign]
                    });
                    _selectedStatus = SignatureStatus.ManuallySigned;
                }

                // Check if status list is empty before changing state
                if (_signatureStatusList.length > 0) {
                    this.setState({
                        signatureStatusList: _signatureStatusList,
                        selectedStatus: _selectedStatus,
                        changeStatusState: {
                            show: true,
                            selected: undefined,
                            saveCheckIds: undefined,
                            remove: false
                        },
                        showLoader: false
                    });
                }
            } else if (
                selectedDocuments.every(
                    (i) =>
                        i.document.signatureStatus == _signatureStatusAwaitingUpload ||
                        i.document.signatureStatus == _signatureStatusAwaitingEsign
                )
            ) {
                _signatureStatusList.push({
                    value: SignatureStatus.ManuallySigned,
                    name: SignatureStatus[SignatureStatus.ManuallySigned]
                });
                _selectedStatus = SignatureStatus.ManuallySigned;

                this.setState({
                    signatureStatusList: _signatureStatusList,
                    selectedStatus: _selectedStatus,
                    changeStatusState: {
                        show: true,
                        selected: undefined,
                        saveCheckIds: undefined,
                        remove: false
                    },
                    showLoader: false
                });
            } else {
                VenusNotifier.Error("Error : Status cannot be changed.", null);
            }
        }
    }

    private onChangeStatusForQuickReportOpen(rowIndex: number = NO_INDEX) {
        let selectedDocumentIds = this.selectedDocumentIds();
        let selectedDocuments = this.selectedQuickReportDocuments();
        let _selectedStatus = this.state.selectedStatus;

        if (selectedDocumentIds.length == 0) {
            VenusNotifier.Error("Error: Please select documents to change the status.", null);
        } else {
            selectedDocumentIds.map((id, i) => {
                if (this.props.taxDocuments[id].isFullyLoaded == undefined || !this.props.taxDocuments[id].isFullyLoaded) {
                    this.props.requestTaxDocument(id, true);
                }
            });

            let _signatureStatusList: DeliveredReturnsState.ISignatureStatusList[] = [];
            let _signatureStatusAwaitingEsign: any = SignatureStatus[SignatureStatus.AwaitingESign];
            let _signatureStatusAwaitingUpload: any = SignatureStatus[SignatureStatus.AwaitingUpload];
            let _signatureStatusManuallySigned: any = SignatureStatus[SignatureStatus.ManuallySigned];
            let _signatureStatusPartiallySigned: any = SignatureStatus[SignatureStatus.PartiallySigned];

            if (
                selectedDocuments.every((i: any) => i.document.signatureStatus == _signatureStatusAwaitingEsign) ||
                selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusAwaitingUpload) ||
                selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusManuallySigned) ||
                selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusPartiallySigned)
            ) {
                if (selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusAwaitingEsign)) {
                    if (selectedDocuments.every((i) => i.signedCount == 0) == true) {
                        _signatureStatusList.push({
                            value: SignatureStatus.AwaitingESign,
                            name: SignatureStatus[SignatureStatus.AwaitingESign]
                        });
                        _signatureStatusList.push({
                            value: SignatureStatus.ManuallySigned,
                            name: SignatureStatus[SignatureStatus.ManuallySigned]
                        });
                        _selectedStatus = SignatureStatus.AwaitingESign;
                    }

                    //Exception condition in case of both type of AwaitingESign Documents are selected together
                    else if (selectedDocuments.some((i) => i.signedCount == 0)) {
                        VenusNotifier.Error("Error : Status cannot be changed.", null);
                    } else {
                        _signatureStatusList.push({
                            value: SignatureStatus.AwaitingESign,
                            name: SignatureStatus[SignatureStatus.AwaitingESign]
                        });
                        _signatureStatusList.push({
                            value: SignatureStatus.SignedAndESigned,
                            name: SignatureStatus[SignatureStatus.SignedAndESigned]
                        });
                        _selectedStatus = SignatureStatus.AwaitingESign;
                    }
                } else if (selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusPartiallySigned)) {
                    _signatureStatusList.push({
                        value: SignatureStatus.PartiallySigned,
                        name: SignatureStatus[SignatureStatus.PartiallySigned]
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.SignedAndESigned,
                        name: SignatureStatus[SignatureStatus.SignedAndESigned]
                    });
                    _selectedStatus = SignatureStatus.PartiallySigned;
                } else if (selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusAwaitingUpload)) {
                    _signatureStatusList.push({
                        value: SignatureStatus.AwaitingUpload,
                        name: SignatureStatus[SignatureStatus.AwaitingUpload]
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.ManuallySigned,
                        name: SignatureStatus[SignatureStatus.ManuallySigned]
                    });
                    _selectedStatus = SignatureStatus.AwaitingUpload;
                } else if (selectedDocuments.every((i) => i.document.signatureStatus == _signatureStatusManuallySigned)) {
                    _signatureStatusList.push({
                        value: SignatureStatus.ManuallySigned,
                        name: SignatureStatus[SignatureStatus.ManuallySigned]
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.AwaitingUpload,
                        name: SignatureStatus[SignatureStatus.AwaitingUpload]
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.AwaitingESign,
                        name: SignatureStatus[SignatureStatus.AwaitingESign]
                    });
                    _selectedStatus = SignatureStatus.ManuallySigned;
                }

                // Check if status list is empty before changing state
                if (_signatureStatusList.length > 0) {
                    this.setState({
                        signatureStatusList: _signatureStatusList,
                        selectedStatus: _selectedStatus,
                        changeStatusState: {
                            show: true,
                            selected: undefined,
                            saveCheckIds: undefined,
                            remove: false
                        },
                        showLoader: false
                    });
                }
            } else if (
                selectedDocuments.every(
                    (i) =>
                        i.document.signatureStatus == _signatureStatusAwaitingUpload ||
                        i.document.signatureStatus == _signatureStatusAwaitingEsign
                )
            ) {
                _signatureStatusList.push({
                    value: SignatureStatus.ManuallySigned,
                    name: SignatureStatus[SignatureStatus.ManuallySigned]
                });
                _selectedStatus = SignatureStatus.ManuallySigned;

                this.setState({
                    signatureStatusList: _signatureStatusList,
                    selectedStatus: _selectedStatus,
                    changeStatusState: {
                        show: true,
                        selected: undefined,
                        saveCheckIds: undefined,
                        remove: false
                    },
                    showLoader: false
                });
            } else {
                VenusNotifier.Error("Error : Status cannot be changed.", null);
            }
        }
    }

    private onChangeStatusCancel() {
        this.setState({
            changeStatusState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            }
        });
    }

    private onChangeStatusSave(status: SignatureStatus, reason: string) {
        const changeStatusResourceId =
            this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.ChangeStatus_Header;
        let ids = this.selectedDocumentIds();
        ids.forEach((id, i) => {
            let taxDocument = this.props.taxDocuments[id]?.taxReturn;
            taxDocument.signatureStatus = status;
            taxDocument.deliveredBy = this.props.taxDocuments[id]?.taxReturn.deliveredByUser?.userId;
            taxDocument.isModified = true;
            if (
                taxDocument.signatureStatus == SignatureStatus.AwaitingESign ||
                taxDocument.signatureStatus == SignatureStatus.PartiallySigned
            ) {
                taxDocument.documentSettings.documentSignatureSetting.signatureFormSelectionType = SignatureType.ESign;
            }
            if (
                taxDocument.signatureStatus == SignatureStatus.AwaitingUpload ||
                taxDocument.signatureStatus == SignatureStatus.ManuallySigned
            ) {
                taxDocument.documentSettings.documentSignatureSetting.signatureFormSelectionType = SignatureType.ManualSign;
            }
            if (
                taxDocument.documentStatus == DocumentStatus.DELIVERED &&
                (taxDocument.signatureStatus == SignatureStatus.ESigned ||
                    taxDocument.signatureStatus == SignatureStatus.ESignedInOffice ||
                    taxDocument.signatureStatus == SignatureStatus.ManuallySigned ||
                    taxDocument.signatureStatus == SignatureStatus.SignedAndESigned)
            ) {
                taxDocument.documentStatus = DocumentStatus.USERSIGNED;
            }
            this.props.saveTaxDocument(
                taxDocument,
                undefined,
                (data) => {
                    if (status === SignatureStatus.SignedAndESigned) {
                        taxDocument.groupId === 0 || !taxDocument.groupId
                            ? this.props.cancelSigningReminder(taxDocument, changeStatusResourceId)
                            : this.props.cancelGroupedReturnSigningReminder(taxDocument);
                        this.props.UpdateStatusToSignedAndESigned(
                            taxDocument,
                            reason,
                            this.props.parentResourceId +
                                DeliveredReturnReportResourceId.HeaderPrefix +
                                CommonResources.ChangeStatus_Header
                        );
                    }
                    if (status == SignatureStatus.ManuallySigned) {
                        this.props.CleanUpSigningOrder(taxDocument, changeStatusResourceId);
                        this.props.statusChangeEvent(taxDocument, false, changeStatusResourceId);
                        taxDocument.groupId === 0 || !taxDocument.groupId
                            ? this.props.cancelSigningReminder(taxDocument, changeStatusResourceId)
                            : this.props.cancelGroupedReturnSigningReminder(taxDocument);

                        this.props.LogChangeStatusToManaullySignedTransaction(taxDocument, changeStatusResourceId);
                        if (
                            taxDocument.documentSettings.deliverySettings.reportingEngine &&
                            taxDocument.documentSettings.deliverySettings.reportingEngine === ReportingEngineType.TaxCaddy &&
                            taxDocument.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy === false
                        )
                            this.props.SubmitReturnsToTaxCaddy(taxDocument, changeStatusResourceId);
                    }
                    if (status === SignatureStatus.AwaitingESign || status === SignatureStatus.PartiallySigned) {
                        this.props.deleteProcessInfo(taxDocument.id, changeStatusResourceId);
                        this.props.statusChangeEvent(taxDocument, false, changeStatusResourceId);
                    }
                    if (status === SignatureStatus.AwaitingUpload) {
                        this.props.statusChangeEvent(taxDocument, false, changeStatusResourceId);
                    }
                    if (this.state.showQuickReports === false) {
                        let query = this.buildQuery(this.state.page, this.state.pageSize);
                        this.props.requestDeliveredReturns(query, true);
                    } else {
                        let quickReportRef: any = this.refs.QuickReturnsTable;
                        quickReportRef.onPageReload();
                    }
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                changeStatusResourceId
            );
        });
        logger.trackTrace(`status change saved successfully for documentIds: ${ids?.toString()}`);
        this.setState({
            changeStatusState: {
                show: true,
                selected: undefined,
                saveCheckIds: ids,
                remove: false
            },
            showLoader: true
        });
    }

    //====================ChangeStatus Ends Here=================================

    //====================CustomColumn Starts Here=================================

    private onCustomColumnOpen(rowIndex: number = NO_INDEX) {
        let selectedDocumentIds = this.selectedDocumentIds();
        if (selectedDocumentIds.length == 0) {
            VenusNotifier.Error("Error: Please select one or more documents.", null);
        } else {
            logger.trackPageView(`custom column started for documentIds: ${selectedDocumentIds?.toString()}`);
            selectedDocumentIds.map((id, i) => {
                if (this.props.taxDocuments[id].isFullyLoaded == undefined || !this.props.taxDocuments[id].isFullyLoaded) {
                    this.props.requestTaxDocument(id, true);
                }
            });

            this.setState({
                customColumnState: {
                    show: true,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false
                },
                hideCustomCulumnLoader: false,
                selectedColumnValue: this.getSelectedCustomColumnValue(selectedDocumentIds)
            });
        }
    }

    getSelectedCustomColumnValue(selectedDocumentIds: number[]) {
        // method to fetch selected document custom column value
        if (selectedDocumentIds.length == 1) {
            let customColumn = this.props.delivered.deliveredReturnTableModel.documents.filter(
                (x) => x.document.id == selectedDocumentIds[0]
            )[0].customColumn;
            return customColumn !== "" && customColumn !== undefined
                ? this.props.customColumnData.customColumn.customColumnValues.filter((x) => x.value === customColumn)[0]?.id
                : this.props.userSettings.settings.defaultSettings &&
                  this.props.userSettings.settings.defaultSettings.customColumnValue !== 0 &&
                  this.props.userSettings.settings.defaultSettings.customColumnValue !== undefined
                ? this.props.userSettings.settings.defaultSettings.customColumnValue
                : 0;
        } else {
            let columnValue: string[] = [];
            selectedDocumentIds.map((id, i) => {
                let customColumn = this.props.delivered.deliveredReturnTableModel.documents.filter((x) => x.document.id == id)[0]
                    .customColumn;
                columnValue.push(customColumn);
            });

            let isSame: boolean = false;
            let tempValue: string = "";
            isSame = columnValue.every((x) => x == columnValue[0]);

            if (isSame) {
                tempValue = this.props.delivered.deliveredReturnTableModel.documents.filter(
                    (x) => x.document.id == selectedDocumentIds[0]
                )[0].customColumn;
                return tempValue != "" && tempValue != undefined
                    ? this.props.customColumnData.customColumn.customColumnValues.filter((x) => x.value === tempValue)[0]?.id
                    : this.props.userSettings.settings.defaultSettings &&
                      this.props.userSettings.settings.defaultSettings.customColumnValue !== 0 &&
                      this.props.userSettings.settings.defaultSettings.customColumnValue !== undefined
                    ? this.props.userSettings.settings.defaultSettings.customColumnValue
                    : 0;
            } else return 0;
        }
    }
    private onCustomColumnDropdownChange(value: any) {
        this.setState({ selectedColumnValue: value });
    }
    private onDownloadOptionChange(value: any) {
        if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads });
        } else {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadNow });
        }
    }
    private onDownloadOptionSave(value: any) {
        var settings = this.props.userSettings.settings;
        if (this.state.selectedDefaultDownloadType == true) {
            settings.defaultSettings.download.rememberDefaultDownload = true;
            settings.defaultSettings.download.downloadType = this.state.selectedDownloadType;
            this.props.saveUserSettings(settings, true);
        }
        if (this.state.loadExcelSpinner) {
            let downloadType =
                this.props.userSettings.settings.defaultSettings.download.downloadType ||
                DefaultDownloadInfoType.PromptMeEveryTime;
            if (!this.state.showQuickReports) {
                this.onExportToExcel(() => {
                    this.setState({
                        loadExcelSpinner: false,
                        selectedDownloadType: downloadType
                    });
                });
            } else {
                this.onExportToExcelQuickReport(() => {
                    this.setState({
                        loadExcelSpinner: false,
                        selectedDownloadType: downloadType
                    });
                });
            }
        } else {
            let ids = this.selectedDocumentIds();
            if (ids.length > 1) {
                this.onEfileBulkSelectionConfirm(ids);
            } else {
                ShowLoader(Common.OverlayMessages.Downloading);
                this.props.downloadEfileForm(ids, this.state.isBulkSelectionEnabled ? this.onPageReload : this.clearSelection);
            }
        }
        this.setState({
            DownloadTypeOptionState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            }
        });
    }
    private useDefaultDownloadType(event: any) {
        if (this.state.selectedDefaultDownloadType == false) {
            this.setState({ selectedDefaultDownloadType: true });
        } else {
            this.setState({ selectedDefaultDownloadType: false });
        }
    }
    private onCustomColumnSave() {
        const customColumnResourceId =
            this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.CustomColum_Header;
        if (this.state.selectedColumnValue == 0) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.SelectCustomColumnValue, null);
            return false;
        }
        let ids = this.selectedDocumentIds();
        let customColumn = this.props.customColumnData.customColumn.customColumnValues.filter(
            (i) => i.id == this.state.selectedColumnValue
        );

        if (customColumn && customColumn.length > 0) {
            ids.forEach((id, i) => {
                let taxDocument = this.props.taxDocuments[id]?.taxReturn;
                let currentCustomColumnId: number = 0;
                if (taxDocument.documentSettings.documentDisplaySetting.customColumn != null) {
                    currentCustomColumnId = taxDocument.documentSettings.documentDisplaySetting.customColumn.id;
                    let currentCustomColumn = this.props.customColumnData.customColumn.customColumnValues.filter(
                        (i) => i.id == currentCustomColumnId
                    );
                    if (currentCustomColumn.length > 0) {
                        currentCustomColumn[0].inUseCount--;
                        this.props.updateCustomColumnValue(currentCustomColumn[0], false, customColumnResourceId);
                    }
                }
                taxDocument.documentSettings.documentDisplaySetting.customColumn = customColumn[0];
                taxDocument.documentSettings.isModified = true;
                customColumn[0].inUseCount++;
                this.props.saveTaxDocument(
                    taxDocument,
                    customColumn[0],
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    customColumnResourceId
                );
                this.props.updateTaxDocumentCustomColumnValue(id, customColumn[0].value);
            });

            this.setState({
                customColumnState: {
                    show: true,
                    selected: undefined,
                    saveCheckIds: ids,
                    remove: false
                }
            });
        }
    }

    private onCustomColumnRemove() {
        let ids = this.selectedDocumentIds();
        let customColumn = this.props.customColumnData.customColumn.customColumnValues.filter(
            (i) => i.id == this.state.selectedColumnValue
        );
        if (customColumn && customColumn.length > 0) {
            let temp = this.selectedDocuments().filter((p) => p.customColumn == customColumn[0].value);
            if (temp.length == 0 && temp.length != ids.length) {
                VenusNotifier.Warning(GroupTabConstants.Page.WarningValueNotMatch, null);
                this.setState({ hideCustomCulumnLoader: true });
            }
            temp.forEach((value, i) => {
                let taxDocument = this.props.taxDocuments[value.document.id]?.taxReturn;
                taxDocument.documentSettings.documentDisplaySetting.customColumn = {
                    id: 0,
                    value: "",
                    customColumn: 0,
                    inUseCount: 0
                };
                taxDocument.documentSettings.isModified = true;
                customColumn[0].inUseCount--;
                this.props.saveTaxDocument(
                    taxDocument,
                    customColumn[0],
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.HeaderPrefix +
                        CommonResources.CustomColum_Header
                );
                this.props.updateTaxDocumentCustomColumnValue(value.document.id, "");
            });

            this.setState({
                customColumnState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: true
                }
            });
        }
    }

    private onCustomColumnCancel() {
        this.setState({
            customColumnState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            }
        });
    }
    private onDownloadTypeCancel() {
        if (this.state.loadExcelSpinner) {
            let downloadType =
                this.props.userSettings.settings.defaultSettings.download.downloadType ||
                DefaultDownloadInfoType.PromptMeEveryTime;
            this.setState({
                selectedDownloadType: downloadType
            });
        }
        this.setState({
            DownloadTypeOptionState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            },
            loadExcelSpinner: false
        });
    }

    private customColumnUpdateBulk = () => {
        this.customColumnBulk(BulkOperationEndPoint.UpdateCustomColumn);
    };

    private customColumnRemoveBulk = () => {
        this.customColumnBulk(BulkOperationEndPoint.RemoveCustomColumn);
    };

    private customColumnBulk = (apiEndPoint: string) => {
        if (this.state.selectedColumnValue == 0) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.SelectCustomColumnValue, null);
            return false;
        }

        let customColumn = this.props.customColumnData.customColumn.customColumnValues.filter(
            (i) => i.id == this.state.selectedColumnValue
        );

        if (customColumn && customColumn.length > 0) {
            let query = this.getBulkOperationQuery();

            this.props.customcolumnUpdateBulk(
                query,
                customColumn[0],
                apiEndPoint,
                () => {
                    this.setState({
                        customColumnState: {
                            show: false,
                            selected: undefined,
                            saveCheckIds: undefined,
                            remove: true
                        },
                        deSelectedRows: [],
                        selectedRows: [],
                        selectedDocumentGuid: "",
                        isBulkSelectionEnabled: false
                    });
                },
                this.props.parentResourceId + CommonResources.HeaderPrefix + CommonResources.CustomColum_Header
            );
        }
    };

    //====================CustomColumn Ends Here=================================

    //====================SendReminder Starts Here===============================

    onSendReminderValidate() {
        this.isSendReminderFromHeader = true;
        let ids = this.selectedDocumentIds();
        let selectedDocuments = this.selectedDocuments();

        let selectedReturns = ids.map((id, i) => {
            return this.props.taxDocuments[id]?.taxReturn;
        });

        let isGroupedReturnExists = selectedReturns.some((x) => x.groupId > 0);

        if (this.state.isBulkSelectionEnabled) {
            HideLoader();
            let selectedRecordsForBulk = this.props.delivered.totalRowCount - this.state.deSelectedRows?.length;

            this.setState({
                sendReminderState: {
                    ...this.state.sendReminderState,
                    show: true,
                    selectedDocumentIds: ids,
                    isBulkSelectionEnabled: true,
                    isSigningReminderEnabled: false,
                    totalCount: selectedRecordsForBulk
                }
            });
        } else {
            let isValid: boolean = true;
            let isSigningReminderEnabled = false;
            let i: number = 0;
            if (selectedDocuments.length == 1) {
                let signatureStatus = Helper.getSignatureStatusAsString(selectedDocuments[i].document.signatureStatus);
                if (
                    signatureStatus === SignatureStatus[SignatureStatus.DeliveredToTaxCaddy] ||
                    signatureStatus === SignatureStatus[SignatureStatus.Uploaded] ||
                    signatureStatus === SignatureStatus[SignatureStatus.SignedAndESigned] ||
                    signatureStatus === SignatureStatus[SignatureStatus.AlternateDelivery] ||
                    signatureStatus === SignatureStatus[SignatureStatus.AlternateAndUploaded] ||
                    signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned] ||
                    signatureStatus === SignatureStatus[SignatureStatus.ESigned] ||
                    signatureStatus === SignatureStatus[SignatureStatus.SignatureStampingFailed] ||
                    signatureStatus === SignatureStatus[SignatureStatus.ESignedInOffice]
                ) {
                    isValid = false;
                }
            }
            if (isValid) {
                for (i = 0; i < selectedDocuments.length; i++) {
                    let documentId: number = selectedDocuments[i].document.id;
                    if (
                        this.props.taxDocuments[documentId].isFullyLoaded == undefined ||
                        !this.props.taxDocuments[documentId].isFullyLoaded
                    ) {
                        this.props.requestTaxDocument(documentId, true, undefined, undefined, () =>
                            this.onSendReminderValidate()
                        );
                        ShowLoader(Common.OverlayMessages.Processing);
                        return;
                    }
                    if (
                        selectedDocuments[i].document?.documentSettings?.documentNotificationSetting
                            .signingReminderNotificationSettingsModel.enableSigningReminder
                    ) {
                        isSigningReminderEnabled = true;
                        break;
                    }
                }
            }
            if (!isValid) {
                HideLoader();
                VenusNotifier.Error("Error : We cannot send reminder email notification for this document.", null);
            } else {
                HideLoader();
                this.setState({
                    sendReminderState: {
                        ...this.state.sendReminderState,
                        show: true,
                        selectedDocumentIds: ids,
                        bulkReturnsSelected: selectedReturns,
                        isBulkSelectionEnabled: false,
                        isSigningReminderEnabled: isSigningReminderEnabled,
                        totalCount: selectedReturns?.length,
                        isGroupedReturnExists: isGroupedReturnExists
                    }
                });
            }
        }
    }

    onSendReminderValidateIndividualReturn(rowIndex: number) {
        this.isSendReminderFromHeader = false;
        let ids: number[] = this.onPopupOpen(rowIndex);
        let selectedReturns = ids.map((id, i) => {
            return this.props.taxDocuments[id]?.taxReturn;
        });
        const documents = this.props.delivered.deliveredReturnTableModel.documents;
        const selectedDocuments = documents.filter((doc) => ids.includes(doc.document.id));

        let isValid: boolean = true;
        let isSigningReminderEnabled = false;
        let i: number = 0;
        if (isValid) {
            for (i = 0; i < selectedDocuments.length; i++) {
                let documentId: number = selectedDocuments[i].document.id;
                if (
                    this.props.taxDocuments[documentId].isFullyLoaded == undefined ||
                    !this.props.taxDocuments[documentId].isFullyLoaded
                ) {
                    this.props.requestTaxDocument(documentId, true, undefined, undefined, () =>
                        this.onSendReminderValidateIndividualReturn(rowIndex)
                    );
                    ShowLoader(Common.OverlayMessages.Processing);
                    return;
                }
                if (
                    selectedDocuments[i].document?.documentSettings?.documentNotificationSetting
                        .signingReminderNotificationSettingsModel.enableSigningReminder
                ) {
                    isSigningReminderEnabled = true;
                    break;
                }
            }
        }
        if (!isValid) {
            HideLoader();
            VenusNotifier.Error("Error : We cannot send reminder email notification for this document.", null);
        } else {
            HideLoader();
            this.setState({
                sendReminderState: {
                    show: true,
                    selectedDocumentIds: ids,
                    bulkReturnsSelected: selectedReturns,
                    isBulkSelectionEnabled: false,
                    isSigningReminderEnabled: isSigningReminderEnabled,
                    totalCount: 1,
                    individualReturnSelected: this.props.taxDocuments[ids[0]].taxReturn,
                    isGroupedReturnExists: false
                }
            });
        }
    }

    private OnSendReminderCancel() {
        this.setState({
            sendReminderState: {
                show: false,
                selectedDocumentIds: undefined,
                bulkReturnsSelected: [],
                isBulkSelectionEnabled: false,
                isSigningReminderEnabled: false,
                individualReturnSelected: undefined,
                totalCount: 0,
                isGroupedReturnExists: false
            }
        });
    }

    OnSendReminderForQuickReport() {
        let selectedDocuments = this.selectedQuickReportDocuments();
        let ids = this.selectedDocumentIds();
        let selectedReturns = ids.map((id, i) => {
            return this.props.taxDocuments[id]?.taxReturn;
        });

        let isGroupedReturnExists = selectedReturns.some((x) => x.groupId > 0);

        if (selectedDocuments.length == 0) {
            alert("Error: Please select one or more documents");
        } else if (this.state.isBulkSelectionEnabled) {
            HideLoader();
            this.setState({
                sendReminderState: {
                    ...this.state.sendReminderState,
                    show: true,
                    bulkReturnsSelected: selectedReturns,
                    selectedDocumentIds: ids,
                    isBulkSelectionEnabled: true,
                    isSigningReminderEnabled: false,
                    totalCount: this.props.quickReports.totalRowCount - this.state.deSelectedRows?.length
                }
            });
        } else {
            let isValid: boolean = true;
            let isSigningReminderEnabled = false;
            let i: number = 0;
            if (selectedDocuments.length == 1) {
                let signatureStatus = Helper.getSignatureStatusAsString(selectedDocuments[i].document.signatureStatus);
                if (
                    signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned] ||
                    signatureStatus === SignatureStatus[SignatureStatus.SignatureStampingFailed]
                ) {
                    isValid = false;
                }
            }
            if (isValid) {
                for (i = 0; i < selectedDocuments.length; i++) {
                    let documentId: number = selectedDocuments[i].document.id;
                    if (
                        this.props.taxDocuments[documentId].isFullyLoaded == undefined ||
                        !this.props.taxDocuments[documentId].isFullyLoaded
                    ) {
                        this.props.requestTaxDocument(documentId, true, undefined, undefined, this.OnSendReminderForQuickReport);
                        ShowLoader(Common.OverlayMessages.Processing);
                        return;
                    }
                    if (
                        selectedDocuments[i].document?.documentSettings?.documentNotificationSetting
                            .signingReminderNotificationSettingsModel.enableSigningReminder
                    ) {
                        isSigningReminderEnabled = true;
                        break;
                    }
                }
            }

            if (!isValid) {
                HideLoader();
                VenusNotifier.Error("Error : We cannot send reminder email notification for this document.", null);
            } else {
                HideLoader();
                this.setState({
                    sendReminderState: {
                        ...this.state.sendReminderState,
                        show: true,
                        selectedDocumentIds: ids,
                        bulkReturnsSelected: selectedReturns,
                        isBulkSelectionEnabled: false,
                        isSigningReminderEnabled: isSigningReminderEnabled,
                        totalCount: selectedReturns.length,
                        isGroupedReturnExists: isGroupedReturnExists
                    }
                });
            }
        }
    }

    private sendReminderConfirm = (ids: number[]) => {
        let selectedDocuments = this.selectedDocuments();
        ids = ids.filter((documentId) => {
            let documentIndex = selectedDocuments.findIndex((doc) => doc.document.id === documentId);

            if (documentIndex !== -1) {
                let signatureStatus = Helper.getSignatureStatusAsString(
                    selectedDocuments[documentIndex].document.signatureStatus
                );
                return !(
                    signatureStatus === SignatureStatus[SignatureStatus.DeliveredToTaxCaddy] ||
                    signatureStatus === SignatureStatus[SignatureStatus.Uploaded] ||
                    signatureStatus === SignatureStatus[SignatureStatus.SignedAndESigned] ||
                    signatureStatus === SignatureStatus[SignatureStatus.AlternateDelivery] ||
                    signatureStatus === SignatureStatus[SignatureStatus.AlternateAndUploaded] ||
                    signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned] ||
                    signatureStatus === SignatureStatus[SignatureStatus.ESigned] ||
                    signatureStatus === SignatureStatus[SignatureStatus.SignatureStampingFailed] ||
                    signatureStatus === SignatureStatus[SignatureStatus.ESignedInOffice]
                );
            }
            return true;
        });

        let documents = ids
            .map((x) => {
                let index = this.props.delivered.deliveredReturnTableModel.documents.findIndex((z) => z.document.id === x);
                if (index > NO_INDEX) {
                    return {
                        documentId: x,
                        isGroupedReturn: this.props.delivered.deliveredReturnTableModel.documents[index].document.groupId > 0,
                        groupId: this.props.delivered.deliveredReturnTableModel.documents[index].document.groupId
                    } as ITaxDocumentViewModel;
                } else {
                    return { documentId: 0, isGroupedReturn: false, groupId: 0 } as ITaxDocumentViewModel;
                }
            })
            .filter((y) => y.documentId > 0);

        this.props.sendReminder(
            documents,
            this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.Reminder_Header
        );
        this.setState({
            sendReminderState: {
                show: false,
                selectedDocumentIds: ids,
                bulkReturnsSelected: [],
                isSigningReminderEnabled: false,
                isBulkSelectionEnabled: false,
                individualReturnSelected: undefined,
                totalCount: 0,
                isGroupedReturnExists: false
            },
            selectedRows: []
        });
        this.onUpdateCompletionSendReminder(this.props.taxDocuments, "sendReminderState", this.state.sendReminderState);
        logger.trackPageView(`send reminder started for documentIds: ${ids?.toString()}`);
    };

    private sendBulkReminders = (ids: number[]) => {
        ShowLoader(Common.OverlayMessages.Processing);
        this.props.sendRemindersBulk(
            this.getBulkOperationQuery(),
            () => {
                HideLoader();
                this.setState({
                    sendReminderState: {
                        show: false,
                        selectedDocumentIds: undefined,
                        bulkReturnsSelected: [],
                        isSigningReminderEnabled: false,
                        isBulkSelectionEnabled: false,
                        individualReturnSelected: undefined,
                        totalCount: 0,
                        isGroupedReturnExists: false
                    },
                    deSelectedRows: [],
                    selectedRows: [],
                    selectedDocumentGuid: "",
                    isBulkSelectionEnabled: false
                });
            },
            this.props.parentResourceId + CommonResources.HeaderPrefix + CommonResources.Reminder_Header
        );
        logger.trackPageView(`send reminder started for documentIds: ${ids?.toString()}`);
    };

    private sendBulkRemindersForQuickReport = () => {
        ShowLoader(Common.OverlayMessages.Processing);
        let query = this.getQuickReportBulkOperationQuery();

        this.props.sendRemindersForQuickReportBulk(
            query,
            () => {
                HideLoader();
                this.setState({
                    sendReminderState: {
                        show: false,
                        selectedDocumentIds: undefined,
                        bulkReturnsSelected: [],
                        isSigningReminderEnabled: false,
                        isBulkSelectionEnabled: false,
                        individualReturnSelected: undefined,
                        totalCount: 0,
                        isGroupedReturnExists: false
                    },
                    deSelectedRows: [],
                    selectedRows: [],
                    selectedDocumentGuid: "",
                    isBulkSelectionEnabled: false
                });
            },
            this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.Reminder_Header
        );
    };

    //====================SendReminder Ends Here=================================

    //====================Resend Access Link Starts Here===============================

    private onResendAccessLinkOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        let taxReturn = this.props.taxDocuments[id]?.taxReturn;
        ids.map((id, i) => {
            this.props.requestSignedDetails(id, () => {
                this.props.requestTaxDocumentClientTracking(
                    id,
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.GridPrefix +
                        DeliveredReturnReportResourceId.ResendAccessLink
                );
            });
        });
        this.setState({
            resendAccessLinkState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
        logger.trackPageView(`Resend access link started for documentId: ${ids[0]}`, {
            DocumentId: taxReturn.id?.toString(),
            DocumentGuid: taxReturn.documentGuid
        });
    }

    private onResendAccessLink(id: number, model: ISignerModel) {
        this.props.resendAccessLink(
            id,
            model,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.ResendAccessLink
        );
        this.setState({
            resendAccessLinkState: {
                show: true,
                model: undefined,
                saveCheckId: id
            }
        });
    }

    private onResendAccessLinkCancel() {
        this.setState({
            resendAccessLinkState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    //====================Resend Access Link Ends Here===============================

    //====================Unlock Return==========================================
    private onUnlockReturnOpen = (rowIndex: number) => {
        const deliveredReturn = this.props.delivered.deliveredReturnTableModel.documents[rowIndex];
        this.setState({
            unlockReturnState: {
                show: true,
                documentId: deliveredReturn.document.id,
                clients: deliveredReturn.clientTypes
            }
        });
    };

    private unlockReturn = (documentId: number, clientType: string) => {
        const client = TaxClients[clientType as keyof typeof TaxClients];
        if (client) {
            if (client === TaxClients.Controller) {
                const documentIndex = this.props.delivered.deliveredReturnTableModel.documents.findIndex(
                    (x) => x.document.id === documentId
                );
                if (documentIndex > NO_INDEX) {
                    const taxDocument = this.props.delivered.deliveredReturnTableModel.documents[documentIndex].document;
                    this.props.unlockGroupedReturnDocument(taxDocument.groupId, this.reloadTableData);
                }
            } else {
                this.props.unlockDocument(documentId, client, this.reloadTableData);
            }
            this.onUnlockReturnClose();
        }
    };

    private onUnlockReturnClose = () => {
        this.setState({
            unlockReturnState: {
                show: false,
                clients: "",
                documentId: 0
            }
        });
    };

    private reloadTableData = () => {
        this.setState({ selectedRows: [], selectedDocumentGuid: "" }, this.onPageReload);
    };

    //====================Client Tracking Starts Here===============================

    private getDeliveredDocumentsModel = (index: number) => {
        return this.props.delivered.deliveredReturnTableModel.documents[index];
    };

    private onClientTrackingOpen(rowIndex: number = NO_INDEX, isGroupReturn?: boolean) {
        if (isGroupReturn) {
            this.onControllerTrackingOpen(rowIndex);
        } else {
            let ids = this.onPopupOpen(rowIndex);
            ids.map((id, i) => {
                this.props.requestTaxDocumentClientTracking(
                    id,
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.GridPrefix +
                        DeliveredReturnReportResourceId.ClientTracking
                );

                this.props.requestInternalUserTracking(
                    id,
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.GridPrefix +
                        DeliveredReturnReportResourceId.TabInternalTracking
                );
            });

            this.setState({
                clientTrackingState: {
                    show: true,
                    model: this.getStoreItem(rowIndex)?.taxReturn,
                    saveCheckId: undefined
                }
            });
        }
    }

    private onControllerTrackingOpen = (rowIndex: number = NO_INDEX) => {
        let documents = this.getDeliveredDocumentsModel(rowIndex);
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestTaxDocumentClientTracking(
                id,
                this.props.parentResourceId +
                    DeliveredReturnReportResourceId.GridPrefix +
                    DeliveredReturnReportResourceId.ClientTracking
            );

            this.props.requestControllerTracking(
                id,
                documents.document.groupId,
                () => {},
                this.props.parentResourceId +
                    DeliveredReturnReportResourceId.GridPrefix +
                    GroupedReturnReportResources.ControllerTracking_Grid
            );
        });

        this.setState({
            clientTrackingState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    };

    private onClientTrackingCancel() {
        this.setState({
            clientTrackingState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    //=====================Client Tracking Ends Here================================

    //====================RecallReturn Starts Here=================================

    private onRecallReturnOpen(rowIndex: number = NO_INDEX, resourceId: string) {
        let ids = this.onPopupOpen(rowIndex);

        let name: any;
        let taxReturn: any = this.props.taxDocuments[ids[0]]?.taxReturn;
        if (isPartnership(taxReturn)) {
            name = taxReturn.partnership.name;
        } else {
            if (isIndividual(taxReturn) || isMutual(taxReturn)) {
                name = taxReturn.taxpayer.name;
            }
        }
        let clientId = taxReturn.clientId;
        let type = taxReturn.engagementType.toString().slice(1, 10);
        let year = taxReturn.taxYear;

        if (ids.length == 0) {
            VenusNotifier.Warning("Please select document(s) to recall!", null);
            return;
        }

        let obj = this;
        let confirmationString: string =
            taxReturn.documentStatus === DocumentStatus.DOWNLOADPDF
                ? "Are you sure you want to Reprocess this return?"
                : "Are you sure you want to Recall this return?";
        let message: string =
            confirmationString +
            " <br/><br/>" +
            '<table class="wrapper recall-return-table"> <tr><th style="width:40%">Client Name</th> <th style="width:40%">Client ID</th> <th style="width:10%">Type</th> <th style="width:10%">Tax Year</th></tr> <tr><td><div class="ellipsis" title="' +
            name +
            '">' +
            name +
            '</div></td> <td><div class="ellipsis" title="' +
            clientId +
            '">' +
            clientId +
            "</div></td><td>" +
            type +
            " </td><td>" +
            year +
            " </td></tr></table>";

        let title: string = taxReturn.documentStatus === DocumentStatus.DOWNLOADPDF ? "Reprocess Return" : "Recall Return";

        bootbox.confirm({
            title: title,
            message: message,
            className: "recall-return-modal",
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ids.map((id, i) => {
                        obj.onRecallReturnSave(id, resourceId);
                    });
                    logger.trackPageView(
                        `Recall return started for documentId: ${taxReturn.id?.toString()} and documentGuid: ${
                            taxReturn.documentGuid
                        }`,
                        { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                    );
                }
            }
        });
    }

    private onRecallReturnSave(id: number, resourceId: string) {
        let taxReturn = this.props.taxDocuments[id]?.taxReturn;
        this.props.recallTaxDocument(taxReturn, this.clearFilters, undefined, undefined, resourceId);

        if (taxReturn.documentStatus === DocumentStatus.DOWNLOADPDF) {
            this.setState({
                reprocessReturnState: {
                    show: false,
                    model: undefined,
                    saveCheckId: id
                }
            });
        } else {
            this.setState({
                recallReturnState: {
                    show: false,
                    model: undefined,
                    saveCheckId: id
                }
            });
        }
    }

    //====================RecallReturn Ends Here=================================

    //====================Group RecallReturn Starts Here=================================

    private onGroupRecallReturnOpen = (groupId: number, taxDocumentId: number = NO_INDEX) => {
        let taxDocuments: ITaxReturn[] = [];
        taxDocuments.push(this.props.taxDocuments[taxDocumentId].taxReturn);
        let obj = this;
        this.recallReturnPopupConfirmation(taxDocuments, () => {
            taxDocuments.map((taxDocument, i) => {
                obj.onGroupedRecallReturnSave(groupId, taxDocument);
            });
        });
    };

    private onRecallGroupReturnOpen = (rowIndex: number, groupId: number) => {
        let taxDocuments: ITaxReturn[] | undefined = [];
        ShowLoader();
        this.props.requestGroupedReturnDocumentLevelTaxReturns(
            this.buildQueryForDocumentTaxReturns(groupId),
            groupId,
            true,
            (data) => {
                HideLoader();
                taxDocuments = data?.map((model, index) => model.document);
                let obj = this;
                this.recallReturnPopupConfirmation(taxDocuments, () => {
                    obj.onGroupRecallReturnSave(groupId);
                });
            }
        );
    };
    private buildQueryForDocumentTaxReturns = (groupId: number) => {
        return (
            "?searchString=" +
            encodeURIComponent(this.state.searchString ?? "") +
            "&isArchived=" +
            this.props.isArchived +
            "&groupId=" +
            groupId
        );
    };
    private recallReturnPopupConfirmation = (taxDocuments: ITaxReturn[] | undefined, callBack: () => void) => {
        let documentMessage = "";

        taxDocuments.map((taxReturn, index) => {
            let name: any;

            if (isPartnership(taxReturn)) {
                name = taxReturn.partnership.name;
            } else {
                if (isIndividual(taxReturn) || isMutual(taxReturn)) {
                    name = taxReturn.taxpayer.name;
                }
            }
            let clientId = taxReturn.clientId;
            let type = taxReturn.engagementType.toString().slice(1, 10);
            let year = taxReturn.taxYear;

            if (taxDocuments.length == 0) {
                VenusNotifier.Warning("Please select document(s) to recall!", null);
                return;
            }

            documentMessage =
                documentMessage +
                '<tr><td><div class="ellipsis" title="' +
                name +
                '">' +
                name +
                '</div></td> <td><div class="ellipsis" title="' +
                clientId +
                '">' +
                clientId +
                "</div></td><td>" +
                type +
                " </td><td>" +
                year +
                " </td></tr>";
        });
        let confirmationString: string = "Are you sure you want to Recall this grouped return?";
        let message: string =
            confirmationString +
            " <br/><br/>" +
            '<table class="wrapper recall-return-table"> ' +
            '<tr> <th style="width:40%">Client Name</th> <th style="width:40%">Client ID</th> <th style="width:10%">Type</th> <th style="width:10%">Tax Year</th></tr>' +
            documentMessage +
            "</table > ";

        let title: string = "Recall Return";

        bootbox.confirm({
            title: title,
            message: message,
            className: "recall-return-modal",
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    callBack();
                }
            }
        });
    };

    private onGroupRecallReturnSave = (id: number) => {
        ShowLoader(Common.OverlayMessages.Recalling);
        this.props.recallGroupedReturnDocument(
            id,
            this.props.isArchived,
            () => {
                HideLoader();
                this.onPageReload();
                VenusNotifier.Success(successMsg["recallReturnState"], null);
            },
            () => {
                HideLoader();
                VenusNotifier.Error(errorMsg["recallReturnState"], null);
            },
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.RecallReturn
        );
    };

    private onGroupedRecallReturnSave = (groupId: number, taxReturn: ITaxReturn) => {
        ShowLoader(Common.OverlayMessages.Recalling);
        this.props.recallGroupedReturnIndividualDocument(
            taxReturn,
            groupId,
            () => {
                this.onRecallReturnSuccessCallBack(taxReturn);
            },
            () => {
                this.onRecallReturnErrorCallBack(taxReturn);
            },
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.RecallReturn
        );
    };

    private onRecallReturnSuccessCallBack = (taxReturn: ITaxReturn) => {
        HideLoader();
        if (taxReturn.documentStatus === DocumentStatus.DOWNLOADPDF) {
            VenusNotifier.Success(successMsg["reprocessReturnState"], null);
        } else {
            VenusNotifier.Success(successMsg["recallReturnState"], null);
        }
        this.onPageReload();
    };

    private onRecallReturnErrorCallBack = (taxReturn: ITaxReturn) => {
        HideLoader();
        if (taxReturn.documentStatus === DocumentStatus.DOWNLOADPDF) {
            VenusNotifier.Success(errorMsg["reprocessReturnState"], null);
        } else {
            VenusNotifier.Success(errorMsg["recallReturnState"], null);
        }
    };

    //==================== Group RecallReturn Ends Here=================================

    //====================UploadRevisionDocument Starts Here=================================
    private onUploadRevisionDocumentOpen(rowIndex: number) {
        let ids: number[] = this.onPopupOpen(rowIndex);
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        let taxReturn = this.props.taxDocuments[id]?.taxReturn;

        if (ids.length == 0) {
            VenusNotifier.Warning("Please select document(s) to upload new estimate !", null);
            return;
        }
        logger.trackPageView(`Upload revision document started for documentIds: ${ids?.toString()}`, {
            DocumentId: taxReturn.id?.toString(),
            DocumentGuid: taxReturn.documentGuid
        });
        ids.map((id, i) => {
            if (!Helper.isClientInfoLoaded(this.props.taxDocuments[id]?.taxReturn)) {
                this.props.requestTaxDocument(id, true);
            }
        });

        this.setState({
            uploadRevisionDocumentState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    onUploadRevisionDocumentCancel = () => {
        this.setState({
            uploadRevisionDocumentState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    };

    onSubmitRevisionDocument = (revisionDocumentUploadData: IRevisionDocumentUploadData, callback?: () => void) => {
        if (revisionDocumentUploadData.revisionDocumentsUploadData.length > 0) {
            this.props.submitRevisionDocumentToTaxReturn(
                revisionDocumentUploadData,
                () => {
                    if (callback) callback();
                    let query = this.buildQuery(this.state.page, this.state.pageSize);
                    this.props.requestDeliveredReturns(query, true);
                },
                this.props.parentResourceId +
                    DeliveredReturnReportResourceId.GridPrefix +
                    DeliveredReturnReportResourceId.UploadEstimate
            );

            this.setState({
                uploadRevisionDocumentState: {
                    show: false,
                    model: undefined,
                    saveCheckId: revisionDocumentUploadData.documentId
                }
            });
        }
    };
    //====================UploadRevisionDocument Ends Here=================================

    //====================VoucherReminder Starts Here=================================

    private onNotificationReminderSave(
        id: number,
        allowVoucherReminder: boolean,
        voucherReminderInDays: number,
        allowSigningReminder: boolean,
        signingReminderInDays: number
    ) {
        let customColumn: IColumnValues | undefined;
        let taxDocument = this.props.taxDocuments[id]?.taxReturn;
        if (taxDocument && taxDocument.documentSettings && (taxDocument.groupId == 0 || !taxDocument.groupId)) {
            taxDocument.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder =
                allowVoucherReminder;
            taxDocument.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder =
                voucherReminderInDays;
            taxDocument.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder =
                allowSigningReminder;
            taxDocument.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder =
                signingReminderInDays;
            taxDocument.documentSettings.isModified = true;
            this.props.saveTaxDocument(
                taxDocument,
                customColumn,
                this.onTaxDocumentUpdated,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                this.props.parentResourceId +
                    DeliveredReturnReportResourceId.GridPrefix +
                    DeliveredReturnReportResourceId.AutomaticReminder
            );
        }

        this.setState({
            sendReminderState: {
                show: false,
                selectedDocumentIds: undefined,
                bulkReturnsSelected: [],
                isSigningReminderEnabled: false,
                isBulkSelectionEnabled: false,
                individualReturnSelected: undefined,
                totalCount: 0,
                isGroupedReturnExists: false
            },
            voucherReminderState: {
                show: false,
                model: undefined,
                saveCheckId: id
            }
        });
    }

    private onBulkNotificationReminderSave(
        allowVoucherReminder: boolean,
        voucherReminderInDays: number,
        allowSigningReminder: boolean,
        signingReminderInDays: number
    ) {
        let ids = this.selectedDocumentIds();
        let query = this.getBulkOperationQuery();
        logger.trackPageView(`bulk voucher reminder started for documentIds: ${ids?.toString()}`);

        let selectedReturns = ids.map((id, i) => {
            return this.props.taxDocuments[id]?.taxReturn;
        });

        const isGroupedReturnsOnly = selectedReturns.every((x) => x.groupId > 0);
        if (!isGroupedReturnsOnly) {
            const documentIds = selectedReturns
                .map((x) => {
                    if (x.groupId === 0 || !x.groupId) {
                        return x.id;
                    }
                })
                .filter((x) => x !== undefined);
            this.props.updateVoucherReminderBulk(
                documentIds,
                this.state.isBulkSelectionEnabled,
                query,
                allowVoucherReminder,
                voucherReminderInDays,
                allowSigningReminder,
                signingReminderInDays,
                this.onPageReload,
                this.props.parentResourceId +
                    DeliveredReturnReportResourceId.HeaderPrefix +
                    DeliveredReturnReportResourceId.AutomaticReminder
            );
        }

        this.setState(
            {
                sendReminderState: {
                    show: false,
                    selectedDocumentIds: undefined,
                    bulkReturnsSelected: [],
                    isSigningReminderEnabled: false,
                    isBulkSelectionEnabled: false,
                    individualReturnSelected: undefined,
                    totalCount: 0,
                    isGroupedReturnExists: false
                },
                isBulkSelectionEnabled: false,
                deSelectedRows: [],
                selectedRows: [],
                selectedDocumentGuid: ""
            },
            () => {
                this.clearSelection();
            }
        );
    }

    private onBulkNotificationReminderSaveForQuickReport(
        allowVoucherReminder: boolean,
        voucherReminderInDays: number,
        allowSigningReminder: boolean,
        signingReminderInDays: number
    ) {
        let ids = this.selectedDocumentIds();
        let selectedReturns = ids.map((id, i) => {
            return this.props.taxDocuments[id]?.taxReturn;
        });

        const isGroupedReturnsOnly = selectedReturns.every((x) => x.groupId > 0);
        if (!isGroupedReturnsOnly) {
            let query = this.getQuickReportBulkOperationQuery();

            const documentIds = selectedReturns
                .map((x) => {
                    if (x.groupId === 0 || !x.groupId) {
                        return x.id;
                    }
                })
                .filter((x) => x !== undefined);

            this.props.updateVoucherReminderForQuickReportBulk(
                documentIds,
                this.state.isBulkSelectionEnabled,
                query,
                allowVoucherReminder,
                voucherReminderInDays,
                allowSigningReminder,
                signingReminderInDays,
                this.clearSelection,
                this.props.parentResourceId +
                    DeliveredReturnReportResourceId.HeaderPrefix +
                    DeliveredReturnReportResourceId.AutomaticReminder
            );
        }

        this.setState(
            {
                sendReminderState: {
                    show: false,
                    selectedDocumentIds: undefined,
                    bulkReturnsSelected: [],
                    isSigningReminderEnabled: false,
                    isBulkSelectionEnabled: false,
                    individualReturnSelected: undefined,
                    totalCount: 0,
                    isGroupedReturnExists: false
                },
                isBulkSelectionEnabled: false,
                deSelectedRows: [],
                selectedRows: [],
                selectedDocumentGuid: ""
            },
            () => {
                this.clearSelection();
            }
        );
    }
    private onTaxDocumentUpdated = (taxDocument: ITaxReturn) => {
        this.props.setVoucherDueDateReminder(
            taxDocument,
            this.onSetVoucherReminderCompleted,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.AutomaticReminder
        );
    };

    private onSetVoucherReminderCompleted = (reminderData: ReminderStore.IReminderData) => {
        if (reminderData.error === true) {
            VenusNotifier.Error(errorMsg.voucherDueDateReminderState, "voucherDueDateReminderState");
        }

        this.onUpdateCompletion(this.props.taxDocuments, "voucherReminderState", this.state.voucherReminderState);
    };
    //====================VoucherReminder Ends Here=================================

    //====================View Access Code Starts Here===============================

    private onViewAccessCodeOpen(rowIndex: number) {
        const taxReturn: IDeliveredTaxDocument = this.props.delivered.deliveredReturnTableModel.documents[rowIndex];
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        let taxreturn = this.props.taxDocuments[id]?.taxReturn;

        logger.trackPageView(`View Access Code started for client guid: ${taxReturn.clientGuid}`, {
            DocumentId: taxreturn.id?.toString(),
            DocumentGuid: taxreturn.documentGuid
        });
        if (!this.props.taxDocuments[taxReturn.document.id].isFullyLoaded) {
            this.props.requestTaxDocument(taxReturn.document.id, true, undefined, undefined, () => {
                this.props.requestSignedDetails(taxReturn.document.id, () => {
                    this.props.requestTaxDocumentAccessCode(
                        taxReturn.document.id,
                        true,
                        () => {
                            this.setState({
                                accessCodeState: {
                                    show: true,
                                    model: this.getStoreItem(rowIndex)?.taxReturn,
                                    saveCheckId: undefined
                                }
                            });
                        },
                        this.props.parentResourceId +
                            DeliveredReturnReportResourceId.GridPrefix +
                            DeliveredReturnReportResourceId.ViewAccessCode
                    );
                });
            });
        } else {
            this.props.requestTaxDocumentAccessCode(
                taxReturn.document.id,
                true,
                () => {
                    this.setState({
                        accessCodeState: {
                            show: true,
                            model: this.getStoreItem(rowIndex)?.taxReturn,
                            saveCheckId: undefined
                        }
                    });
                },
                this.props.parentResourceId +
                    DeliveredReturnReportResourceId.GridPrefix +
                    DeliveredReturnReportResourceId.ViewAccessCode
            );
        }

        this.setState({
            accessCodeState: {
                show: true,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onGenerateOTP(documentId: number, clientType: ClientTypes, clientGUID: string, callBack: () => void) {
        this.props.generateOTP(
            documentId,
            clientType,
            clientGUID,
            callBack,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.ViewAccessCode
        );
        this.setState({
            accessCodeState: {
                show: true,
                model: this.props.taxDocuments[documentId]?.taxReturn,
                saveCheckId: documentId
            }
        });
    }

    private onViewAccessCodeCancel() {
        this.setState({
            accessCodeState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    //=====================View Access Code Ends Here================================

    //====================Retention Policy Starts Here===============================
    private onRetentionPolicyOpen(rowIndex: number = NO_INDEX) {
        let ids: number[] = this.onPopupOpen(rowIndex);
        this.props.requestCompanySettings(true);
        if (this.props.taxDocuments[ids[0]].isFullyLoaded == undefined || !this.props.taxDocuments[ids[0]].isFullyLoaded) {
            this.props.requestTaxDocument(ids[0], true, undefined, undefined, (newData: ITaxReturn) => {
                if (newData.documentSettings.documentRetentionSetting.retentionPeriod === 0) {
                    let retentionPeriod: number = 0;
                    if (this.props.company.companySettings) {
                        if (isIndividual(newData) || isMutual(newData)) {
                            if (newData.taxpayer.additionalInfo.isAttested) {
                                retentionPeriod = this.props.company.companySettings.retentionSettingsModel.attestRetentionPeriod;
                            } else {
                                retentionPeriod = this.props.company.companySettings.retentionSettingsModel.retentionPeriod;
                            }
                        }

                        if (isPartnership(newData)) {
                            if (newData.partnership.additionalInfo.isAttested) {
                                retentionPeriod = this.props.company.companySettings.retentionSettingsModel.attestRetentionPeriod;
                            } else {
                                retentionPeriod = this.props.company.companySettings.retentionSettingsModel.retentionPeriod;
                            }
                        }
                    }
                    newData.documentSettings.documentRetentionSetting.retentionPeriod = retentionPeriod;
                }

                this.setState({
                    retentionPolicyState: {
                        show: true,
                        model: newData,
                        saveCheckId: undefined
                    }
                });
            });
        }

        this.setState({
            retentionPolicyState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onRetentionPolicySave(id: number, isAttested: boolean, retentionPeriod: number) {
        let customColumn: IColumnValues | undefined;
        let taxDocument = this.props.taxDocuments[id]?.taxReturn;
        taxDocument.documentSettings.documentRetentionSetting.retentionPeriod = retentionPeriod;
        taxDocument.documentSettings.isModified = true;

        if (isIndividual(taxDocument) || isMutual(taxDocument)) {
            taxDocument.taxpayer.isModified = true;
            taxDocument.taxpayer.additionalInfo.isAttested = isAttested;
        } else if (isPartnership(taxDocument)) {
            taxDocument.partnership.isModified = true;
            taxDocument.partnership.additionalInfo.isAttested = isAttested;
        }

        this.props.saveTaxDocument(
            taxDocument,
            customColumn,
            (data) => {
                let query = this.buildQuery(this.state.page, this.state.pageSize);
                this.props.requestDeliveredReturns(query, true);
            },
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.RetentionPolicy
        );

        this.setState({
            retentionPolicyState: {
                show: true,
                model: undefined,
                saveCheckId: id
            }
        });
    }

    private onRetentionPolicyCancel() {
        this.setState({
            retentionPolicyState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    //====================Retention Policy Ends Here===============================

    //====================Download Returns Starts Here===============================

    private onDownloadReturnOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestTaxDocument(id, true, undefined, undefined, () => {
                this.props.requestDownloadableReturns(
                    id,
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.GridPrefix +
                        DeliveredReturnReportResourceId.DownloadReturns
                );
                this.setState({
                    downloadReturnState: {
                        show: true,
                        model: this.getStoreItem(rowIndex)?.taxReturn,
                        saveCheckId: undefined
                    }
                });
            });
        });
    }

    private onDownloadReturnAll(
        documentGuid: string,
        documentId: number,
        taxYear: number,
        isEfileForm: boolean,
        resourceId: string
    ) {
        ShowLoader(Common.OverlayMessages.Downloading);
        const model = this.props.taxDocuments[documentId]?.taxReturn;
        const isEsignedOrManuallySigned =
            model.signatureStatus.toString() == SignatureStatus[SignatureStatus.AwaitingESign] ||
            model.signatureStatus.toString() == SignatureStatus[SignatureStatus.AwaitingUpload] ||
            model.signatureStatus.toString() == SignatureStatus[SignatureStatus.ManuallySigned] ||
            model.signatureStatus.toString() == SignatureStatus[SignatureStatus.PartiallySigned]
                ? false
                : true;

        this.props.downloadAllReturn(
            documentGuid,
            documentId,
            taxYear,
            isEfileForm,
            model.clientId,
            model.engagementType,
            getUnformattedClientName(model),
            isEsignedOrManuallySigned,
            resourceId
        );
        if (isEfileForm) {
            let state = { ...this.state.downloadEformFilesState };
            state.show = false;
            this.setState({ downloadEformFilesState: state });
        } else {
            let state = { ...this.state.downloadReturnState };
            state.show = false;
            this.setState({ downloadReturnState: state });
        }
    }

    private isAlternateDelivery = (taxReturn: ITaxReturn): boolean => {
        return taxReturn.signatureStatus.toString() === SignatureStatus[SignatureStatus.AlternateAndUploaded];
    };

    private onDownloadFile(
        documentGuid: string,
        documentId: number,
        taxYear: number,
        fileName: string,
        groupId: number,
        signedDocumentId: number,
        isDownloadReturn: boolean = false,
        clientId: string,
        resourceId?: string
    ) {
        let isUpdateDocTransaction: boolean = false;
        const model = this.props.taxDocuments[documentId]?.taxReturn;
        const isK1Attachment = model.k1Attachments && model.k1Attachments.length > 0 ? true : false;
        let isSinglePrinted: boolean = false;
        if (model.documentSettings) {
            if (model.documentSettings.deliverySettings) {
                let deliveryMode: string = model.documentSettings.deliverySettings.deliveryMode.toString();
                if (
                    deliveryMode === DeliveryMode[DeliveryMode.PaperFiled] ||
                    deliveryMode === DeliveryMode.PaperFiled.toString()
                ) {
                    let deliveryType: string =
                        model.documentSettings.deliverySettings.paperReturnSettings.deliveryType.toString();
                    if (
                        deliveryType === PaperReturnDeliveryType[PaperReturnDeliveryType.SingleFile] ||
                        deliveryType === PaperReturnDeliveryType.SingleFile.toString()
                    ) {
                        isSinglePrinted = true;
                    }
                }
            }
        } else {
            isSinglePrinted = this.isAlternateDelivery(model);
        }
        if (groupId == DocumentGroups.EFile && !isDownloadReturn) {
            isUpdateDocTransaction = true;
        }
        if (groupId == DocumentGroups.K1) {
            fileName = "k1.zip";
        }
        this.props.downloadReturn(
            documentGuid,
            documentId,
            taxYear,
            fileName,
            groupId,
            signedDocumentId,
            clientId,
            getUnformattedClientName(model),
            model.engagementType,
            isSinglePrinted,
            isK1Attachment,
            this.onPageReload,
            isUpdateDocTransaction,
            resourceId
        );
    }

    private onDownloadAdditionalDocuments = (
        documentGuid: string,
        documentId: number,
        taxYear: number,
        clientId: string,
        additionalEsignDocuments: IDocumentData[],
        isSigned?: boolean,
        resourceId?: string
    ) => {
        let name: any;
        let taxReturn: any = this.props.taxDocuments[documentId]?.taxReturn;
        if (isPartnership(taxReturn)) {
            name = taxReturn.partnership.name;
        } else {
            if (isIndividual(taxReturn) || isMutual(taxReturn)) {
                name = taxReturn.taxpayer.name;
            }
        }
        this.props.downloadAdditionalEsignDocuments(
            documentGuid,
            documentId,
            taxYear,
            clientId,
            name,
            taxReturn.engagementType,
            additionalEsignDocuments,
            isSigned,
            this.onPageReload,
            resourceId
        );
    };

    private downloadMergedPaperFileDocument(documentGuid: string, documentId: number, taxYear: number, clientId: string) {
        const model = this.props.taxDocuments[documentId]?.taxReturn;
        this.props.downloadMergedPaperFileDocument(
            documentGuid,
            taxYear,
            clientId,
            getUnformattedClientName(model),
            undefined,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.DownloadReturns
        );
    }

    private onDownloadReturnCancel() {
        this.setState({
            downloadReturnState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    //====================Download Returns End Here===============================

    //====================Download Efile Forms Starts Here===============================

    private onDownloadEfileFormsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        let taxReturn = this.props.taxDocuments[id]?.taxReturn;
        ids.map((id, i) => {
            if (this.props.taxDocuments[id].isFullyLoaded == undefined || !this.props.taxDocuments[id].isFullyLoaded) {
                this.loadFullTaxDocument(id).then(() => {
                    this.props.requestDownloadableEfileForms(
                        id,
                        this.props.parentResourceId +
                            DeliveredReturnReportResourceId.GridPrefix +
                            DeliveredReturnReportResourceId.DownloadEfileForm
                    );
                    this.setState({
                        downloadEformFilesState: {
                            show: true,
                            model: this.getStoreItem(rowIndex)?.taxReturn,
                            saveCheckId: undefined
                        }
                    });
                });
                logger.trackPageView(`DownloadEfileForms started for documentId: ${id?.toString()}`, {
                    DocumentId: taxReturn.id?.toString(),
                    DocumentGuid: taxReturn.documentGuid
                });
            } else {
                this.props.requestDownloadableEfileForms(
                    id,
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.GridPrefix +
                        DeliveredReturnReportResourceId.DownloadEfileForm
                );
            }
        });
        this.setState({
            downloadEformFilesState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private loadFullTaxDocument = (id: number): Promise<any> => {
        var promise: any = null;
        promise = new Promise((resolve: any) => {
            this.props.requestTaxDocument(id, true, undefined, undefined, () => {
                resolve();
            });
        });
        return promise;
    };

    private onMultipleDownloadEfileForms() {
        let selectedDocuments = this.selectedDocuments();
        let ids = this.selectedDocumentIds();

        if (ids.length == 0) {
            VenusNotifier.Warning("Please select one or more documents", null);
            return;
        } else if (this.state.isBulkSelectionEnabled) {
            this.multipleDownloadEfileFormsBulk(ids);
        } else {
            logger.trackPageView(`multiple download Efile started for documentIds: ${ids?.toString()}`);
            let isValid: boolean = true;
            let i: number = 0;
            for (i = 0; i < selectedDocuments.length; i++) {
                let signatureStatus: string = selectedDocuments[i].document.signatureStatus.toString();
                if (
                    signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned] ||
                    signatureStatus === SignatureStatus[SignatureStatus.AwaitingESign] ||
                    signatureStatus === SignatureStatus[SignatureStatus.AwaitingUpload] ||
                    signatureStatus === SignatureStatus[SignatureStatus.DeliveredToTaxCaddy] ||
                    signatureStatus === SignatureStatus[SignatureStatus.AlternateDelivery] ||
                    signatureStatus === SignatureStatus[SignatureStatus.DownloadingFailed] ||
                    signatureStatus === SignatureStatus[SignatureStatus.PartiallySigned] ||
                    signatureStatus === SignatureStatus[SignatureStatus.SignatureStampingFailed]
                ) {
                    isValid = false;
                    break;
                }
            }

            if (!isValid) {
                VenusNotifier.Error(DeliveredReturnsConstants.ErrorMessage.UploadedESignedEfileFormsCanOnlyDownloadable, null);
            } else {
                if (ids.length > 1) {
                    if (this.props.userSettings.settings.defaultSettings.download.rememberDefaultDownload == false) {
                        this.setState({
                            selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads,
                            DownloadTypeOptionState: {
                                show: true,
                                selected: undefined,
                                saveCheckIds: undefined,
                                remove: false
                            }
                        });
                    } else {
                        this.onEfileBulkSelectionConfirm(ids);
                    }
                    logger.trackTrace(`downloadEfileForm for documentIds: ${ids?.toString()}`);
                } else {
                    ShowLoader(Common.OverlayMessages.Downloading);
                    this.props.downloadEfileForm(
                        ids,
                        this.state.isBulkSelectionEnabled ? this.onPageReload : this.clearSelection,
                        this.props.parentResourceId +
                            CommonResources.HeaderPrefix +
                            DeliveredReturnReportResourceId.DownloadEfileForm
                    );
                }
            }
        }
    }

    private multipleDownloadEfileFormsBulk = (ids: number[]) => {
        HideLoader();
        bootbox.confirm({
            message: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileWarning,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Yes',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result == true) {
                    this.setState({
                        selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads,
                        DownloadTypeOptionState: {
                            show: true,
                            selected: undefined,
                            saveCheckIds: undefined,
                            remove: false
                        }
                    });
                }
            }
        });
    };

    private onEfileBulkSelectionConfirm = (ids: number[]) => {
        let query = this.getBulkOperationQuery();
        this.props.downloadEFileFormsBulk(
            ids,
            this.state.isBulkSelectionEnabled,
            query,
            this.state.selectedDownloadType,
            undefined,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.HeaderPrefix +
                DeliveredReturnReportResourceId.DownloadEfileForm
        );
    };

    private onDownloadEfileFormsCancel() {
        this.setState({
            downloadEformFilesState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    //====================Download Efile Forms End Here===============================

    //====================Upload Forms Starts Here=============================

    private onUploadFormsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            if (this.props.taxDocuments[id].isFullyLoaded == undefined || !this.props.taxDocuments[id].isFullyLoaded) {
                this.props.requestTaxDocument(id, true);
            }
        });

        this.setState({
            uploadFormsState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onUploadFormsSuccess(id: number, files: string[]) {
        let taxDocument = this.props.taxDocuments[id]?.taxReturn;
        if (taxDocument.documentStatus === DocumentStatus.DOWNLOADPDF) {
            taxDocument.signatureStatus = SignatureStatus.AlternateAndUploaded;
        } else {
            taxDocument.signatureStatus = SignatureStatus.Uploaded;
        }
        taxDocument.isModified = true;

        this.props.submitUploadForms(
            taxDocument,
            files,
            undefined,
            undefined,
            this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix + DeliveredReturnReportResourceId.UploadForms
        );

        this.setState({
            uploadFormsState: {
                show: true,
                model: undefined,
                saveCheckId: id
            }
        });
    }

    private onUploadFormsCancel() {
        this.setState({
            uploadFormsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    //====================Upload Forms Ends Here===============================

    //====================Signed Details Starts Here=============================

    private onSignedDetailsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestSignedDetails(id, () => {
                this.props.requestSignedEvents(id);
            });
        });

        this.setState({
            signedDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onSignedDetailsCancel() {
        this.setState({
            signedDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    //====================Signed Details Ends Here===============================

    //====================Download History Starts Here=============================

    private onDownloadHistoryOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestTaxDocumentDownloadHistory(id);
        });

        this.setState({
            downloadHistoryState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onDownloadHistoryCancel() {
        this.setState({
            downloadHistoryState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    //====================Download History Ends Here===============================

    //==================TaxPayerView ==============================================

    private onTaxPayerViewClick(rowIndex: number) {
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        let model = this.props.taxDocuments[id]?.taxReturn;
        if (!Helper.isClientInfoLoaded(model)) {
            this.props.requestTaxDocument(id, true);
        }
        this.setState({
            clientViewState: {
                show: true,
                model: model,
                saveCheckId: undefined
            }
        });
    }

    private onTaxPayerViewCancel(id: number) {
        let item = this.state.clientViewState.model as ITaxReturn;
        this.props.requestTaxDocument(id, true);

        this.setState({
            clientViewState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            },
            screenShareState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onTaxPayerViewLinkClick(rowIndex: number, clientType: ClientTypes) {
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        ShowLoader();
        this.props.requestTaxDocument(
            id,
            true,
            true,
            clientType,
            undefined,
            undefined,
            this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix + DeliveredReturnReportResourceId.ClientView
        );
    }

    //==================TaxpayerView End===========================================

    //==================Delete Tax Return Start ==============================================
    private onDeleteTaxReturnOpen(rowIndex: number = NO_INDEX, resourceId: string) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(DeleteTaxReturnConstants.SelectDocumentWarning, null);
            return;
        }
        logger.trackPageView(`delete tax return started for documentIds: ${ids?.toString()}`);
        let obj = this;
        bootbox.confirm({
            title: DeleteTaxReturnConstants.Title,
            message: DeleteTaxReturnConstants.ConfirmDeletionWithoutUndone,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    obj.state.isBulkSelectionEnabled
                        ? !obj.state.showQuickReports
                            ? obj.onBulkDeleteDeliveredReturns(resourceId)
                            : obj.onBulkDeleteQuickReportReturns(resourceId)
                        : obj.onDeleteTaxReturnSave(ids, resourceId);
                }
            }
        });
    }

    private onDeleteTaxReturnSave(ids: number[], resourceId: string) {
        ShowLoader(Common.OverlayMessages.Deleting);
        this.props.deleteDeliveredTaxDocument(ids, this.onDeleteCompletion, resourceId);
        this.setState({
            deleteReturnsState: {
                show: false,
                model: undefined,
                saveCheckId: ids[0]
            }
        });
    }

    private onBulkDeleteDeliveredReturns = (resourceId: string) => {
        let query = this.getBulkOperationQuery();
        this.props.deleteDocumentsBulk(
            query,
            () => {
                this.setState({
                    deleteReturnsState: {
                        show: false,
                        model: undefined,
                        saveCheckId: undefined
                    }
                });
            },
            resourceId
        );
        this.clearSelection();
    };

    private onDeleteCompletion() {
        HideLoader();
        if (this.state.showQuickReports === false) {
            if (this.props.delivered.deliveredReturnTableModel.documents.length === 1 && this.state.page > 1) {
                const currentPage = this.state.page;
                this.setState({
                    page: currentPage - 1
                });
            }
            this.onPageReload();
        } else {
            let quickReportRef: any = this.refs.QuickReturnsTable;
            quickReportRef.onPageReload();
        }
    }

    private onBulkDeleteQuickReportReturns = (resourceId) => {
        let query = this.getQuickReportBulkOperationQuery();
        this.props.deleteQuickReportDocumentsBulk(
            query,
            () => {
                this.setState({
                    deleteReturnsState: {
                        show: false,
                        model: undefined,
                        saveCheckId: undefined
                    }
                });
            },
            resourceId
        );
        this.clearSelection();
    };
    //==================Delete Tax Return End ==============================================

    //==================Controller View Start=============================================
    private onControllerViewClick = (groupId: number) => {
        this.setState(
            {
                controller: initialControllerInfo
            },
            () => {
                this.props.requestControllerInfo(
                    groupId,
                    (data) => {
                        let state = { ...this.state.controllerViewState };
                        state.show = true;
                        this.setState({
                            controller: data,
                            controllerViewState: state
                        });
                    },
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.GridPrefix +
                        DeliveredReturnReportResourceId.EditClientInfo
                );
            }
        );
    };

    private onControllerViewOpen = (groupId: number) => {
        ShowLoader();
        this.props.requestGenerateTaxPayerView(
            groupId,
            this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix + DeliveredReturnReportResourceId.ClientView
        );
    };

    //==================Controller View Ends=============================================

    //==================Screen Share Start=============================================

    private isScreenShareEnabled(): boolean {
        return (
            this.props.company.companySettings && this.props.company.companySettings?.displaySettingsModel.isScreenShareEnabled
        );
    }

    private onScreenShareViewClick(rowIndex: number) {
        const id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        const model = this.props.taxDocuments[id]?.taxReturn;
        if (!Helper.isClientInfoLoaded(model)) {
            this.props.requestTaxDocument(id, true);
        }
        this.setState({
            screenShareState: {
                show: false,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            },
            screenShareOtp: "",
            controller: initialControllerInfo,
            isControllerScreenShare: false
        });
        this.ssShow = true;
    }

    private onControllerScreenShareOpen = (
        controllerGuid: string,
        groupId: number = NO_INDEX,
        clientId: string,
        clientGuid: string,
        isControllerScreenShare: boolean
    ) => {
        this.props.generateTimeBasedOTP(
            controllerGuid,
            groupId,
            this.otpCallback,
            this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix + DeliveredReturnReportResourceId.ScreenShare
        );

        this.setState({
            screenShareState: {
                show: true,
                model: undefined,
                saveCheckId: undefined
            },
            screenShareOtp: "",
            isControllerScreenShare: isControllerScreenShare
        });
        this.ssShow = false;
        this.ssClientId = clientId;
        this.ssClientGuid = clientGuid;
    };

    private onScreenShareOpen(clientId: string, rowIndex: number = NO_INDEX, isControllerScreenShare: boolean) {
        this.props.generateTimeBasedOTP(
            clientId,
            rowIndex,
            this.otpCallback,
            this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix + DeliveredReturnReportResourceId.ScreenShare
        );
        this.setState({
            screenShareState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            },
            screenShareOtp: "",
            isControllerScreenShare: isControllerScreenShare
        });
        this.ssShow = false;
        this.ssClientId = clientId;
    }

    private otpCallback = (rowIndex: number, otp: string) => {
        this.setState({
            screenShareOtp: otp
        });
    };

    private onScreenShareStart(clientGuid: string) {
        ShowLoader(Common.OverlayMessages.ScreenSharing);
        this.props.requestScreenShareUrl(
            clientGuid,
            undefined,
            this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix + DeliveredReturnReportResourceId.ScreenShare
        );
        this.setState((prevState) => ({ screenShareState: { ...prevState.screenShareState, show: false } }));
    }

    private onControllerScreenShareStart = (controllerGuid: string) => {
        ShowLoader(Common.OverlayMessages.ScreenSharing);
        this.props.requestGroupedReturnScreenShareUrl(
            this.ssClientGuid,
            controllerGuid,
            this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix + DeliveredReturnReportResourceId.ScreenShare
        );
        this.setState((prevState) => ({ screenShareState: { ...prevState.screenShareState, show: false } }));
    };

    private onScreenShareCancel() {
        this.setState({
            screenShareState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    unloadControllerScreenShareData = (callback: () => void) => {
        this.setState(
            {
                controller: initialControllerInfo,
                screenShareState: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            },
            callback
        );
        this.ssShow = true;
    };

    private onControllerScreenShareViewClick = (rowIndex: number, groupId: number) => {
        const id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        const model = this.props.taxDocuments[id]?.taxReturn;
        const controllerData = { ...this.state.controller };
        if (Helper.isClientInfoLoaded(model) && controllerData.groupId === groupId) {
            this.setState({
                controller: controllerData,
                screenShareState: {
                    show: false,
                    model: model,
                    saveCheckId: undefined
                }
            });
            this.ssShow = true;
        } else if (!Helper.isClientInfoLoaded(model) && controllerData.groupId === groupId) {
            this.unloadControllerScreenShareData(() => {
                this.props.requestTaxDocument(id, true, undefined, undefined, (data) => {
                    this.setState({
                        controller: controllerData,
                        screenShareState: {
                            show: false,
                            model: data,
                            saveCheckId: undefined
                        }
                    });
                });
            });
        } else if (Helper.isClientInfoLoaded(model) && controllerData.groupId !== groupId) {
            this.unloadControllerScreenShareData(() => {
                this.props.requestControllerInfo(groupId, (data) => {
                    this.setState({
                        controller: data,
                        screenShareState: {
                            show: false,
                            model: model,
                            saveCheckId: undefined
                        }
                    });
                });
            });
        } else {
            this.unloadControllerScreenShareData(() => {
                this.props.requestTaxDocument(
                    id,
                    true,
                    undefined,
                    undefined,
                    (data1) => {
                        this.props.requestControllerInfo(
                            groupId,
                            (data) => {
                                this.setState({
                                    controller: data,
                                    screenShareState: {
                                        show: false,
                                        model: data1,
                                        saveCheckId: undefined
                                    }
                                });
                            },
                            this.props.parentResourceId +
                                DeliveredReturnReportResourceId.GridPrefix +
                                DeliveredReturnReportResourceId.EditClientInfo
                        );
                    },
                    undefined,
                    undefined
                );
            });
        }
    };

    //==================Screen Share End===============================================

    //====================Change Office Location Starts Here===============================
    private onChangeOfficeLocationOpen = (rowIndex: number = NO_INDEX) => {
        let selectedDocumentIds = this.selectedDocumentIds();

        if (selectedDocumentIds.length == 0) {
            VenusNotifier.Error("Error: Please select documents to change office location.", null);
        } else {
            this.setState({
                changeOfficeLocationState: {
                    show: true,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false
                },
                showLoader: false
            });
        }
    };

    private onChangeOfficeLocationContinue = (locationId: number, contactPersonId: number, callBack: () => void) => {
        let ids = this.selectedDoocumentIdsWithoutGroup();
        let query = this.getBulkOperationQuery();

        if (this.state.isBulkSelectionEnabled) {
            this.props.changeOfficeLocationBulk(
                query,
                locationId,
                contactPersonId,
                () => {
                    this.onChangeOfficeLocationSuccess(callBack);
                },
                () => {
                    this.onChangeOfficeLocationError(callBack);
                },
                this.props.parentResourceId +
                    DeliveredReturnReportResourceId.HeaderPrefix +
                    DeliveredReturnReportResourceId.ChangeOfficeLocation
            );
        } else {
            if (ids.length > 0) {
                this.props.changeOfficeLocation(
                    ids,
                    locationId,
                    contactPersonId,
                    () => {
                        this.onChangeOfficeLocationSuccess(callBack);
                    },
                    () => {
                        this.onChangeOfficeLocationError(callBack);
                    },
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.HeaderPrefix +
                        DeliveredReturnReportResourceId.ChangeOfficeLocation
                );
            } else {
                this.onChangeOfficeLocationSuccess(callBack);
            }
        }
    };

    onChangeOfficeLocationSuccess = (callBack: () => void) => {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.ChangeOfficeLocationSuccess, "");

        this.setState(
            {
                changeOfficeLocationState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false
                },
                selectedRows: [],
                deSelectedRows: [],
                isBulkSelectionEnabled: false,
                showBulkSelectionMessage: false,
                selectedDocumentGuid: ""
            },
            this.onPageReload
        );
        callBack();
    };

    onChangeOfficeLocationError = (callBack: () => void) => {
        VenusNotifier.Error(DeliveredReturnsConstants.ErrorMessage.ChangeOfficeLocationError, "");

        this.setState({
            changeOfficeLocationState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            },
            selectedRows: [],
            deSelectedRows: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false,
            selectedDocumentGuid: ""
        });
        callBack();
    };

    private onChangeOfficeLocationCancel = () => {
        this.setState({
            changeOfficeLocationState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            }
        });
    };

    //====================Change Office Location Ends Here=================================

    //====================Edit Group Name Start=================================
    private GROUPID: number = 0;
    private onEditGroupInfoOpen = (groupId: number) => {
        this.GROUPID = groupId;
        ShowLoader();
        this.props.requestGroupInfo(groupId, this.handleEditGroupInfoOpenRequest);
    };
    handleEditGroupInfoOpenRequest = (groupInfo: IGroupInfo) => {
        HideLoader();
        if (groupInfo) {
            this.setState({
                editGroupInfoState: {
                    show: true,
                    model: groupInfo
                }
            });
        }
    };
    private onEditGroupInfoCancel = () => {
        this.setState({
            editGroupInfoState: {
                show: false,
                model: undefined
            }
        });
    };
    onGroupInfoUpdate = (groupInfo: IGroupInfo, resetLoadingMessageState: () => void, failureCallback: () => void) => {
        this.props.updateGroup(
            groupInfo,
            () => {
                resetLoadingMessageState();
                this.setState({
                    editGroupInfoState: {
                        show: false,
                        model: undefined
                    }
                });
                this.props.requestGroupConfiguration(true);
                this.onPageReload();
            },
            () => {
                failureCallback();
            },
            undefined,
            this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix + GroupedReturnReportResources.EditGroup_Grid
        );
    };
    //====================Edit Group Name End=================================

    //--------------------------------Group Send Reminder Starts here-------------------------------------

    private onGroupSendReminderOpen = (rowIndex: number) => {
        const deliveredGroupReturnModel = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document;
        let self = this;
        ShowLoader(Common.OverlayMessages.Processing);
        this.props.requestGroupSettings(deliveredGroupReturnModel.groupId, (groupInfo: IGroupInfo) => {
            let noOfDaysForVoucherReminder =
                groupInfo.groupSettings.groupNotificationSettings.paymentVoucherNotificationSettingsModel
                    .noOfDaysForVoucherReminder;

            let noOfDaysForSigningReminder =
                groupInfo.groupSettings.groupNotificationSettings.signingReminderNotificationSettingsModel
                    .noOfDaysForSigningReminder;

            let paymentVoucherNotificationSettingsModel =
                noOfDaysForVoucherReminder > 0
                    ? groupInfo.groupSettings.groupNotificationSettings.paymentVoucherNotificationSettingsModel
                    : this.getCompanyVoucherReminderSettings();

            let signingReminderNotificationSettingsModel =
                noOfDaysForSigningReminder > 0
                    ? groupInfo.groupSettings.groupNotificationSettings.signingReminderNotificationSettingsModel
                    : this.getCompanySigningReminderSettings();

            let groupSettings: IGroupSettingsModel = {
                groupId: groupInfo.id,
                groupDeliverySettings: GroupedReturnProcessStore.unloadedGroupDeliveryState,
                groupNotificationSettings: {
                    ...GroupedReturnProcessStore.unloadedGroupNotificationSettings,
                    paymentVoucherNotificationSettingsModel: paymentVoucherNotificationSettingsModel,
                    signingReminderNotificationSettingsModel: signingReminderNotificationSettingsModel
                },
                groupRetentionSettings: GroupedReturnProcessStore.unloadedGroupRetentionSetting,
                groupSignatureSettings: GroupedReturnProcessStore.unloadedGroupSignatureSetting,
                groupK1Settings: GroupedReturnProcessStore.unloadedGroupK1Settings
            };
            HideLoader();
            self.setState({
                groupSendReminderState: {
                    show: true,
                    model: deliveredGroupReturnModel,
                    groupSettings: groupSettings
                }
            });
        });
    };

    private getCompanyVoucherReminderSettings = () => {
        return this.props.company.companySettings
            ? this.props.company.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings
            : initialCompanySettings.notificationSettingsModel.paymentVoucherNotificationSettings;
    };

    private getCompanySigningReminderSettings = () => {
        return this.props.company.companySettings
            ? this.props.company.companySettings.notificationSettingsModel.signingReminderNotificationSettings
            : initialCompanySettings.notificationSettingsModel.signingReminderNotificationSettings;
    };

    private onGroupSendReminderCancel = () => {
        this.setState({
            groupSendReminderState: {
                show: false,
                model: undefined,
                groupSettings: undefined
            }
        });
    };

    onSendReminderNow = (documentId: number, groupId: number) => {
        let sendReminderRequestList: GroupedReturnSendReminderRequest[] = [];
        ShowLoader(Common.OverlayMessages.Processing);
        sendReminderRequestList = this.getSendReminderRequestList(documentId, groupId);
        this.props.sendReminderForGroupedReturn(
            sendReminderRequestList,
            () => {
                this.onSendReminderGroupSuccess();
            },
            undefined,
            this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix + CommonResources.Reminder_Header
        );
        HideLoader();
        this.setState({
            groupSendReminderState: {
                show: false,
                model: undefined,
                groupSettings: undefined
            }
        });
    };

    private getSendReminderRequestList(documentId: number, groupId: number): GroupedReturnSendReminderRequest[] {
        let sendReminderRequestList: GroupedReturnSendReminderRequest[] = [];
        let documentIds: number[] = [];
        documentIds.push(documentId);
        sendReminderRequestList.push({ documentId: documentIds, groupId: groupId });
        return sendReminderRequestList;
    }

    onSendReminderGroupSuccess = () => {
        HideLoader();
        VenusNotifier.Success("Reminder has been sent successfully for the selected Group", null);
        this.forceUpdate();
    };

    private onGroupVoucherReminderSave = (
        groupId: number,
        allowVoucherReminder: boolean,
        voucherReminderInDays: number,
        allowSigningReminder: boolean,
        signingReminderInDays: number
    ) => {
        ShowLoader(Common.OverlayMessages.Processing);
        this.props.updateDeliveredReturnsVoucherReminder(
            groupId,
            allowVoucherReminder,
            voucherReminderInDays,
            allowSigningReminder,
            signingReminderInDays,
            () => {
                HideLoader();
                this.setState({
                    groupSendReminderState: {
                        show: false,
                        model: undefined,
                        groupSettings: undefined
                    }
                });
                VenusNotifier.Success(successMsg["voucherReminderState"], undefined);
            },
            this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix + CommonResources.Reminder_Header
        );
    };

    //====================Group Send Reminder Ends Here=================================

    clearFilters = () => {
        this.setState({ selectedRows: [], selectedDocumentGuid: "" }, this.onPageReload);
        let deliveredReturnsTable: any = this.tableRef;
        deliveredReturnsTable.refs.Filters.setState({ activeFilter: "", appliedFilters: [] });
    };

    buildQuery(page: number, pageSize: number) {
        return (
            "?PageNo=" +
            page +
            "&SortBy=" +
            this.state.sortName +
            "&SortOrder=" +
            this.state.sortOrder +
            "&selectedTaxYear=" +
            (this.state.filterTaxYear === "-1" ? "" : this.state.filterTaxYear) +
            "&PageSize=" +
            pageSize +
            "&filterName=" +
            (this.state.filterName === "-1" ? "" : encodeURIComponent(this.state.filterName)) +
            "&filterClientId=" +
            encodeURIComponent(this.state.filterClientId) +
            "&filterPartner=" +
            encodeURIComponent(this.state.filterPartner) +
            "&filterStatus=" +
            (this.state.filterStatus === "-1" ? "" : this.state.filterStatus) +
            "&filterLocation=" +
            (this.state.filterLocation === "-1" ? "" : this.state.filterLocation) +
            "&filterDeliveredOn=" +
            (this.state.filterDeliveredOn || "") +
            "&filterEngagementType=" +
            this.state.filterEngagementType +
            "&filterCustomColumn=" +
            encodeURIComponent(this.state.filterCustomColumn) +
            "&filterDownloadedBy=" +
            this.state.filterDownloadedBy +
            "&filterLastReminderOn=" +
            (this.state.filterLastReminderOn || "") +
            "&filterRetentionPeriod=" +
            (this.state.filterRetentionPeriod || "") +
            "&filterSentBy=" +
            encodeURIComponent(this.state.filterSentBy) +
            "&isArchived=" +
            this.props.isArchived +
            "&filterDocumentFilingType=" +
            this.state.filterDocumentFilingType +
            "&isPaperFileEnabled=" +
            this.paperFileEnabled() +
            "&filterGroupType=" +
            this.state.filterGroupType
        );
    }

    buildQuickReportQuery(page: number, pageSize: number) {
        return (
            "?PageNo=" +
            1 +
            "&SortBy=" +
            "" +
            "&SortOrder=" +
            "desc" +
            "&SearchString=" +
            "" +
            "&selectedTaxYear=" +
            (new Date().getFullYear() - 1).toString() +
            "&PageSize=" +
            pageSize +
            "&filterName=" +
            "" +
            "&filterClientId=" +
            "" +
            "&filterPartner=" +
            "" +
            "&filterStatus=" +
            "" +
            "&filterLocation=" +
            "" +
            "&filterDeliveredOn=" +
            "" +
            "&filterSentBy=" +
            "" +
            "&filterSignatureStatus=" +
            "" +
            "&isArchived=" +
            this.props.isArchived +
            "&filterDocumentFilingType=" +
            this.state.filterDocumentFilingType
        );
    }

    loadDeliveredReturns() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestDeliveredReturns(queryString);
    }

    fetchDeliveredReturns(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page, pageSize: sizePerPage }, () => {
            this.props.requestDeliveredReturns(queryString);
        });
    }

    private selectedDocumentIds() {
        let ids: number[] = [];
        this.state.selectedRows.map((row, i) => {
            let selectedDocument =
                this.state.showQuickReports === false
                    ? this.props.delivered.deliveredReturnTableModel.documents[row]
                    : this.props.quickReports.quickReportTableModel.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.document.id);
            }
        });
        return ids;
    }
    private selectedDoocumentIdsWithoutGroup() {
        let ids: number[] = [];
        this.state.selectedRows.map((row, i) => {
            let selectedDocument =
                this.state.showQuickReports === false
                    ? this.props.delivered.deliveredReturnTableModel.documents[row]
                    : this.props.quickReports.quickReportTableModel.documents[row];
            if (selectedDocument) {
                if (selectedDocument.document.groupId === 0) {
                    ids.push(selectedDocument.document.id);
                }
            }
        });
        return ids;
    }

    private setSelectedDocumentsGuid() {
        let documentGuids: string[] = [];
        this.state.selectedRows.map((row, i) => {
            let selectedDocument =
                this.state.showQuickReports === false
                    ? this.props.delivered.deliveredReturnTableModel.documents[row]
                    : this.props.quickReports.quickReportTableModel.documents[row];
            if (selectedDocument) {
                documentGuids.push(selectedDocument.document.documentGuid);
            }
        });

        this.setState({
            selectedDocumentGuid: documentGuids.join(",")
        });
    }

    private selectedDocumentIdsAndLoadingStatus(): DeliveredReturnsState.ISelectedDocument[] {
        let selectedDocuments: DeliveredReturnsState.ISelectedDocument[] = [];
        let ids = this.selectedDocumentIds();
        ids.map((id, index) => {
            selectedDocuments.push({ id: id, isfullyLoaded: this.props.taxDocuments[id].isFullyLoaded ? true : false });
        });
        return selectedDocuments;
    }

    private selectedDocuments() {
        let docs: IDeliveredTaxDocument[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.delivered.deliveredReturnTableModel.documents[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }

    private selectedQuickReportDocuments() {
        let docs: IQuickReportDocument[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.quickReports.quickReportTableModel.documents[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }

    private lazyLoadSelected(ids: number[]) {
        ids.forEach((id, i) => {
            this.props.requestTaxDocument(id, false);
        });
    }

    private isLoaded(allReturns: TaxDocumentStore.ITaxDocumentDictionary, ids: number[]) {
        for (var i in ids) {
            if (allReturns[ids[i]] && allReturns[ids[i]].isLoading) {
                return false;
            }
        }
        return true;
    }

    private onPopupOpen(rowIndex: number) {
        let ids = [rowIndex];
        if (rowIndex !== NO_INDEX) {
            if (this.state.showQuickReports) {
                return [this.props.quickReports.quickReportTableModel.documents[rowIndex].document.id];
            }
            return [this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id];
        }
        if (this.state.selectedRows.length == 0) {
            return [];
        }
        return this.selectedDocumentIds();
    }

    private getStoreItem(index: number) {
        let id =
            this.state.showQuickReports === false
                ? this.props.delivered.deliveredReturnTableModel.documents[index].document.id
                : this.props.quickReports.quickReportTableModel.documents[index].document.id;
        return this.props.taxDocuments[id];
    }

    private onUpdateCompletion(
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary,
        stateKey: string,
        state: DeliveredReturnsState.IPopupStateSingle
    ) {
        if (state.saveCheckId && this.isLoaded(taxDocuments, [state.saveCheckId])) {
            if (stateKey != "reportProblemState") {
                if (taxDocuments[state.saveCheckId] && taxDocuments[state.saveCheckId].error) {
                    VenusNotifier.Error(errorMsg[stateKey], stateKey);
                    this.props.requestTaxDocument(state.saveCheckId, true);
                } else {
                    if (
                        stateKey == "resendAccessLinkState" ||
                        stateKey == "retentionPolicyState" ||
                        stateKey == "voucherReminderState"
                    ) {
                        VenusNotifier.Success(successMsg[stateKey], stateKey);
                    } else {
                        VenusNotifier.Success(successMsg[stateKey], stateKey);
                        this.setState({ selectedRows: [], selectedDocumentGuid: "", showBulkSelectionMessage: false });
                    }
                }
            }
            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }

    private onUpdateCompletionMultiple(
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary,
        stateKey: string,
        state: DeliveredReturnsState.IPopupStateMultiple
    ) {
        if (state.saveCheckIds && this.isLoaded(taxDocuments, state.saveCheckIds)) {
            let err = false;
            state.saveCheckIds.map((id, i) => {
                if (taxDocuments[id] && taxDocuments[id].error) {
                    this.props.requestTaxDocument(id, true);
                    err = true;
                }
            });
            if (err) {
                VenusNotifier.Error(errorMsg[stateKey], stateKey);
            } else if (state.remove) {
                VenusNotifier.Success(successMsg["customColumnRemoveState"], stateKey);
                this.setState({ selectedRows: [], selectedDocumentGuid: "", showBulkSelectionMessage: false });
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
                this.setState({ selectedRows: [], selectedDocumentGuid: "", showBulkSelectionMessage: false });
            }

            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }

    private onUpdateCompletionSendReminder(
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary,
        stateKey: string,
        state: DeliveredReturnsState.ISendReminderState
    ) {
        if (state.selectedDocumentIds && this.isLoaded(taxDocuments, state.selectedDocumentIds)) {
            let err = false;
            state.selectedDocumentIds.map((id, i) => {
                if (taxDocuments[id] && taxDocuments[id].error) {
                    this.props.requestTaxDocument(id, true);
                    err = true;
                }
            });
            if (err) {
                VenusNotifier.Error(errorMsg[stateKey], stateKey);
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
                this.setState({ selectedRows: [], selectedDocumentGuid: "", showBulkSelectionMessage: false });
            }

            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false
                }
            }));
        }
    }

    private isTaxReturnNull = (taxDocuments: TaxDocumentStore.ITaxDocumentDictionary, id: number): boolean => {
        if (taxDocuments[id] && taxDocuments[id].taxReturn != undefined) {
            return true;
        }
        return false;
    };
    private checkForPopupCompletion(taxDocuments: TaxDocumentStore.ITaxDocumentDictionary) {
        this.onUpdateCompletion(taxDocuments, "editClientInfoState", this.state.editClientInfoState);
        this.onUpdateCompletionMultiple(taxDocuments, "changeStatusState", this.state.changeStatusState);
        this.onUpdateCompletion(taxDocuments, "reportProblemState", this.state.reportProblemState);
        this.onUpdateCompletionMultiple(taxDocuments, "customColumnState", this.state.customColumnState);
        this.onUpdateCompletionMultiple(taxDocuments, "downloadTypeOptionState", this.state.DownloadTypeOptionState);
        this.onUpdateCompletionMultiple(taxDocuments, "archiveReturnsState", this.state.archiveReturnsState);
        this.onUpdateCompletionMultiple(taxDocuments, "setAccessState", this.state.setAccessState);
        this.onUpdateCompletion(taxDocuments, "resendAccessLinkState", this.state.resendAccessLinkState);
        this.onUpdateCompletion(taxDocuments, "recallReturnState", this.state.recallReturnState);
        this.onUpdateCompletion(taxDocuments, "uploadFormsState", this.state.uploadFormsState);
        this.onUpdateCompletion(taxDocuments, "clientViewState", this.state.clientViewState);
        this.onUpdateCompletionMultiple(taxDocuments, "restoreReturnsState", this.state.restoreReturnsState);
        this.onUpdateCompletion(taxDocuments, "deleteReturnsState", this.state.deleteReturnsState);
        this.onUpdateCompletion(taxDocuments, "retentionPolicyState", this.state.retentionPolicyState);
    }

    private updateStates(props: DeliveredReturnsProps) {
        if (
            this.state.editClientInfoState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.editClientInfoState.model.id)
        ) {
            this.setState({
                editClientInfoState: {
                    ...this.state.editClientInfoState,
                    model: props.taxDocuments[this.state.editClientInfoState.model.id]?.taxReturn
                }
            });
        }

        if (
            this.state.reportProblemState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.reportProblemState.model.id)
        ) {
            this.setState({
                reportProblemState: {
                    ...this.state.reportProblemState,
                    model: props.taxDocuments[this.state.reportProblemState.model.id]?.taxReturn
                }
            });
        }

        if (
            this.state.uploadRevisionDocumentState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.uploadRevisionDocumentState.model.id)
        ) {
            this.setState({
                uploadRevisionDocumentState: {
                    ...this.state.uploadRevisionDocumentState,
                    model: props.taxDocuments[this.state.uploadRevisionDocumentState.model.id]?.taxReturn
                }
            });
        }

        if (
            this.state.retentionPolicyState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.retentionPolicyState.model.id)
        ) {
            this.setState({
                retentionPolicyState: {
                    ...this.state.retentionPolicyState,
                    model: props.taxDocuments[this.state.retentionPolicyState.model.id]?.taxReturn
                }
            });
        }

        if (
            this.state.clientViewState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.clientViewState.model.id) &&
            props.taxDocuments[this.state.clientViewState.model.id].isFullyLoaded !=
                this.state.clientViewState.model.isfullyLoaded
        ) {
            let clientViewStateReturn = props.taxDocuments[this.state.clientViewState.model.id]?.taxReturn;
            clientViewStateReturn.isfullyLoaded =
                props.taxDocuments[this.state.clientViewState.model.id]?.taxReturn.isfullyLoaded;

            this.setState({
                clientViewState: {
                    ...this.state.clientViewState,
                    model: clientViewStateReturn
                }
            });
        }

        if (
            this.state.screenShareState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.screenShareState.model.id) &&
            props.taxDocuments[this.state.screenShareState.model.id].isFullyLoaded !=
                this.state.screenShareState.model.isfullyLoaded
        ) {
            let screenShareStateReturn = props.taxDocuments[this.state.screenShareState.model.id]?.taxReturn;
            screenShareStateReturn.isfullyLoaded = props.taxDocuments[this.state.screenShareState.model.id].isFullyLoaded;

            this.setState({
                screenShareState: {
                    ...this.state.screenShareState,
                    model: screenShareStateReturn
                }
            });
        }

        if (this.state.isBulkSelectionEnabled) {
            let selectedRows: number[] = [];
            if (!this.state.showQuickReports) {
                props.delivered.deliveredReturnTableModel.documents.forEach((value, index) => {
                    this.state.deSelectedRows.find((m) => m === value.document.id) === undefined && selectedRows.push(index);
                });
            } else {
                props.quickReports.quickReportTableModel.documents.forEach((value, index) => {
                    this.state.deSelectedRows.find((m) => m === value.document.id) === undefined && selectedRows.push(index);
                });
            }

            this.setState({ selectedRows: selectedRows });
        }

        if (props.columnSettings) {
            if (props.columnSettings.DeliveredReturnsColumnSettings && !props.isArchived) {
                this.setState({
                    columnSettings: props.columnSettings.DeliveredReturnsColumnSettings
                });
            }
            if (props.columnSettings.ArchivedReturnsColumnSettings && props.isArchived) {
                this.setState({
                    columnSettings: props.columnSettings.ArchivedReturnsColumnSettings
                });
            }
        }
    }
    private getDefaultFilter = (filters: IFilters[]): string | undefined => {
        let existsMasterFilter = filters.findIndex((x) => x.isMasterFilter) > -1 ? true : false;
        let existsDefaultFilter = filters.findIndex((x) => x.isDefaultFilter) > -1 ? true : false;

        if (existsMasterFilter) {
            let mName = filters.find((x) => x.isMasterFilter);
            return mName ? mName.name : undefined;
        } else if (existsDefaultFilter) {
            let dName = filters.find((x) => x.isDefaultFilter);
            return dName ? dName.name : undefined;
        }
        return undefined;
    };

    private getTaxReturnFromState = (): ITaxReturn => {
        let id = 0;

        if (this.state.clientViewState && this.state.clientViewState.show && this.state.clientViewState.model) {
            id = this.state.clientViewState.model.id;
        }

        if (this.state.screenShareState && this.state.screenShareState.model) {
            id = this.state.screenShareState.model.id;
        }

        if (id && id > 0) {
            let taxReturn = this.props.taxDocuments[id]?.taxReturn;
            if (taxReturn) {
                taxReturn.isfullyLoaded = this.props.taxDocuments[id].isFullyLoaded;
                return this.props.taxDocuments[id]?.taxReturn;
            }
        }

        return {} as ITaxReturn;
    };

    onSelectQuickReports = (quickReportType: QuickReportType, query?: string, callback?: () => void) => {
        this.setState({ showQuickReports: true, selectedQuickReport: quickReportType });
        query
            ? this.props.requestQuickReport(query, true, quickReportType, callback && callback)
            : this.props.requestQuickReport(this.buildQuickReportQuery(1, 20), true, quickReportType, callback && callback);
    };

    closeQuickReport = () => {
        this.clearSelection();
        this.setState({ showQuickReports: false, selectedQuickReport: QuickReportType.None });
        this.fetchDeliveredReturns(1, pageSize);
    };

    ///////////////////////////////////////////Revised Documents Start/////////////////////////////////////////
    public PROCESSINGROWINDEX: number = 0;
    onProcessRevisedDocumentsOpen = (rowIndex: number) => {
        const uploadEstimateResourceId =
            this.props.parentResourceId +
            DeliveredReturnReportResourceId.GridPrefix +
            DeliveredReturnReportResourceId.UploadEstimate;
        const item = this.getStoreItem(rowIndex);
        const taxReturn: ITaxReturn = item?.taxReturn;
        this.PROCESSINGROWINDEX = rowIndex;
        const deliveredReturn = this.props.delivered.deliveredReturnTableModel.documents[rowIndex];
        const isMerged = deliveredReturn.hasRevisionDocumentMerged;
        if (!isMerged || this.state.revisionDocuments.length == 0) {
            this.props.requestRevisionDocumentsUploadDetails(
                taxReturn.id,
                (revisionDocuments: ITaxDocumentRevision[]) => {
                    this.setState({ revisionDocuments: revisionDocuments });
                    const nonSupportedCount = revisionDocuments.filter(
                        (x) => x.status == TaxDocumentRevisionStatus.NonSupported
                    ).length;
                    const errorCount = revisionDocuments.filter((x) => x.status == TaxDocumentRevisionStatus.Error).length;
                    const recognizedCount = revisionDocuments.filter((x) => x.status == TaxDocumentRevisionStatus.Ready).length;
                    if (nonSupportedCount > 0 || errorCount > 0) {
                        bootbox.confirm({
                            title: "Process Return",
                            closeButton: false,
                            message: RevisionDocumentsConstant.RevisionProcessModalConfirmation.replace(
                                "<RecognizedForms>",
                                recognizedCount.toString()
                            )
                                .replace("<NonRecognizedForms>", (errorCount + nonSupportedCount).toString())
                                .replace(
                                    "<TotalForms>",
                                    revisionDocuments
                                        .filter((x) => x.status != TaxDocumentRevisionStatus.Merged)
                                        .length.toString()
                                ),
                            buttons: {
                                cancel: {
                                    label: '<i class="fas fa-times"></i> Discard & Re-upload',
                                    className: "btn-white btn-default"
                                },
                                confirm: {
                                    label: '<i class="fas fa-check"></i> Process Anyway',
                                    className: "btn-info"
                                }
                            },
                            callback: (result: boolean) => {
                                if (result) {
                                    this.props.requestRevisedTaxDocument(
                                        taxReturn.id,
                                        this.handleRequest,
                                        uploadEstimateResourceId
                                    );
                                    this.props.discardUnrecognizedRevisedTaxDocuments(taxReturn.id, uploadEstimateResourceId);
                                    this.setState({ revisedProcessReturnModalLoader: true });
                                } else {
                                    this.props.discardRevisedTaxDocuments(taxReturn.id, undefined, uploadEstimateResourceId);
                                    this.onUploadRevisionDocumentOpen(rowIndex);
                                }
                            }
                        });
                    } else {
                        this.props.requestRevisedTaxDocument(taxReturn.id, this.handleRequest, uploadEstimateResourceId);
                        this.setState({ revisedProcessReturnModalLoader: true });
                    }
                },
                uploadEstimateResourceId
            );
        } else {
            this.props.requestRevisedTaxDocument(taxReturn.id, this.handleRequest, uploadEstimateResourceId);
            this.setState({ revisedProcessReturnModalLoader: true });
        }
    };

    handleRequest = (taxReturn: ITaxReturn) => {
        const voucherIndex = taxReturn.formGroups.findIndex((x) => x.group == DocumentGroups.Vouchers);
        let isVoucherDuplicated: boolean = false;
        const voucherForms = taxReturn.formGroups[voucherIndex].forms as IVoucher[];
        isVoucherDuplicated = voucherForms.every((x) => x.voucherStatus == VoucherStatus.Existing);

        if (isVoucherDuplicated) {
            this.setState({ revisedProcessReturnModalLoader: false });
            bootbox.confirm({
                title: "Process Return",
                message: RevisionDocumentsConstant.NoRevisedEstimatesFound,
                closeButton: false,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Discard & Re-upload',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Process Anyway',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.checkIfReturnInUse(taxReturn);
                    } else {
                        this.props.discardRevisedTaxDocuments(
                            taxReturn.id,
                            undefined,
                            this.props.parentResourceId +
                                DeliveredReturnReportResourceId.GridPrefix +
                                DeliveredReturnReportResourceId.UploadEstimate
                        );
                        this.onUploadRevisionDocumentOpen(this.PROCESSINGROWINDEX);
                    }
                }
            });
        } else {
            this.checkIfReturnInUse(taxReturn);
        }
    };

    checkIfReturnInUse = (taxReturn: ITaxReturn) => {
        const assignedToUser = this.props.userStore.users.find((x) => x.userId == taxReturn.assignTo);
        if (taxReturn.lockedBy > 0 && taxReturn.assignTo !== this.props.userProfile?.userId) {
            // locked, assigned to other user
            this.setState({ revisedProcessReturnModalLoader: false });
            bootbox.confirm({
                title: "Process Return",
                message: RevisionDocumentsConstant.ReturnInUse.replaceAll(
                    "<UserName>",
                    assignedToUser?.firstName.concat(" ", assignedToUser?.lastName)
                ),
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Process Anyway',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.revisedProcessReturnModalSetUp(taxReturn);
                    }
                }
            });
        } else if (taxReturn.lockedBy <= 0 && taxReturn.assignTo !== this.props.userProfile?.userId) {
            taxReturn.assignTo = this.props.userProfile?.userId != undefined ? this.props.userProfile.userId : 0;
            taxReturn.assignedUser.email = this.props.userProfile.email;
            taxReturn.assignedUser.firstName = this.props.userProfile.firstName;
            taxReturn.assignedUser.lastName = this.props.userProfile.lastName;
            taxReturn.assignedUser.userId = this.props.userProfile?.userId || 0;
            this.revisedProcessReturnModalSetUp(taxReturn);
        } else {
            this.revisedProcessReturnModalSetUp(taxReturn);
        }
    };

    revisedProcessReturnModalSetUp = (taxReturn: ITaxReturn) => {
        this.props.requestTaxReturnPdf(taxReturn, true);
        this.props.requestTaxingAuthorities();
        this.props.requestCompanySettings(false);
        if (taxReturn.groupId > 0) {
            this.props.requestGroupInfo(taxReturn.groupId, (revisedReturnGroupInfo: IGroupInfo) => {
                this.setState({
                    groupInfo: revisedReturnGroupInfo
                });
            });
        }
        // download delegated signers
        if (this.props.userProfile?.userId) {
            const userId = this.props.userProfile.userId as number;
            this.props.requestDelegatedSigners(userId);
        }

        // download the signature default ero signer
        if (taxReturn.partner?.userId) {
            this.props.requestDownloadPath(taxReturn.partner.userId);
        }

        this.setState({
            revisedProcessReturnModalLoader: false,
            revisedProcessReturnWindow: {
                show: true
            },
            processingDocumentId: taxReturn.id
        });
    };

    handleRevisedProcessReturnCancel = (id: number) => {
        this.setState({
            revisedProcessReturnWindow: {
                show: false
            },
            processingDocumentId: 0,
            groupInfo: undefined
        });
        this.props.resetTaxDocument(id);
    };

    updateDocumentInitialStatus = (taxReturn: ITaxReturn) => {
        this.props.updateDocumentInitialStatus(
            taxReturn,
            undefined,
            undefined,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.UploadEstimate
        );
    };

    ///////////////////////////////////////////Revised Documents End///////////////////////////////////////////

    /////////////////////////////////////////Discard Revised Documents Start////////////////////////////////////////

    onDiscardRevisedDocuments = (rowIndex: number) => {
        const item = this.getStoreItem(rowIndex);
        const taxReturn: ITaxReturn = item?.taxReturn;

        bootbox.confirm({
            title: RevisionDocumentsConstant.DiscardTitle,
            message: RevisionDocumentsConstant.DiscardConfirmatiomMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.props.discardRevisedTaxDocuments(
                        taxReturn.id,
                        undefined,
                        this.props.parentResourceId +
                            DeliveredReturnReportResourceId.GridPrefix +
                            DeliveredReturnReportResourceId.UploadEstimate
                    );
                }
            }
        });
    };

    private onExportToExcelQuickReport(onExportToExcelComplete: () => void) {
        let downloadType = this.state.selectedDownloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        if (downloadType == DefaultDownloadInfoType.DownloadNow) {
            let quickReportRef: any = this.refs.QuickReturnsTable;
            let queryString = quickReportRef.buildQuery(quickReportRef.state.page, pageSize);
            this.props.exportQuickReportAsExcel(
                queryString,
                this.state.selectedQuickReport,
                this.paperFileEnabled(),
                onExportToExcelComplete,
                this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.ExportToExcel
            );
        } else if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadToMyDownloads) {
            let quickReportRef: any = this.refs.QuickReturnsTable;
            this.props.exportToExcelBulk(
                quickReportRef.getBulkOperationQuery(),
                () => {
                    this.props.getMyDownloadsListAsync();
                    onExportToExcelComplete();
                },
                this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.ExportToExcel
            );
        } else {
            onExportToExcelComplete();
            this.setState({
                DownloadTypeOptionState: {
                    show: true,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false
                },
                selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads,
                loadExcelSpinner: true,
                selectedDefaultDownloadType: false
            });
        }
    }

    /////////////////////////////////////////Discard Revised Documents End////////////////////////////////////////

    private updateTPOpted = (tpOpted: boolean) => {
        this.setState({ isTPOpted: tpOpted, smsSettingChanged: true });
    };

    private updateSpouseOpted = (spouseOpted: boolean) => {
        this.setState({ isSpouseOpted: spouseOpted, smsSettingChanged: true });
    };

    private updateSMSNotificationDetails = (taxReturn: ITaxReturn) => {
        const dataToUpdateSMSNotificationDetails = {
            oldClientId: this.state.oldClientId.length > 0 ? this.state.oldClientId : null,
            documentId: taxReturn.id,
            clientId: taxReturn.clientId,
            tpDetails: {
                countryCode: isPartnership(taxReturn)
                    ? taxReturn.partnership?.countryCode && taxReturn.partnership?.countryCode.length > 0
                        ? taxReturn.partnership?.countryCode
                        : null
                    : taxReturn.taxpayer?.countryCode && taxReturn.taxpayer?.countryCode.length > 0
                    ? taxReturn.taxpayer?.countryCode
                    : null,
                mobileNumber: isPartnership(taxReturn)
                    ? taxReturn.partnership.mobileNumber && taxReturn.partnership.mobileNumber.length > 0
                        ? taxReturn.partnership.mobileNumber
                        : null
                    : taxReturn.taxpayer?.mobileNumber && taxReturn.taxpayer?.mobileNumber.length > 0
                    ? taxReturn.taxpayer?.mobileNumber
                    : null,
                isOpted: this.state.isTPOpted
            },
            spouseDetails: isPartnership(taxReturn)
                ? null
                : !isMutual(taxReturn)
                ? null
                : {
                      countryCode:
                          taxReturn.spouse?.countryCode && taxReturn.spouse?.countryCode.length > 0
                              ? taxReturn.spouse?.countryCode
                              : null,
                      mobileNumber:
                          taxReturn.spouse?.mobileNumber && taxReturn.spouse?.mobileNumber.length > 0
                              ? taxReturn.spouse?.mobileNumber
                              : null,
                      isOpted: this.state.isSpouseOpted
                  }
        };

        this.state.showSMSTextNotifications && this.props.updateSMSNotificationDetails(dataToUpdateSMSNotificationDetails);
    };

    //==================== GR View Access Code Starts Here ===============================

    private onGroupReturnViewAccessCodeOpen = (rowIndex: number = NO_INDEX) => {
        const groupId = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.groupId;
        const documentId = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        ShowLoader();
        this.props.requestGroupedReturnAccessCode(
            groupId,
            documentId,
            () => {
                HideLoader();
                this.setState({
                    groupedReturnAccessCodeState: {
                        show: true,
                        model: this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document
                    }
                });
            },
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.ViewAccessCode
        );
    };

    private onGroupReturnGenerateOTP = (groupId: number, documentId: number, clientGUID: string, callBack: () => void) => {
        const index = this.props.delivered.deliveredReturnTableModel.documents.findIndex((x) => x.document.id === documentId);
        this.props.requestGroupedReturnOtpGenerate(
            groupId,
            documentId,
            clientGUID,
            callBack,
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.ViewAccessCode
        );
        this.setState({
            groupedReturnAccessCodeState: {
                show: true,
                model: this.props.delivered.deliveredReturnTableModel.documents[index].document
            }
        });
    };

    private onGroupReturnViewAccessCodeCancel = () => {
        this.setState({
            groupedReturnAccessCodeState: {
                show: false,
                model: undefined
            }
        });
    };
    //=====================View Access Code Ends Here================================

    //====================Resend Access Link Starts Here===============================

    private onGroupReturnResendAccessLinkOpen = (rowIndex: number = NO_INDEX) => {
        let deliveredTaxDocument = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document;
        const groupId = deliveredTaxDocument.groupId;
        const documentId = deliveredTaxDocument.id;

        ShowLoader();

        this.props.requestControllerAccessLink(
            groupId,
            documentId,
            () => {
                HideLoader();
                this.setState({
                    groupedReturnResendAccessLinkState: {
                        show: true,
                        model: this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document
                    }
                });
            },
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.ResendAccessLink
        );
    };

    private onGroupReturnResendAccessLink = (id: number) => {
        this.props.resendGroupedReturnAccessLink(
            id,
            () => {
                VenusNotifier.Success(successMsg["resendAccessLinkState"], null);
            },
            this.props.parentResourceId +
                DeliveredReturnReportResourceId.GridPrefix +
                DeliveredReturnReportResourceId.ResendAccessLink
        );
    };

    private onGroupReturnResendAccessLinkCancel = () => {
        this.setState({
            groupedReturnResendAccessLinkState: {
                show: false,
                model: undefined
            }
        });
    };
    //====================Resend Access Link Ends Here===============================

    //====================Grouped Report a problem starts here ==============================

    private onGroupedReportProblemOpen = (rowIndex: number = NO_INDEX) => {
        const groupId = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.groupId;
        const groupReportModel: ITaxReturn = { id: 0, clientId: "" } as ITaxReturn;

        this.setState({
            groupReportProblemState: {
                show: true,
                model: groupReportModel,
                saveCheckId: groupId
            }
        });
    };

    private onGroupedReportProblemCancel = () => {
        this.setState({
            groupReportProblemState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    };

    private onGroupedReportProblemSave = (id: number, problemDetails: IReportProblemDetails, callback?: () => void) => {
        let groupId: number = this.state.groupReportProblemState.saveCheckId ? this.state.groupReportProblemState.saveCheckId : 0;
        if (problemDetails.description.trim() === "") {
            VenusNotifier.Warning("Please enter problem description to report.", null);
        } else {
            if (callback)
                this.props.reportDocumentGroupProblem(
                    problemDetails,
                    groupId,
                    callback,
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.GridPrefix +
                        DeliveredReturnReportResourceId.ReportAProblem
                );
            else
                this.props.reportDocumentGroupProblem(
                    problemDetails,
                    groupId,
                    undefined,
                    this.props.parentResourceId +
                        DeliveredReturnReportResourceId.GridPrefix +
                        DeliveredReturnReportResourceId.ReportAProblem
                );

            this.setState({
                groupReportProblemState: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            });
        }
    };

    //========================Grouped Report a problem ends here=======================================

    //======================Change Status starts here================================
    onRowChangeStatusOpen = (rowIndex: number = NO_INDEX) => {
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        let deliveredTaxDocument = this.props.delivered.deliveredReturnTableModel.documents[rowIndex];
        if (this.props.taxDocuments[id].isFullyLoaded == undefined || !this.props.taxDocuments[id].isFullyLoaded) {
            ShowLoader();
            this.props.requestTaxDocument(id, true, undefined, undefined, () => {
                HideLoader();
                this.setState({
                    rowChangeStatusState: {
                        show: true,
                        model: deliveredTaxDocument
                    }
                });
            });
        } else {
            this.setState({
                rowChangeStatusState: {
                    show: true,
                    model: deliveredTaxDocument
                }
            });
        }
    };

    private onRowChangeStatusSave = (documentId: number, status: SignatureStatus, reason: string) => {
        const changeStatusResourceId =
            this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix + CommonResources.ChangeStatus_Header;

        let taxDocument = this.props.taxDocuments[documentId]?.taxReturn;

        taxDocument.signatureStatus = status;
        taxDocument.deliveredBy = taxDocument.deliveredByUser?.userId;
        taxDocument.isModified = true;
        if (
            taxDocument.signatureStatus == SignatureStatus.AwaitingESign ||
            taxDocument.signatureStatus == SignatureStatus.PartiallySigned
        ) {
            taxDocument.documentSettings.documentSignatureSetting.signatureFormSelectionType = SignatureType.ESign;
        }
        if (
            taxDocument.signatureStatus == SignatureStatus.AwaitingUpload ||
            taxDocument.signatureStatus == SignatureStatus.ManuallySigned
        ) {
            taxDocument.documentSettings.documentSignatureSetting.signatureFormSelectionType = SignatureType.ManualSign;
        }
        if (
            taxDocument.documentStatus == DocumentStatus.DELIVERED &&
            (taxDocument.signatureStatus == SignatureStatus.ESigned ||
                taxDocument.signatureStatus == SignatureStatus.ESignedInOffice ||
                taxDocument.signatureStatus == SignatureStatus.ManuallySigned ||
                taxDocument.signatureStatus == SignatureStatus.SignedAndESigned)
        ) {
            taxDocument.documentStatus = DocumentStatus.USERSIGNED;
        }
        ShowLoader();
        this.props.saveTaxDocument(
            taxDocument,
            undefined,
            (data) => {
                HideLoader();
                if (status === SignatureStatus.SignedAndESigned) {
                    taxDocument.groupId === 0 || !taxDocument.groupId
                        ? this.props.cancelSigningReminder(taxDocument, changeStatusResourceId)
                        : this.props.cancelGroupedReturnSigningReminder(taxDocument);
                    this.props.UpdateStatusToSignedAndESigned(
                        taxDocument,
                        reason,
                        this.props.parentResourceId +
                            DeliveredReturnReportResourceId.HeaderPrefix +
                            CommonResources.ChangeStatus_Header
                    );
                }
                if (status == SignatureStatus.ManuallySigned) {
                    this.props.CleanUpSigningOrder(taxDocument, changeStatusResourceId);
                    this.props.statusChangeEvent(taxDocument, false, changeStatusResourceId);
                    taxDocument.groupId === 0 || !taxDocument.groupId
                        ? this.props.cancelSigningReminder(taxDocument, changeStatusResourceId)
                        : this.props.cancelGroupedReturnSigningReminder(taxDocument);
                    this.props.LogChangeStatusToManaullySignedTransaction(taxDocument, changeStatusResourceId);
                    if (
                        taxDocument.documentSettings.deliverySettings.reportingEngine &&
                        taxDocument.documentSettings.deliverySettings.reportingEngine === ReportingEngineType.TaxCaddy &&
                        taxDocument.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy === false
                    )
                        this.props.SubmitReturnsToTaxCaddy(taxDocument, changeStatusResourceId);
                }
                if (status === SignatureStatus.AwaitingESign || status === SignatureStatus.PartiallySigned) {
                    this.props.deleteProcessInfo(taxDocument.id, changeStatusResourceId);
                    this.props.statusChangeEvent(taxDocument, false, changeStatusResourceId);
                }
                if (status === SignatureStatus.AwaitingUpload) {
                    this.props.statusChangeEvent(taxDocument, false, changeStatusResourceId);
                }
                this.onPageReload();
                VenusNotifier.Success(successMsg["changeStatusState"], null);
            },
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            changeStatusResourceId
        );

        this.setState({
            rowChangeStatusState: {
                show: false,
                model: undefined
            },
            showLoader: true
        });
    };

    private onRowChangeStatusCancel = () => {
        this.setState({
            rowChangeStatusState: {
                show: false,
                model: undefined
            }
        });
    };

    //======================Change Status ends here==================================

    public render() {
        const customColumnHeader = this.props.customColumnData.customColumn
            ? this.props.customColumnData.customColumn.header
            : "";
        const isPaperFileEnabled = this.paperFileEnabled();
        const isScreenShareEnabled = this.isScreenShareEnabled();

        return (
            <div>
                <div className="user-assignment-content devilvered-archived-screen">
                    <DeliveredReturnsHeader
                        parentResourceIdentifier={this.props.parentResourceId}
                        headerResourceIdentifier={this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix}
                        pageTitle={this.props.pageTitle}
                        onSetAccessOpen={this.onSetAccessOpen}
                        onArchiveReturnsOpen={this.onArchiveReturnsOpen}
                        onChangeStatusOpen={
                            !this.state.selectedQuickReport ? this.onChangeStatusOpen : this.onChangeStatusForQuickReportOpen
                        }
                        onCustomColumnOpen={this.onCustomColumnOpen}
                        onMultipleDownloadEfileForms={this.onMultipleDownloadEfileForms}
                        onSendReminder={
                            !this.state.selectedQuickReport ? this.onSendReminderValidate : this.OnSendReminderForQuickReport
                        }
                        selectedDocumentCount={this.state.selectedRows.length}
                        customColumnTitle={
                            this.props.customColumnData.customColumn ? this.props.customColumnData.customColumn.header : ""
                        }
                        users={this.props.userStore.users}
                        onPageReload={this.onPageReload}
                        company={this.props.company}
                        selectedERO={
                            this.props.userSettings.settings && this.props.userSettings.settings.defaultSettings
                                ? this.props.userSettings.settings.defaultSettings.eroUser
                                : 0
                        }
                        uploadTaxReturn={this.props.uploadTaxReturnStore}
                        getUploadLink={this.props.getUploadLink}
                        processTaxReturn={this.props.processTaxReturn}
                        submitTaxReturn={this.props.submitTaxReturn}
                        isArchived={this.props.isArchived}
                        onRestoreReturnOpen={this.onRestoreReturnsOpen}
                        isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
                        onDeleteTaxReturnOpen={this.onDeleteTaxReturnOpen}
                        companySettings={
                            this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings
                        }
                        userDefaultSettings={this.props.userSettings.settings}
                        defaultGroup={this.state.defaultGroup ? this.state.defaultGroup : ""}
                        groupInfo={this.props.groupedReturnStore.groupInfo}
                        groupAccess={this.props.groupedReturnStore.groupAccess}
                        updateGroupInfoState={this.props.requestGroupConfiguration}
                        showQuickReports={this.state.showQuickReports}
                        loadExcelSpinner={this.state.loadExcelSpinner}
                        userProfle={this.props.userProfile}
                        ssrUsers={this.props.ssrUsers.userslist}
                        taxSoftwareSetting={this.props.taxSoftwareSetting}
                        clientManagementSetting={this.props.clientManagementSetting}
                        requestClientManagementSetting={this.props.requestClientManagementSetting}
                        onChangeOfficeLocationOpen={this.onChangeOfficeLocationOpen}
                    />
                    <br />
                    <input type="hidden" id="selectedDocumentsGuid" value={this.state.selectedDocumentGuid} />
                    {this.state.showQuickReports === false && this.props.delivered.deliveredReturnTableModel.documents && (
                        <DeliveredReturnsTable
                            ref="DeliveredReturnsTable"
                            onPageChange={this.onPageChange}
                            onSortChange={this.onSortChange}
                            onFilterChange={this.onFilterChange}
                            pageNo={this.state.page}
                            onExportToExcel={this.onExportToExcel}
                            onFilterNameChange={this.onFilterNameChange}
                            onFilterSave={this.onFilterSave}
                            onFilterUpdate={this.onFilterUpdate}
                            onFilterDelete={this.onFilterDelete}
                            filterList={this.props.reportFilters.filters}
                            currentFilter={this.state.filter}
                            onSetDefaultFilter={this.onSetDefaultFilter}
                            onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                            saveFilterShow={this.state.saveFilterShow}
                            onSaveFilterHide={this.onSaveFilterHide}
                            onSaveFilterShow={this.onSaveFilterShow}
                            totalRows={this.props.delivered.totalRowCount}
                            onClientTrackingOpen={this.onClientTrackingOpen}
                            onDownloadEfileFormsOpen={this.onDownloadEfileFormsOpen}
                            onDownloadReturnOpen={this.onDownloadReturnOpen}
                            onArchiveTaxReturn={this.onArchiveTaxReturn}
                            onEditClientInfoOpen={this.onEditClientInfoOpen}
                            onRecallReturnOpen={this.onRecallReturnOpen}
                            onRemoveReport={this.onRemoveReport}
                            onReportProblemOpen={this.onReportProblemOpen}
                            onDeleteTaxReturnOpen={this.onDeleteTaxReturnOpen}
                            onResendAccessLinkOpen={this.onResendAccessLinkOpen}
                            onTaxpayerView={this.onTaxpayerView}
                            onUnlockReturnOpen={this.onUnlockReturnOpen}
                            onViewAccessCodeOpen={this.onViewAccessCodeOpen}
                            onRetentionPolicyOpen={this.onRetentionPolicyOpen}
                            onSignedDetailsOpen={this.onSignedDetailsOpen}
                            onDownloadHistoryOpen={this.onDownloadHistoryOpen}
                            onUploadFormsOpen={this.onUploadFormsOpen}
                            onScreenShareViewClick={this.onScreenShareViewClick}
                            onScreenShareOpen={this.onScreenShareOpen}
                            showScreenShareSubMenu={this.ssShow}
                            isLoading={this.props.delivered.loading}
                            pageSize={pageSize}
                            onRestoreReturnOpen={this.onRestoreReturnsOpen}
                            isArchived={this.props.isArchived}
                            customColumnHeader={customColumnHeader}
                            onRowSelect={this.onRowSelect}
                            onSelectAll={this.onSelectAll}
                            selectedRows={this.state.selectedRows}
                            delivered={this.props.delivered}
                            defaultFilter={this.getDefaultFilter(this.props.reportFilters.filters)}
                            loadGrid={this.loadDeliveredReturns}
                            onClientViewClick={this.onTaxPayerViewClick.bind(this)}
                            onClientViewLinkClick={this.onTaxPayerViewLinkClick.bind(this)}
                            onClientViewCancel={this.onTaxPayerViewCancel.bind(this)}
                            taxReturn={this.getTaxReturnFromState()}
                            showClientSubMenu={this.state.clientViewState.show}
                            showBulkSelectionMessage={this.state.showBulkSelectionMessage}
                            onBulkSelectionChange={this.onBulkSelectionChange}
                            clearSelection={this.clearSelection}
                            columnSettings={this.state.columnSettings}
                            saveColumnSettings={this.props.saveColumnSettings}
                            proxyFilter={this.proxyFilter}
                            filterType={this.reportFilterType}
                            onPageReload={this.onPageReload}
                            refreshDelay={this.state.refreshDelay}
                            onSelectQuickReports={this.onSelectQuickReports}
                            setTableRef={this.setTableRef}
                            setClearFilter={this.setClearFilter}
                            loadSpinner={this.state.loadExcelSpinner}
                            isPaperFiledEnabled={isPaperFileEnabled}
                            onUploadRevisionDocumentOpen={this.onUploadRevisionDocumentOpen}
                            onRevisedProcessReturnOpen={this.onProcessRevisedDocumentsOpen}
                            onDiscardRevisedDocumentOpen={this.onDiscardRevisedDocuments}
                            onFilterUpdateForTaxYear={this.onFilterUpdateForTaxYear}
                            isScreenShareEnabled={isScreenShareEnabled}
                            parentResourceGridIdentifier={
                                this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix
                            }
                            parentResourceHeaderIdentifier={
                                this.props.parentResourceId + DeliveredReturnReportResourceId.HeaderPrefix
                            }
                            userResources={this.props.userResources.resourceIds}
                            getOfficeLocationDropdown={this.props.getOfficeLocationDropdown}
                            locationDropdown={
                                this.props.officeLocation === undefined ? [] : this.props.officeLocation.locationDropdown
                            }
                            loggedInUserLocation={this.loggedInUserLocation}
                            onSendReminder={this.onSendReminderValidate}
                            onSendReminderValidateIndividualReturn={this.onSendReminderValidateIndividualReturn}
                            onRowChangeStatusOpen={this.onRowChangeStatusOpen}
                            onGroupReturnViewAccessCodeOpen={this.onGroupReturnViewAccessCodeOpen}
                            onGroupedReportProblemOpen={this.onGroupedReportProblemOpen}
                            onGroupReturnResendAccessLinkOpen={this.onGroupReturnResendAccessLinkOpen}
                            groupInfo={this.props.groupedReturnStore.groupInfo}
                            onEditGroupInfoOpen={this.onEditGroupInfoOpen}
                            controllerInfo={this.state.controller}
                            onControllerScreenShareOpen={this.onControllerScreenShareOpen}
                            onControllerScreenShareViewClick={this.onControllerScreenShareViewClick}
                            onGroupSendReminderOpen={this.onGroupSendReminderOpen}
                            onRecallGroupReturnOpen={this.onRecallGroupReturnOpen}
                            onGroupRecallReturnOpen={this.onGroupRecallReturnOpen}
                            clientNames={clientNames}
                            showControllerSubMenu={this.state.controllerViewState.show}
                            onControllerViewClick={this.onControllerViewClick}
                            onControllerViewOpen={this.onControllerViewOpen}
                        />
                    )}

                    {this.state.showQuickReports && (
                        <QuickReports
                            ref="QuickReturnsTable"
                            exportQuickReportAsExcel={this.props.exportQuickReportAsExcel}
                            isLoading={this.props.quickReports.loading}
                            selectedQuickReport={this.state.selectedQuickReport}
                            onSelectQuickReports={this.onSelectQuickReports}
                            closeQuickReport={this.closeQuickReport}
                            quickReports={this.props.quickReports}
                            onRowSelect={this.onRowSelect}
                            onSelectAll={this.onSelectAll}
                            selectedRows={this.state.selectedRows}
                            unSelectedRows={this.state.deSelectedRows}
                            showBulkSelectionMessage={this.state.showBulkSelectionMessage}
                            onBulkSelectionChange={this.onBulkSelectionChange}
                            isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
                            onClearSelection={() => {
                                this.setState({
                                    selectedRows: [],
                                    selectedDocumentGuid: "",
                                    showBulkSelectionMessage: false
                                });
                            }}
                            isArchived={this.props.isArchived}
                            isPaperFiledEnabled={isPaperFileEnabled}
                            onExportToExcelQuickReport={this.onExportToExcelQuickReport}
                            onSignedDetailsOpen={this.onSignedDetailsOpen}
                        />
                    )}
                    <CustomColumn
                        show={this.state.customColumnState.show}
                        onCancel={this.onCustomColumnCancel}
                        onCustomColumnSave={
                            this.state.isBulkSelectionEnabled ? this.customColumnUpdateBulk : this.onCustomColumnSave
                        }
                        onCustomColumnRemove={
                            this.state.isBulkSelectionEnabled ? this.customColumnRemoveBulk : this.onCustomColumnRemove
                        }
                        customColumnDropdownValues={this.props.customColumnData.customColumn}
                        selectedDocuments={this.selectedDocumentIdsAndLoadingStatus()}
                        selectedColumnValue={this.state.selectedColumnValue}
                        onCustomColumnDropdownChange={this.onCustomColumnDropdownChange}
                        hideLoader={this.state.hideCustomCulumnLoader}
                    />
                    <DownloadTypeOption
                        show={this.state.DownloadTypeOptionState.show}
                        onCancel={this.onDownloadTypeCancel}
                        selectedDocuments={this.selectedDocumentIdsAndLoadingStatus()}
                        hideLoader={this.state.hideCustomCulumnLoader}
                        selectedDownloadType={this.state.selectedDownloadType}
                        onDownloadOptionChange={this.onDownloadOptionChange}
                        onDownloadOptionSave={this.onDownloadOptionSave}
                        useDefaultDownloadType={this.useDefaultDownloadType}
                        selectedDefaultDownloadType={this.state.selectedDefaultDownloadType}
                    />

                    <DownloadReturns
                        show={this.state.downloadReturnState.show}
                        onCancel={this.onDownloadReturnCancel}
                        taxReturn={this.state.downloadReturnState.model as ITaxReturn}
                        onDownloadIndividual={this.onDownloadFile}
                        onDownloadAll={this.onDownloadReturnAll}
                        onDownloadAttachments={this.props.downloadAttachments}
                        onDownloadAllAttachments={this.props.downloadAllAttachments}
                        isArchivedReturn={false}
                        onDownloadAdditionalEsignDocuments={this.onDownloadAdditionalDocuments}
                        onDownloadMergedPaperFileDocument={this.downloadMergedPaperFileDocument}
                        onDownloadK1Attachments={this.props.downloadK1Attachments}
                        onDownloadAllK1Attachments={this.props.downloadAllK1Attachments}
                        resourceId={
                            this.props.parentResourceId +
                            DeliveredReturnReportResourceId.GridPrefix +
                            DeliveredReturnReportResourceId.DownloadReturns
                        }
                    />

                    <DownloadEfileForm
                        show={this.state.downloadEformFilesState.show}
                        onCancel={this.onDownloadEfileFormsCancel}
                        taxReturn={this.state.downloadEformFilesState.model as ITaxReturn}
                        downloadIndividual={this.onDownloadFile}
                        downloadAll={this.onDownloadReturnAll}
                        downloadAdditionalEsignDocuments={this.onDownloadAdditionalDocuments}
                        resourceId={
                            this.props.parentResourceId +
                            DeliveredReturnReportResourceId.GridPrefix +
                            DeliveredReturnReportResourceId.DownloadEfileForm
                        }
                    />

                    <ReportProblemModal
                        show={this.state.reportProblemState.show}
                        onCancel={this.onReportProblemCancel}
                        onReportProblemSave={this.onReportProblemSave}
                        model={this.state.reportProblemState.model as ITaxReturn}
                        loggedInCPA={this.props.userProfile}
                        companyName={this.props.company.companyProfile.companyInfo.companyName}
                        reportProblemType={ReportProblemTypes.ReturnSpecific}
                        problemStep={ReportedStep.Report}
                        resourceId={
                            this.props.parentResourceId +
                            DeliveredReturnReportResourceId.GridPrefix +
                            DeliveredReturnReportResourceId.ReportAProblem
                        }
                    />

                    <ReportProblemModal
                        show={this.state.groupReportProblemState.show}
                        onCancel={this.onGroupedReportProblemCancel}
                        onReportProblemSave={this.onGroupedReportProblemSave}
                        model={this.state.groupReportProblemState.model as ITaxReturn}
                        loggedInCPA={this.props.userProfile}
                        companyName={this.props.company.companyProfile.companyInfo.companyName}
                        reportProblemType={ReportProblemTypes.Generic}
                        problemStep={ReportedStep.Report}
                    />

                    <EventTrackingModal
                        show={this.state.clientTrackingState.show}
                        onCancel={this.onClientTrackingCancel}
                        taxReturn={this.state.clientTrackingState.model as ITaxReturn}
                        parentResourceGridIdentifier={this.props.parentResourceId + DeliveredReturnReportResourceId.GridPrefix}
                        userResources={this.props.userResources}
                    />

                    <DownloadHistory
                        show={this.state.downloadHistoryState.show}
                        onCancel={this.onDownloadHistoryCancel}
                        taxReturn={this.state.downloadHistoryState.model as ITaxReturn}
                    />

                    <UploadForms
                        show={this.state.uploadFormsState.show}
                        onCancel={this.onUploadFormsCancel}
                        taxReturn={this.state.uploadFormsState.model as ITaxReturn}
                        onUploadFormsSuccess={this.onUploadFormsSuccess}
                        resourceId={
                            this.props.parentResourceId +
                            DeliveredReturnReportResourceId.GridPrefix +
                            DeliveredReturnReportResourceId.UploadForms
                        }
                    />

                    <SendReminder
                        show={this.state.sendReminderState.show}
                        onHide={this.OnSendReminderCancel}
                        selectedDocumentsIds={this.state.sendReminderState.selectedDocumentIds as number[]}
                        isSigningReminderEnabled={this.state.sendReminderState.isSigningReminderEnabled}
                        companySettings={
                            this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings
                        }
                        bulkReturnsSelected={this.state.sendReminderState.bulkReturnsSelected as ITaxReturn[]}
                        onSendReminderNow={
                            !this.state.sendReminderState.isBulkSelectionEnabled
                                ? () => this.sendReminderConfirm(this.state.sendReminderState.selectedDocumentIds as number[])
                                : this.state.showQuickReports
                                ? () => this.sendBulkRemindersForQuickReport()
                                : () => this.sendBulkReminders(this.state.sendReminderState.selectedDocumentIds as number[])
                        }
                        onNotificationReminderSave={this.onNotificationReminderSave}
                        onBulkNotificationReminderSave={
                            !this.state.showQuickReports
                                ? this.onBulkNotificationReminderSave
                                : this.onBulkNotificationReminderSaveForQuickReport
                        }
                        isSendReminderFromHeader={this.isSendReminderFromHeader}
                        isAllowUserToEnableVoucherReminder={this.props.requestUserToEnableVoucherReminder}
                        resourceId={
                            this.props.parentResourceId +
                            DeliveredReturnReportResourceId.GridPrefix +
                            DeliveredReturnReportResourceId.AutomaticReminder
                        }
                        individualReturnSelected={this.state.sendReminderState.individualReturnSelected}
                        totalCount={this.state.sendReminderState.totalCount}
                        isGroupedReturnExists={this.state.sendReminderState.isGroupedReturnExists}
                    />

                    <GroupReminderModal
                        show={this.state.groupSendReminderState.show}
                        onHide={this.onGroupSendReminderCancel}
                        onSendReminderNow={this.onSendReminderNow}
                        onSaveAutomatedReminder={this.onGroupVoucherReminderSave}
                        groupSettings={this.state.groupSendReminderState.groupSettings as IGroupSettingsModel}
                        model={this.state.groupSendReminderState.model}
                    />

                    <SignedDetails
                        taxReturn={this.state.signedDetailsState.model as ITaxReturn}
                        onCancel={this.onSignedDetailsCancel}
                        show={this.state.signedDetailsState.show}
                    />

                    {this.state.setAccessState.show && (
                        <SuiteModal width="787" theme="light" title="Set Access" hide={this.onSetAccessCancel}>
                            <SetAccessCommonModal
                                onCancel={this.onSetAccessCancel}
                                onApplyAccess={this.onSetAccessSave}
                                returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                                availableUsers={this.state.availableUsers}
                                selectedUsers={this.state.selectedUsers}
                                availableUserGroups={this.state.availableUserGroups}
                                selectedUserGroups={this.state.selectedUserGroups}
                                isDragAndDropDisabled={false}
                                isGroupedReturnExists={this.state.isGroupedReturnExists}
                            />
                        </SuiteModal>
                    )}

                    <ViewAccessCode
                        show={this.state.accessCodeState.show}
                        taxReturn={this.state.accessCodeState.model as ITaxReturn}
                        onCancel={this.onViewAccessCodeCancel}
                        onGenerateOTP={this.onGenerateOTP}
                    />

                    <RetentionComponent
                        show={this.state.retentionPolicyState.show}
                        taxReturn={this.state.retentionPolicyState.model as ITaxReturn}
                        onCancel={this.onRetentionPolicyCancel}
                        companySettings={this.props.company.companySettings}
                        onSave={this.onRetentionPolicySave}
                    />

                    <ClientInfo
                        show={this.state.editClientInfoState.show}
                        model={this.state.editClientInfoState.model as ITaxReturn}
                        onCancel={this.onEditClientInfoCancel}
                        onSave={this.onEditClientInfoSave}
                        updateTaxDocument={this.props.updateTaxDocument}
                        isDeliveredReturn={true}
                        nextSignerDetails={this.props.nextSignerDetails}
                        states={this.props.company.companyProfile.countryStates}
                        locationDropdown={
                            this.props.officeLocation === undefined ? [] : this.props.officeLocation.locationDropdown
                        }
                        isGroupedReturn={false}
                        loadingMessage={this.state.loadingMessage}
                        setLoadingMessageToInitialState={this.setLoadingMessageToInitialState}
                        smsNotificationDetails={this.state.smsNotificationDetails}
                        showSMSTextNotifications={this.state.showSMSTextNotifications}
                        isTPOpted={this.state.isTPOpted}
                        isSpouseOpted={this.state.isSpouseOpted}
                        oldClientId={this.state.oldClientId}
                        updateTPOpted={this.updateTPOpted}
                        updateSpouseOpted={this.updateSpouseOpted}
                        mobileNumberChanged={() => {
                            this.setState({ smsSettingChanged: true });
                        }}
                    />

                    {this.state.editControllerInfoState.show && (
                        <GroupReturnClientInfo
                            show={this.state.editControllerInfoState.show}
                            model={this.state.editControllerInfoState.model as ITaxReturn}
                            groupInfo={this.state.editControllerInfoState.groupInfo as IGroupInfo}
                            onCancel={this.onGroupReturnClientInfoCancel}
                            onEditInfoSave={this.onGroupReturnClientInfoSave}
                            updateTaxDocument={this.props.updateTaxDocument}
                            isDeliveredReturn={true}
                            states={this.props.company.companyProfile.countryStates}
                            updateControllerInfoData={this.props.updateControllerInfoData}
                            isGroupedReturn={true}
                            nextSignerDetails={this.props.nextSignerDetails}
                            sendClientEmailUpdateMail={this.sendMail}
                            sendControllerEmailUpdateMail={this.props.sendControllertInfoMail}
                        />
                    )}

                    <ReportChangeStatusModal
                        show={this.state.changeStatusState.show}
                        selectedReturnStatus={this.state.selectedStatus}
                        onChangeStatusSave={this.onChangeStatusSave}
                        selectedDocuments={this.selectedDocumentIdsAndLoadingStatus()}
                        signatureList={this.state.signatureStatusList}
                        onCancel={this.onChangeStatusCancel}
                        showLoader={this.state.showLoader}
                    />

                    <ResendAccessLinkModal
                        show={this.state.resendAccessLinkState.show}
                        model={this.state.resendAccessLinkState.model as ITaxReturn}
                        onResendAccessLink={this.onResendAccessLink}
                        onCancel={this.onResendAccessLinkCancel}
                    />

                    {this.isScreenShareEnabled() &&
                        (this.state.isControllerScreenShare ? (
                            <ControllerScreenShare
                                show={this.state.screenShareState.show}
                                onCancel={this.onScreenShareCancel}
                                controller={this.state.controller}
                                onStartScreenShare={this.onControllerScreenShareStart}
                                otp={this.state.screenShareOtp}
                                clientId={this.ssClientId}
                            />
                        ) : (
                            <ScreenShare
                                show={this.state.screenShareState.show}
                                onCancel={this.onScreenShareCancel}
                                clientId={this.ssClientId}
                                taxReturn={this.state.screenShareState.model as ITaxReturn}
                                startScreenShare={this.onScreenShareStart}
                                otp={this.state.screenShareOtp}
                            />
                        ))}
                    <UploadRevisionDocumentModal
                        model={this.state.uploadRevisionDocumentState.model as ITaxReturn}
                        showUploadModal={this.state.uploadRevisionDocumentState.show}
                        onUploadRevisionDocumentCancel={this.onUploadRevisionDocumentCancel}
                        maxFileLimitUpload={30}
                        getRevisionDocumentUploadLink={this.props.getRevisionDocumentUploadLink}
                        deleteUploadedRevisionDocumentBlobFile={this.props.deleteUploadedRevisionDocumentBlobFile}
                        onSubmit={this.onSubmitRevisionDocument}
                        companySettings={this.props.company.companySettings}
                        resourceId={
                            this.props.parentResourceId +
                            DeliveredReturnReportResourceId.GridPrefix +
                            DeliveredReturnReportResourceId.UploadEstimate
                        }
                    />

                    {this.state.showMismatchModal && (
                        <MismatchModal
                            showMismatchModal={this.state.showMismatchModal}
                            clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                            onPrimaryButtonClick={this.onPrimaryButtonClick}
                            onSecondaryButtonClick={this.onSecondaryButtonClick}
                            closeMismatchModal={this.closeMismatchModal}
                            taxReturnType={Helper.taxReturnType(this.state.editClientSaveState.taxReturn)}
                            popupInProgress={this.state.popupInProgress}
                        />
                    )}

                    {this.state.showLocationMismatchActionModel && (
                        <LocationMismatchActionModal
                            onCancel={this.onCloseLocationMismatchActionModal}
                            onContinue={this.onContinueLocationMismatchActionModal}
                            show={this.state.showLocationMismatchActionModel}
                            locationName={this.getLocationName()}
                        />
                    )}

                    <UnlockReturnModal
                        show={this.state.unlockReturnState.show}
                        documentId={this.state.unlockReturnState.documentId}
                        clientTypes={this.state.unlockReturnState.clients}
                        onUnlockReturn={this.unlockReturn}
                        onCancel={this.onUnlockReturnClose}
                    />

                    <GroupedReturnViewAccessCode
                        show={this.state.groupedReturnAccessCodeState.show}
                        model={this.state.groupedReturnAccessCodeState.model}
                        onCancel={this.onGroupReturnViewAccessCodeCancel}
                        onGenerateOTP={this.onGroupReturnGenerateOTP}
                    />

                    <GroupedReturnResendAccessLinkModal
                        show={this.state.groupedReturnResendAccessLinkState.show}
                        model={this.state.groupedReturnResendAccessLinkState.model}
                        onResendAccessLink={this.onGroupReturnResendAccessLink}
                        onCancel={this.onGroupReturnResendAccessLinkCancel}
                    />

                    <DeliveredGroupedIndividualChangeStatusModal
                        show={this.state.rowChangeStatusState.show}
                        onChangeStatusSave={this.onRowChangeStatusSave}
                        onCancel={this.onRowChangeStatusCancel}
                        showLoader={this.state.showLoader}
                        model={this.state.rowChangeStatusState.model}
                    />

                    <Loader
                        loading={this.state.revisedProcessReturnModalLoader}
                        text={ProcessReturnConstants.ProcessReturnOpenMessage}
                    />

                    {this.state.processingDocumentId > 0 && (
                        <RevisedProcessReturnModal
                            auth={this.props.auth}
                            key={"revised-taxReturn-model" + this.state.processingDocumentId}
                            order={1}
                            taxDocuments={this.props.taxDocuments}
                            pdfDocuments={this.props.pdfDocuments}
                            updateDocumentInitialStatus={this.updateDocumentInitialStatus}
                            users={this.props.userStore.users}
                            companySettings={this.props.company.companySettings}
                            authorities={this.props.company.taxingAuthorities}
                            authorityLookup={this.props.company.authorityLookup}
                            userBasicProfile={this.props.userProfile}
                            userSettings={this.props.userSettings}
                            requestDelegatedSigners={this.props.requestDelegatedSigners}
                            company={this.props.company}
                            saveProcessReturnInfo={this.props.saveRevisedProcessReturnInfo}
                            userSignatures={this.props.userSignatures}
                            voucherStore={this.props.voucherStore}
                            requestTaxDocument={this.props.requestTaxDocument}
                            onReportProblemSave={this.onReportProblemSave}
                            onReportProblemOpen={this.onReportProblemOpen}
                            onReportProblemCancel={this.onReportProblemCancel}
                            reportProblemState={this.state.reportProblemState}
                            saveTaxingAuthority={this.props.saveTaxingAuthority}
                            requestTaxingAuthorities={this.props.requestTaxingAuthorities}
                            requestEroSignaturePath={this.props.requestDownloadPath}
                            show={this.state.revisedProcessReturnWindow.show}
                            onCancel={this.handleRevisedProcessReturnCancel}
                            docId={this.state.processingDocumentId}
                            updateTaxDocument={this.props.updateTaxDocument}
                            loadPdf={this.props.requestTaxReturnPdf}
                            deliverToClient={this.props.deliverRevisedTaxReturn}
                            getVoucherUploadLink={this.props.requestVoucherUploadLink}
                            getMultiVoucherUploadLink={this.props.requestMultiVoucherUploadLink}
                            requestVoucherDueDates={this.props.requestVoucherDueDates}
                            requestPaymentUrls={this.props.requestPaymentUrls}
                            requestVoucherFormNames={this.props.requestVoucherFormNames}
                            getAllTaxingAuthorities={this.props.company.taxingAuthorities}
                            groupInfo={this.state.groupInfo}
                            updateRevisedTaxDocumentStatus={this.props.updateReviseDocumentStatus}
                            revisionDocuments={this.state.revisionDocuments}
                            uploadEstimateResourceId={
                                this.props.parentResourceId +
                                DeliveredReturnReportResourceId.GridPrefix +
                                DeliveredReturnReportResourceId.UploadEstimate
                            }
                        />
                    )}
                    <ChangeOfficeLocationModal
                        closeModal={this.onChangeOfficeLocationCancel}
                        showModal={this.state.changeOfficeLocationState.show}
                        onChangeOfficeLocationConfirm={this.onChangeOfficeLocationContinue}
                        locationDropdown={
                            this.props.officeLocation === undefined ? [] : this.props.officeLocation.locationDropdown
                        }
                    />

                    <EditGroupInfoModal
                        groupInfoList={this.props.groupedReturnStore.groupInfo}
                        onCancel={this.onEditGroupInfoCancel}
                        onGroupInfoChange={this.onGroupInfoUpdate}
                        showEditGroupInfo={this.state.editGroupInfoState.show}
                        groupInfo={this.state.editGroupInfoState.model as IGroupInfo}
                    />
                </div>
            </div>
        );
    }
}
