import * as React from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { DropdownComponent } from "../../common/controls/DropdownComponent";
import { IGroupSettingsModel } from "../../../store/groupedreturns/GroupedReturnProcessState";
import CustomToggleSwitch from "../CustomToggleSwitch";
import { IDeliveredTaxReturns } from "../DeliveredTaxReturns";

interface IGroupedReturnVoucherReminderProps {
    groupSettings: IGroupSettingsModel;
    model: IDeliveredTaxReturns;
    handleVoucherReminderChange: (allowVoucherReminder: boolean) => void;
    handleSigningReminderChange: (allowSigningReminder: boolean) => void;
    handleVoucherReminderInDaysChange: (voucherReminderInDays: number) => void;
    handleSigningReminderInDaysChange: (signingReminderInDays: number) => void;
}

interface SelectOptions {
    value: any;
    label: string;
}

interface IGroupedReturnVoucherReminderStates {
    loading: boolean;
    message: string;
    selectedReminder: SelectOptions;
    reminderOptions: SelectOptions[];
    selectedSingingReminder: SelectOptions;
    signingReminderOptions: SelectOptions[];
    initialVoucherReminderPopupState: InitialVoucherReminderPopupState;
}

interface InitialVoucherReminderPopupState {
    allowVoucherReminder: boolean;
    voucherReminderInDays: number;
    allowSigningReminder: boolean;
    signingReminderInDays: number;
}

const msg = {
    saving: "Saving Reminder...",
    loading: "Loading, please wait..."
};

export class GroupedReturnVoucherReminder extends React.Component<
    IGroupedReturnVoucherReminderProps,
    IGroupedReturnVoucherReminderStates
> {
    constructor(props: IGroupedReturnVoucherReminderProps) {
        super(props);
        this.state = {
            loading: true,
            reminderOptions: [],
            selectedReminder: { value: 1, label: "1 day" },
            signingReminderOptions: [],
            selectedSingingReminder: { value: 1, label: "1 day" },
            message: msg.loading,
            initialVoucherReminderPopupState: {
                allowVoucherReminder: false,
                voucherReminderInDays: 0,
                allowSigningReminder: false,
                signingReminderInDays: 0
            }
        };
        this.handleSigningReminderChange = this.handleSigningReminderChange.bind(this);
        this.handleSigningToggleSwitchChange = this.handleSigningToggleSwitchChange.bind(this);
        this.handleVoucherReminderChange = this.handleVoucherReminderChange.bind(this);
        this.handleVoucherToggleSwitchChange = this.handleVoucherToggleSwitchChange.bind(this);
    }

    SetReminderOptions(props: IGroupedReturnVoucherReminderProps) {
        var reminderOptions = [];
        for (var i = 1; i <= 30; i++) {
            reminderOptions.push({ value: i, label: i === 1 ? i + " day" : i + " days" });
        }
        let noOfDaysForVoucherReminder: number =
            props.groupSettings.groupNotificationSettings.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder;
        let noOfDaysForSigningReminder: number =
            props.groupSettings.groupNotificationSettings.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder;
        this.setState({
            reminderOptions: reminderOptions,
            selectedReminder: {
                value: noOfDaysForVoucherReminder,
                label: noOfDaysForVoucherReminder + (noOfDaysForVoucherReminder === 1 ? " day" : " days")
            },
            selectedSingingReminder: {
                value: noOfDaysForSigningReminder,
                label: noOfDaysForSigningReminder + (noOfDaysForSigningReminder === 1 ? " day" : " days")
            },
            signingReminderOptions: reminderOptions
        });
    }

    private handleVoucherToggleSwitchChange = (event: any) => {
        this.props.groupSettings.groupNotificationSettings.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder =
            event.target.checked;
        if (event.target.checked) {
            this.props.handleVoucherReminderChange(true);
        } else {
            this.props.handleVoucherReminderChange(false);
        }
        this.forceUpdate();
    };

    private handleSigningToggleSwitchChange = (event: any) => {
        this.props.groupSettings.groupNotificationSettings.signingReminderNotificationSettingsModel.enableSigningReminder =
            event.target.checked;
        if (event.target.checked) {
            this.props.handleSigningReminderChange(true);
        } else {
            this.props.handleSigningReminderChange(false);
        }
        this.forceUpdate();
    };

    private handleVoucherReminderChange = (selectedOption: any) => {
        if (selectedOption == null) {
            this.setState(
                {
                    selectedReminder: {
                        value: selectedOption,
                        label: "Select..."
                    }
                },
                () => {
                    this.props.handleVoucherReminderInDaysChange(selectedOption);
                }
            );
        } else {
            this.props.groupSettings.groupNotificationSettings.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder =
                selectedOption;
            this.setState(
                {
                    selectedReminder: {
                        value: selectedOption,
                        label: selectedOption + (selectedOption === 1 ? " day" : " days")
                    }
                },
                () => {
                    this.props.handleVoucherReminderInDaysChange(selectedOption);
                }
            );
        }
    };

    private handleSigningReminderChange = (selectedOption: any) => {
        if (selectedOption == null) {
            this.setState(
                {
                    selectedSingingReminder: {
                        value: selectedOption,
                        label: "Select..."
                    }
                },
                () => {
                    this.props.handleSigningReminderInDaysChange(selectedOption);
                }
            );
        } else {
            this.props.groupSettings.groupNotificationSettings.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder =
                selectedOption;
            this.setState(
                {
                    selectedSingingReminder: {
                        value: selectedOption,
                        label: selectedOption + (selectedOption === 1 ? " day" : " days")
                    }
                },
                () => {
                    this.props.handleSigningReminderInDaysChange(selectedOption);
                }
            );
        }
    };

    componentDidMount() {
        if (this.props.groupSettings && this.props.groupSettings.groupNotificationSettings) {
            this.setState({
                initialVoucherReminderPopupState: {
                    allowVoucherReminder:
                        this.props.groupSettings.groupNotificationSettings.paymentVoucherNotificationSettingsModel
                            .enablePaymentVoucherReminder,
                    voucherReminderInDays:
                        this.props.groupSettings.groupNotificationSettings.paymentVoucherNotificationSettingsModel
                            .noOfDaysForVoucherReminder,
                    allowSigningReminder:
                        this.props.groupSettings.groupNotificationSettings.signingReminderNotificationSettingsModel
                            .enableSigningReminder,
                    signingReminderInDays:
                        this.props.groupSettings.groupNotificationSettings.signingReminderNotificationSettingsModel
                            .noOfDaysForSigningReminder
                }
            });
            this.SetReminderOptions(this.props);
        }
    }
    componentWillReceiveProps(props: IGroupedReturnVoucherReminderProps) {
        if (props.groupSettings && props.groupSettings.groupNotificationSettings) {
            this.setState({
                loading: false,
                initialVoucherReminderPopupState: {
                    allowVoucherReminder:
                        props.groupSettings.groupNotificationSettings.paymentVoucherNotificationSettingsModel
                            .enablePaymentVoucherReminder,
                    voucherReminderInDays:
                        props.groupSettings.groupNotificationSettings.paymentVoucherNotificationSettingsModel
                            .noOfDaysForVoucherReminder,
                    allowSigningReminder:
                        props.groupSettings.groupNotificationSettings.signingReminderNotificationSettingsModel
                            .enableSigningReminder,
                    signingReminderInDays:
                        props.groupSettings.groupNotificationSettings.signingReminderNotificationSettingsModel
                            .noOfDaysForSigningReminder
                }
            });
        } else {
            this.setState({ loading: true });
        }
    }

    public signingReminderDescription = () => (
        <div className="mt-3">
            <div className="text-danger mb-2">Please Note!</div>
            <div>
                User cannot update reminders for the signature statuses "E-Signed", "Uploaded", "Manually Signed", "Signed &
                E-Signed", "AlternateDelivery", "DeliveredToTaxCaddy", "SignatureStampingFailed", "E-Signed – In Office".
            </div>
        </div>
    );

    public voucherReminderDescription = () => (
        <div className="mt-3">
            <div className="text-danger mb-2">Please Note!</div>
            <div className="mb-2">Voucher reminders cannot be delivered for the returns due to one of the following reasons:</div>
            <ul className="ml-3">
                <Row>
                    <Col>
                        <li>Tax Payer has disabled the settings</li>
                        <li>All voucher payments flagged as paid</li>
                    </Col>
                    <Col>
                        <li>No voucher found for the return</li>
                        <li>Expired Vouchers</li>
                    </Col>
                </Row>
            </ul>
        </div>
    );

    public render() {
        return (
            <div className="reminder-modal py-2">
                <Alert variant="primary">
                    <i className="fas fa-exclamation-triangle"></i>
                    The changes made will impact all the returns belonging to{" "}
                    {this.props.model && this.props.model.groupName ? this.props.model.groupName : ""}
                </Alert>
                <div className="modal-body py-3" style={{ padding: "16px 16px 16px 20px" }}>
                    <div className="mb-4">
                        <div>
                            <b>Voucher</b>
                        </div>
                        <Row className="align-items-center" noGutters>
                            <Col xs={4}>Send a reminder</Col>
                            <Col sm={3}>
                                <DropdownComponent
                                    id="ddlVoucherReminder"
                                    options={this.state.reminderOptions}
                                    onChange={this.handleVoucherReminderChange}
                                    selectedValue={this.state.selectedReminder}
                                    clearable={true}
                                    disabled={
                                        !this.props.groupSettings?.groupNotificationSettings
                                            .paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder
                                    }
                                />
                            </Col>
                            <Col className="ml-3" xs={3}>
                                before due date
                            </Col>
                            <Col>
                                <CustomToggleSwitch
                                    switched={
                                        this.props.groupSettings?.groupNotificationSettings
                                            .paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder
                                    }
                                    handleChange={this.handleVoucherToggleSwitchChange}
                                />
                            </Col>
                        </Row>
                        {this.voucherReminderDescription()}
                    </div>

                    <div>
                        <div>
                            <b>Signing</b>
                        </div>
                        <Row className="align-items-center" noGutters>
                            <Col xs={4}>Send a reminder every</Col>
                            <Col xs={3}>
                                <DropdownComponent
                                    id="ddlVoucherReminder"
                                    options={this.state.signingReminderOptions}
                                    onChange={this.handleSigningReminderChange}
                                    selectedValue={this.state.selectedSingingReminder}
                                    disabled={
                                        !this.props.groupSettings?.groupNotificationSettings
                                            .signingReminderNotificationSettingsModel.enableSigningReminder
                                    }
                                    clearable={true}
                                />
                            </Col>
                            <Col className="ml-3" xs={3}>
                                since last reminder / initial mail
                            </Col>
                            <Col>
                                <CustomToggleSwitch
                                    switched={
                                        this.props.groupSettings?.groupNotificationSettings
                                            .signingReminderNotificationSettingsModel.enableSigningReminder
                                    }
                                    handleChange={this.handleSigningToggleSwitchChange}
                                />
                            </Col>
                        </Row>
                        {this.signingReminderDescription()}
                    </div>
                </div>
            </div>
        );
    }
}
