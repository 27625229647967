let moment = require("moment");
import * as bootbox from "bootbox";
import * as React from "react";
import * as ReactDom from "react-dom";
import {
    BootstrapTable,
    TableHeaderColumn,
    TextFilter,
    CustomFilter,
    SelectFilter,
    CustomSelectProps,
    CustomFilterParameters
} from "react-bootstrap-table";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as DeliveredReturnsState from "../../../store/reports/DeliveredReturnsState";
import { CheckBoxComponent, CheckBoxSize } from "../../common/CheckBoxComponent";
import { EngagementList } from "../../../Core/Common/Common";
import {
    ClientTypes,
    DaysRangeOptionsList,
    engagementType,
    getClientName,
    ITaxReturn,
    SignatureStatus,
    DocumentFilingType,
    IOfficeLocation
} from "../../common/TaxReturn";
import { DeliveredReturnsConstants, DeliveredReturnsTableConstants } from "../../helper/Constants";
import * as Helper from "../../helper/HelperFunctions";
import { VenusNotifier } from "../../helper/VenusNotifier";
import { CustomDateFilter } from "../DeliveredReturns/CustomDateFilter";
import { DeliveredReportAction } from "../DeliveredReturns/DeliveredReportAction";
import { Filters, IFilters, QuickReportType, ReportFilterType } from "../Filters";
import { CustomMultiSelect } from "../../common/MultipleSelectComponent";
import { ColumnSettings } from "./../ColumnSettings/ColumnSettings";
import { IColumnSettings, ReportType } from "./../../../store/reports/ColumnSettings/ColumnSettingsState";
import { DocumentFilingTypeList, DocumentFilingTypeOptionsList } from "../../common/ProcessReturnModal/PaperFile/PaperFile";
import { CommonResources } from "../../helper/ResourceIdConstants";
import { IDropdown } from "../../../Core/Common/Dropdown";
import { NO_INDEX } from "../../helper/HelperFunctions";
import { IGroupInfo, IControllerInfo } from "../../../store/groupedreturns/GroupedReturnProcessState";
import SingleSelectSearchableDropdown from "../../common/SingleSelectSearchableDropdown";

interface TableData {
    delivered: DeliveredReturnsState.DeliveredReturnsState;
    saveFilterShow: boolean;
    onPageChange: any;
    onSortChange: any;
    onFilterChange: any;
    pageNo: number;
    totalRows: number;
    customColumnHeader: string;
    onExportToExcel(onExportToExcelComplete: () => void): void;
    onFilterNameChange(event: any): void;
    loadGrid(): void;

    onFilterSave(onApplyFilter: (filter: IFilters) => void): void;
    onFilterUpdate(filterName: string): void;
    onFilterDelete(filterName: string, filterType: ReportFilterType): any;
    filterList: IFilters[];
    currentFilter: IFilters;
    onSetDefaultFilter(name: string, filterType: ReportFilterType): void;
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType): void;
    defaultFilter: string | undefined;
    onSaveFilterShow(): void;
    onSaveFilterHide(): void;

    onEditClientInfoOpen: (rowIndex: number) => void;
    onDownloadEfileFormsOpen: (rowIndex: number) => void;
    onArchiveTaxReturn: (rowIndex: number) => void;
    onDownloadReturnOpen: (rowIndex: number) => void;
    onResendAccessLinkOpen: (rowIndex: number) => void;
    onClientTrackingOpen: (rowIndex: number, isGroupReturn?: boolean) => void;
    onTaxpayerView: (rowIndex: number) => void;
    onUnlockReturnOpen: (rowIndex: number) => void;
    onRecallReturnOpen: (rowIndex: number, resourceId: string) => void;
    onUploadRevisionDocumentOpen: (rowIndex: number) => void;
    onReportProblemOpen: (rowIndex: number) => void;
    onDeleteTaxReturnOpen: (rowIndex: number, resourceId: string) => void;
    onViewAccessCodeOpen: (rowIndex: number) => void;
    onRetentionPolicyOpen: (rowIndex: number) => void;
    onRemoveReport: (event: any) => void;
    onSignedDetailsOpen: (rowIndex: number) => void;
    onDownloadHistoryOpen: (rowIndex: number) => void;
    onUploadFormsOpen: (rowIndex: number) => void;
    onRestoreReturnOpen: (rowIndex: number, resourceId: string) => void;
    isArchived: boolean;
    isLoading: boolean;
    pageSize: number;
    onScreenShareViewClick: (rowIndex: number) => void;
    onScreenShareOpen: (clientId: string, rowIndex: number, isControllerScreenShare: boolean) => void;
    showScreenShareSubMenu: boolean;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    onClientViewClick: (rowIndex: number) => void;
    onClientViewLinkClick: (rowIndex: number, clientType: ClientTypes) => void;
    onClientViewCancel(id: number): void;
    taxReturn: ITaxReturn;
    showClientSubMenu: boolean;
    showBulkSelectionMessage: boolean;
    onBulkSelectionChange: (isEnabled: boolean) => void;
    clearSelection: () => void;
    proxyFilter: string;
    filterType: ReportFilterType;
    loadSpinner?: boolean;

    onPageReload: () => void;
    isPaperFiledEnabled: boolean;
    isScreenShareEnabled: boolean;
    onRevisedProcessReturnOpen: (rowIndex: number) => void;
    onDiscardRevisedDocumentOpen: (rowIndex: number) => void;
    onSendReminder: () => void;
    onSendReminderValidateIndividualReturn: (rowIndex: number) => void;
    onRowChangeStatusOpen: (rowIndex: number) => void;
    onGroupReturnViewAccessCodeOpen: (rowIndex: number) => void;
    onGroupedReportProblemOpen: (rowIndex: number) => void;
    onGroupReturnResendAccessLinkOpen: (rowIndex: number) => void;
    onGroupSendReminderOpen: (rowIndex: number) => void;

    controllerInfo: IControllerInfo;
    onControllerScreenShareViewClick: (rowIndex: number, groupId: number) => void;
    onControllerScreenShareOpen: (
        controllerGuid: string,
        groupId: number,
        clientId: string,
        clientGuid: string,
        isControllerScreenShare: boolean
    ) => void;
    onRecallGroupReturnOpen: (rowIndex: number, groupId: number) => void;
    onGroupRecallReturnOpen: (groupId: number, taxDocumentId: number) => void;
    clientNames: string[];
    showControllerSubMenu: boolean;
    onControllerViewClick: (groupId: number) => void;
    onControllerViewOpen: (groupId: number) => void;
}
interface DeliveredReturnsTableProps extends TableData {
    columnSettings: IColumnSettings[];
    saveColumnSettings: (reportType: ReportType, data: IColumnSettings[], callback?: () => void) => void;
    refreshDelay?: boolean;
    setTableRef: any;
    setClearFilter?: () => void;
    onSelectQuickReports?: (quickReportType: QuickReportType) => any;
    onFilterUpdateForTaxYear?: (filterName: string, taxYearString: string) => void;
    parentResourceGridIdentifier: string;
    parentResourceHeaderIdentifier: string;
    userResources: string[];
    getOfficeLocationDropdown: (reload: boolean) => void;
    locationDropdown: IDropdown[];
    loggedInUserLocation: (userLocations: number[]) => void;
    onEditGroupInfoOpen: (groupId: number) => void;
}
export interface IShowExportExcelState {
    isHiddenExportExcel: boolean;
    isActive: boolean;
}

export interface InUseReturnsPageState {
    officeLocationList: any[];
    isHiddenExportExcel: boolean;
}

interface DeliveredReturnsTableState extends IShowExportExcelState {
    customColumnOptions: IColumnSettings[];
    tempCustomColumns: IColumnSettings[];
    modifiedCustomColumns: IColumnSettings[];
    showColumnSettingsLoader: boolean;
    showColumnSettings: boolean;
    officeLocationList: any[];
    userLocations: number[];
}
const isEqual = require("react-fast-compare");
const SignatureStatusList = {
    None: "None",
    ESigned: "E-Signed",
    ManuallySigned: "Manually Signed",
    Uploaded: "Uploaded",
    AwaitingESign: "Awaiting E-Sign",
    AwaitingUpload: "Awaiting Upload",
    Locked: "Locked",
    MailOrFax: "Mail Or Fax",
    SignedAndESigned: "Signed And E-Signed",
    AutoParsed: "Auto-Parsed",
    Processing: "Processing",
    Delivering: "Delivering",
    Delivered: "Delivered",
    DeliveryFailed: "Delivery Failed",
    UploadInProgress: "Upload In Progress",
    DeliveredToTaxCaddy: "Delivered To TaxCaddy",
    AlternateDelivery: "Alternate Delivery",
    AlternateAndUploaded: "Alternate And Uploaded",
    PartiallySigned: "Partially Signed",
    SignatureStampingFailed: "Signature Stamping Failed",
    ESignedInOffice: "E-Signed – In Office"
};

export class DeliveredReturnsTable extends React.Component<DeliveredReturnsTableProps, DeliveredReturnsTableState> {
    private filterChanged: boolean = false;
    private taxYearList: any[] = [];
    private customLastReminderFilter: any;
    private customExpireDateFilter: any;
    private customStatusMultiSelect: any;
    private customOfficeMultiSelect: any;
    private customTaxYearMultiSelect: any;
    public isAppiedFilter: boolean = false;
    public FiltersRef: any = null;
    private customClientNameFilter: any;
    private customGroupNameFilter: any;

    public headerRefStore: any = {
        taxDocumentName: null,
        clientId: null,
        engagementType: null,
        partner: null,
        deliveredBy: null,
        deliveredDate: null,
        lastReminder: null,
        retentionPeriod: null,
        downloaded: null,
        testColumn: null,
        filingType: null
    };

    constructor(props: DeliveredReturnsTableProps) {
        super(props);
        this.state = {
            isActive: true,
            isHiddenExportExcel: false,
            customColumnOptions: [],
            showColumnSettingsLoader: false,
            tempCustomColumns: [],
            modifiedCustomColumns: [],
            showColumnSettings: false,
            officeLocationList: [],
            userLocations: []
        };
        this.onClearFilter = this.onClearFilter.bind(this);
        this.defaultType = this.defaultType.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onLoadSelectedFilter = this.onLoadSelectedFilter.bind(this);
        this.onFilterSaveClick = this.onFilterSaveClick.bind(this);
        this.ReminderDateFilter = this.ReminderDateFilter.bind(this);
        this.RetentionDateFilter = this.RetentionDateFilter.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.taxYearList = Helper.PrepareTaxYear();
        Helper.fetchUserOfficeLocation(this.setOfficeLocation);
        this.props.getOfficeLocationDropdown(false);
        this.props.setTableRef(this);
    }

    private setOfficeLocation = (officeLocationList: IOfficeLocation[]) => {
        let list;
        let userLocation: number[] = [];
        if (officeLocationList?.length) {
            list = officeLocationList.map(({ locationName: label, locationId: value }) => ({ label, value }));
            officeLocationList.map((item) => {
                if (item.locationId) {
                    userLocation.push(item.locationId);
                }
            });
        } else {
            list = [];
        }
        var blank = { label: "Blanks", value: -100 };
        list.push(blank);
        this.setState({ officeLocationList: list });
        this.setState({ userLocations: userLocation }, () => this.props.loggedInUserLocation(this.state.userLocations));
    };

    private setTaxpayerNameList = (clientNames: string[]) => {
        const list = clientNames && clientNames.map((clientName: string) => ({ label: clientName, value: clientName }));
        return list;
    };

    private getTaxpayerNameDropdown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;

        return (
            <SingleSelectSearchableDropdown
                id="taxpayerNameFilter"
                onRef={(ref: any) => (this.customClientNameFilter = ref)}
                filterHandler={filterHandler}
                options={options}
                customPlaceHolder={placeholder}
                className={"groupNameFilter"}
            />
        );
    };

    private setGroupNameList = (groupNameList: IGroupInfo[]) => {
        const list = groupNameList.map((x: IGroupInfo) => ({ label: x.name, value: x.id }));
        const options = [{ label: "Standard Returns", value: -2 }, { label: "Grouped Returns", value: -3 }, ...list];
        return options;
    };

    private getGroupNameDropdown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;

        return (
            <SingleSelectSearchableDropdown
                id="groupNameFilter"
                onRef={(ref: any) => (this.customGroupNameFilter = ref)}
                filterHandler={filterHandler}
                options={options}
                customPlaceHolder={placeholder}
                className={"groupNameFilter"}
            />
        );
    };

    UNSAFE_componentWillReceiveProps(props: DeliveredReturnsTableProps) {
        this.setState({
            customColumnOptions: props.columnSettings,
            tempCustomColumns: props.columnSettings,
            modifiedCustomColumns: props.columnSettings
        });
    }

    renderShowsTotal(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    private setNoContent() {
        if (this.props.isLoading) {
            return (
                <LoadingOverlay style={{ height: "400px" }}>
                    <Loader loading={this.props.isLoading} />
                </LoadingOverlay>
            );
        } else {
            return DeliveredReturnsTableConstants.OtherMessage.NoReturnsFound;
        }
    }

    private defaultType(cell: any, row: any) {
        return (
            <div title={cell} className="ellipsis">
                {cell}
            </div>
        );
    }

    customTypeTooltip = (cell: any, row: any) => {
        return `${row.engagementType}`;
    };

    customFilingTypeTooltip = (cell: any, row: any) => {
        return `${row.documentFilingType}`;
    };

    customStatusTooltip = (cell: any, row: any) => {
        const status:
            | "None"
            | "ESigned"
            | "ManuallySigned"
            | "Uploaded"
            | "AwaitingESign"
            | "AwaitingUpload"
            | "Locked"
            | "MailOrFax"
            | "SignedAndESigned"
            | "AutoParsed"
            | "Processing"
            | "Delivering"
            | "Delivered"
            | "DeliveryFailed"
            | "PartiallySigned"
            | "SignatureStampingFailed"
            | "ESignedInOffice" =
            typeof row.signatureStatusIdType === "number"
                ? SignatureStatus[row.signatureStatusIdType]
                : row.signatureStatusIdType;
        return `${SignatureStatusList[status]}`;
    };

    customDownloadedTooltip = (cell: any, row: any) => {
        return `${
            cell > 0 &&
            (row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESigned] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESignedInOffice] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.Uploaded] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.SignedAndESigned])
                ? "Downloaded"
                : row.bulkDownloadCount > 0
                ? "Downloaded"
                : "To Be Downloaded"
        }`;
    };

    private onFilterChange(dataField: any) {
        if (!this.filterChanged && !this.isAppiedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField, this.props.filterType);
            this.filterChanged = false;
        }
    }

    public onClearFilter(clearAll?: boolean) {
        this.filterChanged = true;

        for (var key of Object.keys(this.headerRefStore)) {
            this.headerRefStore[key] !== null ? this.headerRefStore[key]?.cleanFiltered() : "";
        }

        this.customStatusMultiSelect?.cleanFiltered(clearAll);
        this.customOfficeMultiSelect?.cleanFiltered(clearAll);
        this.customTaxYearMultiSelect?.cleanFiltered(clearAll);
        this.customLastReminderFilter?.cleanFilteredValue();
        this.customExpireDateFilter?.cleanFilteredValue();
        this.customGroupNameFilter?.cleanFiltered && this.customGroupNameFilter?.cleanFiltered(clearAll);
        this.customClientNameFilter?.cleanFiltered && this.customClientNameFilter?.cleanFiltered(clearAll);
        this.filterChanged = false;
        this.props.clearSelection();
    }

    private onFilterSaveClick(filterName: string) {
        if (!Helper.validateFilter(this.props.currentFilter)) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterFieldsAreEmpty, null);
            return;
        }

        let temThis = this;
        if (typeof filterName == "undefined" || filterName.length == 0 || filterName == this.props.proxyFilter) {
            this.props.onSaveFilterShow();
        } else {
            bootbox.dialog({
                title: DeliveredReturnsTableConstants.Title.FilterSaveUpdate,
                message: DeliveredReturnsTableConstants.OtherMessage.DoYouWantToCreateNewFilterOrUpdateTheCurrent,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i>' + DeliveredReturnsTableConstants.FormLabel.NoUpdateTheCurrent,
                        className: "btn-white",
                        callback: function () {
                            temThis.props.onFilterUpdate(filterName);
                        }
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i>' + DeliveredReturnsTableConstants.FormLabel.YesCreateNew,
                        className: "btn-info",
                        callback: function () {
                            temThis.props.onSaveFilterShow();
                        }
                    }
                }
            });
        }
    }

    public onLoadSelectedFilter(filter?: IFilters) {
        if (filter !== undefined && filter.fields !== undefined && filter.fields.status === undefined) {
            filter.fields.status = "-1";
        }

        this.isAppiedFilter = true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;

            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case "taxDocumentName":
                        if (this.props.isArchived) {
                            (this.customClientNameFilter as TableHeaderColumn)?.applyFilter &&
                                (this.customClientNameFilter as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        }
                        else {
                            this.headerRefStore["taxDocumentName"]?.applyFilter(filter.fields[field]);
                        }
                        break;
                    case "clientId":
                        this.headerRefStore["clientId"]?.applyFilter(filter.fields[field]);
                        break;
                    case "partner":
                        this.headerRefStore["partner"]?.applyFilter(filter.fields[field]);
                        break;
                    case "deliveredBy":
                        this.headerRefStore["deliveredBy"]?.applyFilter(filter.fields[field]);
                        break;
                    case "status":
                        this.customStatusMultiSelect?.applyFilter(filter.fields[field]);
                        break;
                    case "locationName":
                        this.customOfficeMultiSelect?.applyFilter(filter.fields[field]);
                        break;
                    case "deliveredDate":
                        let deliveredDateFilter: any = filter.fields[field];
                        if (deliveredDateFilter) {
                            this.headerRefStore["deliveredDate"]?.applyFilter(filter.fields[field]);
                        }
                        break;
                    case "lastReminder":
                        this.customLastReminderFilter?.applyFilter(filter.fields[field]);
                        break;
                    case "engagementType":
                        this.headerRefStore["engagementType"]?.applyFilter(filter.fields[field]);
                        break;
                    case "downloaded":
                        this.headerRefStore["downloaded"]?.applyFilter(filter.fields[field]);

                        break;
                    case "testColumn":
                        this.headerRefStore["testColumn"]?.applyFilter(filter.fields[field]);

                        break;
                    case "retentionPeriod":
                        this.customExpireDateFilter?.applyFilter(filter.fields[field]);
                        break;
                    case "taxYear":
                        this.customTaxYearMultiSelect?.applyFilter(filter.fields[field]);
                        break;
                    case "documentFilingType":
                        this.headerRefStore["documentFilingType"]?.applyFilter(filter.fields[field]);
                        break;
                    case "groupName":
                        (this.customGroupNameFilter as TableHeaderColumn)?.applyFilter &&
                            (this.customGroupNameFilter as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                }
                this.filterChanged = false;
            }

            this.isAppiedFilter = false;
            this.onFilterChange(filter.fields);
            (this.refs.table as BootstrapTable)?.handleSearch(filter.searchText);
        }
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (
            <CheckBoxComponent
                size={CheckBoxSize.sm}
                id={"delivered-returns-checkbox-" + props.rowIndex}
                indeterminate={props.indeterminate}
                checked={props.checked}
                disabled={props.disabled}
                onChange={(e: any) => props.onChange(e, props.rowIndex)}
                ref={(input: any) => {
                    if (input) {
                        input.indeterminate = props.indeterminate;
                    }
                }}
                text={""}
            />
        );
    };

    private ReminderDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (
            <CustomDateFilter
                onRef={(ref: any) => (this.customLastReminderFilter = ref)}
                filterHandler={filterHandler}
                customFilterParameters={customFilterParameters}
            />
        );
    };

    private RetentionDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (
            <CustomDateFilter
                onRef={(ref: any) => (this.customExpireDateFilter = ref)}
                filterHandler={filterHandler}
                customFilterParameters={customFilterParameters}
            />
        );
    };

    private test = (event: any, filterHandler: any, customFilterParameters: any) => {
        filterHandler(event.target.value, "CustomFilter");
    };

    private getStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customStatusMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                enableAllFilter={enableAllFilter}
                placeholder={placeholder}
            />
        );
    };

    private getOfficeSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customOfficeMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                enableAllFilter={enableAllFilter}
                handleTextOverflow={true}
                placeholder={placeholder}
            />
        );
    };

    onFilterUpdate = () => {
        let activeFilter: string = (this.FiltersRef as any) !== null ? (this.FiltersRef as any).state.activeFilter : "";
        let allTaxYearString: string = Helper.AllTaxYearSelectedString();
        if (activeFilter.length !== 0 && this.props.onFilterUpdateForTaxYear) {
            this.props.onFilterUpdateForTaxYear(activeFilter, allTaxYearString);
        }
    };

    private getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                enableAllFilter={enableAllFilter}
                placeholder={placeholder}
                setClearFilter={this.props.setClearFilter}
                filterType="TaxYear"
                onFilterUpdate={this.onFilterUpdate}
            />
        );
    };

    private signatureStatusIdType = (cell: any, row: any) => {
        let status: any = row.signatureStatusIdType;
        let signatureType:
            | "None"
            | "ESigned"
            | "ESignedInOffice"
            | "ManuallySigned"
            | "Uploaded"
            | "AwaitingESign"
            | "AwaitingUpload"
            | "Locked"
            | "MailOrFax"
            | "SignedAndESigned"
            | "AutoParsed"
            | "Processing"
            | "Delivering"
            | "Delivered"
            | "DeliveryFailed"
            | "PartiallySigned"
            | "SignatureStampingFailed" = typeof status == "number" ? SignatureStatus[status] : status;
        if (
            row.signedCount > 0 &&
            (row.signatureStatusIdType === SignatureStatus[SignatureStatus.PartiallySigned] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESignedInOffice])
        ) {
            return (
                <Link to={"#"} onClick={() => this.props.onSignedDetailsOpen(row.rowIndex)}>
                    {SignatureStatusList[signatureType]?.toUpperCase()}
                </Link>
            );
        }

        if (row.signatureStatusIdType === SignatureStatus[SignatureStatus.SignatureStampingFailed]) {
            return (
                <Link to={"#"} onClick={() => this.props.onSignedDetailsOpen(row.rowIndex)}>
                    {SignatureStatusList[signatureType].toUpperCase()}
                </Link>
            );
        } else if (row.signedCount > 0 && row.signatureStatusIdType === SignatureStatus[SignatureStatus.AwaitingUpload]) {
            return (
                <Link to={"#"} onClick={() => this.props.onSignedDetailsOpen(row.rowIndex)}>
                    {SignatureStatusList[signatureType]?.toUpperCase()}
                </Link>
            );
        }
        return <span>{SignatureStatusList[signatureType]?.toUpperCase()}</span>;
    };

    private deliveredReportAction = (cell: any, row: any) => {
        return (
            <DeliveredReportAction
                rowIndex={row.rowIndex}
                signatureStatus={row.signatureStatusIdType}
                onClientTrackingOpen={this.props.onClientTrackingOpen}
                onDownloadEfileFormsOpen={this.props.onDownloadEfileFormsOpen}
                onDownloadReturnOpen={this.props.onDownloadReturnOpen}
                onArchiveTaxReturn={this.props.onArchiveTaxReturn}
                onEditClientInfoOpen={this.props.onEditClientInfoOpen}
                onRecallReturnOpen={this.props.onRecallReturnOpen}
                onUnlockReturnOpen={this.props.onUnlockReturnOpen}
                onRemoveReport={this.props.onRemoveReport}
                onReportProblemOpen={this.props.onReportProblemOpen}
                onDeleteTaxReturnOpen={this.props.onDeleteTaxReturnOpen}
                onResendAccessLinkOpen={this.props.onResendAccessLinkOpen}
                onTaxpayerView={this.props.onTaxpayerView}
                onViewAccessCodeOpen={this.props.onViewAccessCodeOpen}
                onRetentionPolicyOpen={this.props.onRetentionPolicyOpen}
                onUploadRevisionDocumentOpen={this.props.onUploadRevisionDocumentOpen}
                title={cell}
                onRestoreReturnOpen={this.props.onRestoreReturnOpen}
                isArchived={this.props.isArchived}
                onUploadFormsOpen={this.props.onUploadFormsOpen}
                onClientViewClick={this.props.onClientViewClick}
                onClientViewLinkClick={this.props.onClientViewLinkClick}
                onClientViewCancel={this.props.onClientViewCancel}
                taxReturn={this.props.taxReturn}
                showClientSubMenu={this.props.showClientSubMenu}
                deliveredReturnData={this.props.delivered.deliveredReturnTableModel.documents}
                onScreenShareViewClick={this.props.onScreenShareViewClick}
                onScreenShareOpen={this.props.onScreenShareOpen}
                showScreenShareSubMenu={this.props.showScreenShareSubMenu}
                onRevisedProcessReturnOpen={this.props.onRevisedProcessReturnOpen}
                onDiscardRevisedDocumentOpen={this.props.onDiscardRevisedDocumentOpen}
                isScreenShareEnabled={this.props.isScreenShareEnabled}
                parentResourceIdentifier={this.props.parentResourceGridIdentifier}
                userResources={this.props.userResources}
                onSendReminderValidateIndividualReturn={this.props.onSendReminderValidateIndividualReturn}
                onRowChangeStatusOpen={this.props.onRowChangeStatusOpen}
                onGroupReturnViewAccessCodeOpen={this.props.onGroupReturnViewAccessCodeOpen}
                onGroupedReportProblemOpen={this.props.onGroupedReportProblemOpen}
                onGroupReturnResendAccessLinkOpen={this.props.onGroupReturnResendAccessLinkOpen}
                isGroupReturn={row.groupName ? true : false}
                onEditGroupInfoOpen={this.props.onEditGroupInfoOpen}
                onControllerScreenShareOpen={this.props.onControllerScreenShareOpen}
                controllerInfo={this.props.controllerInfo}
                onControllerScreenShareViewClick={this.props.onControllerScreenShareViewClick}
                onGroupSendReminderOpen={this.props.onGroupSendReminderOpen}
                onRecallGroupReturnOpen={this.props.onRecallGroupReturnOpen}
                onGroupRecallReturnOpen={this.props.onGroupRecallReturnOpen}
                onControllerViewClick={this.props.onControllerViewClick}
                onControllerViewOpen={this.props.onControllerViewOpen}
                showControllerSubMenu={this.props.showControllerSubMenu}
            />
        );
    };

    private downloadedHistory = (cell: any, row: any) => {
        return row.downloaded > 0 &&
            (row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESigned] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESignedInOffice] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.Uploaded] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.AlternateDelivery] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.AlternateAndUploaded] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.SignedAndESigned]) ? (
            <Link to={"#"} onClick={() => this.props.onDownloadHistoryOpen(row.rowIndex)}>
                Downloaded
            </Link>
        ) : row.bulkDownloadCount > 0 || row.downloaded > 0 ? (
            <span>Downloaded</span>
        ) : (
            <span>To Be Downloaded</span>
        );
    };

    private createCustomToolBar = () => {
        return (
            <div className="col-sm-12 col-md-12 col-lg-12" style={{ height: 33, position: "unset" }}>
                <Filters
                    ref={(ref: any) => (this.FiltersRef = ref)}
                    onFilterNameChange={this.props.onFilterNameChange}
                    onFilterSave={this.props.onFilterSave}
                    onClearFilter={this.onClearFilter}
                    onExportToExcel={this.props.onExportToExcel}
                    filterList={this.props.filterList}
                    onSetDefaultFilter={this.props.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.props.onRemoveDefaultFilter}
                    onFilterDelete={this.props.onFilterDelete}
                    show={this.props.saveFilterShow}
                    onFilterSaveClick={this.onFilterSaveClick}
                    onSaveFilterHide={this.props.onSaveFilterHide}
                    onLoadSelectedFilter={this.onLoadSelectedFilter}
                    defaultFilter={this.props.defaultFilter}
                    loadGrid={this.props.loadGrid}
                    showExportExcel={this.state.isHiddenExportExcel}
                    OnOpenColumnSettings={this.OnOpenColumnSettings}
                    proxyFilter={this.props.proxyFilter}
                    onPageReload={this.props.onPageReload}
                    refreshDelay={this.props.refreshDelay}
                    disableColumnSettings={this.props.isLoading}
                    onSelectQuickReports={this.props.onSelectQuickReports}
                    showQuickReportOption={true}
                    setClearFilter={this.props.setClearFilter}
                    loadSpinner={this.props.loadSpinner}
                    totalRows={this.props.totalRows}
                    parentResourceIdentifier={this.props.parentResourceHeaderIdentifier}
                />
            </div>
        );
    };

    private showBulkAlertMessage() {
        let documentsInPage = this.props.delivered.deliveredReturnTableModel.documents.length;
        if (!this.props.isArchived && this.props.totalRows > 20 && this.props.showBulkSelectionMessage)
            return (
                <div
                    className="font14"
                    style={{
                        position: "absolute",
                        display: "inline-block",
                        zIndex: 10,
                        padding: "0px 0px"
                    }}
                >
                    <Alert variant="warning" style={{ padding: "6px", width: "fit-content" }}>
                        <span className="fa fa-exclamation-triangle" style={{ marginRight: "5px", paddingLeft: "5px" }}></span>{" "}
                        All {documentsInPage} records on this page are selected. To select the remaining{" "}
                        {this.props.totalRows - documentsInPage} filtered records,
                        <Link to={"#"} onClick={() => this.props.onBulkSelectionChange(true)}>
                            {" "}
                            click here
                        </Link>
                    </Alert>
                </div>
            );
    }

    shouldComponentUpdate(nextProps: TableData, nextState: IShowExportExcelState) {
        return (
            !isEqual(
                this.props.delivered.deliveredReturnTableModel.documents,
                nextProps.delivered.deliveredReturnTableModel.documents
            ) ||
            this.props.isLoading !== nextProps.isLoading ||
            this.props.saveFilterShow !== nextProps.saveFilterShow ||
            !isEqual(this.state, nextState) ||
            isEqual(this.props.selectedRows, nextProps.selectedRows) ||
            !isEqual(this.props.showBulkSelectionMessage, nextProps.showBulkSelectionMessage) ||
            !isEqual(this.props.defaultFilter, nextProps.defaultFilter) ||
            !isEqual(this.props.currentFilter, nextProps.currentFilter)
        );
    }

    private DownloadedOptionsList = {
        0: "To Be Downloaded",
        1: "Downloaded"
    };

    private SignatureStatusOptionsList = [
        { label: "E-SIGNED", value: 1 },
        { label: "MANUALLY SIGNED", value: 2 },
        { label: "UPLOADED", value: 3 },
        { label: "AWAITING E-SIGN", value: 4 },
        { label: "AWAITING UPLOAD", value: 5 },
        { label: "SIGNED AND E-SIGNED", value: 8 },
        { label: "DELIVERED TO TAXCADDY", value: 10 },
        { label: "TAXCADDY DELIVERY FAILED", value: 16 },
        { label: "ALTERNATE DELIVERY", value: 17 },
        { label: "ALTERNATE AND UPLOADED", value: 19 },
        { label: "PARTIALLY SIGNED", value: 20 },
        { label: "SIGNATURE STAMPING FAILED", value: 21 },
        { label: "E-SIGNED – IN OFFICE", value: 22 }
    ];

    private getColumns = (): any[] => {
        return [
            {
                header: "Group Name",
                key: "groupName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "120px",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getGroupNameDropdown,
                    customFilterParameters: {
                        options: this.setGroupNameList(this.props.groupInfo),
                        enableAllFilter: false,
                        placeholder: "Group Name"
                    }
                }
            },
            {
                header: "",
                key: "index",
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "index", style: { font: "bold" } } as TextFilter
            },
            {
                header: "Name",
                key: "taxDocumentName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "180px",
                filter: !this.props.isArchived ? { type: "TextFilter", placeholder: "Name", style: { font: "bold" }, delay: 2500 } as TextFilter :
                    {
                        type: "CustomFilter",
                        getElement: this.getTaxpayerNameDropdown,
                        customFilterParameters: {
                            options: this.setTaxpayerNameList(this.props.clientNames),
                            enableAllFilter: false,
                            placeholder: "Client Name"
                        }
                }
            },
            {
                header: "Client ID",
                key: "clientId",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "Client ID", style: { font: "bold" }, delay: 2500 } as TextFilter
            },
            {
                header: "Type",
                key: "engagementType",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: this.customTypeTooltip,
                isHidden: false,
                width: "75px",
                filter: { type: "SelectFilter", placeholder: "Select Type...", options: EngagementList } as SelectFilter
            },
            {
                header: "Tax Year",
                key: "taxYear",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: "90px",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getYearMultiSelectDropDown,
                    customFilterParameters: {
                        options: this.taxYearList,
                        enableAllFilter: true,
                        placeholder: "Select Year..."
                    } as any
                } as CustomFilter
            },
            {
                header: "ERO / Signer",
                key: "partner",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "ERO / Signer", style: { font: "bold" }, delay: 2500 } as TextFilter
            },
            {
                header: "Office Location",
                key: "locationName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "130px",
                Placeholder: "Office Location",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getOfficeSelectDropDown,
                    customFilterParameters: {
                        options: this.state.officeLocationList,
                        enableAllFilter: false,
                        placeholder: "Select Office Location..."
                    } as any
                } as CustomFilter
            },
            {
                header: "Sent By",
                key: "deliveredBy",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "Sent By", style: { font: "bold" }, delay: 2500 } as TextFilter
            },
            {
                header: "Delivered Date",
                key: "deliveredDate",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: {
                    type: "SelectFilter",
                    placeholder: "Select Delivered Date Limit",
                    options: DaysRangeOptionsList
                } as SelectFilter
            },
            {
                header: "Status",
                key: "status", // String-based value accessors!
                isKey: false,
                dataFormat: this.signatureStatusIdType,
                columnClassName: "",
                dataSort: true,
                toolTip: this.customStatusTooltip,
                isHidden: false,
                width: "112px",
                Placeholder: "Status",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getStatusMultiSelectDropDown,
                    customFilterParameters: {
                        options: this.SignatureStatusOptionsList,
                        enableAllFilter: false,
                        placeholder: "Select Status..."
                    } as any
                } as CustomFilter
            },
            {
                header: "Filing Type",
                key: "documentFilingType",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: this.customFilingTypeTooltip,
                isHidden: this.props.isPaperFiledEnabled ? false : true,
                width: "auto",
                Placeholder: "Filing Type",
                filter: {
                    type: "SelectFilter",
                    placeholder: "Select Filing Type...",
                    options: DocumentFilingTypeOptionsList
                } as SelectFilter
            },
            {
                header: "Last Reminder",
                key: "lastReminder", // String-based value accessors!
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: {
                    type: "CustomFilter",
                    getElement: this.ReminderDateFilter
                } as CustomFilter
            },
            {
                header: this.props.customColumnHeader, //need to get it from data base
                key: "testColumn", // String-based value accessors!
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: this.props.customColumnHeader != "" ? false : true,
                width: "auto",
                filter: {
                    type: "TextFilter",
                    placeholder: this.props.customColumnHeader,
                    style: { font: "bold" },
                    delay: 2500
                } as TextFilter // change the placeholder according to header
            },
            {
                header: "Downloaded",
                key: "downloaded", // String-based value accessors!
                isKey: false,
                dataFormat: this.downloadedHistory,
                columnClassName: "",
                dataSort: true,
                toolTip: this.customDownloadedTooltip,
                isHidden: false,
                width: "auto",
                filter: {
                    type: "SelectFilter",
                    options: this.DownloadedOptionsList,
                    placeholder: "Select Downloaded..."
                } as SelectFilter
            },
            {
                header: "Deletion Date",
                key: "retentionPeriod", // String-based value accessors!
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: {
                    type: "CustomFilter",
                    getElement: this.RetentionDateFilter
                } as CustomFilter
            },

            {
                header: "Action",
                key: "button", // String-based value accessors!
                isKey: false,
                dataFormat: this.deliveredReportAction,
                columnClassName: "button-cell",
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: "100px",
                filter: { type: "TextFilter", placeholder: "", style: { display: "none" } } as TextFilter
            }
        ];
    };

    private getCustomColumns = (columns: any) => {
        if (this.state.customColumnOptions.length === 0) {
            return columns;
        }
        let columnHeader: any[] = columns;
        if (!this.props.isPaperFiledEnabled) {
            let columnState = [...this.state.customColumnOptions];
            let indexFilingTypeColumn = columnState.findIndex((a) => a.columnName == "Filing Type");
            if (indexFilingTypeColumn != -1) {
                columnState.splice(indexFilingTypeColumn, 1);
                this.setState({
                    customColumnOptions: columnState,
                    modifiedCustomColumns: columnState
                });
            }
        } else {
            let columnState = [...this.state.customColumnOptions];
            let index = columnState.findIndex((a) => a.columnName == "Filing Type");
            if (index == -1) {
                let filingTypeColumnOrder: number = 9;
                let notExists: boolean = columnState.findIndex((col) => col.columnOrder === filingTypeColumnOrder) !== -1;
                let filingTypeColumn: IColumnSettings = {
                    columnName: "Filing Type",
                    columnOrder: filingTypeColumnOrder,
                    isActive: 0
                };
                let filingTypeInsertPosition = 0;
                columnState.map((col, index) => {
                    if (notExists && col.columnOrder == filingTypeColumnOrder) {
                        filingTypeInsertPosition = index;
                    }
                    if (notExists && col.columnOrder >= filingTypeColumnOrder) {
                        col.columnOrder = col.columnOrder + 1;
                        return col;
                    }
                    return col;
                });
                filingTypeInsertPosition =
                    filingTypeInsertPosition !== 0
                        ? filingTypeInsertPosition
                        : columnState.findIndex((col) => col.columnOrder === filingTypeColumnOrder - 1) + 1;
                columnState.splice(filingTypeInsertPosition, 0, filingTypeColumn);
                this.setState({
                    customColumnOptions: columnState,
                    modifiedCustomColumns: columnState
                });
            }
        }
        if (!this.props.customColumnHeader) {
            let columnState = [...this.state.customColumnOptions];
            let indexTestColumn = columnState.findIndex((a) => a.columnName == "testColumn");
            if (indexTestColumn != -1) {
                columnState.splice(indexTestColumn, 1);
                this.setState({
                    customColumnOptions: columnState,
                    modifiedCustomColumns: columnState
                });
            }
        } else {
            let columnState = [...this.state.customColumnOptions];
            let index = columnState.findIndex((a) => a.columnName == "testColumn");
            if (index == -1) {
                let testColumnOrder: number = this.props.isPaperFiledEnabled ? 11 : 10;
                let notExists: boolean = columnState.findIndex((col) => col.columnOrder === testColumnOrder) !== -1;
                let testColumn: IColumnSettings = {
                    columnName: "testColumn",
                    columnOrder: testColumnOrder,
                    isActive: 1
                };
                let testColumninsertPosition = 0;
                columnState.map((col, index) => {
                    if (notExists && col.columnOrder === testColumnOrder) {
                        testColumninsertPosition = index;
                    }
                    if (notExists && col.columnOrder >= testColumnOrder) {
                        col.columnOrder = col.columnOrder + 1;
                        return col;
                    }
                    return col;
                });
                testColumninsertPosition =
                    testColumninsertPosition !== 0
                        ? testColumninsertPosition
                        : columnState.findIndex((col) => col.columnOrder === testColumnOrder - 1) + 1;
                columnState.splice(testColumninsertPosition, 0, testColumn);
                this.setState({
                    customColumnOptions: columnState,
                    modifiedCustomColumns: columnState
                });
            }
        }
        let order: number = 0;
        let removedColumn: any = null;
        for (let i = 0; i < this.state.customColumnOptions.length; i++) {
            for (let j = 0; j < columnHeader.length; j++) {
                if (
                    this.state.customColumnOptions[i].columnName.trim() == columnHeader[j].header.trim() ||
                    this.state.customColumnOptions[i].columnName.trim() == columnHeader[j].key.trim()
                ) {
                    order = this.state.customColumnOptions[i].columnOrder;
                    columnHeader[j].isHidden = !this.state.customColumnOptions[i].isActive;
                    removedColumn = columnHeader.splice(j, 1);
                    columnHeader.splice(order - 1, 0, removedColumn[0]);
                }
            }
        }

        return columnHeader;
    };

    //===================================================================== ColumnSettings ===========================================================================//

    private onDragStart = (event: any, listIndex: any) => {
        let index = listIndex;
        event.dataTransfer.setData("item", index);
    };

    private onDropped = (event: any, listIndex: any) => {
        let copy: IColumnSettings[] =
            this.state.modifiedCustomColumns.length > 0 ? [...this.state.modifiedCustomColumns] : this.getColumnSettings();
        let temp = JSON.parse(JSON.stringify(copy));
        let oldIndex: number = parseInt(event.dataTransfer.getData("item"));
        if (oldIndex != listIndex) {
            let parent: any = document.getElementById("item-list");
            let target: any = parent && parent.children[listIndex];
            let dropped: any = parent && parent.children[oldIndex];
            dropped && dropped.remove();
            let newIndex = listIndex;
            if (newIndex < oldIndex) {
                target && target.closest("li").before(dropped);
            } else {
                target && target.closest("li").after(dropped);
            }

            let childNodes = parent.getElementsByTagName("li");
            for (let j = 0; j < childNodes.length; j++) {
                temp.map((items: any, index: number) => {
                    if (items.columnName == childNodes[j].className) {
                        items.columnOrder = j + 1;
                    }
                });
            }
            temp = temp.sort((a: any, b: any) => (a.columnOrder < b.columnOrder ? -1 : a.columnOrder > b.columnOrder ? 1 : 0));
            this.setState({ modifiedCustomColumns: temp });
        }
    };

    private activateOrDeactivateCustomColumn = (event: any, name: string) => {
        const { modifiedCustomColumns } = this.state;
        let copy: IColumnSettings[] = modifiedCustomColumns.length > 0 ? [...modifiedCustomColumns] : this.getColumnSettings();
        let temp = JSON.parse(JSON.stringify(copy));
        temp.map((items: IColumnSettings) => {
            if (items.columnName == name) {
                if (event.target.checked) {
                    items.isActive = 1;
                } else {
                    items.isActive = 0;
                }
            }
        });
        this.setState({ modifiedCustomColumns: temp });
    };

    private OnSaveCustomColumns = () => {
        this.setState({ showColumnSettingsLoader: true });
        var toSave =
            this.state.modifiedCustomColumns && this.state.modifiedCustomColumns.length > 0
                ? this.state.modifiedCustomColumns
                : this.getColumnSettings();
        var columnSettings: IColumnSettings[] = toSave.map((col) => {
            if (col.columnName === this.props.customColumnHeader)
                return {
                    ...col,
                    columnName: "testColumn"
                };
            else return col;
        });
        this.setState(
            {
                customColumnOptions: columnSettings
            },
            () => {
                let type: ReportType = ReportType.Delivered;
                if (this.props.isArchived) type = ReportType.Archived;
                this.props.saveColumnSettings(type, this.state.customColumnOptions, this.callback);
            }
        );
    };

    private onReset = () => {
        this.setState({
            modifiedCustomColumns: this.state.tempCustomColumns
        });
        this.OnCloseColumnSettings();
    };

    private callback = () => {
        this.setState({ showColumnSettingsLoader: false });
        this.OnCloseColumnSettings();
        VenusNotifier.Success(DeliveredReturnsConstants.OtherMessage.SaveColumnSettingsSuccess, null);
    };

    private OnCloseColumnSettings = () => {
        this.setState({ showColumnSettings: false });
    };

    private OnOpenColumnSettings = () => {
        this.setState({ showColumnSettings: true });
    };

    private getColumnSettings = (): IColumnSettings[] => {
        return this.getColumns()
            .filter((column) => !column.isHidden)
            .map((col, index) => {
                if (col.key == "testColumn") {
                    return {
                        columnName: "testColumn",
                        columnOrder: index + 1,
                        isActive: 1
                    } as IColumnSettings;
                } else {
                    return {
                        columnName: col.header,
                        columnOrder: index + 1,
                        isActive: 1
                    } as IColumnSettings;
                }
            });
    };

    private getListItem = () => {
        let columnsOptions: IColumnSettings[] = [];
        if (this.state.modifiedCustomColumns.length <= 0) {
            columnsOptions = this.getColumnSettings();
        } else {
            columnsOptions = this.state.modifiedCustomColumns;
        }
        if (!this.props.customColumnHeader) {
            var index = columnsOptions.findIndex((a) => a.columnName == "testColumn");
            if (index != -1) {
                columnsOptions.splice(index, 1);
            }
        }
        if (!this.props.isPaperFiledEnabled) {
            var index = columnsOptions.findIndex((a) => a.columnName == "Filing Type");
            if (index != -1) {
                columnsOptions.splice(index, 1);
            }
        } else {
            let filingTypeColumnOrder: number = 9;
            let notExists: boolean = columnsOptions.findIndex((col) => col.columnOrder === filingTypeColumnOrder) !== -1;
            if (!notExists) {
                let filingTypeColumn: IColumnSettings = {
                    columnName: "Filing Type",
                    columnOrder: filingTypeColumnOrder,
                    isActive: 0
                };
                let filingTypeInsertPosition =
                    columnsOptions.findIndex((col) => col.columnOrder === filingTypeColumnOrder - 1) + 1;
                columnsOptions.splice(filingTypeInsertPosition, 0, filingTypeColumn);
            }
        }
        let items = columnsOptions
            .filter((col) => col.columnName != "Action")
            .map((value, index) => {
                let isColumnActive: boolean = value.isActive == 1 ? true : false;
                return (
                    <li
                        key={value.columnName + value.columnOrder}
                        className={value.columnName}
                        onDrop={(e) => this.onDropped(e, index)}
                        draggable={true}
                        onDragStart={(e) => this.onDragStart(e, index)}
                        onDragOver={(e) => e?.preventDefault()}
                        onDragEnd={(e) => e?.preventDefault()}
                        style={{ listStyleType: "none", marginBottom: "20px", width: "100%", display: "flex" }}
                    >
                        <div style={{ width: "100%" }}>
                            <span style={{ float: "left" }} className="col-sm-5">
                                <input
                                    type="text"
                                    style={{ pointerEvents: "none" }}
                                    className={"form-control draggable-input noOutline"}
                                    value={value.columnName == "testColumn" ? this.props.customColumnHeader : value.columnName}
                                    disabled={value.columnName == "Client ID" ? true : false}
                                />
                            </span>
                            {value.columnName == "Client ID" ? (
                                <span style={{ padding: "0px 10px" }} className="col-sm-4">
                                    <i
                                        className="fa fa-info-circle"
                                        title={"This column is required"}
                                        style={{ paddingTop: "10px", color: "#26b2ad", cursor: "pointer" }}
                                    ></i>
                                </span>
                            ) : (
                                <label className="toggleSwitch col-sm-4">
                                    <input
                                        type="checkbox"
                                        checked={isColumnActive}
                                        onChange={(event) => this.activateOrDeactivateCustomColumn(event, value.columnName)}
                                    ></input>
                                    <span className="columnFilterSlider round"></span>
                                </label>
                            )}
                        </div>
                    </li>
                );
            });
        return items;
    };

    private FilingType = (filingType: DocumentFilingType) => {
        let datafilingType: any = filingType;
        let filingTypeName: "None" | "EFiled" | "Both" =
            typeof datafilingType == "number" ? DocumentFilingType[datafilingType] : datafilingType;
        return `${DocumentFilingTypeList[filingTypeName]}`;
    };

    //=========================================================================================================================================================//

    public render() {
        let columns = this.getColumns();

        const options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick: function (row: any) {},
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            clearSearch: true,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        const pagination = true;

        let selected: number[] = [];

        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push((this.props.pageNo - 1) * this.props.pageSize + rowIndex);
            }
        }

        let selectRowProp: any = {
            mode: "checkbox",
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: "row-selected"
        };

        let Columns = this.getCustomColumns(columns);

        let data: any = [];

        if (this.props.delivered.deliveredReturnTableModel.documents) {
            data = this.props.delivered.deliveredReturnTableModel.documents.map((model, index) => {
                let locationName = model.document.location?.locationName;
                if (model.document.locationId && !locationName) {
                    const index = this.props.locationDropdown.findIndex((x) => x.value == model.document.locationId);
                    if (index != NO_INDEX) {
                        locationName = this.props.locationDropdown[index].name;
                    }
                }
                return {
                    taxDocumentName: getClientName(model.document),
                    clientId: model.document.clientId,
                    engagementType: engagementType(model.document.engagementType),
                    partner: model.document.partnerId == 0 || model.document.partnerId == null ? "" : model.document.partner.lastName + ", " + model.document.partner.firstName,
                    locationName: locationName ? locationName : "",
                    deliveredBy:
                        model.document.deliveredByUser == undefined || model.document.deliveredByUser.userId == 0
                            ? ""
                            : model.document.deliveredByUser.lastName + ", " + model.document.deliveredByUser.firstName,
                    deliveredDate: moment(model.document.deliveredOn).format("MM/DD/YYYY"),
                    signatureStatusIdType: model.document.signatureStatus,
                    lastReminder: model.lastReminderOn == null ? "" : moment(model.lastReminderOn).format("MM/DD/YYYY"),
                    testColumn: model.customColumn == null ? "" : model.customColumn,
                    downloaded: model.downloadsCount,
                    bulkDownloadCount: model.bulkDownloadCount,
                    button: "Actions",
                    index: (this.props.pageNo - 1) * this.props.pageSize + index,
                    signedCount: model.signedCount,
                    id: model.document.id,
                    documentKey: model.document.documentGuid,
                    taxYear: model.document.taxYear,
                    retentionPeriod: model.retentionPeriod == null ? "" : moment(model.retentionPeriod).format("MM/DD/YYYY"),
                    rowIndex: index,
                    documentFilingType: this.FilingType(model.documentFilingType),
                    groupId: model.document.groupId,
                    groupName: model.document.groupName
                };
            });
        }

        return (
            <div className="pos-relative">
                <ColumnSettings
                    activateOrDeactivateColumn={this.activateOrDeactivateCustomColumn}
                    getListItem={this.getListItem}
                    onDragStart={this.onDragStart}
                    onDropped={this.onDropped}
                    onReset={this.onReset}
                    OnSaveCustomColumns={this.OnSaveCustomColumns}
                    showCustomColumn={this.state.showColumnSettings}
                    showLoader={this.state.showColumnSettingsLoader}
                />
                {this.showBulkAlertMessage()}
                <div data-test-auto="B510184C-5041-464B-9904-107F0B3C6F60">
                    <BootstrapTable
                        ref="table"
                        data={data}
                        remote={true}
                        fetchInfo={{ dataTotalSize: this.props.totalRows }}
                        options={options}
                        striped
                        hover={true}
                        pagination={pagination}
                        selectRow={selectRowProp}
                    >
                        {Columns.map((value: any, index: any) => {
                            return (
                                <TableHeaderColumn
                                    key={index}
                                    ref={(e) => {
                                        this.headerRefStore[value.key] = e;
                                    }}
                                    isKey={value.isKey}
                                    dataField={value.key}
                                    hidden={value.isHidden}
                                    width={value.width}
                                    dataFormat={value.dataFormat}
                                    columnClassName={value.columnClassName}
                                    columnTitle={value.toolTip}
                                    filter={value.filter}
                                    dataSort={value.dataSort}
                                >
                                    {" "}
                                    {value.width === "auto" ? (
                                        <span title={value.header} className="table-text-sort">
                                            {value.header}
                                        </span>
                                    ) : (
                                        value.header
                                    )}
                                </TableHeaderColumn>
                            );
                        })}
                    </BootstrapTable>
                </div>
            </div>
        );
    }
}

export default DeliveredReturnsTable;
