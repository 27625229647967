import * as React from 'react';
import { Button, Modal } from "react-bootstrap";
import { ImportCCHAxcess } from '../../helper/Constants';

interface DisconnectModalProps {
    show:boolean,
    onDisconnect():void;     
    onCancel():void;
}

export const DisconnectModal: React.FC<DisconnectModalProps> = ({ show, onDisconnect, onCancel }) => {
    
    let onProceedBtnClick =() => {        
        onDisconnect();
    }

    let onCancelBtnClick =() => {        
        onCancel();
    }
    
    const headerConstants = ImportCCHAxcess.Header;
    
    return(<>
        <Modal className="cch-modal" show={show} onHide={onCancelBtnClick}>
                    <Modal.Header closeButton>                        
                        <Modal.Title>{headerConstants.disconnect}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className='col-sm-12 col-md-12 col-xl-12'>
                                <div className="row">
                                    <p> {headerConstants.disconnectText}
                                    </p>                           
                                </div>
                            </div>
                        </div>
                    </Modal.Body>                
                    <Modal.Footer>
                        <Button variant="default" className="btn btn-only btn-white btn-default" onClick={onCancelBtnClick} data-test-auto="D4D4E151-755C-4992-AE6C-6A0662BBD463">
                            {headerConstants.cancel}
                        </Button>
                        <Button variant="primary" className="btn btn-only btn-info"  onClick={onProceedBtnClick} data-test-auto ="BA504366-A1F5-4FCB-80E4-15BD5E39AA25">
                            {headerConstants.proceed}
                        </Button>                       
                    </Modal.Footer>
                </Modal>

    </>);
};