import { IUserGroups } from '../../../Core/ViewModels/User/UserViewModel';
import { AuthenticationProvider } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';

export interface IModalProps {
	caption: string;
	onToggle(prop: string): any;
};

export interface IUserProfile {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	extension: string;
	faxNumber: string;
	email: string;
	oldPassword: string;
	password: string;
	confirmPassword: string;
	userId: number;
	authenticationProviders: AuthenticationProvider[];
	metadata: string;
	countryCode: string;
	mobileNumber: string;
    isMobileVerify: boolean;
}



export interface IUserLoginHistory {
	userEmail: string;
	loggedInOn: string;
	loggedOutOn: string;
	IPAddress: string;
	clientApp: string;
	deviceId: string;
	customData: string;
}

export const initialUserProfile: IUserProfile = {
	firstName: '',
	lastName: '',
	phoneNumber: '',
	extension: '',
	faxNumber: '',
	email: '',
	oldPassword: '',
	password: '',
	confirmPassword: '',
	userId: 0,	
	userGroups: [],
	authenticationProviders: [],
	metadata: "",
	countryCode: "",
	mobileNumber: "",
	isMobileVerify: false
}

export interface IOneTimePassword {
	id: number;
	otp: string;
	retryCount: number;
	otpType: OTPType;
	address: string;
	createdOn: Date;
	otpData: IOTPData;
}

export enum OTPType {
	MFA = 1,
	MobileVerification = 2
}

export interface IOTPData {
	userId: number;
}

export const initialOneTimePassword: IOneTimePassword = {
	id: 0,
	otp: '',
	retryCount: 0,
	otpType: OTPType.MobileVerification,
	address: '',
	createdOn: new Date(),
	otpData: {
		userId: 0
	}
}

export interface IOTPState {
	mfaOTPLength: number;
	validOTP: boolean;
}

export interface IUserProfileModel {
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phone: string;
    extension: string;
    fax: string;
    countryCode: string;
    mobileNumber: string;
    isMobileVerify: boolean;
    ptin: string;
    readonlyFields: string[];
	userGroups: string;
}
export const initialUserModel: IUserProfileModel = {
    firstName: '',
    lastName: '',
    phone: '',
    extension: '',
    fax: '',
    emailAddress: '',
    userId: 0,
    countryCode: "",
    mobileNumber: "",
    isMobileVerify: false,
    ptin: "",
    readonlyFields: [],
	userGroups: ""
}
export interface IPasswordUpdateModel {
    password: string;
    newPassword: string;
}

export interface IPasswordPolicySettings {
    passwordPolicy: PasswordPolicyType;
    passwordSettings: IPasswordSettings;
    modifiedDate: Date;
}

export interface IPasswordSettings {
    passwordOptions: PasswordOptions
    length: number;
    passwordAge: number;
}

export enum PasswordPolicyType {
    NoPolicy = "NoPolicy",
    SSRDefault = "SSRDefault",
    FirmDefinedPolicy = "FirmDefinedPolicy"
}

export enum PasswordOptions {
    None = 0,
    UpperCase = 1,
    LowerCase = 2,
    Numbers = 4,
    SpecialCharacters = 8
}
