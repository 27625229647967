import * as React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { DocumentGroups, EngagementType, Arrangement } from '../../common/TaxReturn';
import { isK1ShareHolder } from '../../helper/HelperFunctions';

export interface IReGroupPageProps {
    selectedGroup: DocumentGroups[];
    engagementType: EngagementType;
    arrangement: Arrangement;
    onRegroup: (destinationGroup: DocumentGroups) => void;
    isEnableInvoice?: boolean;
};
const NO_INDEX = -1;
export class ReGroupPage extends React.Component<IReGroupPageProps, {}> {
    public render() {
        const { selectedGroup, onRegroup } = this.props;
        const engagementType = ((typeof this.props.engagementType == "number") ? EngagementType[this.props.engagementType] : this.props.engagementType);
        return (<DropdownButton style={{ zIndex: 10000 }}
            size="sm"
            variant="warning"
            title="Move To"
            id="move-to-dropdown"
            alignRight>

            {selectedGroup.findIndex(x => x === DocumentGroups.Transmittals) == NO_INDEX &&
                <Dropdown.Item className="font14"
                    data-test-auto="1F6591BB-D38D-466C-A27D-B27ED31EC0F8" eventKey="1" onClick={() => { onRegroup(DocumentGroups.Transmittals) }} href="#">
                <i style={{ marginLeft: '-15px' }} className='fas fa-file-alt ddl-icon'></i>Transmittals</Dropdown.Item>}

            {selectedGroup.findIndex(x => x === DocumentGroups.TaxReturns) == NO_INDEX &&
                <Dropdown.Item className="font14"
                    data-test-auto="C5EF0A27-33E6-413F-BF76-F2885EF478B1" eventKey="2" onClick={() => { onRegroup(DocumentGroups.TaxReturns) }} href="#">
                    <i className='fas fa-copy ddl-icon'></i>Tax Returns</Dropdown.Item>}

            {selectedGroup.findIndex(x => x === DocumentGroups.EFile) == NO_INDEX &&
                <Dropdown.Item className="font14" 
                    data-test-auto="58625E2A-F7D4-4CE5-BBA5-C4DA72C3E341" eventKey="3" onClick={() => { onRegroup(DocumentGroups.EFile) }} href="#">
                <i style={{ marginLeft: '-15px' }} className='fas fa-pen-nib ddl-icon'></i>E-File</Dropdown.Item>}

            {selectedGroup.findIndex(x => x === DocumentGroups.Vouchers) == NO_INDEX &&
                <Dropdown.Item className="font14" 
                    data-test-auto="5DE4E238-5A82-479A-9893-EEF2199DF377" eventKey="4" onClick={() => { onRegroup(DocumentGroups.Vouchers) }} href="#">
                <i style={{ marginLeft: '-15px' }} className='fas fa-money-check-alt ddl-icon'></i>Vouchers</Dropdown.Item>}

            {this.props.isEnableInvoice && selectedGroup.findIndex(x => x === DocumentGroups.Invoice) == NO_INDEX &&
                <Dropdown.Item className="font14" 
                    data-test-auto="1FE77968-8D48-4ABE-B851-5C2CD1EF9B8A" eventKey="5" onClick={() => { onRegroup(DocumentGroups.Invoice) }} href="#">
                <i style={{ marginLeft: '-15px' }} className='fas fa-scroll ddl-icon'></i>Invoices</Dropdown.Item>}

            {selectedGroup.findIndex(x => x === DocumentGroups.K1) == NO_INDEX && isK1ShareHolder(this.props.engagementType, this.props.arrangement) &&
                <Dropdown.Item className="font14" 
                    data-test-auto="22743DC2-14C1-4D54-8BD4-980363A7FEE3" eventKey="6" onClick={() => { onRegroup(DocumentGroups.K1) }} href="#">
                <i style={{ marginLeft: '-15px' }} className='fas fa-id-badge ddl-icon'></i>K1's</Dropdown.Item>}

            {selectedGroup.findIndex(x => x === DocumentGroups.Deleted) == NO_INDEX &&
                <Dropdown.Item className="font14" 
                    data-test-auto="9554BC70-D712-4FB3-A5B4-D656EA3727B5" eventKey="7" onClick={() => { onRegroup(DocumentGroups.Deleted) }} href="#">
                <i style={{ marginLeft: '-15px' }} className='fas fa-trash ddl-icon'></i>Deleted</Dropdown.Item>}
        </DropdownButton>)
    }
};
