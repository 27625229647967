import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { DropTarget } from "react-drag-drop-container";
import * as PdfStore from "../../../store/pdf/PdfStore";
import * as TaxDocument from "../../common/TaxReturn";
import { GroupCard } from "./GroupCard";
import { GroupPages } from "./GroupPages";
import { GroupToolBar } from "./GroupToolBar/GroupToolBar";
import Scrollbars from "react-custom-scrollbars";
import { GroupTabConstants } from "../../helper/Constants";
import * as bootbox from "bootbox";
import { enumToNumbers, isK1ShareHolder } from "../../helper/HelperFunctions";
import { IBookmarkSection } from "../ProcessReturnModal/ProcessReturnModels";

export interface IGroupsViewProps {
    docId: number;
    formGroups: TaxDocument.IGroup[];
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    engagementType: TaxDocument.EngagementType;
    arrangement: TaxDocument.Arrangement;
    deletePages: (pages: number[]) => void;
    onPreviewPage: (group: TaxDocument.DocumentGroups, activePage: number, sortedPageOrder: number[]) => void;
    reGroupPreview: (pages: number[], destinationGroup: TaxDocument.DocumentGroups) => void;
    renderTrigger: string;
    isAssignedToLoggedinUser: boolean;
    isEnableInvoice: boolean;
    efileBookmarks: IBookmarkSection[];
}
export interface groupDictionary {
    [index: number]: {
        label: string;
        icon: string;
        color: string;
    };
}
interface IGroupViewState {
    thumbnails: string[];
    preview?: number; //PageNumber or undefined
    previewTitle: string;
    activeGroup: TaxDocument.DocumentGroups;
    selectedPages: ISelectedPagesDictionary;
    orderedGroupType: TaxDocument.TabOrder;
}

export interface ISelectedPagesDictionary {
    [pageNo: number]: {
        source: TaxDocument.DocumentGroups;
    };
}

export class GroupsView extends React.Component<IGroupsViewProps, IGroupViewState> {
    constructor(props: IGroupsViewProps) {
        super(props);

        this.state = {
            thumbnails: [],
            preview: undefined,
            activeGroup: TaxDocument.DocumentGroups.None,
            previewTitle: "Title",
            orderedGroupType: TaxDocument.TabOrder.None,
            selectedPages: []
        };

        this.groupData = {};
        this.groupData[TaxDocument.DocumentGroups.None] = {
            label: "View All",
            icon: "fas fa-folder",
            color: "grey"
        };
        this.groupData[TaxDocument.DocumentGroups.Transmittals] = {
            label: "Transmittals",
            icon: "fas fa-list-alt",
            color: "#FFC400"
        };
        this.groupData[TaxDocument.DocumentGroups.EFile] = {
            label: "E-File Authorizations",
            icon: "fas fa-pen-nib",
            color: "#F1AF5E"
        };
        this.groupData[TaxDocument.DocumentGroups.Vouchers] = {
            label: "Vouchers",
            icon: "fas fa-money-check-alt",
            color: "#E59FA1"
        };
        this.groupData[TaxDocument.DocumentGroups.TaxReturns] = {
            label: "Tax Returns",
            icon: "fas fa-copy",
            color: "#8DC63F"
        };
        this.groupData[TaxDocument.DocumentGroups.Invoice] = {
            label: "Invoices",
            icon: "fas fa-scroll",
            color: "#A0BDFA"
        };
        this.groupData[TaxDocument.DocumentGroups.K1] = {
            label: "K-1's",
            icon: "fas fa-id-card-alt",
            color: "#DD88EE"
        };
        this.groupData[TaxDocument.DocumentGroups.Deleted] = {
            label: "Deleted",
            icon: "fas fa-trash",
            color: "#595959"
        };

        this.onCardClick = this.onCardClick.bind(this);
        this.onDeletePage = this.onDeletePage.bind(this);
        this.onPreviewPage = this.onPreviewPage.bind(this);
        this.handlePageDrop = this.handlePageDrop.bind(this);
    }

    private groupData: groupDictionary;

    public render() {
        let cards: any[] = [];
        var documentGroupVerticalTabKeys = enumToNumbers(TaxDocument.DocumentGroupVerticalTab);
        var formGroupsKeys = this.props.formGroups.map((x) => x.group);
        var emptyTabs = documentGroupVerticalTabKeys.filter((item) => formGroupsKeys.indexOf(item) < 0);
        this.props.formGroups.map((group, i) => {
            {
                if (!cards[group.group] || cards[TaxDocument.DocumentGroups.Deleted]) {
                    if (
                        (group.group === TaxDocument.DocumentGroups.Invoice && !this.props.isEnableInvoice) ||
                        group.group === TaxDocument.DocumentGroups.Deleted
                    ) {
                        cards = this.getDeletedGroup(group, cards);
                    } else {
                        cards = this.getDefaultGroup(group, cards);
                    }
                }
            }
        });
        if (cards[TaxDocument.DocumentGroups.Deleted] && emptyTabs.indexOf(TaxDocument.DocumentGroups.Deleted) !== -1) {
            emptyTabs.splice(emptyTabs.indexOf(TaxDocument.DocumentGroups.Deleted), 1);
        }

        for (var i = 0; i < emptyTabs.length; i++) {
            var enumNumber = emptyTabs[i];
            if (
                !(
                    (!isK1ShareHolder(this.props.engagementType, this.props.arrangement) &&
                        emptyTabs[i] === TaxDocument.DocumentGroupVerticalTab.K1) ||
                    (emptyTabs[i] === TaxDocument.DocumentGroupVerticalTab.Invoice && !this.props.isEnableInvoice)
                )
            ) {
                cards[enumNumber] = {
                    type: enumNumber,
                    label: this.groupData[enumNumber].label,
                    icon: this.groupData[enumNumber].icon,
                    color: this.groupData[enumNumber].color,
                    onClick: this.onCardClick,
                    model: null
                };
            }
        }
        // Re-ordering based on Process Return Tab order
        let k1Index = TaxDocument.DocumentGroupVerticalTab.K1;
        let taxreturnIndex = TaxDocument.DocumentGroupVerticalTab.TaxReturns;
        let invoiceIndex = TaxDocument.DocumentGroupVerticalTab.Invoice;
        let efileIndex = TaxDocument.DocumentGroupVerticalTab.EFile;
        if (cards[k1Index]) {
            cards.splice(efileIndex, 0, cards.splice(taxreturnIndex, 1)[0]);
            cards.splice(invoiceIndex, 0, cards.splice(k1Index, 1)[0]);
        } else {
            cards.splice(efileIndex, 0, cards.splice(taxreturnIndex, 1)[0]);
        }

        cards[0] == undefined && cards.splice(0, 1);

        if (cards.length === 0) return false;

        let activeGroup = cards[cards.findIndex((card) => (card === undefined ? false : card.type === this.state.activeGroup))];

        return (
            <Row style={{ marginTop: 10 }}>
                <Col sm={3} className="grouping-targets" style={{ height: "calc(100vh - 190px)" }}>
                    <div className={"scroll marL15"}>
                        <Scrollbars>
                            <GroupCard
                                color={"grey"}
                                icon={"fas fa-folder"}
                                label={"View All"}
                                onClick={this.onCardClick}
                                type={TaxDocument.DocumentGroups.None}
                                active={this.state.activeGroup === TaxDocument.DocumentGroups.None}
                                accepts={["page"]}
                            />
                            {cards.map((card, i) => {
                                return (
                                    <DropTarget
                                        highlightClassName="highlighted"
                                        dropData={card.type}
                                        targetKey={"groups"}
                                        key={"highlightedGroup" + i}
                                    >
                                        <GroupCard
                                            key={"groupCard" + i}
                                            color={card.color}
                                            icon={card.icon}
                                            label={card.label}
                                            onClick={card.onClick}
                                            type={card.type}
                                            active={this.state.activeGroup === card.type}
                                            accepts={["page"]}
                                        ></GroupCard>
                                    </DropTarget>
                                );
                            })}
                        </Scrollbars>
                    </div>
                </Col>

                <Col sm={9}>
                    {Object.keys(this.state.selectedPages).length > 0 && (
                        <div className="group-view-hedear color-change-animation" style={{ minHeight: "40px" }}>
                            <GroupToolBar
                                onClearSelection={this.handleClearSelection}
                                onDelete={this.onDelete}
                                selectedPages={this.state.selectedPages}
                                engagementType={this.props.engagementType}
                                arrangement={this.props.arrangement}
                                onMove={this.onMove}
                                isEnableInvoice={this.props.isEnableInvoice}
                            />
                        </div>
                    )}

                    <Scrollbars style={{ height: "70vh" }} autoHide>
                        {this.state.activeGroup === TaxDocument.DocumentGroups.None || activeGroup === undefined ? (
                            cards.map((card, i) => {
                                return (
                                    <GroupPages
                                        documentGroup={this.state.activeGroup}
                                        key={"group-pages" + i}
                                        color={card.color}
                                        icon={card.icon}
                                        label={card.label}
                                        group={card.model}
                                        groupIcons={this.groupData}
                                        selectedPages={this.state.selectedPages}
                                        docId={this.props.docId}
                                        pdfDocuments={this.props.pdfDocuments}
                                        onPreviewPage={this.props.onPreviewPage}
                                        onDeletePage={this.onDeletePage}
                                        onDropPage={this.handlePageDrop}
                                        onSelectPage={this.onSelectPage}
                                        onUnSelectPage={this.onUnSelectPage}
                                        efileBookmarks={this.props.efileBookmarks}
                                    />
                                );
                            })
                        ) : (
                            <GroupPages
                                documentGroup={this.state.activeGroup}
                                color={activeGroup.color}
                                icon={activeGroup.icon}
                                label={activeGroup.label}
                                group={activeGroup.model}
                                docId={this.props.docId}
                                pdfDocuments={this.props.pdfDocuments}
                                groupIcons={this.groupData}
                                selectedPages={this.state.selectedPages}
                                onPreviewPage={this.props.onPreviewPage}
                                onDeletePage={this.onDeletePage}
                                onDropPage={this.handlePageDrop}
                                onSelectPage={this.onSelectPage}
                                onUnSelectPage={this.onUnSelectPage}
                                efileBookmarks={this.props.efileBookmarks}
                            />
                        )}
                    </Scrollbars>
                </Col>
            </Row>
        );
    }

    private getDeletedGroup = (group: TaxDocument.IGroup, cards: any[]) => {
        if (cards[TaxDocument.DocumentGroups.Deleted]) {
            cards[TaxDocument.DocumentGroups.Deleted].model.forms = cards[TaxDocument.DocumentGroups.Deleted].model.forms.concat(
                group.forms
            );
        } else {
            cards[TaxDocument.DocumentGroups.Deleted] = {
                type: TaxDocument.DocumentGroups.Deleted,
                label: this.groupData[TaxDocument.DocumentGroups.Deleted].label,
                icon: this.groupData[TaxDocument.DocumentGroups.Deleted].icon,
                color: this.groupData[TaxDocument.DocumentGroups.Deleted].color,
                onClick: this.onCardClick
            };
            let deletedGroup: TaxDocument.IGroup = {
                group: TaxDocument.DocumentGroups.Deleted,
                forms: group.forms
            };

            cards[TaxDocument.DocumentGroups.Deleted].model = deletedGroup;
        }
        return cards;
    };

    private getDefaultGroup = (group: TaxDocument.IGroup, cards: any[]) => {
        cards[group.group] = {
            type: group.group,
            label: this.groupData[group.group].label,
            icon: this.groupData[group.group].icon,
            color: this.groupData[group.group].color,
            onClick: this.onCardClick
        };
        if (group.group === TaxDocument.DocumentGroups.K1 && cards[group.group]) {
            let k1Group: TaxDocument.IGroup = {
                group: TaxDocument.DocumentGroups.K1,
                forms: []
            };
            this.props.formGroups
                .filter((m) => m.group === TaxDocument.DocumentGroups.K1)
                .map((group, i) => {
                    k1Group.forms = k1Group.forms.concat(group.forms);
                });
            cards[group.group].model = k1Group;
        } else {
            cards[group.group] ? (cards[group.group].model = group) : undefined;
        }

        return cards;
    };

    private onCardClick(type: TaxDocument.DocumentGroups) {
        //TODO: dispatch event to the right side children
        const engagementType = +TaxDocument.EngagementType[this.props.engagementType];
        if (type === TaxDocument.DocumentGroups.Transmittals) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.Transmittals });
        } else if (type === TaxDocument.DocumentGroups.TaxReturns) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.TaxReturns });
        } else if (type === TaxDocument.DocumentGroups.EFile) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.EFile });
        } else if (type === TaxDocument.DocumentGroups.Vouchers) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.Vouchers });
        } else if (type === TaxDocument.DocumentGroups.Invoice) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.K1 });
        } else if (type === TaxDocument.DocumentGroups.Deleted) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.Invoice });
        }
        if (engagementType === TaxDocument.EngagementType.E1065 || engagementType === TaxDocument.EngagementType.E1120S) {
            if (type === TaxDocument.DocumentGroups.K1) {
                this.setState({ orderedGroupType: TaxDocument.TabOrder.K1 });
            } else if (type === TaxDocument.DocumentGroups.Invoice) {
                this.setState({ orderedGroupType: TaxDocument.TabOrder.Invoice });
            } else if (type === TaxDocument.DocumentGroups.Deleted) {
                this.setState({ orderedGroupType: TaxDocument.TabOrder.Deleted });
            }
        }
        this.setState({ activeGroup: type, selectedPages: [] });
    }

    private onDeletePage(page: number) {
        let _self = this;
        bootbox.confirm({
            message: GroupTabConstants.Page.DeleteConfirm,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: "btn-success"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    _self.props.reGroupPreview([page], TaxDocument.DocumentGroups.Deleted);
                    Object.keys(_self.state.selectedPages).find((x) => parseInt(x) === page) && _self.onUnSelectPage(page);
                }
            }
        });
    }

    private onPreviewPage(page: number, title: string) {
        this.setState({ preview: page, previewTitle: title });
    }

    /* Group Tab Select/Unselect/Drop Events */

    private getSelectedPages = (): number[] => {
        return Object.keys(this.state.selectedPages).map((x) => parseInt(x));
    };

    private onSelectPage = (pageNo: number, documentGroup: TaxDocument.DocumentGroups) => {
        this.state.selectedPages[pageNo] = {
            source: documentGroup
        };
        this.setState({ selectedPages: this.state.selectedPages });
    };

    private onUnSelectPage = (pageNo: number) => {
        delete this.state.selectedPages[pageNo];
        this.setState({ selectedPages: this.state.selectedPages });
    };

    private handlePageDrop(item: any) {
        if (this.state.selectedPages[item.dragData.pageNo]) {
            this.props.reGroupPreview(this.getSelectedPages(), item.dropData);
        } else {
            this.props.reGroupPreview([item.dragData.pageNo], item.dropData);
        }

        this.handleClearSelection();
    }

    /* End */

    /* Group Tab Move/Clear Selection/Delete */

    private onMove = (destinationGroup: TaxDocument.DocumentGroups) => {
        this.props.reGroupPreview(this.getSelectedPages(), destinationGroup);
        this.handleClearSelection();
    };

    private onDelete = () => {
        let pageThis = this;

        bootbox.confirm({
            message: GroupTabConstants.Page.DeleteConfirm,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: "btn btn-info"
                }
            },
            callback: function (result) {
                if (result) {
                    pageThis.props.reGroupPreview(pageThis.getSelectedPages(), TaxDocument.DocumentGroups.Deleted);
                    pageThis.handleClearSelection();
                }
            }
        });
    };

    private handleClearSelection = () => {
        this.setState({ selectedPages: [] });
    };

    /* End */
}
//=============================================================================
