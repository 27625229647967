import * as React from 'react';
import { Button } from "react-bootstrap";
import { ImportCCHAxcess } from '../../helper/Constants';
import { DisconnectModal} from './DisconnectModal';
import { useState } from 'react';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { useRef } from 'react';
import { useEffect } from 'react';


interface HeaderProps {
    isLoggedIn:boolean,
    loggedInUsername :string,
    isLoading : boolean;
    onDisconnect():void;         
    onLogin():void
}

export const Header: React.FC<HeaderProps> = ({ isLoggedIn, loggedInUsername, isLoading,onDisconnect, onLogin }) => {

    const [showDisconnectModal, setshowDisconnectModal] = useState(false);
    const focusRef = useRef<HTMLSpanElement>(null);

    let OnOLoginBtnClick =() => {        
        onLogin();
    } 
    
    let OnCancelBtnClick = () => {
        setshowDisconnectModal(false);
    }

    let OnDisConnectBtnClick =() => {
        setshowDisconnectModal(true);        
    }    

    let OnDisConnectFromPopup = () => {   
        setshowDisconnectModal(false);
        onDisconnect();
    }

    useEffect(() => {
        if(focusRef && focusRef.current)
            focusRef.current.focus();
    });

    const headerConstants = ImportCCHAxcess.Header;
    
    return(
        <>                  
            <div className="row">
                <div className="col-sm-12">
                    <h3>{headerConstants.title}</h3>  
                    <span className='d-flex mt-3' style={{fontSize:16}}>{headerConstants.loginTitle}</span>                      
                </div>
            </div>                
            <div className='row mt-2 mb-1'>
                <div className='col-sm-11'>
                    {isLoggedIn ?                             
                    (<div className='connection-status'><span className='status-indicator connected'></span> <span className='text-connected'>{headerConstants.connected}</span> <span> | </span><span className='font-italic'>{headerConstants.loggedInAs} {loggedInUsername}</span></div>) :
                    (<div className='connection-status'><span className='status-indicator disconnected'></span> <span>{headerConstants.notConnected}</span></div>) 
                    }       
                </div>
                <div className='col-sm-1'>       
                <span tabIndex={0} ref={focusRef}></span>                 
                    {isLoggedIn ? 
                        (
                            <Button
                            tabIndex={1}
                            variant="default"                                    
                            onClick={() => OnDisConnectBtnClick()}
                            title={headerConstants.disconnect}                            
                            className="btn-white btn-default btn-only mr-10 btn-right"                            
                            data-test-auto="87ACA8B6-FD9F-4B25-ADCB-918F29B5CA6F">                                    
                                {headerConstants.disconnect}
                        </Button>  
                        ) :
                        (
                            <Button
                            tabIndex={1}
                            variant="primary"                                    
                            onClick={() => OnOLoginBtnClick()}
                            title={headerConstants.login}                            
                            className="btn-info btn-only mr-10 btn-right"                            
                            data-test-auto="A245E110-F888-451B-8AC5-D971E4F19266">                                    
                                {headerConstants.login}
                        </Button>                        
                        )
                    }
                </div>                
            </div>
            {isLoading && (
            <LoadingOverlay>
                <Loader loading={isLoading} />
            </LoadingOverlay>                                          
            )}                 
            <DisconnectModal show={showDisconnectModal} onDisconnect={OnDisConnectFromPopup} onCancel={OnCancelBtnClick}></DisconnectModal>            
        </>
    );
};