import * as React from 'react';
import { Col, FormLabel, FormControl, Card, Row, Accordion, Button } from 'react-bootstrap';
import Select from 'react-select';
import { ITaxReturn, EngagementType, isJointFilingAllowed } from '../../TaxReturn';
import * as TaxDocument from '../../../common/TaxReturn';
import { IBookmarkSection, IShareHolder } from '../ProcessReturnModels';
import { TaxIdentificationNumber } from '../../../common/controls/TaxIdentificationNumber';
import { CompanySettingsConstants, ValidationContants } from '../../../helper/Constants';
import { GetFileMagicNumber, GetCountryCode } from '../../../helper/HelperFunctions';
import { PhoneNumberComponent } from '../../../common/PhoneNumberComponent';
import { CustomDatePicker } from '../../../common/controls/CustomDatePicker';
import { CheckBoxComponent } from '../../CheckBoxComponent';
import SSNMaskComponent from '../../controls/SSNMaskComponent';

export interface IShareHolderInfoProps {
    taxReturn: TaxDocument.ITaxReturn;
    name: string;
    ssn: string;
    mobileNumber: string;
    countryCode: string;
    emailAddress: string;
    entityType: TaxDocument.EntityType;
    partnerAddress: string;
    financialYearEnd: Date | undefined;
    isK1ReminderEnabled: boolean;
    shareHolderInfo: IShareHolder;
    onNameChange: (event: any) => void;
    onSSNChange: (id: number, value: string) => void;
    onChangeCountryCode: (event: any) => void;
    onChangeMobileNumber: (value: any) => void;
    onEmailChange: (event: any) => void;
    onAddressChange: (event: any) => void;
    onEntityTypeChange: (event: any) => void;
    handleBlurName: (id: number, event: any) => void;
    handleBlurSSN: (id: number, event: any) => void;
    handleEntityTypeChange: (id: number, event: any) => void;
    handleBlurEmail: (id: number, event: any) => void;
    handleBlurMobileNumber: (id: number, event: any) => void;
    handleBlurCountryCode: (id: number, event: any) => void;
    handleBlurAddress: (id: number, event: any) => void;
    handleFinancialYearEndChange: (date: any) => void;
    handleK1ReminderChange: (id: number, event: any) => void;
    isIndividualEntityType: () => boolean;
    isAssignedToLoggedinUser: boolean;
    isK1SSNMaskingEnabled: boolean;
}

export interface IShareHolderInfoStates {

    open: boolean;

}

export class ShareholderInfo extends React.Component<IShareHolderInfoProps, IShareHolderInfoStates>
{
    state: IShareHolderInfoStates = {
        open: true
    }

    public render() {
        const EntityTypeOptions = [
            { value: 1, label: 'Individual' },
            { value: 2, label: 'Entity' }
        ];
        let icon = this.state.open ? "fas fa-minus" : "fas fa-plus";
        return (
            <Accordion defaultActiveKey="0" onSelect={() => this.setState({ open: !this.state.open })}>
                <Card>
                    <Accordion.Toggle eventKey="0">
                        <Card.Header style={{ padding: "10px" }}>
                            <Card.Title as="h3" style={{ marginBottom: '0px' }}>
                                <i className={icon}></i>
                                {this.props.taxReturn.engagementType.toString() == EngagementType[EngagementType.E1065] ? "Partner Information" : "Shareholder Information"}
                            </Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Row className="marB15">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    Name
                                </Col>
                                <Col sm={8} >
                                    <FormControl
                                        type="text"
                                        placeholder="Name"
                                        data-test-auto="C271B787-892B-4F93-AA4C-3D8F3E40B943"
                                        value={
                                            this.props.name ? this.props.name : ""
                                        }
                                        onChange={this.props.onNameChange.bind(this)}
                                        onBlur={this.props.handleBlurName.bind(this, this.props.shareHolderInfo?.id)}
                                    />
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    Partner Type
                                </Col>
                                <Col sm={8} >

                                    <Select
                                        name="form-field-name"
                                        value={this.props.entityType}
                                        onChange={this.props.onEntityTypeChange.bind(this)}
                                        onBlur={this.props.handleEntityTypeChange.bind(this, this.props.shareHolderInfo?.id)}
                                        options={EntityTypeOptions}
                                        data-test-auto="2D4B4504-CEA5-4252-8D57-D4E85F4898F5"
                                        clearable={false}
                                        placeholder="Select Partner Type"
                                    />
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    SSN/EIN
                                </Col>
                                <Col sm={8} >
                                    {!this.props.isK1SSNMaskingEnabled ?
                                        <TaxIdentificationNumber
                                            mask={this.props.entityType === TaxDocument.EntityType.Individual ? '000-00-0000' : '00-0000000'}
                                            placeholder={"Enter SSN/EIN"}
                                            value={this.props.ssn ? this.props.ssn : ""}
                                            onBlur={this.props.handleBlurSSN.bind(this, this.props.shareHolderInfo?.id)}
                                            onChange={this.props.onSSNChange.bind(this, this.props.shareHolderInfo?.id)}
                                            disabled={this.props.entityType === TaxDocument.EntityType.None}
                                        />
                                        :
                                        <SSNMaskComponent
                                            mask={this.props.entityType === TaxDocument.EntityType.Individual ? '000-00-0000' : '00-0000000'}
                                            placeholder={"Enter SSN/EIN"}
                                            value={this.props.ssn ? this.props.ssn : ""}
                                            onBlur={this.props.handleBlurSSN.bind(this, this.props.shareHolderInfo?.id)}
                                            onChange={this.props.onSSNChange.bind(this, this.props.shareHolderInfo?.id)}
                                            disabled={this.props.entityType === TaxDocument.EntityType.None}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col className="text-left padT08 padL00 dispFlex" sm={4} as={FormLabel}>
                                    <Col className="text-left" sm={9} style={{ whiteSpace: 'nowrap' }}>
                                        Mobile
                                    </Col>
                                    <Col sm={3} className="padR00">
                                        <i className="help-icon fas fa-question-circle padR00 fontS15" data-toggle="tooltip"
                                            title={CompanySettingsConstants.MFASettings.HelpText}></i>
                                    </Col>
                                </Col>
                                <Col sm={8} className="dispFlex">
                                    <Col sm={5} className="padL00 padR02 customClassCC">
                                        <Select
                                            name="form-field-name"
                                            value={this.props.countryCode}
                                            onChange={this.props.onChangeCountryCode.bind(this)}
                                            onBlur={this.props.handleBlurCountryCode.bind(this, this.props.shareHolderInfo?.id)}
                                            options={GetCountryCode()}
                                            data-test-auto="2D4B4504-CEA5-4252-8D57-D4E85F4898F5"
                                            clearable={true}
                                            searchable={true}
                                        >
                                        </Select>
                                    </Col>
                                    <Col sm={7} className="pad00">
                                        <PhoneNumberComponent
                                            phoneNumber={this.props.mobileNumber}
                                            handleChangePhoneNumber={this.props.onChangeMobileNumber.bind(this)}
                                            onBlur={this.props.handleBlurMobileNumber.bind(this, this.props.shareHolderInfo?.id)}
                                            disabled={!this.props.isAssignedToLoggedinUser}
                                        >
                                        </PhoneNumberComponent>

                                    </Col>
                                </Col>
                            </Row>

                            <Row className="marB15">
                                <Col className="text-left" sm={4} as={FormLabel}>
                                    Email Address
                                </Col>
                                <Col sm={8} >
                                    <FormControl
                                        type="text"
                                        placeholder="Email Address"
                                        data-test-auto="22D5DF6D-85FA-4646-819F-3A5856B7BF7B"
                                        value={this.props.emailAddress ? this.props.emailAddress : ""
                                        }
                                        onChange={this.props.onEmailChange.bind(this)}
                                        onBlur={this.props.handleBlurEmail.bind(this, this.props.shareHolderInfo?.id)}
                                    />
                                </Col>
                            </Row>

                            {(this.props.shareHolderInfo?.k1StatusId > 0) ? <Row className="marB10">
                                <Col className="text-left" sm={4} as={FormLabel}>
                                    Partner's Address
                                </Col>
                                <Col sm={8} >
                                    <textarea
                                        className="form-control"
                                        rows={1}
                                        placeholder="Address"
                                        data-test-auto="0fc0b438-2d7c-4935-9808-667e50315e9b"
                                        value={this.props.partnerAddress ? this.props.partnerAddress : ""}
                                        onChange={this.props.onAddressChange.bind(this)}
                                        onBlur={this.props.handleBlurAddress.bind(this, this.props.shareHolderInfo?.id)}
                                    />
                                </Col>
                            </Row> : ""}

                            {(this.props.shareHolderInfo?.k1StatusId > 0) ? <Row className="marB15">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    Year End
                                </Col>
                                <Col sm={8} className={'zindexzero'}>
                                    <CustomDatePicker
                                        data-test-auto="df90794a-2d23-433b-aa6d-04043f9ec2a3"
                                        value={this.props.financialYearEnd ? (new Date(this.props.financialYearEnd).getFullYear() == 1 ? undefined : this.props.financialYearEnd) : undefined}
                                        onChange={this.props.handleFinancialYearEndChange.bind(this)}
                                        calendarPlacement="top"
                                    />
                                </Col>
                            </Row> : ""}


                            {/* Commented as a part US: 103985
                             <Row className="marB15">
                                <Col className="text-left">
                                    <CheckBoxComponent
                                        text="Do not send Partner Information Update Email"
                                        id={`${this.props.shareHolderInfo?.id}`}
                                        checked={!this.props.shareHolderInfo.isK1ReminderEnabled}
                                        onChange={this.props.handleK1ReminderChange.bind(this, this.props.shareHolderInfo?.id)}
                                    />
                                </Col>
                            </Row> */}

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
