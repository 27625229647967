export const CommonResources = {
    /* Upload Buttons */
    CCHUploadButton: "BtnCCH",
    UTUploadButton: "BtnUT",
    GSUploadButton: "BtnGS",
    LTUploadButton: "BtnLT",

    HeaderPrefix: "hdr_",

    /* Header Buttons */
    SetAccess_Header: "BtnSetAcs",
    CustomColum_Header: "BtnChngCstmColm",
    DownloadEFile_Header: "BtnDwldefile",
    Reminder_Header: "BtnSndRemdr",
    Archive_Header: "BtnArch",
    ChangeStatus_Header: "BtnChngSts",
    ExportToExcel: "BtnExport",
    Restore_Header: "BtnRestore",

    /* Header Submit*/
    CCHSubmitButton: "CCHupld_BtnSbmt",
    UTSubmitButton: "UTupld_BtnSbmt",
    LTSubmitButton: "LTupld_BtnSbmt",
    GSSubmitButton: "GSupld_BtnSbmt",
    ReportAProblemButton: "BtnRptPrblm",

    CCHReSubmitButton: "CCHupld_BtnReSbmt",
    UTReSubmitButton: "UTupld_BtnReSbmt",
    LTReSubmitButton: "LTupld_BtnReSbmt",
    GSReSubmitButton: "GSupld_BtnReSbmt",

    CCHReUploadButton: "BtnReCCH",
    UTReUploadButton: "BtnReUT",
    GSReUploadButton: "BtnReGS",
    LTReUploadButton: "BtnReLT"
};

export const SendTaxReturnResources = {
    /* Common */
    Prefix: "SSR_TxRtnInPrcs_",
    HeaderButtonPrefix: "SSR_TxRtnInPrcs_hdr_",
    GridButtonPrefix: "SSR_TxRtnInPrcs_grid_",

    /*Header*/
    HeaderAssignButton: "BtnAssign",
    HeaderSetAccessButton: "BtnSetacs",
    HeaderGroupReturnButton: "BtnGrp",
    HeaderDeleteButton: "BtnDlt",
    HeaderDeliverGroupButton: "BtnGrpDel",

    /*InProcess*/
    SendForReviewMenu: "SndFrReviw",
    SendToEROSignerMenu: "SndtoERO",
    ApproveForDeliveryMenu: "AprvFRDelivry",
    DeliverToClientMenu: "DelvertoClnt",
    DeliverToCompanyMenu: "DelvertoComp",
    DeliverToTaxCaddyMenu: "DelvertoTxCdy",
    DownloadPDFMenu: "DwnldPDF",
    DeliverWithTaxCaddyMenu: "DelverwthTxCdy",
    DeliverToControllerMenu : "DelvertoContr",
    /*Grid*/
    GridSetAccessButton: "BtnSetacs",
    GridReportAProblemButton: "BtnRptPrblm",
    GridProcessButton: "BtnProcs",
    GridDoubleClickProcessButton: "Procs_Dblclick",
    GridDeleteButton: "BtnDlt",
    GridEditClientButton: "BtnEdtClnt",
    GridReprocessButton: "BtnReProcs",
    GridUpdateButton: "BtnUpdRtn",
    GridClientTrackingButton: "BtnClntTrk",
    GridChangeStatus: "BtnChngSts",
    ClientView: "BtnClntViw",
    EditGroupName: "BtnEditGroup",
};

export const SendGroupedTaxReturnResources = {
    /* Common */
    Prefix: "SSR_GrTxRtnInPrcs_",
    HeaderButtonPrefix: "SSR_GrTxRtnInPrcs_hdr_",
    ControllerGridPrefix: "SSR_GrTxRtnInPrcs_Grid_",
    DocumentGridPrefix: "SSR_GrTxRtnInPrcs_Doc_Grid_",

    /* Header */
    HeaderAssignButton: "BtnAssign",
    HeaderSetAccessButton: "BtnSetAcs",
    HeaderDeleteButton: "BtnDlt",
    HeaderChangeGroup: "BtnChngGrp",

    /* Controller Guid */
    ProcessGroup_Grid: "BtnPrcsGrp",
    EditController_Grid: "BtnEdtCntrlInfo",
    DeleteGroup_Grid: "BtnDelGrp",
    EditGroupName_Grid: "BtnEditGrpName",
    SetAccess_Grid: "BtnSetAcs",
    ReportAProblem_Grid: "BtnRprtProb",

    /* Document Grid */
    ProcessReturn_Document_Grid: "BtnPrcsRtn",
    ReProcessReturn_Document_Grid: "BtnRePrcsRtn",
    EditClientInfo_Document_Grid: "BtnEdtClntInfo",
    UploadCorrectedReturn_Document_Grid: "BtnUpldCrctdRtn",
    DeleteReturn_Document_Grid: "BtnDlt",
    ChangeStatus_Document_Grid: "BtnChngSts",
    ReportAProblem_Document_Grid: "BtnRprtProb",

    /* Internal Routing */
    SendForReview: "SndFrReviw",
    SendToEroSigner: "SndToERO",
    ApproveForDelivery: "AprvFrDlvry",

    /* Delivery */

    DeliverToController: "DlvrToCntrlr",
    DownloadPdf: "DwnldPDF"
};

export const InUseReturnAndInUseGroupedReturnResources = {
    /* Common */

    // For InUse Returns
    InUsePrefix: "SSR_InUseRtn_",
    InUseHeaderPrefix: "SSR_InUseRtn_hdr_",
    InUseGridPrefix: "SSR_InUseRtn_Grid_",

    // For InUseGrouped Returns
    InUseGroupedPrefix: "SSR_InUseGrRtn_",
    InUseGroupedHeaderPrefix: "SSR_InUseGrRtn_hdr_",
    InUseGroupedControllerGridPrefix: "SSR_InUseGrRtn_Grid_",
    InUseGroupedDocumentGridPrefix: "SSR_InUseGrRtn_Doc_Grid_",

    /* Header */
    HeaderUnlockReturnButton: "BtnUnlkRtn",

    /* Table */
    TableUnlockReturnButton: "BtnUnlkRtn"
};

export const GroupedReturnReportResources = {
    /* Common */
    Prefix: "SSR_GrRtnRpt_",
    HeaderPrefix: "SSR_GrRtnRpt_hdr_",
    ControllerGridPrefix: "SSR_GrRtnRpt_Grid_",
    DocumentGridPrefix: "SSR_GrRtnRpt_Doc_Grid_",
    ArchivePrefix: "SSR_GrRtnArchRpt_",
    ArchiveHeaderPrefix: "SSR_GrRtnArchRpt_hdr_",
    ControllerGridArchivePrefix: "SSR_GrRtnArchRpt_Grid_",
    DocumentGridArchivePrefix: "SSR_GrRtnArchRpt_Doc_Grid_",

    /* Controller Grid*/
    EditController_Grid: "BtnEdtCntrlInfo",
    EditGroup_Grid: "BtnEditGroup",
    UnLockRetun_Grid: "BtnUnLockRtn",
    DownloadEFileForm_Grid: "BtnDwldefile",
    ResendAccessLink_Grid: "BtnResndAcsLink",
    RecallReturn_Grid: "BtnRecalRtn",
    Reminder_Grid: "BtnAutmtdRmdrs",
    ViewAccessCode_Grid: "BtnViwAcsCod",
    ReportAProblem_Grid: "BtnRptPrblm",
    ControllerTracking_Grid: "BtnCntrlTrkng",
    ControllerView_Grid: "BtnCntrlView",
    DownloadReturn: "BtnDwldRtn",
    Screenshare_Grid: "BtnScrnShr",
    Group_Grid_Delete: "BtnGroupDlt",
    Archive_Grid: "BtnArch",
    Restore_Grid: "BtnRestore",

    /* Document Grid*/
    EditClientInfo_Document_Grid: "BtnEdtClntInfo",
    ChangeStatus_Document_Grid: "BtnChngSts",
    ClientView_Document_Grid: "BtnClientView",
    RecallReturn_Document_Grid: "BtnRecalRtn",
    UploadEstimates_Document_Grid: "BtnUplEstmts",
    ReportAProblem_Document_Grid: "BtnRptPrblm",
    ClientTracking_Document_Grid: "BtnClntTrkng",
    DownloadReturs_Document_Grid: "BtnDwldRtn",
    ChangeCustomColum_Document_Grid: "BtnChngCstmColm",
    Screenshare_Document_Grid: "BtnScrnShr",
    DeleteReturn_Document_Grid: "BtnDlt",
    DownloadEfileForms_Document_Grid: "BtnEfileDwld",
    UploadForms_Document_Grid: "BtnUplForms",
    Archive_Document_Grid: "BtnArch",
    Restore_Document_Grid: "BtnRestore"
};
export const DeliveredReturnReportResourceId = {
    DeliveredPrefix: "SSR_DlvrdRtn_",
    ArchivePrefix: "SSR_ArchRtn_",
    GridPrefix: "Grid_",
    HeaderPrefix: "hdr_",

    AutomaticReminder: "BtnAutmtdRmdrs",
    EditClientInfo: "BtnEdtClntInfo",
    ResendAccessLink: "BtnResndAcs",
    UploadEstimate: "BtnUpldEstmt",
    ViewAccessCode: "BtnViwAcsCode",
    RetentionPolicy: "BtnRetntionPlcy",
    ReportAProblem: "BtnRptPrblm",
    ClientTracking: "BtnClntTrkng",
    ClientView: "BtnClntViw",
    ScreenShare: "BtnScrnShr",
    DownloadEfileForm: "BtnDwldefile",
    DownloadReturns: "BtnDwldRtn",
    ArchiveReturn: "BtnArch",
    UploadForms: "BtnUploadForms",
    RecallReturn: "BtnRcalRtn",
    RestoreReturn: "BtnRestore",
    DeleteReturn: "BtnDlt",
    ReprocessReturn: "BtnReprcs",
    ChangeOfficeLocation: "BtnChngLoc",
    TabInternalTracking: "TabIntrnlTrkng",
    EditGroupName: "BtnEditGroup",
    ChangeStatus: "BtnChngSts",
};

export const ReminderManagementResource = {
    Prefix: "SSR_RmdrMngt_",
    Filter: "BtnFltr",
    Grid: "Grid"
};

export const GroupReturnSettingsResources = {
    /* Common */
    GRSettingsPrefix: "SSR_GrStgns_",

    /* Client Instructions */
    InitialEmail: "InitEml_",
    ReviewAndSign: "RvwSign_",
    VoucherReminders: "VchrRmdrs_",
    DownloadForms: "DwnldFrms_",
    DownloadInstructions: "DwnldIns_",
    PaymentInstructions: "PymtIns_",
    VoucherRevisionNotification: "VchrRvsnNotifn_"
};

export const TaxReturnSettingsResources = {
    /* Common */
    SavedMessagesPrefix: "SSR_SvdMsgs_",
    ClientInstructionPrefix: "SSR_ClntIns_",
    VoucherAndInvoicesPrefix: "SSR_VchrAndIns_",
    ESignOptionsPrefix: "SSR_ESignOps_",
    GeneralPrefix: "SSR_Gnrl_",
    K1SettingsAndInstructionsPrefix: "SSR_K1StgnsAndIns_",
    SecurityPrefix: "SSR_Secr_",
    HeaderPrefix: "Hdr_",
    CustomColumnHeaderPrefix: "CustClmnHdr_",

    /* Saved Messages */
    SavedMessages: "SvdMsgs_",
    AttachmentInstructions: "AtchIns_",
    PaperFileInstructions: "PprFile_",

    /* Client Instructions */
    InitialEmail: "InitEml_",
    ReviewAndSign: "RvwSign_",
    VoucherReminders: "VchrRmdrs_",
    DownloadForms: "DwnldFrms_",
    PaymentInstructions: "PymtIns_",
    InvoicePaymentSuccess: "InvcPymtScs_",
    VoucherRevisionNotification: "VchrRvsnNotifn_",
    RecallReturn: "RclRtn_",

    /* Voucher & Invoices */
    CustomAuthorities: "CustAuth_",

    /* ESign Options */
    AdditionalDocumentType: "AdtnlDocType_",
    ManualSignatureOptions: "MnulSignOps_",

    /* General */
    FilingOptions: "FlgnOps_",
    CustomizeDeliveredReport: "CustDlvrRpt_",
    Integration: "Intgn_",
    DownloadPDF: "DwnldPdf_",
    Footer: "Ftr_",
    Watermarks: "Wtrmrks_",

    /* K1 Settings & Instructions */
    K1Instructions: "K1Ins_",
    K1DistibutionInstructions: "K1DistIns_",
    K1RecipientUpdateEmail: "K1RecipUpdEml_",
    K1ClientUpdateEmail: "K1ClntUpdEml_",
    K1RecipientUpdatePage: "K1RecipUpdPg_",
    K1MaskUnMask: "K1MaskUnMask_",

    /* Security */
    ScreenShare: "ScrnShr_",
    MultifactorAuth: "MltFacAuth_",
    InternalUsers: "IntrnlUsrs_",
    TaxpayerUsers: "TxPyrUsrs_",
    SingleSignOn: "SnglSignOn_",
    IpFiltering: "IpFltng_",

    /* Action Buttons */
    ButtonApply: "BtnApply",
    ButtonAdd: "BtnAdd",
    ButtonEdit: "BtnEdit",
    ButtonDelete: "BtnDelete",
    ButtonActivateOrDeactivate: "BtnActOrDeAct",
    ToggleEnableOrDisable: "TglEnblOrDisbl",
    ButtonDownload: "BtnDwnld",
    ButtonReplace: "BtnReplace"
};

export const InstructionsActionButtonResources = {
    /* Action Buttons */
    ButtonAdd: "BtnAdd",
    ButtonEdit: "BtnEdit",
    ButtonDelete: "BtnDelete"
};

export const UsageReportResources = {
    Prefix: "SSR_UsgRprt",
    ExportButton: "ExportBtn",
    PrintButton: "PrintBtn",
    RunReport: "RunRprt"
};

export const RecycleDocumentResources = {
    Prefix: "SSR_RecycleRtn_",
    DeleteButton: "hdr_BtnDelete",
    RestoreButton: "hdr_BtnRestore"
};

export const RecycleGroupedDocumentResources = {
    Prefix: "SSR_GrpRecycleRtn_",
    DeleteButton: "hdr_BtnDelete",
    RestoreButton: "hdr_BtnRestore"
};

export const K1DeliveredStatusResources = {
    Prefix: "SSR_K1DlvrdSts_",
    ClientView: "BtnClntViw",
    K1PartnerUpdate: "BtnK1PrtnrUpdt",
    DownloadCSV: "BtnDwnldCsv",
    SendReminder: "BtnSndRemdr"
};
export const UserProfileResources = {
    SignatureStampSection: "SSR_Profile_Settings_SignatureStamp"
};

export const TopRibbon = {
    ReportAProblem: "SSR_RptPrblm_Top_Ribbon"
};

export const SSOAccessRequest = {
    Prefix: "SSR_UsrPerm",
    Grant: "grant",
    Deny: "deny"
};

export const ImportCCHAxcessResources = {
    ViewEdit: "SSR_CCHImport_Report"
};

export const SSRGetResourceIds = {
    PaperFileInstructionsAddControl : "SSR_SvdMsgs_PprFile_BtnAdd"
};
