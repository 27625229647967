import * as React from 'react';
import { ModalBody, ModalFooter, Row, Col, Alert, FormLabel, Button } from 'react-bootstrap';
import { ITaxingAuthority, IRefundInfo } from '../TaxReturn';
import { CurrencyTextBox } from '../../common/controls/CurrencyTextBox';

export interface IAddedPopoverProps {
    usStates: ITaxingAuthority[];
    addedRefund: IRefundInfo;
    addedRefundAuthorityChange(value: any): void;
    onChangeRefundAmount(value: number): void;
    handleOverPaymentApplied(value: number): void;
    onCancelAddedRefundPopover(value: any): void;
    submitAddedRefundPopover(): void;
    refundValue: number;
    showAlert: boolean;
    message: string;
    isEdit: boolean;
    hideAddEditOption: boolean
}

export class AddedRefundPopover extends React.Component<IAddedPopoverProps, {}>{
    render() {
        return <div>
            <ModalBody>

                {this.props.showAlert ?
                    <Alert variant="danger" ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                    </i><span style={{ fontSize: "11px" }}>{this.props.showAlert ? this.props.message : ""}</span></Alert> : " "}
                <Row className="marB15">

                    <Col className="text-left padT08" md={4} as={FormLabel}>
                        Authority
                    </Col>

                    <Col md={8}>
                        <select className="form-control"
                            id="authority"
                            value={this.props.addedRefund.authorityID}
                            onChange={this.props.addedRefundAuthorityChange}
                            data-test-auto="0F977C9E-557E-4D36-B2D3-20946C82426A">
                            <option value={0} data-abbreviation=''>Select</option>
                            {this.props.usStates.map((authority, index) => {
                                return <option key={index} value={authority.Id} data-abbreviation={authority.Abbreviation}>
                                    {authority.AuthorityName}
                                </option>
                            })}
                        </select>
                    </Col>


                </Row>
                <Row className="marB15">
                    <Col className="text-left padT08" sm={4} as={FormLabel}>
                        Overpayment
                    </Col>

                    <Col sm={8} className="zeroborderRadius">
                        <CurrencyTextBox
                            value={this.props.addedRefund.overPayment}
                            onChange={this.props.onChangeRefundAmount} />
                    </Col>
                </Row>
                <Row className="marB15">
                    <Col className="text-left padT08" sm={4} as={FormLabel}>
                        Applied
                    </Col>
                    <Col sm={8} className="zeroborderRadius">
                        <CurrencyTextBox
                            value={this.props.addedRefund.overPaymentApplied}
                            placeholder="Applied"
                            onChange={this.props.handleOverPaymentApplied} />
                    </Col>
                </Row>
                <Row className="marB15">
                    <Col className="text-left padT08" sm={4} as={FormLabel}>
                        Refund
                    </Col>
                    <Col sm={8} className="zeroborderRadius">
                        <CurrencyTextBox
                            value={this.props.refundValue}
                            disabled={true}
                        />
                    </Col>
                </Row>
            </ModalBody>
                {
                    !this.props.hideAddEditOption ?
                        (
                        <ModalFooter>
                            <Button className="btn-default"
                            variant='white'
                            data-test-auto="786869DE-77EE-4B47-990D-2B71564831E7"
                            onClick={this.props.onCancelAddedRefundPopover}><i className="fas fa-times"></i>
                                Cancel
                            </Button>
                            <Button
                                variant='info'
                                data-test-auto="C18E7E98-D36C-4ABC-A973-8CFD33DFB209"
                                onClick={this.props.submitAddedRefundPopover}
                            > {this.props.isEdit ? <i className='fas fa-check'></i> : <i className='fas fa-plus'></i>}
                                {this.props.isEdit ? "Update" : "Add"}
                            </Button>
                        </ModalFooter>)
                        : ""
                }
        </div>
    }

}
