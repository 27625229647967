import * as React from "react";
import { FormGroup, FormLabel, FormControl, Col, Card, Accordion } from "react-bootstrap";
import { ClientInfoProperty } from "../../ClientInfoEnum";
import { ITaxClient } from "../../TaxClient";
import { ClientInfoRightSideCardType } from "../Tabs/TabClientInfo";
import { FilterSSN, GetCountryCode } from "../../../helper/HelperFunctions";
import { TaxIdentificationNumber } from "../../../common/controls/TaxIdentificationNumber";
import Select from "react-select";
import { PhoneNumberComponent } from "../../../common/PhoneNumberComponent";
import { CompanySettingsConstants } from "../../../helper/Constants";
import { EngagementType, IPrefilledClientInfo } from "../../TaxReturn";
import { isEqual } from "lodash";

export interface IPartnershipProps {
    clientId: string;
    model: ITaxClient;
    handleValueChange(item: ClientInfoProperty, value: string): void;
    isAssignedToLoggedinUser?: boolean;
    expandPartnershipCard: boolean;
    onCardToggle: (cardType: ClientInfoRightSideCardType) => void;
    engagementType: EngagementType;
    prefilledClientInfo?: IPrefilledClientInfo;
    resetPrefillInfo?: (item: ClientInfoProperty) => void;
}

export interface IPartnershipState {
    id: number;
    name: string;
    email: string;
    eIN: string;
    mobileNumber: string;
    countryCode: string;
}

export class PartnershipSidebar extends React.Component<IPartnershipProps, IPartnershipState> {
    constructor(props: IPartnershipProps) {
        super(props);
        this.state = {
            name: props.model.name !== undefined ? props.model.name : "",
            id: props.model.id !== undefined ? props.model.id : 0,
            email: props.model.email !== undefined ? props.model.email : "",
            eIN: props.model.ssn !== undefined ? props.model.ssn : "",
            mobileNumber: props.model.mobileNumber !== undefined ? props.model.mobileNumber : "",
            countryCode: props.model.countryCode !== undefined ? props.model.countryCode : "+1"
        };
        this.handleChangeId = this.handleChangeId.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleBlurName = this.handleBlurName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeEIN = this.handleChangeEIN.bind(this);
        this.handleBlurEmail = this.handleBlurEmail.bind(this);
        this.handleChangeMobileNumber = this.handleChangeMobileNumber.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IPartnershipProps) {
        if (nextProps.model && isEqual(this.props.prefilledClientInfo, nextProps.prefilledClientInfo)) {
            this.setState({
                name: nextProps.model.name,
                id: nextProps.model.id,
                email: nextProps.model.email,
                eIN: nextProps.model.ssn,
                mobileNumber: nextProps.model.mobileNumber,
                countryCode: nextProps.model.countryCode
            });
        }
    }

    handleResetPrefillInfo = (item: ClientInfoProperty) => {
        this.props.resetPrefillInfo && this.props.resetPrefillInfo(item);
    };

    handleChangeId(event: any) {
        this.props.handleValueChange(ClientInfoProperty.clientId, event.target.value);
    }

    handleChangeName(event: any) {
        this.setState({ name: event.target.value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerName);
    }
    handleChangeEmail(event: any) {
        this.setState({ email: event.target.value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerEmail);
    }
    handleChangeEIN(value: string) {
        this.props.handleValueChange(ClientInfoProperty.partnerEin, value);
        this.handleResetPrefillInfo(ClientInfoProperty.partnerEin);
    }

    handleChangeMobileNumber = (value: any) => {
        this.setState({ mobileNumber: value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerMobileNumber);
    };

    onChangeCountryCode = (event: any) => {
        let value = event ? event.value : "";
        this.setState({ countryCode: value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerCountryCode);
    };

    handleBlurName(event: any) {
        this.props.handleValueChange(ClientInfoProperty.partnerName, event.target.value);
    }
    handleBlurEmail(event: any) {
        this.props.handleValueChange(ClientInfoProperty.partnerEmail, event.target.value);
    }
    handleBlurMobileNumber = (event: any) => {
        this.props.handleValueChange(ClientInfoProperty.partnerMobileNumber, this.state.mobileNumber);
    };

    handleBlurCountryCode = (event: any) => {
        this.props.handleValueChange(ClientInfoProperty.partnerCountryCode, this.state.countryCode);
    };

    getpreFilledClassName = (clientInfoProperty: ClientInfoProperty): string => {
        let isPrefilled = false;
        if (this.props.prefilledClientInfo) {
            switch (clientInfoProperty) {
                case ClientInfoProperty.partnerName:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerName];
                    break;
                case ClientInfoProperty.partnerEin:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerEin];
                    break;
                case ClientInfoProperty.partnerEmail:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerEmail];
                    break;
                case ClientInfoProperty.partnerCountryCode:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerCountryCode];
                    break;
                case ClientInfoProperty.partnerMobileNumber:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerMobileNumber];
                    break;
            }
        }
        return isPrefilled ? "prefilledField" : "";
    };

    public render() {
        let icon = this.props.expandPartnershipCard ? "fas fa-minus" : "fas fa-plus";
        return (
            <Accordion
                activeKey={this.props.expandPartnershipCard ? "0" : ""}
                onSelect={() => {
                    this.props.onCardToggle(ClientInfoRightSideCardType.PartnershipInformationCard);
                }}
            >
                <Card>
                    <Accordion.Toggle eventKey="0">
                        <Card.Header style={{ padding: "10px" }}>
                            <Card.Title as="h3">
                                <i className={icon}></i>{" "}
                                {this.props.engagementType.toString() === EngagementType[EngagementType.E1041]
                                    ? "Trust"
                                    : this.props.engagementType.toString() === EngagementType[EngagementType.E1065] ||
                                      this.props.engagementType.toString() === EngagementType[EngagementType.E1120] ||
                                      this.props.engagementType.toString() === EngagementType[EngagementType.E1120S]
                                    ? "Entity"
                                    : "Company"}
                            </Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    Name
                                </Col>
                                <Col sm={8}>
                                    <FormControl
                                        type="text"
                                        className={this.getpreFilledClassName(ClientInfoProperty.partnerName)}
                                        value={this.state.name}
                                        onBlur={this.handleBlurName}
                                        onChange={this.handleChangeName}
                                        placeholder="Company Name"
                                        data-test-auto="9F18CE2E-7161-4242-B7FB-261F6FA3C5B5"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    EIN
                                </Col>
                                <Col sm={8}>
                                    <TaxIdentificationNumber
                                        onChange={this.handleChangeEIN}
                                        className={this.getpreFilledClassName(ClientInfoProperty.partnerEin)}
                                        value={FilterSSN(this.props.model.ssn)}
                                        placeholder="Company EIN"
                                        mask="00-0000000"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08 dispFlex" sm={4} as={FormLabel}>
                                    <Col sm={9} className="padL00">
                                        Mobile
                                    </Col>
                                    <Col sm={3} className="padR00">
                                        <i
                                            className="help-icon fas fa-question-circle padR00 fontS15"
                                            data-toggle="tooltip"
                                            title={CompanySettingsConstants.MFASettings.HelpText}
                                        ></i>
                                    </Col>
                                </Col>
                                <Col sm={8} className="dispFlex">
                                    <Col sm={5} className="padL00 padR02 customClassCC">
                                        <Select
                                            name="form-field-country-code"
                                            value={this.state.countryCode}
                                            className={this.getpreFilledClassName(ClientInfoProperty.partnerCountryCode)}
                                            onChange={this.onChangeCountryCode}
                                            onBlur={this.handleBlurCountryCode}
                                            options={GetCountryCode()}
                                            data-test-auto="EB1E7C0C-1427-444A-A3B6-4815820DA04C"
                                            disabled={!this.props.isAssignedToLoggedinUser}
                                            clearable={true}
                                            searchable={true}
                                        ></Select>
                                    </Col>
                                    <Col sm={7} className="pad00">
                                        <PhoneNumberComponent
                                            phoneNumber={this.state.mobileNumber ? this.state.mobileNumber : ""}
                                            handleChangePhoneNumber={this.handleChangeMobileNumber}
                                            className={this.getpreFilledClassName(ClientInfoProperty.partnerMobileNumber)}
                                            onBlur={this.handleBlurMobileNumber}
                                            disabled={!this.props.isAssignedToLoggedinUser}
                                        ></PhoneNumberComponent>
                                    </Col>
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    Email
                                </Col>
                                <Col sm={8}>
                                    <FormControl
                                        type="email"
                                        value={this.state.email}
                                        className={this.getpreFilledClassName(ClientInfoProperty.partnerEmail)}
                                        onChange={this.handleChangeEmail}
                                        onBlur={this.handleBlurEmail}
                                        placeholder="Company Email"
                                        data-test-auto="42FD5F59-7326-457F-B929-85246CAB6B56"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
export default PartnershipSidebar;
