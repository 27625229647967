import * as bootbox from 'bootbox';
import * as Enumerable from 'linq';
import { PDFDocumentProxy, PDFJSStatic } from 'pdfjs-dist';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BookmarkTree } from '../../../../components/common/BookmarkTree';
import { SignerTooltipPopup } from '../../../../components/common/Generic/SignerTooltipPopup';
import { IPdfDocumentFacade, PdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import { PdfProperties } from '../../../../Core/ViewModels/Common/PdfHelper';
import { SignatureFormSelectionType } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IUserGroup } from '../../../../Core/ViewModels/User/UserViewModel';
import * as AdditionalDocumentTypeStore from '../../../../store/common/AdditionalDocumentTypeStore';
import * as UserSignatureStore from '../../../../store/common/UserSignatureStore';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import * as AdditionalEsignDocumentStore from '../../../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import * as UserSettingStore from '../../../../store/userManagement/UserSettingStore';
import { Accordian } from '../../../common/controls/Accordian';
import { UploadAdditionalEsignDocumentModal } from '../../../common/ProcessReturnModal/AdditionalEsignDocuments/UploadAdditionalEsignDocumentModal';
import { EfileSignatureControlCard, ISigner } from '../../../common/ProcessReturnModal/RightSidebar/EfileSignatureControlPanel';
import * as TaxDocument from '../../../common/TaxReturn';
import * as Helper from '../../../helper/HelperFunctions';
import { getFileExtension } from '../../../helper/Validations';
import { IUserProfile } from '../../../navigation/profile/ProfileObjects';
import { DocumentUploadData, EsignControlHelper, IAdditionalESignDocumentControl,
    IAdditionalESignDocumentPage, IDocumentData, initialControl, IOptions, ICheckBoxControl, IRadioButtonControl,
    ChoosableControlData } from '../AdditionalEsignDocuments/AdditionalEsignDocument';
import { EditAdditionalEsignDocument } from '../AdditionalEsignDocuments/EditAdditionalEsignDocument';
import { AwesomePdfViewerUrl } from '../AwesomePdfViewerUrl';
import { INVALID_PAGE } from '../CustomPagination/CustomPagination';
import {
    PdfPageSignatureControls
} from '../ProcessReturnModels';
import { IEfileSignControlBoxItem } from '../RightSidebar/EfileSignControl';
import { EROSignatureCard } from '../RightSidebar/EROSignaturePanel';
import { AdditionalEsignDocuments, EsignHelpTextConstant } from './../../../helper/Constants';
import { dragStartSignatureControl , getControlText, getControlTopPositionPDFJS, getTopAdjustmentSignatureControlValue  } from './../SignatureControls/SignatureHelper';
import { logger } from '../../../../routes/LoggedIn';
import { IBlobFile } from '../../../../store/common';
import { SignatureControlTypes } from '../../TaxReturn';
import { Guid } from '../../../../Core/Utilities/Guid';
import ControlBase ,{  PDF_VIEWER_BASE_HEIGHT }  from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { SignatureControlConstants } from './../../../helper/Constants';
import { PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { IAuthState } from '../../../../store/auth/reducer';

const Draggabilly: any = require('draggabilly/draggabilly');
const PDFJS: PDFJSStatic = require('pdfjs-dist');
const NO_INDEX = -1;

export interface IAdditionalEsignDocument {
    documentType: number;
    eSignDocuments: DocumentUploadData[];
}

export interface IAdditionalEsignDocProps {
    auth: IAuthState;
    getAddtionalEsignDocumentUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    getAddtionalEsignDocumentDownLoadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    convertDocToPdfArtifactAsync: (url: string, callback?: (data?: IBlobFile) => void) => void;
    taxReturn: TaxDocument.ITaxReturn;
    deleteUploadedAdditionalDocumentBlobFile: (documentGuid: string, fileName: string, taxYear: number, callback?: () => void) => void;
    deleteAdditionalDocument: (documentId: number, fileName: string, callback?: () => void) => void;
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    userSettings: UserSettingStore.UserSettings;
    isEsign: boolean;
    enableEsignatureForBusinessReturns: boolean;
    company: CompanyStore.ICompanyData;
    userProfile: IUserProfile;
    signatureFormSelectionType: SignatureFormSelectionType;
    userSignatures: UserSignatureStore.IUserSignatures;
    updateEROSignatureStamp(userId: number): void;
    isEnableInvoice: boolean;
    renderTrigger: string;
    tabType?: number;
    isAssignedToLoggedinUser: boolean;
    reGroupPreview: (pages: number[], destinationGroup: TaxDocument.DocumentGroups) => void;
    addAdditionalEsignDocumentData: (uploadedDocumentData: DocumentUploadData[]) => void;
    uploadedAdditionalEsignDocumentData: DocumentUploadData[];
    updateAdditionalEsignDocumentData: (uploadedDocumentData: DocumentUploadData) => void;
    requestAdditionalDocumentType: (reload: boolean) => void;
    additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
}

export interface IAdditionalEsignDocState {
    showUploadModal: boolean;
    showEditDocumentModal: boolean;
    uploadedDocumentData: DocumentUploadData[];
    selectedESignDocument: DocumentUploadData;
    isBusy: boolean;
    currentPage: number;
    zoomEnabled: boolean;
    focusedGroup: TaxDocument.SignatureGroupType;
    signer: ISigner[];
    selectedSigner: ISigner;
    eroSigners: TaxDocument.IEROSigner[];
    selectedEROSigner: TaxDocument.IEROSigner;
    currentDocumentType: number;
    selectedEditFileName: string;
    toolTipControl: IAdditionalESignDocumentControl;
    showToolTipPopup: boolean;
    additionalDocuments: IAdditionalEsignDocument[];
    scale: number;
    content: {
        header: string,
        title: string,
        tooltipContent: string
    },
    signatureControlType: number
}

export class TabAdditionalEsignDocuments extends React.Component<IAdditionalEsignDocProps, any>{
    private _pdfViewer: any;
    constructor(props: IAdditionalEsignDocProps) {
        super(props);
        
        this.state = {
            showUploadModal: false,
            showEditDocumentModal: false,
            uploadedDocumentData: [],
            selectedESignDocument: {} as DocumentUploadData,
            isBusy: false,
            currentPage: INVALID_PAGE,
            zoomEnabled: false,
            focusedGroup: TaxDocument.SignatureGroupType.None,
            signer: [],
            selectedSigner: {
                value: 0,
                label: "",
                signatureRole: TaxDocument.SignatureControlRole.None
            },
            eroSigners: [],
            selectedEROSigner: {
                value: 0,
                label: "",
                isEnableSignatureDelegation: true,
                eroImage: ""
            },
            additionalDocuments: [],
            currentDocumentType: 0,
            selectedEditFileName: "",
            toolTipControl: initialControl,
            showToolTipPopup: false,
            documentTypes: [],
            isBookmarksLoaded: false,
            scale: PdfProperties.DefaultScale,
            content: {
                header: "",
                title: "",
                tooltipContent: ""
            },
            signatureControlType: 0
        }
    }

    UNSAFE_componentWillMount() {
        this.props.requestAdditionalDocumentType(true);
        this.setDropdownValues();
    }

    componentDidMount() {
        if (this.props.additionalDocumentTypeData != undefined || this.props.additionalDocumentTypeData != null) {
            this.setState({
                documentTypes: this.prepareDocumentTypeList(this.props.additionalDocumentTypeData)
            });
        }
        if (this.props.uploadedAdditionalEsignDocumentData && this.props.uploadedAdditionalEsignDocumentData.length < 1) {
            const filterDeletedAdditionalEsignDocs: IDocumentData[] = this.props.taxReturn.additionalEsignDocuments.filter((item: IDocumentData) => item.isDeleted === false);
            this.loadSavedAdditionalEsignDocuments(filterDeletedAdditionalEsignDocs);
        }
        if (this.props.uploadedAdditionalEsignDocumentData && this.props.uploadedAdditionalEsignDocumentData.length > 0) {
            this.setState({
                uploadedDocumentData: this.props.uploadedAdditionalEsignDocumentData,
                selectedESignDocument: this.props.uploadedAdditionalEsignDocumentData[0],
                currentPage: 1,
                additionalDocuments: this.prepareEsignDocumentsData(this.props.uploadedAdditionalEsignDocumentData)
            });
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps: IAdditionalEsignDocProps) {
        let selectedPage: number = this.state.currentPage;
        if (this.props.userSignatures !== nextProps.userSignatures) {
            this.setEroSignerDropdownValues(nextProps);
        }

        if (this.props.additionalDocumentTypeData !== nextProps.additionalDocumentTypeData) {
            this.setState({
                documentTypes: this.prepareDocumentTypeList(nextProps.additionalDocumentTypeData)
            });
        }

        // load sasUrl and pdf 
        if (this.props.uploadedAdditionalEsignDocumentData.length != nextProps.uploadedAdditionalEsignDocumentData.length) {

            const selectedAdditionalDocument = nextProps.uploadedAdditionalEsignDocumentData.find(x => x.name == this.state.selectedESignDocument.name);

            this.setState({
                uploadedDocumentData: nextProps.uploadedAdditionalEsignDocumentData,
                selectedESignDocument: selectedAdditionalDocument ? selectedAdditionalDocument : nextProps.uploadedAdditionalEsignDocumentData[0],
                currentPage: selectedPage !== INVALID_PAGE ? selectedPage : 1,
                additionalDocuments: this.prepareEsignDocumentsData(nextProps.uploadedAdditionalEsignDocumentData)
            }, () => {
                if (this.state.selectedESignDocument &&
                    this.state.selectedESignDocument.pdf === undefined &&
                    this.state.selectedESignDocument.sasUrl == "") {
                    this.getUploadedAdditionalDocumentSasUrl(nextProps.taxReturn.documentGuid,
                        nextProps.taxReturn.taxYear, this.state.selectedESignDocument.name);
                }
            });
        }
        else if (nextProps.taxReturn.additionalEsignDocuments.filter(x => x.isModified).length > 0) {
            this.setState({
                additionalDocuments: this.prepareEsignDocumentsData(nextProps.uploadedAdditionalEsignDocumentData)
            });
        }
        else if (nextProps.taxReturn.additionalEsignDocuments.filter(x => x.isModified).length > 0) {
            this.setState({
                additionalDocuments: this.prepareEsignDocumentsData(nextProps.uploadedAdditionalEsignDocumentData)
            });
        }

        if (nextProps.uploadedAdditionalEsignDocumentData &&
            nextProps.uploadedAdditionalEsignDocumentData[0] &&
            nextProps.uploadedAdditionalEsignDocumentData[0].pdf == undefined &&
            nextProps.uploadedAdditionalEsignDocumentData[0].sasUrl != undefined &&
            nextProps.uploadedAdditionalEsignDocumentData[0].sasUrl != "" &&
            this.state.selectedESignDocument &&
            this.state.selectedESignDocument.pdf == undefined) {
            let taxReturn = Helper.cloneTaxReturn(nextProps.taxReturn);
            this.loadDocument(nextProps.uploadedAdditionalEsignDocumentData[0].sasUrl, taxReturn);
        }
    }

    private loadSavedAdditionalEsignDocuments = (savedAdditionalEsignDocuments: IDocumentData[]) => {
        let uploadedData: DocumentUploadData[] = [];
        savedAdditionalEsignDocuments.forEach((item, index) => {
            uploadedData.push({
                documentType: item.documentType,
                name: item.fileName,
                sasUrl: "",
                isPDFloaded: false,
                pdf: undefined,
                isDeleted: item.isDeleted
            } as DocumentUploadData);
        })
        this.props.addAdditionalEsignDocumentData(uploadedData);
    }

    private loadDocument = (sasUrl: string, taxReturn: TaxDocument.ITaxReturn) => {
        let selected: DocumentUploadData = this.state.selectedESignDocument;
        this.loadPdf(sasUrl)
            .then((data: IPdfDocumentFacade) => {
                selected.isPDFloaded = true;
                selected.pdf = data;
                selected.sasUrl = sasUrl;

                this.setState({ selectedESignDocument: selected, currentPage: 1 });
                this.props.updateAdditionalEsignDocumentData(selected);
                this.props.updateTaxDocument(taxReturn);
            })
    }

    private prepareDocumentTypeList = (additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState) => {
        let documentTypes: IOptions[] = [];
        if (additionalDocumentTypeData && additionalDocumentTypeData.documentType?.length) {
            additionalDocumentTypeData.documentType.forEach((item, index) => {
                documentTypes.push({
                    label: item.value as string, value: item.id as number
                });
            });
        }
        return documentTypes;
    }

    private setDocumentTypeName(documentTypeId: number): string {
        if (this.props.additionalDocumentTypeData && this.props.additionalDocumentTypeData.documentType.length > 0) {
            return this.props.additionalDocumentTypeData.documentType.filter((x) => { return x.id == documentTypeId })[0].value;
        }
        return "";
    }

    private onAddAdditionalDocument = () => {
        this.setState({ showUploadModal: true });
    }

    private onCloseModal = () => {
        this.setState({ showUploadModal: false });
    }

    private onSubmit = (newUploadedDocumentData: DocumentUploadData[]) => {
        let _self = this;
        let taxReturn = Helper.cloneTaxReturn(_self.props.taxReturn);
        if (taxReturn.additionalEsignDocuments === undefined) {
            taxReturn.additionalEsignDocuments = [];
        }

        newUploadedDocumentData.forEach((item, index) => {
            var documentData: IDocumentData = {} as IDocumentData;
            documentData.id = 0; // new additional document
            documentData.fileName = item.name != undefined ? item.name : "";
            documentData.uploadedBy = _self.props.userProfile?.userId || 0;
            documentData.uploadedOn = new Date;
            documentData.documentType = item.documentType != undefined ? item.documentType : 0;
            documentData.documentControls = [];
            documentData.isModified = true;
            taxReturn.additionalEsignDocuments.push(documentData);
        });

        let uploadedDocumentData: DocumentUploadData[] = _self.state.uploadedDocumentData;
        if (uploadedDocumentData && uploadedDocumentData.length > 0) {
            uploadedDocumentData = uploadedDocumentData.concat(newUploadedDocumentData);
        }
        else {
            uploadedDocumentData = newUploadedDocumentData;
        }

        _self.setState({
            selectedESignDocument: newUploadedDocumentData[0],
            uploadedDocumentData: uploadedDocumentData
        }, () => {
            _self.props.addAdditionalEsignDocumentData(uploadedDocumentData);
            _self.props.updateTaxDocument(taxReturn);
            _self.onCloseModal();
        });
    }

    getUploadedAdditionalDocumentSasUrl = (documentGuid: string, taxYear: number, fileName: string) => {
        let param = '?documentGuid=' + documentGuid +
            '&fileName=' + escape(fileName) +
            '&taxYear=' + taxYear;
        this.props.getAddtionalEsignDocumentDownLoadLink('/api/Download/GetAdditionalEsignDocumentLinkAsync' + param,
            (data?: IBlobFile) => {
                try {
                    if (data) {
                        this.loadDocument(data ? data.sas : "", this.props.taxReturn);
                    }
                } catch (error) {
                    this.setState({ uploadedDocumentData: this.state.uploadedDocumentData });
                }
            });
    }

    private prepareEsignDocumentsData = (addedEsignDocuments: DocumentUploadData[]) => {
        let documentsData: DocumentUploadData[] = addedEsignDocuments;
        documentsData = documentsData.filter((item, index) => item.isDeleted == false);
        let result: any = [];
        if (documentsData && documentsData.length > 0) {
            result = Enumerable.from(documentsData).where(x => x != undefined)
                .groupBy(x => x.documentType)
                .select(groupedItem => {
                    return {
                        "documentType": groupedItem.first().documentType,
                        "documentTypeName": this.setDocumentTypeName(groupedItem.first().documentType),
                        "eSignDocuments": groupedItem.getSource()
                    }
                }).orderBy(y => y.documentTypeName).toArray();
        }
        return result;
    }

    onDocumentClick = (fileName: string) => {
        let selected: DocumentUploadData = this.state.uploadedDocumentData.find((x: DocumentUploadData) => x.name === fileName);
        this.setState({
            selectedESignDocument: selected,
            currentPage: 1
        }, () => {
            if (this.state.selectedESignDocument && !this.state.selectedESignDocument.isPDFloaded) {
                if (this.state.selectedESignDocument.sasUrl == "") {
                    this.getUploadedAdditionalDocumentSasUrl(this.props.taxReturn.documentGuid,
                        this.props.taxReturn.taxYear, this.state.selectedESignDocument.name);
                }
                else {
                    let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
                    this.loadDocument(this.state.selectedESignDocument.sasUrl, taxReturn);
                }
            }
        });
    }

    onDocumentEditClick = (fileName: string, documentType: number) => {
        this.setState({ showEditDocumentModal: true, currentDocumentType: documentType, selectedEditFileName: fileName });
    }

    onPageClick = (pageNo: number) => {
        this.setState({ currentPage: pageNo });
    }

    private loadPdf = async (sasUrl: string) => {
        return await PDFJS.getDocument({ url: sasUrl })
            .then((pdf: PDFDocumentProxy) => {
                let docFacade = new PdfDocumentFacade();
                docFacade.append(pdf, sasUrl);
                return docFacade;
            }, (error: string) => {

            });
    }

    private onCloseEditDocumentModal = () => {
        this.setState({ showEditDocumentModal: false });
    }

    private onDeleteDocument = (documentType: number) => {
        bootbox.confirm({
            message: AdditionalEsignDocuments.deleteConfirmMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    let uploadedDocumentData: DocumentUploadData[] = this.state.uploadedDocumentData;
                    uploadedDocumentData = uploadedDocumentData.filter(x => x.name != this.state.selectedEditFileName);

                    let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
                    taxReturn.additionalEsignDocuments.map((item, index) => {
                        if (item.fileName == this.state.selectedEditFileName) {
                            item.isDeleted = true;
                            item.isModified = true;
                        }
                        return item;
                    });

                    taxReturn.additionalEsignDocuments = taxReturn.additionalEsignDocuments.filter((item, index) => {
                        if (item.id == 0 && item.isDeleted) { }
                        else {
                            return item
                        }
                    })
                    this.props.addAdditionalEsignDocumentData(uploadedDocumentData);
                    this.props.updateTaxDocument(taxReturn);

                    this.onCloseEditDocumentModal();
                }
            }
        });
    }

    private onSaveEditDocumentChanges = (documentType: number) => {
        let uploadedDocumentData: DocumentUploadData[] = this.state.uploadedDocumentData;
        uploadedDocumentData.map((item, index) => {
            if (item.name == this.state.selectedEditFileName) {
                item.documentType = documentType;
                this.props.updateAdditionalEsignDocumentData(item);
            }
        });
        this.setState({
            showEditDocumentModal: false, uploadedDocumentData: uploadedDocumentData, additionalDocuments: this.prepareEsignDocumentsData(uploadedDocumentData)
        });
    }

    private getDocumentControls = (): PdfPageSignatureControls => {
        let documentControls: IAdditionalESignDocumentControl[] = [];
        let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
        signatureType = (typeof signatureType == "number") ? TaxDocument.SignatureType[signatureType] : signatureType;
        documentControls = this.filterControls(signatureType);

        return {
            signatureControls: documentControls,
            eroSigner: { id: this.state.selectedEROSigner.value, signPath: this.state.selectedEROSigner.eroImage },
            signer: { id: this.state.selectedSigner ? this.state.selectedSigner.value : 0, role: this.state.selectedSigner ? this.state.selectedSigner.signatureRole : TaxDocument.SignatureControlRole.None },
            focusedGroup: this.state.focusedGroup
        } as PdfPageSignatureControls;
    }

    private getEroSignatureControls = (): PdfPageSignatureControls => {
        let documentControls: IAdditionalESignDocumentControl[] = [];
        let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
        signatureType = (typeof signatureType == "number") ? TaxDocument.SignatureType[signatureType] : signatureType;
        documentControls = this.filterControls(signatureType);
        return {
            signatureControls: documentControls,
            eroSigner: { id: this.state.selectedEROSigner.value, signPath: this.state.selectedEROSigner.eroImage },
            signer: { id: this.state.selectedSigner ? this.state.selectedSigner.value : 0, role: this.state.selectedSigner ? this.state.selectedSigner.signatureRole : TaxDocument.SignatureControlRole.None },
            focusedGroup: this.state.focusedGroup
        } as PdfPageSignatureControls;
    }

    private filterControls = (signatureType: any): IAdditionalESignDocumentControl[] => {
        let documentControls: IAdditionalESignDocumentControl[] = [];
        let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
        const documentIndex = taxReturn.additionalEsignDocuments.findIndex(x => x.fileName === this.state.selectedESignDocument.name);
        if (documentIndex !== Helper.NO_INDEX) {
            const pageIndex = taxReturn.additionalEsignDocuments[documentIndex].documentControls.findIndex(x => x.pageNo === this.state.currentPage);
            if (pageIndex !== Helper.NO_INDEX) {
                documentControls = taxReturn.additionalEsignDocuments[documentIndex].documentControls[pageIndex].controls;
                if (documentControls) {
                    if (signatureType === TaxDocument.SignatureType[TaxDocument.SignatureType.ManualSign] && this.props.userSettings.settings.useSignatureStamp) {
                        documentControls = documentControls.filter(control => control.signatureControlRole === TaxDocument.SignatureControlRole.ERO);
                    }
                    else if (signatureType === TaxDocument.SignatureType[TaxDocument.SignatureType.ManualSign] && !this.props.userSettings.settings.useSignatureStamp) {
                        documentControls = [];
                    }
                    else {
                        if (!this.props.userSettings.settings.useSignatureStamp) {
                            documentControls = documentControls.filter(control => control.signatureControlRole !== TaxDocument.SignatureControlRole.ERO);
                        }
                    }
                }
            }
        }
        return documentControls;
    }

    private addSignatureControl = (type: TaxDocument.SignatureControlTypes, role: TaxDocument.SignatureControlRole, left: number, top: number) => {
        try {
            logger.trackTrace(`TabAdditionalEsignDocuments --> addSignatureControl started for document: ${this.props.taxReturn.documentGuid} , SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
            let _self = this;

            let control = EsignControlHelper.getControlModal(type, role, left, top);
            control = this.adjustSamePositionControl(control);

            let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
            const documentIndex = taxReturn.additionalEsignDocuments.findIndex(x => x.fileName === _self.state.selectedESignDocument.name);
            if (documentIndex !== Helper.NO_INDEX) {
                taxReturn.additionalEsignDocuments[documentIndex].isModified = true;
                const pageIndex = taxReturn.additionalEsignDocuments[documentIndex].documentControls.findIndex(x => x.pageNo === _self.state.currentPage);
                if (pageIndex === Helper.NO_INDEX) {
                    let documentControls = { pageNo: this.state.currentPage, controls: [control] } as IAdditionalESignDocumentPage;
                    taxReturn.additionalEsignDocuments[documentIndex].documentControls.push(documentControls);
                }
                else {
                    taxReturn.additionalEsignDocuments[documentIndex].documentControls[pageIndex].controls.push(control);
                }

                this.props.updateTaxDocument(taxReturn);

                const { Text, CheckBox, RadioButton } = TaxDocument.SignatureControlTypes;

                if ([Text].includes(control.type)) {
                    this.showControlPropertyPopup(control);
                }
                else if ([CheckBox, RadioButton].includes(control.type)) {
                    this.setControlDefaultTooltipValue(control);
                    this.onSaveToolTip(control, false);
                    this.showControlPropertyPopup(control);
                }
            }
            logger.trackTrace(`TabAdditionalEsignDocuments --> addSignatureControl ended for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
        } catch (error) {
            logger.trackWarning(`TabAdditionalEsignDocuments --> SignatureControlDragandDropError: addSignatureControl throw error for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}, error : ${error}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
        }
    }

    private setControlDefaultTooltipValue = (control: IAdditionalESignDocumentControl) => {
        const { CheckBox, RadioButton } = TaxDocument.SignatureControlTypes;
        if (control.type === CheckBox) {
            control.tooltip = "1";
        } else if (control.type === RadioButton) {
            control.tooltip = "2";
        } 
    }

    private getTooltipContent = (controlType: number):any => {
        const { CheckBox, RadioButton } = TaxDocument.SignatureControlTypes;
        if (controlType === CheckBox) {
            return { header: "Checkbox properties", title: "How many checkbox(es) would you like to add?", tooltipContent: "Mention the count of checkbox(es) to be added. All checkboxes will be selectable by Taxpayer." };
        } else if (controlType === RadioButton) {
            return { header: "Radio button properties", title: "How many radio button(s) would you like to add?", tooltipContent: "Mention the count of radio button(s) to be added. For one set Taxpayer can select only one option." }
        } else {
            return { header: "Tool Tip", title: "Add Text", tooltipContent: "This text will help the signer to enter the necessary details" } ;
        }
    }

    private updateControlData =(control: IAdditionalESignDocumentControl)=>{
        let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
        const documentIndex = taxReturn.additionalEsignDocuments.findIndex(x => x.fileName === this.state.selectedESignDocument.name);
        if (documentIndex !== Helper.NO_INDEX) {
            taxReturn.additionalEsignDocuments[documentIndex].isModified = true;
            const pageIndex = taxReturn.additionalEsignDocuments[documentIndex].documentControls.findIndex(x => x.pageNo === this.state.currentPage);
            if (pageIndex !== Helper.NO_INDEX) {
                let documentControls = taxReturn.additionalEsignDocuments[documentIndex].documentControls[pageIndex].controls;
                for (let i = 0; i < documentControls.length; i++) {
                    let documentControl = documentControls[i];
                    if (documentControl.controlGuid === control.controlGuid) {
                        documentControl.left = control.left;
                        documentControl.top = control.top;
                        if(control.type==SignatureControlTypes.RadioButton){
                            let radioButtonControl= control as IRadioButtonControl;
                            documentControl=radioButtonControl;
                            documentControl.left = control.left;
                            documentControl.top = control.top;
                        }
                        else if(control.type==SignatureControlTypes.CheckBox){
                            let checkBoxControl= documentControl as ICheckBoxControl;
                            documentControl=checkBoxControl;
                            documentControl.left = control.left;
                            documentControl.top = control.top;
                        }
                    }
                }
                this.props.updateTaxDocument(taxReturn);
            }
        }
    }

    private onControlLoad=(control: any,pageElement:any)=>{
         //For Radio button and checkbox functionality 
        if(control.draggable===false){
            this.applyControlStyles(control);
            this.makeDraggableControl(control,pageElement,this.handleControlDragEnd);
            this.registerResizeEvent(control,this.handleResize);
        }
    }

    private applyControlStyles(control: any) {
        const controlElement = document.getElementById(control.id);
        controlElement.removeAttribute('class');
        controlElement.classList.add('choosable-control-group');
        controlElement.classList.add('resizable-both');
        const selectedSignerStyle= controlElement.children[0]?.getAttribute('data-signerstyle');
        controlElement.classList.add(selectedSignerStyle?selectedSignerStyle:'choosable-signature-control-border');
    }

    private registerResizeEvent(control: any,onResize: any): any {
        const element = document.getElementById(control.id);
        if (element) {
            element.onmouseup = function (event: any) { 
                event=event;       
                onResize(control.id.replace(SignatureControlConstants.ControlIdPrefix,''),
                element.offsetHeight, element.offsetWidth);
            };
        }
    }

    private makeDraggableControl=(control: any,pageElement:any, dragEndCallBack: any)=>{
        const draggie = new Draggabilly('#' + control.id, {
            containment: pageElement,
            handle: '.draggable-btn-control'
            });

        draggie.off('dragEnd', '#' + control.id);
        draggie.on('dragEnd', function (event: any, pointer: any) {
            event = event;
            pointer = pointer;
            const controlElement = document.getElementById(control.id);
            controlElement && 
            dragEndCallBack(control.id.replace(SignatureControlConstants.ControlIdPrefix,''),
            controlElement.offsetTop, controlElement.offsetLeft)
        });
        
        this.setDraggableControlItems(this.handleControlItemsDragEnd);
    }

    private setDraggableControlItems(dragEndCallBack: any, handle?: string): any {
       
        const elements: any = document.querySelectorAll('.choosable-control-item');
        
        for (var i = 0; i <= elements.length - 1; i++) {
    
          const element: any = elements[i];

          const draggable = element?.attributes["data-draggable"]?.value;

          if(!draggable) {
            element.setAttribute("data-draggable", "true");
            const draggie = new Draggabilly(element, {
                containment: element.parentElement,
                handle: handle
            });
      
            draggie.off('dragEnd', element);
            draggie.on('dragEnd', function (event: any, pointer: any) {
                event = event;
                pointer = pointer;
        
                if(element){
                    const id:string = element.attributes["data-id"].value;
                    const controlGuid = element.attributes["data-controlguid"].value;
                    
                    dragEndCallBack(id,
                        controlGuid.replace(SignatureControlConstants.ControlIdPrefix,''), 
                        event.target.parentElement.offsetTop, event.target.parentElement.offsetLeft)
                    }
                });
            }
        };
    }

    public handleResize = (controlGuid:string, height: number, width: number) => {
        let controls=this.getDocumentControls();
        const index=controls.signatureControls.findIndex(x=> x.controlGuid===controlGuid);
        if(index!=-1){
            let control=controls.signatureControls[index] as IRadioButtonControl|ICheckBoxControl;
            control.height = height;
            control.width = width;
        }
    }

    private handleControlDragEnd= (controlGuid:string,top: number, left: number) => {
        let controls=this.getDocumentControls();
        const index=controls.signatureControls.findIndex(x=> x.controlGuid===controlGuid);
        if(index!=-1){
            const controltop =top / this.state.scale;
            const controlLeft= left / this.state.scale;
            const pageSize: PageSize = this._pdfViewer.getCurrentPageSize();
            controls.signatureControls[index].left  = ControlBase.getBackendControlLeftPosition(controlLeft);
            controls.signatureControls[index].top = ControlBase.getBackendControlTopPosition(pageSize.height, 
                controltop + getTopAdjustmentSignatureControlValue(controls.signatureControls[index].type));
            let control=controls.signatureControls[index] as IRadioButtonControl|ICheckBoxControl;
            this.updateControlData(control);
        }
    }

    private handleControlItemsDragEnd = (id:string,controlGuid:string,top: number, left: number) => {
       let controls=this.getDocumentControls();
       const index=controls.signatureControls.findIndex(x=> x.controlGuid===controlGuid);
       if(index!=-1){
           let control=controls.signatureControls[index] as IRadioButtonControl|ICheckBoxControl;
           if(control){
              let itemIndex=control.items.findIndex(x=> x.id==id);
              if(itemIndex!=-1){
                control.items[itemIndex].left=left;
                control.items[itemIndex].top=top;
                this.updateControlData(control);
             }
           }
       }
    }

    private adjustSamePositionControl = (control: IAdditionalESignDocumentControl): IAdditionalESignDocumentControl => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
        const documentIndex = taxReturn.additionalEsignDocuments.findIndex(x => x.fileName === this.state.selectedESignDocument.name);
        if (documentIndex !== Helper.NO_INDEX) {
            const pageIndex = taxReturn.additionalEsignDocuments[documentIndex].documentControls.findIndex(x => x.pageNo === this.state.currentPage);
            if (pageIndex !== Helper.NO_INDEX) {
                let documentControls = taxReturn.additionalEsignDocuments[documentIndex].documentControls[pageIndex].controls;
                const index = documentControls.findIndex(x => x.controlGuid === control.controlGuid);
                if (index !== NO_INDEX) {
                    control.left = control.left + 5;
                    control.top = control.top + 5;
                    return this.adjustSamePositionControl(control);
                }
                else {
                    return control;
                }
            }
        }
        return control;
    }

    private dragStartSignatureControl = (event: any, controlType: TaxDocument.SignatureControlTypes, signatureRole: TaxDocument.SignatureControlRole) => {
        try {
            logger.trackTrace(`TabAdditionalEsignDocuments --> dragStartSignatureControl started: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
            const pageSize: PageSize = this._pdfViewer.getCurrentPageSize();
            dragStartSignatureControl(
            event, controlType, signatureRole,
            this.state.selectedEROSigner.label,
            this.state.currentPage,
            this.state.scale,
            this.addSignatureControl,
            pageSize.height);
        } catch (error) {
            logger.trackWarning(`TabAdditionalEsignDocuments --> SignatureControlDragandDropError: dragStartSignatureControl throw error: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}, error: ${error}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
        }
    }


    private dragEnd = (event: any) => {
        try {
            logger.trackTrace(`TabAdditionalEsignDocuments --> dragEnd started for document: ${this.props.taxReturn.documentGuid}`, { "DocumentId": this.props.taxReturn.id, "DocumentGuid": this.props.taxReturn.documentGuid });
            let element = document.getElementById("dragging-element");
            if (element) {
                element.remove();
            }
            logger.trackTrace(`TabAdditionalEsignDocuments --> dragEnd ended for document: ${this.props.taxReturn.documentGuid}`, { "DocumentId": this.props.taxReturn.id, "DocumentGuid": this.props.taxReturn.documentGuid });
        } catch (error) {
            logger.trackWarning(`TabAdditionalEsignDocuments --> SignatureControlDragandDropError: dragEnd throw error for document: ${this.props.taxReturn.documentGuid}, error : ${error}`, { "DocumentId": this.props.taxReturn.id, "DocumentGuid": this.props.taxReturn.documentGuid });
        }
    }

    private onControlUpdate = (oldControl: IAdditionalESignDocumentControl, newControl: IAdditionalESignDocumentControl) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
        const documentIndex = taxReturn.additionalEsignDocuments.findIndex(x => x.fileName === this.state.selectedESignDocument.name);
        if (documentIndex !== Helper.NO_INDEX) {
            taxReturn.additionalEsignDocuments[documentIndex].isModified = true;
            const pageIndex = taxReturn.additionalEsignDocuments[documentIndex].documentControls.findIndex(x => x.pageNo === this.state.currentPage);
            if (pageIndex !== Helper.NO_INDEX) {
                let documentControls = taxReturn.additionalEsignDocuments[documentIndex].documentControls[pageIndex].controls;
                for (let i = 0; i < documentControls.length; i++) {
                    let documentControl = documentControls[i];
                    if (documentControl.controlGuid === oldControl.controlGuid) {
                        documentControl.left = newControl.left;
                        documentControl.top = newControl.top;
                        documentControl.signatureControlRole = newControl.signatureControlRole;
                        documentControl.type = newControl.type;
                        break;
                    }
                }
                this.props.updateTaxDocument(taxReturn);
            }
        }
    }
    private onControlRemove = (control: IAdditionalESignDocumentControl) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
        const documentIndex = taxReturn.additionalEsignDocuments.findIndex(x => x.fileName === this.state.selectedESignDocument.name);
        if (documentIndex !== Helper.NO_INDEX) {
            taxReturn.additionalEsignDocuments[documentIndex].isModified = true;
            const pageIndex = taxReturn.additionalEsignDocuments[documentIndex].documentControls.findIndex(x => x.pageNo === this.state.currentPage);
            if (pageIndex !== Helper.NO_INDEX) {
                taxReturn.additionalEsignDocuments[documentIndex].documentControls[pageIndex].controls =
                    taxReturn.additionalEsignDocuments[documentIndex].documentControls[pageIndex].controls.filter(x => x.controlGuid != control.controlGuid);
                this.props.updateTaxDocument(taxReturn);
                this.forceUpdate();
            }
        }
    }

    private showControlPropertyPopup=(control: IAdditionalESignDocumentControl)=>{
        this.setState({
            toolTipControl: control,
            showToolTipPopup: true,
            signatureControlType: control.type,
            content: this.getTooltipContent(control.type)
        });
    }

    private setDropdownValues = () => {
        var signer = [];
        let selectedSigner = { ...this.state.selectedSigner };
        if (TaxDocument.isMutual(this.props.taxReturn)) {
            if (this.props.taxReturn.taxpayer && this.props.taxReturn.spouse) {
                signer.push({
                    value: this.props.taxReturn.taxpayer.id, label: this.props.taxReturn.taxpayer.name, signatureRole: TaxDocument.SignatureControlRole.Taxpayer
                    , disabled: this.props.taxReturn.taxpayer.isDeceased
                });
                signer.push({
                    value: this.props.taxReturn.spouse.id, label: this.props.taxReturn.spouse.name, signatureRole: TaxDocument.SignatureControlRole.Spouse
                    , disabled: this.props.taxReturn.spouse.isDeceased
                });
                selectedSigner = signer[0].disabled ? signer[1] : signer[0]
            }
        }
        else if (TaxDocument.isIndividual(this.props.taxReturn)) {
            this.props.taxReturn.taxpayer &&
                signer.push({
                    value: this.props.taxReturn.taxpayer.id, label: this.props.taxReturn.taxpayer.name, signatureRole: TaxDocument.SignatureControlRole.Taxpayer
                    , disabled: this.props.taxReturn.taxpayer.isDeceased
                });
            selectedSigner = signer[0]
        }
        else {
            signer.push({ value: this.props.taxReturn.partnerId, label: "Signer", signatureRole: TaxDocument.SignatureControlRole.PartnerShip });
        }

        this.setEroSignerDropdownValues(this.props);
        const focusedGroup = signer.length > 0 ? TaxDocument.SignatureGroupType.Esign :
            (this.state.eroSigners.length > 0 ? TaxDocument.SignatureGroupType.Stamp : TaxDocument.SignatureGroupType.None)

        this.setState({
            signer: signer,
            selectedSigner: selectedSigner.value > 0 ? selectedSigner : signer[0],
            focusedGroup: focusedGroup
        });
    }

    private signerChange = (selectedOption: ISigner) => {
        if (selectedOption) {
            this.setState({
                selectedSigner: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    signatureRole: selectedOption.signatureRole
                }
            })
        }
    }

    private setEroSignerDropdownValues = (nextprops: IAdditionalEsignDocProps) => {
        let eroSigners: TaxDocument.IEROSigner[] = [];
        if (this.props.company != undefined) {
            let companyAsSigner = {
                label: this.props.company.companyProfile.companyInfo.companyName,
                value: 0,
                isEnableSignatureDelegation: true,
                eroImage: this.props.company.signatureUploadLink,
            }
            this.addEroSigner(eroSigners, companyAsSigner);
        }

        if (this.props.auth != undefined
            && this.props.auth.user.profile.role.split(',').includes('Partner')) {
            const currentUser = {
                label: this.props.auth.user.profile.name,
                value: this.props.auth.user.profile.user_id as number,
                isEnableSignatureDelegation: true,
                eroImage: this.props.userSettings.settings.signatureSettings.signaturePath || "",
            }
            this.addEroSigner(eroSigners, currentUser);
        }

        if (this.props.taxReturn.partner != undefined) {
            const partner = this.props.taxReturn.partner;
            const partnerEro = {
                label: partner.firstName + " " + partner.lastName,
                value: partner.userId,
                isEnableSignatureDelegation: true,
                eroImage: this.getEroSignerPath(partner.userId, nextprops)
            }
            this.addEroSigner(eroSigners, partnerEro);
        }

        if (this.props.userSettings.delegatedSigners.length > 0) {
            this.props.userSettings.delegatedSigners.map(eroSigner => {
                let signer = {
                    label: eroSigner.firstName + " " + eroSigner.lastName,
                    value: eroSigner.userId,
                    isEnableSignatureDelegation: eroSigner.isEnableSignatureDelegation,
                    eroImage: eroSigner.signaturePath,
                }
                this.addEroSigner(eroSigners, signer);
            });
        }

        const selectedEROId = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser ?
            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser?.userId
            : this.state.selectedEROSigner.value;
        let selectedEROSigner = eroSigners.find(x => x.value == selectedEROId) as TaxDocument.IEROSigner;
        selectedEROSigner = selectedEROSigner ? selectedEROSigner :
            eroSigners.find(x => x.value === 0) as TaxDocument.IEROSigner;

        this.setState({
            eroSigners: eroSigners,
            selectedEROSigner: selectedEROSigner,
        });
    }

    private addEroSigner = (eroSignersArray: TaxDocument.IEROSigner[], eroSigner: TaxDocument.IEROSigner) => {
        if (eroSignersArray.filter(e => e.value == eroSigner.value).length === 0) {
            eroSignersArray.push(eroSigner);
        }
    }

    private getEroSignerPath = (userId: number, nextprops: IAdditionalEsignDocProps) => {
        let eroImagePath = "";
        if (nextprops.userSignatures[userId] && nextprops.userSignatures[userId].signatureDownloadPath) {
            eroImagePath = nextprops.userSignatures[userId].signatureDownloadPath;
        }
        return eroImagePath;
    }

    private handleEROSignerChange = (selectedOption: TaxDocument.IEROSigner) => {
        if (selectedOption && selectedOption.eroImage !== "") {
            this.setState({
                selectedEROSigner: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    isEnableSignatureDelegation: selectedOption.isEnableSignatureDelegation,
                    eroImage: selectedOption.eroImage
                }
            }, () => {
                this.props.updateEROSignatureStamp(selectedOption.value);
            })
        }
    }

    private onCancelToolTipPopup = () => {
        this.setState({
            toolTipControl: initialControl,
            showToolTipPopup: false,
            signatureControlType: 0
        });
    }

    private onSaveToolTip = (control: IAdditionalESignDocumentControl,updateState:boolean=true) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
        const documentIndex = taxReturn.additionalEsignDocuments.findIndex(x => x.fileName === this.state.selectedESignDocument.name);
        if (documentIndex !== Helper.NO_INDEX) {
            taxReturn.additionalEsignDocuments[documentIndex].isModified = true;
            const pageIndex = taxReturn.additionalEsignDocuments[documentIndex].documentControls.findIndex(x => x.pageNo === this.state.currentPage);
            if (pageIndex !== Helper.NO_INDEX) {
                let documentControls = taxReturn.additionalEsignDocuments[documentIndex].documentControls[pageIndex].controls;
                for (let i = 0; i < documentControls.length; i++) {
                    let documentControl = documentControls[i];
                    if (documentControl.controlGuid === control.controlGuid) {
                        if(control.type==SignatureControlTypes.RadioButton){
                            let radioButtonControl= documentControl as IRadioButtonControl;
                            if(radioButtonControl.items.length!=Number(control.tooltip)){
                                radioButtonControl.items=this.addControlItems(control.tooltip);
                            }
                            radioButtonControl.required = control.required;
                            radioButtonControl.tooltip = control.tooltip;
                            documentControl=radioButtonControl;
                            break;
                          }
                          else if(control.type==SignatureControlTypes.CheckBox){
                            let checkBoxControl= documentControl as ICheckBoxControl;
                            if(checkBoxControl.items.length!=Number(control.tooltip)){
                                checkBoxControl.items=this.addControlItems(control.tooltip);
                            }
                            checkBoxControl.required = control.required;
                            checkBoxControl.tooltip = control.tooltip;
                            documentControl=checkBoxControl;
                            break;
                          }
                          else{
                            documentControl.tooltip = control.tooltip;
                            documentControl.required = control.required;
                            break;
                          }
                    }
                }
                this.props.updateTaxDocument(taxReturn);
            }
        }
        if(updateState){
            this.setState({
                toolTipControl: initialControl,
                showToolTipPopup: false,
                signatureControlType: 0
            });
        }
    }

    private addControlItems=(noofcontrols:any)=>{
        let items: any[] = [];
        let controlLeft=20;
        const name=Guid.newGuid().toString();
        if (noofcontrols) {
            for (let i = 0; i < Number(noofcontrols); i++) {
                let data=ChoosableControlData.createNullObject();
                data.left=(controlLeft*(i+1));
                data.top=2;
                data.id=Guid.newGuid().toString();
                data.name=name;
                items.push(data);
            }
        }
        return items;
    }

    private onSignatureControlFocus = (focusedGroup: TaxDocument.SignatureGroupType) => {
        this.setState({
            focusedGroup: focusedGroup
        });
    }

    private isDocumentTypeNameSet = (additionalDocuments: any[]) => {
        let isNameSet: boolean = false;
        let temp: any = [];
        additionalDocuments && additionalDocuments.map((item: any, index: number) => {
            temp.push(item);
            if (item.documentTypeName != null && item.documentTypeName != "") {
                isNameSet = true;
                return;
            }
        });
        return temp.length === 0 ? true : (temp.length > 0 && isNameSet);
    }

    createLeftPanel = () => {
        var _self = this;
        return (<BookmarkTree
            sectionTitle="Added Documents"
            bookMarksLoading={this.isDocumentTypeNameSet(this.state.additionalDocuments) ? false : true}
            headerContent={
                <button
                    id="btn-add"
                    title="Add Document"
                    onClick={() => { this.onAddAdditionalDocument(); }}
                    data-test-auto="DBF1C6CF-B54F-4BE1-A864-A240FEE8F5AB"
                    className="btn-plus btn-success fa fa-plus">
                </button>
            }
            bodyContent={
                this.state.additionalDocuments && this.state.additionalDocuments.map(function (item: any, index: number) {
                    return <Accordian key={item.documentType}
                        uniqueId={item.documentType}
                        header={item.documentTypeName}
                        content={
                            <ul style={{ marginBottom: 0 }}>
                                {
                                    item && item.eSignDocuments && item.eSignDocuments.map(function (value: DocumentUploadData, index: number) {
                                        return <li className="custom-list-group-item" style={{ marginRight: 0, paddingRight: 0 }}>
                                            <Row style={{ padding: 0 }}>
                                                <Col sm={9} className="bookmark-name wordbreak ellipsis cursor-pointer"
                                                    title={(value.name).replace("." + getFileExtension(value.name), '')}
                                                    style={{ display: "block", paddingRight: 0, marginRight: 0 }}
                                                    onClick={() => { _self.onDocumentClick(value.name) }}>
                                                    {(value.name).replace("." + getFileExtension(value.name), '')}
                                                </Col>
                                                <Col sm={3} className="cursor-pointer fas fa-edit"
                                                    style={{ float: "right" }}
                                                    onClick={() => { _self.onDocumentEditClick(value.name, value.documentType) }}>
                                                </Col>
                                            </Row>
                                        </li>
                                    })
                                }
                            </ul>
                        }
                        loading={false} />
                })
            }
            isNoBodyContent={this.state.additionalDocuments && this.state.additionalDocuments.length > 0 ? false : true}
        />);
    }

    createRightPanel = (enableSignatureOption: boolean, ControlBoxItems: IEfileSignControlBoxItem[]) => {
        return (<>
            {
                enableSignatureOption &&
                <EfileSignatureControlCard
                    title="e-Signatures"
                    selectedSigner={this.state.selectedSigner}
                    signer={this.state.signer}
                    signerChangeHandler={this.signerChange}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    ControlBoxItems={ControlBoxItems}
                    onSignatureControlFocus={this.onSignatureControlFocus}
                    dragStart={this.dragStartSignatureControl}
                    dragEnd={this.dragEnd}
                    keyPrefix={"additional-efile-right-panel-control-"}
                />
            }
            {
                this.props.userSettings.settings.useSignatureStamp &&
                <EROSignatureCard
                    title="ERO Signature Stamp"
                    selectedEROSigner={this.state.selectedEROSigner}
                    eroSigner={this.state.eroSigners}
                    onEROSignerChange={this.handleEROSignerChange}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    onSignatureControlFocus={this.onSignatureControlFocus}
                    dragStart={this.dragStartSignatureControl}
                    dragEnd={this.dragEnd}
                />
            }
        </>)
    }

    createControlBoxItems = () => {
        const engagementType = Number(TaxDocument.EngagementType[this.props.taxReturn.engagementType]);
        const ControlBoxItems: IEfileSignControlBoxItem[] = [];
        if (Helper.isIndividualEnagagementType(engagementType)) {
            ControlBoxItems.push(
                {
                    caption: 'Add Signature Field',
                    icon: 'fas fa-signature',
                    controlType: TaxDocument.SignatureControlTypes.Signature,
                    helpText: EsignHelpTextConstant.ClientSignature
                },
                {
                    caption: 'Add Date Signed Field',
                    icon: 'fas fa-calendar-alt',
                    controlType: TaxDocument.SignatureControlTypes.Date,
                    helpText: EsignHelpTextConstant.SignatureDate
                },
                {
                    caption: 'Add Print Name Field',
                    icon: 'fas fa-user-circle',
                    controlType: TaxDocument.SignatureControlTypes.PrintName,
                    helpText: EsignHelpTextConstant.PrintName
                },
                {
                    caption: 'Add Title Field',
                    icon: 'fas fa-address-card',
                    controlType: TaxDocument.SignatureControlTypes.Title,
                    helpText: EsignHelpTextConstant.Title
                });
        }
        else {
            ControlBoxItems.push(
                {
                    caption: 'Add Signature Field',
                    icon: 'fas fa-signature',
                    controlType: TaxDocument.SignatureControlTypes.Signature,
                    helpText: EsignHelpTextConstant.ClientSignature
                },
                {
                    caption: 'Add Date Signed Field',
                    icon: 'fas fa-calendar-alt',
                    controlType: TaxDocument.SignatureControlTypes.Date,
                    helpText: EsignHelpTextConstant.SignatureDate
                },
                {
                    caption: 'Add Name & Title Field',
                    icon: 'fas fa-address-card',
                    controlType: TaxDocument.SignatureControlTypes.NameAndTitle,
                    helpText: EsignHelpTextConstant.NameAndTitle
                });
        }

        ControlBoxItems.push(
            {
                caption: 'Add Initials Field',
                icon: 'fas fa-font',
                controlType: TaxDocument.SignatureControlTypes.Initial,
                helpText: EsignHelpTextConstant.Initial
            },
            {
                caption: 'Add Company Field',
                icon: 'fas fa-building',
                controlType: TaxDocument.SignatureControlTypes.Company,
                helpText: EsignHelpTextConstant.Company
            },
            {
                caption: 'Text',
                icon: 'fas fa-text-width',
                controlType: TaxDocument.SignatureControlTypes.Text,
                helpText: EsignHelpTextConstant.Text
            },
            {
                caption: 'Check Box',
                icon: 'fas fa-check-square',
                controlType: TaxDocument.SignatureControlTypes.CheckBox,
                helpText: EsignHelpTextConstant.Checkbox
            },
            {
                caption: 'Radio Button',
                icon: 'fas fa-dot-circle',
                controlType: TaxDocument.SignatureControlTypes.RadioButton,
                helpText: EsignHelpTextConstant.RadioButton
            }
        );
        return ControlBoxItems;
    }

    createPages = () => {
        const pages: number[] = [];
        if (this.state.selectedESignDocument &&
            this.state.selectedESignDocument.pdf &&
            this.state.selectedESignDocument.pdf.getLastPage()) {
            const pageCount = this.state.selectedESignDocument.pdf.getLastPage();
            for (var i = 0; i < pageCount; i++) {
                pages.push(i + 1);
            }
        }
        return pages;
    }

    onCurrentPageChange=(page:number)=>{
        this.setState({ currentPage: page });
    }

    render() {
        const { taxReturn } = this.props;
        const ControlBoxItems = this.createControlBoxItems();

        let signatureType: any = taxReturn.documentSettings?.documentSignatureSetting.signatureFormSelectionType;
        signatureType = (typeof signatureType == "number") ? TaxDocument.SignatureType[signatureType] : signatureType;
        let enableSignatureOption: boolean = true;

        if (signatureType == TaxDocument.SignatureType[TaxDocument.SignatureType.ManualSign]) {
            enableSignatureOption = false;
        }
        const pages = this.createPages();

        return (<>
            <AwesomePdfViewerUrl  ref={(ref: any) => this._pdfViewer = ref}
                leftPanel={this.createLeftPanel()}
                rightPanel={this.createRightPanel(enableSignatureOption, ControlBoxItems)}
                currentPage={this.state.currentPage}
                pages={pages}
                pdfUrl={this.state.selectedESignDocument &&
                    this.state.selectedESignDocument.pdf &&
                    this.state.selectedESignDocument.sasUrl}
                enableMoveTo={false}
                signatureControls={this.state.selectedESignDocument &&
                    this.state.selectedESignDocument.pdf &&
                    (enableSignatureOption ? this.getDocumentControls() : this.getEroSignatureControls())
                }
                isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                isIndividual={TaxDocument.isIndividual(this.props.taxReturn)}
                keyPrefix={"additional-efile-control-"}
                onControlRemove={this.onControlRemove}
                onControlUpdate={this.onControlUpdate}
                scale={this.state.scale}
                onScaleChange={(s) => this.setState({ scale: s })}
                onCurrentPageChange={(page) => this.onCurrentPageChange(page)}
                onControlLoad = {this.onControlLoad}
                showControlPropertyPopup={this.showControlPropertyPopup}
            />
            {this.createUploadAdditionalEsignDocumentModal()}
            {this.createEditAdditionalEsignDocument()}
            {this.createSignerTooltipPopup()}
        </>);
    }

    createUploadAdditionalEsignDocumentModal = () => {
        return (this.state.showUploadModal == true && <UploadAdditionalEsignDocumentModal showUploadModal={this.state.showUploadModal}
            onCloseModal={this.onCloseModal}
            taxReturn={this.props.taxReturn}
            documentTypes={this.state.documentTypes}
            getUploadLink={this.props.getAddtionalEsignDocumentUploadLink}
            maxFileLimitUpload={30}
            onSubmit={this.onSubmit}
            deleteUploadedAdditionalDocumentBlobFile={this.props.deleteUploadedAdditionalDocumentBlobFile}
            userProfile={this.props.userProfile}
            convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
            additionalDocumentTypeData={this.props.additionalDocumentTypeData}
        />);
    }

    createEditAdditionalEsignDocument = () => {
        return (this.state.showEditDocumentModal == true && <EditAdditionalEsignDocument documentTypes={this.state.documentTypes}
            selectedDocumentType={this.state.currentDocumentType}
            show={this.state.showEditDocumentModal}
            onCloseModal={this.onCloseEditDocumentModal}
            onSave={this.onSaveEditDocumentChanges}
            onDelete={this.onDeleteDocument} />);
    }

    createSignerTooltipPopup = () => {
        return (<SignerTooltipPopup
            control={this.state.toolTipControl}
            showState={this.state.showToolTipPopup}
            onHide={this.onCancelToolTipPopup}
            submitButtonClick={this.onSaveToolTip}
            initialControl={initialControl}
            content={this.state.content}
            signatureControlType={this.state.signatureControlType}
        />);
    }
}
