import * as React from "react";
import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { VenusNotifier } from "../../helper/VenusNotifier";
import * as Constants from "../../helper/Constants";
import { IGroupSettingsModel } from "../../../store/groupedreturns/GroupedReturnProcessState";
import SendGroupReminderNow from "../../common/GroupLevelActions/SendGroupReminderNow";
import { GroupedReturnVoucherReminder } from "../../common/GroupLevelActions/GroupedReturnVoucherReminder";
import { IDeliveredTaxReturns } from "../../common/DeliveredTaxReturns";

interface IGroupReminderModalProps {
    show: boolean;
    onHide: () => void;
    onSendReminderNow: (documentId: number, groupId: number) => void;
    onSaveAutomatedReminder: (
        id: number,
        allowVoucherReminder: boolean,
        voucherReminderInDays: number,
        allowSigningReminder: boolean,
        signingReminderInDays: number
    ) => void;
    groupSettings: IGroupSettingsModel;
    model: IDeliveredTaxReturns;
}

const GroupReminderModal: FC<IGroupReminderModalProps> = ({
    show,
    onHide,
    onSendReminderNow,
    onSaveAutomatedReminder,
    groupSettings,
    model
}) => {
    const [key, setKey] = useState<string>("scheduled_automated_reminder");

    const [signingReminder, setSigningReminder] = useState<boolean>(false);

    const [allowVoucherReminder, setallowVoucherReminder] = useState<boolean>(false);
    const [allowSigningReminder, setallowSigningReminder] = useState<boolean>(false);
    const [voucherReminderInDays, setvoucherReminderInDays] = useState<number>(0);
    const [signingReminderInDays, setsigningReminderInDays] = useState<number>(0);

    const handleVoucherReminderChange = (allowVoucherReminder: boolean) => {
        setallowVoucherReminder(allowVoucherReminder);
    };
    const handleSigningReminderChange = (allowSigningReminder: boolean) => {
        setallowSigningReminder(allowSigningReminder);
    };
    const handleVoucherReminderInDaysChange = (voucherReminderInDays: number) => {
        setvoucherReminderInDays(voucherReminderInDays);
    };
    const handleSigningReminderInDaysChange = (signingReminderInDays: number) => {
        setsigningReminderInDays(signingReminderInDays);
    };

    useEffect(() => {
        handleVoucherReminderChange(
            groupSettings?.groupNotificationSettings.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder
        );
        handleVoucherReminderInDaysChange(
            groupSettings?.groupNotificationSettings.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder < 1
                ? 1
                : groupSettings?.groupNotificationSettings.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder
        );
        handleSigningReminderChange(
            groupSettings?.groupNotificationSettings.signingReminderNotificationSettingsModel.enableSigningReminder
        );
        handleSigningReminderInDaysChange(
            groupSettings?.groupNotificationSettings.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder < 1
                ? 1
                : groupSettings?.groupNotificationSettings.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder
        );
    }, [model]);

    useEffect(() => {
        if (!show) {
            setKey("scheduled_automated_reminder");
            setSigningReminder(false);
        }
    }, [show]);

    const handleSend = () => {
        if (key === "send_reminder_now") {
            onSendReminderNow(model.id, model.groupId);
        } else {
            let isValid: boolean = true;
            if (allowVoucherReminder && voucherReminderInDays == null) {
                VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNumberofDaysVoucherReminder, null);
                isValid = false;
            }
            if (allowSigningReminder && signingReminderInDays == null) {
                VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNumberofDaysSigningReminder, null);
                isValid = false;
            }
            if (isValid) {
                onSaveAutomatedReminder(
                    groupSettings.groupId,
                    allowVoucherReminder,
                    voucherReminderInDays,
                    allowSigningReminder,
                    signingReminderInDays
                );
            }
        }
    };

    return (
        <Modal show={show} className="reminder-modal" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Send Reminder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    className="custom-tab"
                    fill
                    id="send_reminder"
                    activeKey={key}
                    onSelect={(key) => setKey(key || "send_reminder_now")}
                >
                    <Tab
                        eventKey="send_reminder_now"
                        title={
                            <div>
                                <i className="fas fa-paper-plane"></i>
                                <span className="ml-2">Send Reminder Now</span>
                            </div>
                        }
                    >
                        {<SendGroupReminderNow signingReminder={signingReminder}
                            model={model}
                            isAutomatedSigningReminderEnabled={groupSettings?.groupNotificationSettings.signingReminderNotificationSettingsModel.enableSigningReminder }
                            setSigningReminder={setSigningReminder} />}
                    </Tab>
                    <Tab
                        eventKey="scheduled_automated_reminder"
                        title={
                            <div>
                                <i className="fas fa-calendar-check"></i>
                                <span className="ml-2">Scheduled Automated Reminder</span>
                            </div>
                        }
                    >
                        <GroupedReturnVoucherReminder
                            groupSettings={groupSettings}
                            model={model}
                            handleVoucherReminderChange={handleVoucherReminderChange}
                            handleSigningReminderChange={handleSigningReminderChange}
                            handleVoucherReminderInDaysChange={handleVoucherReminderInDaysChange}
                            handleSigningReminderInDaysChange={handleSigningReminderInDaysChange}
                        />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={onHide}>
                    <i className="fas fa-times"></i> Cancel
                </Button>
                <Button variant="info" onClick={handleSend} disabled={key == "send_reminder_now" && !signingReminder}>
                    {key == "send_reminder_now" ? (
                        <>
                            <i className="fas fa-paper-plane"></i>Send
                        </>
                    ) : (
                        <>
                            <i className="fas fa-save"></i>Save
                        </>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GroupReminderModal;
